import { useEffect, useState, useContext, useRef } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import UserDashboard from "./components/dashboard";
import { LoginService, OpenAPI } from "@propertelligent/client-api";
import { ForgetPassword } from "./components/user/ForgetPassword";
import CheckEmail from "./components/user/CheckEmail";
import { GeneratePdfDocument } from "./components/generatePdfDocument";
import ResetPassword from "./components/user/ResetPassword";
import { MyContext } from "./utils/context/LoginContext";
import { UserLoginData } from "./components/common/charts/Enums";
import { Login } from "./components/user/Login";
import Signup from "./components/user/Signup";
import { BASE_API_URL, MAIN_ROUTES_URLS, URLS, USER_ROLES, USERS } from "./constants/constants";
import { useDispatch } from "react-redux";
import GmailAuthReturn from "./components/Messaging/Email/components/GmailAuthRetrun";
import NewQuestionnaire from "./components/user/NewQuestionnaire";
import CustomMenu from "./layout/CustomMenu";
import CustomTopBar from "./layout/CustomTopBar";
import TenantDashboard from "./tenantComponent/components/dashboard";
import { MenuContext } from "./utils/context/MenuContext"; // Import the MenuContext
import { NotificationPayload, NotificationType, PubSub, TopicsEnum } from "./utils/pubsub";
import Notification from "./components/common/Notification/Notification";
import { GenerateEmail } from "./components/generateEmail";
import { MyOrganization } from "./components/my-organization";
import TenantOnboard from "./components/TenantOnboard";
import InviteUser from "./components/user/inviteUser";

export default function App(): JSX.Element | any {
  const [showNotification, setShowNotification] = useState(false);
  const [isGenerateView, setIsGenerateView] = useState(false);
  const [notificationPayload, setNotificationPayload] =
    useState<NotificationPayload>({ type: NotificationType.None, message: "" });
  const { isLogin, setIsLogin, userRole } = useContext(MyContext);

  const { isOpen, setIsOpen, isHidden, setIsHidden } = useContext(MenuContext); // Use MenuContext
  const location = useLocation();

  const userRoleId = localStorage.getItem("userRoleId");

  const userOnboarded = localStorage.getItem("userOnboarded");
  const tenantData = JSON.parse(localStorage.getItem("tenantInfo"));

  const size = window.innerWidth;
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const loginToken = localStorage.getItem(UserLoginData.token);

  const triggerNotification = (data: NotificationPayload) => {
    setNotificationPayload(data);
    setShowNotification(true);
    window.setTimeout(() => {
      setShowNotification(false);
    }, 4000);
  };

  useEffect(() => {
    const currentLink = window.location.href;

    if (currentLink.includes("localhost")) {
      OpenAPI.BASE = BASE_API_URL;
    }

    if (
      currentLink.includes("generatePdfDocument") ||
      currentLink.includes("generateEmail")
    ) {
      setIsGenerateView(true);
    }

    PubSub.subscribe(
      TopicsEnum.Notification.toString(),
      (data: NotificationPayload) => {
        triggerNotification(data);
      }
    );

    PubSub.subscribe(
      TopicsEnum.Logout.toString(),
      (data: NotificationPayload) => {
        LoginService.putApiLoginLogout();
        triggerNotification(data);
        localStorage.clear();
        setIsLogin(false);
        setTimeout(() => {
          navigate(URLS.LOGIN, {
            state: {
              isComingFromLogout: true,
            },
          });
          navigate(URLS.HOME);
        }, 5000);
      }
    );
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth > 600);
      setIsHidden(window.innerWidth < 400);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setIsOpen, setIsHidden]);

  useEffect(() => {
    setIsLogin(loginToken);
    OpenAPI.TOKEN = loginToken;
  }, [loginToken, setIsLogin]);

  useEffect(() => {
    // Add event listener for clicks outside the menu
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleClickOutside = (event) => {
    const size = window.innerWidth;

    if (menuRef.current && !menuRef.current.contains(event.target)) {
      // Clicked outside of the menu, so collapse it

      if (size < 400) {
        setIsHidden(true);
      } else if (size < 600) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    if (location.pathname === MAIN_ROUTES_URLS.ORGANIZATION) {
      setIsOpen(false);
      setIsHidden(true);
    } else {
      setIsOpen(size > 600);
      setIsHidden(size < 400);
    }
  }, [location.pathname, setIsOpen, setIsHidden, size]);

  const cleanPathname = location.pathname.replace(/^\/+|\/+$/g, "");
  const isOrganizationPath = cleanPathname.startsWith("organization");
  const generatePdf = cleanPathname.startsWith("generatePdfDocument");

  return (
    <>
      {isLogin && isLogin ? (
        <>
          {USERS.USER === USER_ROLES[userRoleId] && (

            <>
              {
                generatePdf ?
                  <Routes>
                    <Route
                      path={URLS.GENERATE_PDF_DOCUMENT}
                      element={<GeneratePdfDocument />}
                    />
                  </Routes>
                  :
                  (
                    <>
                      {userOnboarded === "true" && (
                        <CustomTopBar size={size} userRoleId={userRoleId} showUserName={true} />
                      )}

                      <div className="menuBarContainer" style={{ display: "flex" }}>
                        {userOnboarded === "true" && (
                          <div
                            ref={menuRef}
                            style={{ top: size < 400 ? 0 : 48 }}
                            className={
                              isHidden
                                ? "hiddenMenu"
                                : isOpen
                                  ? isOrganizationPath
                                    ? "openedMenuOrganization"
                                    : "openedMenu"
                                  : "closedMenu"
                            }
                          >
                            {!isOrganizationPath ? (
                              <CustomMenu
                                size={size}
                                userRoleId={parseInt(userRoleId)}
                              />
                            ) : (
                              <MyOrganization />
                            )}
                          </div>
                        )}
                        <div
                          className={`${userOnboarded === "false"
                            ? "hiddenMenuRightSide"
                            : isOpen
                              ? isOrganizationPath
                                ? "openedMenuFullWidth"
                                : "openedMenuRightSide"
                              : "closeMenuRightSide"
                            }`}
                        >
                          <Routes>
                            {/* <Route
                      path={URLS.GENERATE_PDF_DOCUMENT}
                      element={<GeneratePdfDocument />}
                    /> */}
                            <Route
                              path={URLS.USER_DASHBOARD}
                              element={
                                <UserDashboard userRoleId={parseInt(userRoleId)} />
                              }
                            />
                            <Route
                              path="/callback/gmail"
                              element={<GmailAuthReturn />}
                            />
                            <Route
                              path={MAIN_ROUTES_URLS.HOME}
                              element={
                                <UserDashboard userRoleId={parseInt(userRoleId)} />
                              }
                            />
                            <Route
                              path={URLS.QUESTIONNAIRE}
                              element={<NewQuestionnaire />}
                            />
                            {isOrganizationPath && (
                              <Route
                                path={MAIN_ROUTES_URLS.ORGANIZATION}
                                element={<MyOrganization />}
                              />
                            )}
                          </Routes>
                        </div>
                      </div>
                    </>
                  )
              }

            </>
          )}
          {USERS.TENANT === USER_ROLES[userRoleId] && (
            <>
              <CustomTopBar size={size} userRoleId={userRoleId} showUserName={true} />
              {!tenantData?.propertyId && !tenantData?.unitId ? (
                <Routes>
                  <Route
                    path={MAIN_ROUTES_URLS.HOME}
                    element={<TenantOnboard userRoleId={Number(4)} />}
                  />
                </Routes>
              ) : (
                <div className="menuBarContainer" style={{ display: "flex" }}>
                  <div
                    ref={menuRef}
                    style={{ top: size < 400 ? 0 : 48 }}
                    className={
                      isHidden
                        ? "hiddenMenu"
                        : isOpen
                          ? "openedMenu"
                          : "closedMenu"
                    }
                  >
                    <CustomMenu size={size} userRoleId={parseInt(userRoleId)} />
                  </div>

                  <div
                    className={
                      isOpen ? "openedMenuRightSide" : "closeMenuRightSide"
                    }
                  >
                    <Routes>
                      <Route
                        path={MAIN_ROUTES_URLS.HOME}
                        element={
                          <TenantDashboard userRoleId={parseInt(userRoleId)} />
                        }
                      />
                    </Routes>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <Routes>
          <Route path={MAIN_ROUTES_URLS.HOME} element={<Login />} />
          <Route
            path={MAIN_ROUTES_URLS.TENANT_ONBOARD}
            element={<TenantOnboard />}
          />
          <Route
            path={MAIN_ROUTES_URLS.INVITE_USER}
            element={<InviteUser />}
          />
          <Route
            path={URLS.RESET_PASSWORD_BY_USERKEY}
            element={<ResetPassword />}
          />
          <Route path={URLS.FORGET_PASSWORD} element={<ForgetPassword />} />
          <Route path={URLS.CHECK_MAIL} element={<CheckEmail />} />
          <Route path={URLS.SIGN_UP} element={<Signup />} />
          <Route path={URLS.GENERATE_EMAIL} element={<GenerateEmail />} />
          {/* <Route
            path={URLS.SIGNUP_AFTER_GOOGLE}
            element={<SignupAfterGoogle />}
          /> */}
        </Routes>
      )}
      {showNotification && (
        <Notification
          payload={notificationPayload}
          setNotification={(value) => {
            setShowNotification(value);
          }}
        />
      )}
    </>
  );
}
