import * as React from "react";
import { SVGProps } from "react";
import logo from "../assets/AppBarLogo.png";
const Logo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img width="100%" src={logo} />
    </div>
  );
};

export default Logo;
