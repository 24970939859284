import { Route, Routes } from "react-router";
import PaymentsView from "./view";
import SettlePayment from "./SettlePayment";
import "./payments.css"
import AddPaymentForm from "./AddPaymentForm";


export const Payment = () => {


  return (
    <Routes>
      <Route index element={<PaymentsView />} />
      <Route path="/settle-payment/:id" element={<SettlePayment />} />
      <Route path="/create" element={<AddPaymentForm />} />
      <Route path="/edit/:id" element={<AddPaymentForm />} />
    </Routes>
  );
};
