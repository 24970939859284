import { TenantService } from "@propertelligent/client-api";
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import mail from "../../assets/mail.png";
import call from "../../assets/call.png";
import user_red from "../../assets/user_red.png";
import Time_Red from "../../assets/Time_Red.png";
import SimpleSpinner from "../loader";

type Props = {
  tenantId: any;
  loading: boolean;
  tenantData: any;
};

const flexContainerStyle = {
  display: "flex",
  padding: "var(--3, 12px)",
  alignItems: "center",
  gap: "var(--2, 8px)",
  alignSelf: "stretch",
  borderRadius: "var(--rounded-lg, 8px)",
  background: "var(--Primary-Next-level-Lighter, #EBF5FF)",
};


const TenantDetails = React.memo(({ tenantId, loading, tenantData }: Props) => {


  return (
    <>
      {tenantData?.id !== undefined ? (
        <div style={flexContainerStyle}>
          <div className="singleTenantNameDetailsLeftSide">
            <div>
              <div className="sub_text fw-600 dark_gray" style={{paddingLeft: "10px"}} >Tenant details </div>

              <div>
                <div className="singleTenantUnitOr d-flex flex-wrap">
                  <span className="normal_text black ms-0">
                    <img height={16} width={16} src={user_red} />

                    <span className="sub_text fw-500 dark_gray" style={{paddingLeft: "10px"}}>
                      {" "}
                      {tenantData?.firstName} {tenantData?.lastName}
                    </span>
                  </span>

                  <span className="normal_text black ms-0">
                    <span style={{ marginRight: 5 }}>
                      <img height={16} width={16} src={Time_Red} />
                    </span>
                    <span className="sub_text fw-500 dark_gray" style={{paddingLeft: "10px"}}>
                      {tenantData?.property?.address?.streetAddress ||
                        tenantData?.property?.name ||
                        tenantData?.tenancySince}

                      {/* Since 3 months{" "} */}
                    </span>
                  </span>

                  <span className="normal_text black ms-0">
                    <img
                      height={16}
                      width={16}
                      src={mail}
                      style={{ marginRight: 5 }}
                    />
                    <span className="sub_text fw-500 dark_gray" style={{paddingLeft: "10px"}}>{tenantData?.email}</span>
                  </span>

                  <span className="normal_text black ms-0">
                    <img
                      height={16}
                      width={16}
                      src={call}
                      style={{ marginRight: 5 }}
                    />
                    <span className="sub_text fw-500 dark_gray" style={{paddingLeft: "10px"}}>{tenantData?.phoneNumber}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        " "
      )}
    </>
  );
});

export default TenantDetails;
