import { useEffect, useState } from "react";
import { Badge, } from "react-bootstrap";
import "./tenantonboard.css";
import { GetImages } from "../../../utils/GetImages";
import signImg from "../../../assets/sign-img.png";
import ApplicationCard from "./ApplicationCard";
import BasicInfo from "./BasicInfo";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import { LeaseApplicationService, QuestionTypeEnum } from "@propertelligent/client-api";
import apiHelper from "../../../utils/apiHelper";
import { OnboardingCreditCheckForm, OnboardingCurrentAddressForm, OnboardingRentIncomeCheckForm, OnboardingSignatureForm } from "../../../typings/onboarding";
import EmptyData from "../../common/EmptyData";
import CustomInputs from "../../common/Inputs";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import RadioButton from "../../common/RadioButton";

const BoardingReview = ({ editable, isEditClick, postData, setPostData, leaseApplication = null, formData, setFormData, steps, landlord = false, setTenantEdit = null, tenantEdit = null }): any => {
  const [leaseApplicationRes, setleaseApplicationRes] = useState<any>()
  const [Userinfo, setUserInfo] = useState({ firstName: "", lastName: "", email: "", phoneNumber: "" })
  const [leaseApplicationData, setleaseApplicationData] = useState({
    CreditCheck: {} as any, CurrentAddress: {} as any, EmploymentHistory: [],
    References: [], RentIncomeCheck: {} as OnboardingRentIncomeCheckForm, SignatureConfirmation: {} as OnboardingSignatureForm, Questionnaire: []
  })

  const [sectionToggles, setSectionToggles] = useState({
    CreditCheck: { approved: true, notes: "" },
    CurrentAddress: { approved: false, notes: "" },
    EmploymentHistory: { approved: true, notes: "" },
    References: { approved: false, notes: "" },
    RentIncomeCheck: { approved: true, notes: "" },
    Questionnaire: { approved: false, notes: "" },
    SignatureConfirmation: { approved: true, notes: "" }
  });

  const fetchLeaseData = () => {
    if (leaseApplication) {
      apiHelper(LeaseApplicationService.getApiLeaseApplication1, { showNotification: false }, leaseApplication)
        .then((response: any) => {
          if (response?.data?.success) {
            setleaseApplicationRes(response?.data?.success?.dataSections)
            setUserInfo(prev => ({ ...prev, firstName: response?.data?.success?.tenant?.firstName, lastName: response?.data?.success?.tenant?.lastName, email: response?.data?.success?.tenant?.email, phoneNumber: response?.data?.success?.tenant?.phoneNumber }))
            setleaseApplicationData(prevFormData => ({
              ...prevFormData,
              CreditCheck: response?.data?.success?.dataSections?.CreditCheck ? JSON.parse(response.data.success.dataSections.CreditCheck) : null,
              CurrentAddress: response?.data?.success?.dataSections?.CurrentAddress ? JSON.parse(response.data.success.dataSections.CurrentAddress) : null,
              EmploymentHistory: response?.data?.success?.dataSections?.EmploymentHistory ? JSON.parse(response.data.success.dataSections.EmploymentHistory) : null,
              References: response?.data?.success?.dataSections?.References ? JSON.parse(response.data.success.dataSections.References) : null,
              RentIncomeCheck: response?.data?.success?.dataSections?.RentIncomeCheck ? JSON.parse(response.data.success.dataSections.RentIncomeCheck) : null,
              Questionnaire: response?.data?.success?.dataSections?.Questionnaire ? JSON.parse(response.data.success.dataSections.Questionnaire) : null,
              SignatureConfirmation: response?.data?.success?.dataSections?.SignatureConfirmation ? JSON.parse(response.data.success.dataSections.SignatureConfirmation) : null,
            }));
            let reviewSections = response?.data?.success?.reviewSections
            setSectionToggles(prevValue => ({
              ...prevValue,
              CreditCheck: { approved: reviewSections?.CreditCheck?.approved || false, notes: reviewSections?.CreditCheck?.notes ? reviewSections?.CreditCheck?.notes : "", },
              CurrentAddress: { approved: reviewSections?.CurrentAddress?.approved || false, notes: reviewSections?.CurrentAddress?.notes ? reviewSections?.CurrentAddress?.notes : "", },
              EmploymentHistory: { approved: reviewSections?.EmploymentHistory?.approved || false, notes: reviewSections?.EmploymentHistory?.notes ? reviewSections?.EmploymentHistory?.notes : "", },
              References: { approved: reviewSections?.References?.approved || false, notes: reviewSections?.References?.notes ? reviewSections?.References?.notes : "", },
              RentIncomeCheck: { approved: reviewSections?.RentIncomeCheck?.approved || false, notes: reviewSections?.RentIncomeCheck?.notes ? reviewSections?.RentIncomeCheck?.notes : "", },
              Questionnaire: { approved: reviewSections?.Questionnaire?.approved || false, notes: reviewSections?.Questionnaire?.notes ? reviewSections?.Questionnaire?.notes : "", },
              SignatureConfirmation: { approved: reviewSections?.SignatureConfirmation?.approved || false, notes: reviewSections?.SignatureConfirmation?.notes ? reviewSections?.SignatureConfirmation?.notes : "", },
            }));
          }
        }).catch((error) => { })
    } else {
      setUserInfo(prev => ({ ...prev, firstName: "Murtaza", lastName: "Abdeali", email: "murtaza.abdeali@gmail.com", phoneNumber: "+1-111-222-3333" }));
      // setleaseApplicationData(prevFormData => ({
      //   ...prevFormData,
      //   CreditCheck: postData?.leaseApplicationTemplateSections?.CreditCheck ?  postData.leaseApplicationTemplateSections?.CreditCheck : null,
      //   CurrentAddress: postData?.leaseApplicationTemplateSections?.CurrentAddress ?  postData.leaseApplicationTemplateSections?.CurrentAddress : null,
      //   EmploymentHistory: postData?.leaseApplicationTemplateSections?.EmploymentHistory ?  postData.leaseApplicationTemplateSections?.EmploymentHistory : null,
      //   References: postData?.leaseApplicationTemplateSections?.References ?  postData.leaseApplicationTemplateSections?.References : null,
      //   RentIncomeCheck: postData?.leaseApplicationTemplateSections?.RentIncomeCheck ?  postData.leaseApplicationTemplateSections?.RentIncomeCheck : null,
      //   Questionnaire: postData?.leaseApplicationTemplateSections?.Questionnaire ?  postData.leaseApplicationTemplateSections?.Questionnaire : null,
      //   SignatureConfirmation: postData?.leaseApplicationTemplateSections?.SignatureConfirmation ?  postData.leaseApplicationTemplateSections?.SignatureConfirmation : null,
      // }));
    }
  }
  useEffect(() => {
    fetchLeaseData()
    if (postData) {
      setleaseApplicationData(prevFormData => ({
        ...prevFormData,
        CreditCheck: {
          socialSecurityNumber: "123-123-1234",
          creditPermission: "yes"
        } as OnboardingCreditCheckForm,
        CurrentAddress: {
          unitNumber: " 205",
          address: { streetAddress: "5250 Truman Dr", city: "Decatur", state: "GA", zip: "30035", },
          movingDate: "Jun 29, 2023",
          movingReason: "Looking for better apartment"
        } as OnboardingCurrentAddressForm,
        EmploymentHistory: Array.from(
          { length: postData?.leaseApplicationTemplateSections?.EmploymentHistory?.requiredRange },
          () => ({
            employerName: "Hill & Associates",
            startDate: "2024-11-30 ",
            endDate: "2024-11-31",
            firstName: "Khurram",
            lastName: "Hameed",
            email: "khurram@gmail.com",
            phoneNumber: "+1-111-222-3333",
          })
        ),
        References: Array.from(
          { length: postData?.leaseApplicationTemplateSections?.References?.requiredRange },
          () => ({
            firstName: "Khurram",
            lastName: "Hameed",
            email: "khurram@gmail.com",
            phoneNumber: "+1-111-222-3333",
            referenceType: "Employer",
          })
        ),
        RentIncomeCheck: {
          payRentVia: "Both",
          employmentVerification: {
            employerName: "Propertelligent",
            phoneNumber: "+1-111-222-3333",
            income: "3000",
            startDate: "Jun 29, 2023",
          },
          contactDetails: {
            firstName: "murtaza",
            lastName: "Abdeali",
            email: "murtaza.abdeali@gmail.com",
            phoneNumber: "+1-111-222-3333",
          },
          socialWorkerDetails: {
            firstName: "Murtaza",
            lastName: "Abdeali",
            email: "murtaza.abdeali@gmail.com",
            phoneNumber: "+1-111-222-3333",
            AuthorityAmount: "4000",
            AuthorityName: "Propertelligent",

          },
        } as OnboardingRentIncomeCheckForm,
        Questionnaire: postData?.leaseApplicationTemplateSections?.Questionnaire?.questions,
        SignatureConfirmation: { signatureName: "Murtaza", signatureDate: "Abdeali", permission: true }
      }))
    }
  }, [postData, leaseApplication, tenantEdit, setSectionToggles]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((preValue) => ({
      ...preValue,
      SignatureConfirmation: { ...preValue.SignatureConfirmation, [name]: value },
    }));
  };

  const handleEditClick = (stepName) => {

    const stepIndex = steps.indexOf(stepName);
    if (stepIndex !== -1) {
      isEditClick(stepIndex);
    } else {
      console.error("Step not found:",);
    }
  };

  function isEmpty(value) {
    if (Array.isArray(value)) { return value.length === 0; }
    else if (typeof value === 'object' && value !== null) { return Object.keys(value).length === 0; } return false;
  }

  const allEmpty = Object.values(leaseApplicationData).every(isEmpty);
  return (
    <>
      <section className="application-review-body">
        <div className="landlordInfo p-2">
          <div className="d-flex gap-2 align-items-center"><BasicInfo editable={editable} data={Userinfo} isEditClick={undefined} /> </div>
          {(editable || landlord) && <AddTraspButton width={0} bname={landlord ? "EditTenant" : "Edit"} onClick={() => landlord ? setTenantEdit(!tenantEdit) : handleEditClick("BasicDetails")} border={false} />}

        </div>
        {allEmpty ? <EmptyData mainText={"Lease Application Data not found"} button={undefined} /> :
          <div className=" d-flex flex-column gap-3">
            {(postData?.leaseApplicationTemplateSections?.CurrentAddress || !leaseApplicationData?.CurrentAddress == null) && (
              <ApplicationCard
                cardHeaderName={"CURRENT ADDRESS"}
                component={<>
                  <div className="current_address">
                    <div className="current_address_Fdetail">
                      <img src={GetImages.homeIconOrange} />
                      <p className="normal_text black">
                        {`${leaseApplicationData?.CurrentAddress?.unitNumber} ,
                     ${leaseApplicationData?.CurrentAddress?.address?.streetAddress},
                     ${leaseApplicationData?.CurrentAddress?.address?.city},
                     ${leaseApplicationData?.CurrentAddress?.address?.state},
                     ${leaseApplicationData?.CurrentAddress?.address?.zip}`}
                      </p>
                    </div>
                    <div className="current_address_Sdetail">
                      <div className="current_address_Sdetail_box">
                        <p className="normal_text fw-bold black">Move out date</p>
                        <p className="normal_text black">{leaseApplicationData?.CurrentAddress?.movingDate} </p>
                      </div>
                      <div className="current_address_Sdetail_box">
                        <p className="normal_text fw-bold black">Reason to move</p>
                        <p className="normal_text black">{leaseApplicationData?.CurrentAddress?.movingReason} </p>
                      </div>
                    </div>
                  </div>
                </>}
                sectionName={"CurrentAddress"}
                handleEditClick={() => handleEditClick("CurrentAddress")}
                leaseApplication={leaseApplication} fetchLeaseData={fetchLeaseData}
                editable={editable} landlord={landlord} sectionToggles={sectionToggles} setSectionToggles={setSectionToggles} />)}
            {(postData?.leaseApplicationTemplateSections?.CreditCheck || leaseApplicationRes?.CreditCheck) && (
              <ApplicationCard
                cardHeaderName={"CREDIT CHECK"}
                component={<>
                  {leaseApplicationData?.CreditCheck == null ? (<EmptyData mainText={"Data not found"} button={undefined} />
                  ) : (
                    <>
                      <div className="creditcheck">
                        <div className="creditcheck_left">
                          <div className="CommonRoundBackground BackgroundlightOrange Icon40">
                            <img src={GetImages.SSN_ORANGE} alt="SSN_ORANGE" />
                          </div>
                          <div>
                            <p className="normal_text fw-bold black"> Social Security Number</p>
                            <p className="normal_text black">{leaseApplicationData?.CreditCheck?.socialSecurityNumber}</p>
                          </div>
                        </div>
                        <div className="creditcheck_right">
                          <p className="normal_text black">
                            Authorization obtained from the tenant to conduct a
                            credit report check.
                          </p>
                          <div className="BackgroundlightBlue CommonSquareBackground p-3">
                            <p className="normal_text">{leaseApplicationData?.CreditCheck?.creditPermission} </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>} sectionName={"CreditCheck"} handleEditClick={() => handleEditClick("CreditCheck")} leaseApplication={leaseApplication} fetchLeaseData={fetchLeaseData} editable={editable} landlord={landlord} sectionToggles={sectionToggles} setSectionToggles={setSectionToggles} />)}
            {(postData?.leaseApplicationTemplateSections?.RentIncomeCheck || leaseApplicationRes?.RentIncomeCheck) && (
              <>
                <ApplicationCard
                  cardHeaderName={"RENT PAY INFORMATION"}
                  component={<>
                    {(leaseApplicationData?.RentIncomeCheck?.payRentVia === "Income" ||
                      leaseApplicationData?.RentIncomeCheck?.payRentVia === "Both") && (
                        <div className="rentpay_information">
                          <div className="rentpay_information_income">
                            <div className="rentpay_information_income_head"><p className="main_heading fw-normal green">Income</p></div>
                            <div className="d-flex justify-content-between">
                              <div className="rentpay_information_income_info gap-2">
                                <div className="rentpay_information_income_info_card "><p className="normal_text black"> Employer Details </p> </div>
                                <div className="rentpay_information_income_info_card">
                                  <p className="heading black">{leaseApplicationData?.RentIncomeCheck?.employmentVerification?.employerName}</p>
                                  <p className="normal_text black"><i>Income: {leaseApplicationData?.RentIncomeCheck?.employmentVerification?.income}$ /m</i></p>
                                  <p className="normal_text black">Since: {leaseApplicationData?.RentIncomeCheck?.employmentVerification?.startDate}</p>
                                </div>
                              </div>
                              <div className="rentpay_information_income_info gap-2">
                                <p className="normal_text black">Contact Details</p>
                                <div className="rentpay_information_income_detail_first">
                                  <p className="sub_heading fw-normal black">
                                    {`${leaseApplicationData?.RentIncomeCheck?.contactDetails?.firstName} ${leaseApplicationData?.RentIncomeCheck?.contactDetails?.lastName}`}
                                  </p>
                                  <p className="normal_text blue">{leaseApplicationData?.RentIncomeCheck?.contactDetails?.email}</p>
                                </div>
                              </div>
                              <p className="sub_heading fw-normal black">{leaseApplicationData?.RentIncomeCheck?.contactDetails?.phoneNumber} </p>
                            </div>
                          </div>
                        </div>)}
                    {(leaseApplicationData?.RentIncomeCheck?.payRentVia === "Assisted" ||
                      leaseApplicationData?.RentIncomeCheck?.payRentVia === "Both") && (
                        <div className="rentpay_information">
                          <div className="rentpay_information_income">
                            <div className="rentpay_information_income_head">
                              <p className="main_heading fw-normal green"> Assisted </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="rentpay_information_income_info gap-2">
                                <div className="rentpay_information_income_info_card ">
                                  <p className="normal_text black"> Authority Name</p>
                                </div>
                                <div className="rentpay_information_income_info_card">
                                  <p className="heading black">Propertelligent</p>
                                  <p className="normal_text black"><i>Amount: $  {leaseApplicationData?.RentIncomeCheck?.socialWorkerDetails?.AuthorityAmount}/m</i> </p>
                                </div>
                              </div>
                              <div className="rentpay_information_income_info gap-2">
                                <p className="normal_text black"> Social Worker Details </p>
                                <div className="rentpay_information_income_detail_first">
                                  <p className="sub_heading fw-normal black">
                                    {`${leaseApplicationData?.RentIncomeCheck?.socialWorkerDetails?.firstName} ${leaseApplicationData?.RentIncomeCheck?.socialWorkerDetails?.lastName}`}
                                  </p>
                                  <p className="normal_text blue"> {leaseApplicationData?.RentIncomeCheck?.socialWorkerDetails?.email}</p>
                                </div>
                              </div>
                              <p className="sub_heading fw-normal black">{leaseApplicationData?.RentIncomeCheck?.socialWorkerDetails?.phoneNumber} </p>
                            </div>
                          </div>
                        </div>)}

                    {leaseApplicationData?.RentIncomeCheck == null && (<EmptyData mainText={"Data not found"} button={undefined} />)}
                  </>} sectionName={"RentIncomeCheck"} handleEditClick={() => handleEditClick("RentIncomeCheck")} leaseApplication={leaseApplication} fetchLeaseData={fetchLeaseData} editable={editable} landlord={landlord} sectionToggles={sectionToggles} setSectionToggles={setSectionToggles} />
              </>)}
            {(postData?.leaseApplicationTemplateSections?.EmploymentHistory || (Array.isArray(leaseApplicationData?.EmploymentHistory) && leaseApplicationData?.EmploymentHistory.length > 0)
            ) && (
                <ApplicationCard
                  cardHeaderName={"EMPLOYMENT HISTORY"}
                  component={<>
                    {leaseApplicationData?.EmploymentHistory?.map((item, i) => (
                      <div key={i} className="Employment_history  ">
                        <div className="">
                          <p className="heading black">{item?.employerName}</p>
                          <p className="normal_text grey"> <i>{`${item?.startDate} - ${item?.endDate}`}</i></p>
                        </div>
                        <div className="">
                          <p className="sub_heading fw-normal black"> {`${item?.firstName} ${item?.lastName} `}</p>
                          <p className="normal_text blue "> {item?.email} </p>
                        </div>
                        <div className=" ">
                          <p className="d-flex sub_heading fw-normal black"> {item?.phoneNumber}</p>
                        </div>
                      </div>
                    ))}
                    {leaseApplicationData?.EmploymentHistory == null && (<EmptyData mainText={"Data not found"} button={undefined} />)}
                  </>} sectionName={"EmploymentHistory"} handleEditClick={() => handleEditClick("EmploymentHistory")} leaseApplication={leaseApplication} fetchLeaseData={fetchLeaseData} editable={editable} landlord={landlord} sectionToggles={sectionToggles} setSectionToggles={setSectionToggles} />
              )}
            {(postData?.leaseApplicationTemplateSections?.References || (Array.isArray(leaseApplicationData?.References) && leaseApplicationData?.References.length > 0)) && (
              <ApplicationCard
                cardHeaderName={"REFERENCES"}
                component={<>
                  {leaseApplicationData?.References?.map((item, i) => (
                    <div key={i} className="Employment_history">
                      <div className="">
                        <p className="normal_text fw-bold black">Type : <span className="normal_text green">{item?.referenceType}</span></p>
                      </div>
                      <div className="">
                        <p className="sub_heading fw-normal black"> {`${item?.firstName} ${item?.lastName} `}</p>
                        <p className="normal_text blue "> {item?.email}  </p>
                      </div>
                      <div className="">
                        <p className="sub_heading fw-normal black">{item?.phoneNumber} </p>
                      </div>
                    </div>
                  ))}
                  {leaseApplicationData?.References == null && (<EmptyData mainText={"Data not found"} button={undefined} />)}
                </>} sectionName={"References"} handleEditClick={() => handleEditClick("References")} leaseApplication={leaseApplication} fetchLeaseData={fetchLeaseData} editable={editable} landlord={landlord} sectionToggles={sectionToggles} setSectionToggles={setSectionToggles} />)}
            {(postData?.leaseApplicationTemplateSections?.Questionnaire || leaseApplicationData?.Questionnaire?.length) && (
              <ApplicationCard
                cardHeaderName={"QUESTIONNAIRE"}
                component={<>
                  <div className="application-card">
                    <div className="question-list">
                      {leaseApplicationData?.Questionnaire?.map(
                        (item, index) => (
                          <div key={index}>
                            {(item?.questionTypeId === QuestionTypeEnum.SingleChoice || item?.questionTypeId === QuestionTypeEnum.MultipleChoice) && (
                              <div className="question-row">
                                <p className="normal_text black fw-500">{item?.questionText}</p>
                                <p className="d-flex gap-1 ">
                                  {item?.choices?.map((subItem, i) => (
                                    <>
                                      {!leaseApplication ? <Badge bg="primary"> {subItem?.optionText}</Badge> : <Badge bg="primary">{subItem?.isRadio ? subItem?.optionText : ""} </Badge>}
                                    </>
                                  ))}
                                </p>
                              </div>
                            )}
                            {item?.questionTypeId === QuestionTypeEnum.SingleChoice && item?.choices?.some(choice => choice.isRadio && choice.optionText === "Yes") && (
                              <div className="BackgroundlightRed py-3 my-3 px-2 rounded-1 ">
                                <p className="normal_text fw-bold black">Additional Details : <span className="normal_text fw-400"><i>{item.followUpQuestionText}</i> </span></p>
                              </div>
                            )}
                            {item?.questionTypeId === QuestionTypeEnum.ShortAnswer && (
                              <div className="answer">
                                <p className="normal_text black fw-500">{item?.questionText}</p>
                                <p className="normal_text">{item?.shortAnswer}</p>
                              </div>
                            )}
                            {item?.questionTypeId === QuestionTypeEnum.Paragraph && (
                              <div className="answer">
                                <p className="normal_text black fw-500">{item?.questionText}</p>
                                <p className="normal_text italic">{item?.paragraph}</p>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </>} sectionName={"Questionnaire"} handleEditClick={() => handleEditClick("Questionnaire")} leaseApplication={leaseApplication} fetchLeaseData={fetchLeaseData} editable={editable} landlord={landlord} sectionToggles={sectionToggles} setSectionToggles={setSectionToggles} />
            )}
            {(postData?.leaseApplicationTemplateSections?.SignatureConfirmation || leaseApplication) && (
              <ApplicationCard
                cardHeaderName={"SIGNATURE"}
                component={<>
                  <div className="signature-card">
                    <p className="fst-italic normal_text black">{postData?.leaseApplicationTemplateSections?.SignatureConfirmation?.clause}</p>
                    <RadioButton label={"I confirm that I have read and reviewed the document."}
                      type="checkbox" value={undefined} checked={formData?.permission}
                      onChange={() => {
                        setFormData(pre => ({ ...pre, permission: !formData?.permission }));
                      }}
                      name={undefined} />
                    {editable && (
                      <>
                        <div className="authority_form">
                          <CustomInputs
                            name="signatureName"
                            type={FORM_CONTROLFIELD_TYPES.TEXT}
                            value={formData?.signatureName}
                            labelText={"Name"}
                            isFormControl={true}
                            onChange={handleChange}
                            placeholder={"Name"}
                            myClassName={"containerTextField"}
                            isError={undefined}
                            errorMessages={undefined} />
                          <CustomInputs
                            name="signatureDate"
                            type={FORM_CONTROLFIELD_TYPES.DATE}
                            value={formData?.signatureDate}
                            labelText={"Date"}
                            isFormControl={true}
                            onChange={handleChange}
                            placeholder={"Select a date"}
                            myClassName={"containerTextField"}
                            isError={undefined}
                            errorMessages={undefined} />
                        </div>
                      </>
                    )}
                    <div className="signature-sign authority_form">
                      <div className="d-flex flex-column ">
                        <div className="d-flex gap-2 ">
                          <img src={signImg} alt="" />
                          <p className="signature">{formData?.signatureName || leaseApplicationData?.SignatureConfirmation?.signatureName} </p>
                        </div>
                        <div className="signature_border"></div>
                      </div>
                      <div className="d-flex flex-column ">
                        <p className="normal_text black py-3">{formData?.signatureDate || leaseApplicationData?.SignatureConfirmation?.signatureDate}</p>
                        <div className="signature_border"></div>
                      </div>
                    </div>
                  </div>
                </>} sectionName={undefined} handleEditClick={undefined} leaseApplication={leaseApplication} fetchLeaseData={fetchLeaseData} editable={false} landlord={false} sectionToggles={sectionToggles} setSectionToggles={setSectionToggles} />
            )}
          </div>
        }

      </section>
    </>
  );
};
export default BoardingReview;
