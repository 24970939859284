import TopFixedBar from "../../common/listPageLayout/TopFixedBar";
import TopFixedSubBar from "../../common/listPageLayout/TopFixedSubBar";
import { Button, Card, Offcanvas } from "react-bootstrap";
import AddServiceRequestForm from "../AddServiceRequestForm";
import {
  DocumentService,
  LookupService,
  LookupTypesEnum,
  PropertyService,
  ServiceRequestService,
  UnitService,
} from "@propertelligent/client-api";
import { useEffect, useState } from "react";
import { UserLoginData } from "../../common/charts/Enums";
import { useDispatch } from "react-redux";
import apiHelper from "../../../utils/apiHelper";

const TopFixedView = ({
  rowCount,
  searchValue,
  setSearchValue,
  handleSort,
  currentSortDirection,
  SelectInitialOptions,
  selectedOption,
  setSelectedOption,
  isSelectDropdown
}) => {
  const dispatch: any = useDispatch();
  const [ServiceTypes, setServiceTypes] = useState([]);
  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //populate service types
   apiHelper(LookupService.getApiLookup,{showNotification:false},LookupTypesEnum.ServiceTypes)
      .then((response: any) => {
        setServiceTypes(response?.data?.success);
      })
      .catch((err) => {
      });
   apiHelper(PropertyService.getApiPropertyOrganizationlookup,{showNotification:false},
      parseInt(localStorage.getItem(UserLoginData.organization_id))
    )
      .then((response: any) => {
        setProperties(response?.data?.success);
      })
      .catch((err) => {
      });
   apiHelper(UnitService.getApiUnitOrganizationlookup,{showNotification:false},
      parseInt(localStorage.getItem(UserLoginData.organization_id))
    )
      .then((response: any) => {
        setUnits(response?.data?.success);
      })
      .catch((err) => {
      });
  }, []);

  const handleSubmitAddServiceRequest = async ({ values, docsArr }) => {
    setIsLoading(true);
   apiHelper(ServiceRequestService.postApiServiceRequest,{successMessage:"Service Added successfully",failureMessage:"Unable to add service",showNotification:true},values)
      .then((servReqRes) => {
        if (servReqRes?.data?.success) {
          if (docsArr?.length > 0) {
            const uploadDocsArr = docsArr?.map(
              ({ _rawFile, ...apiDocObj }: any) =>
                DocumentService.postApiDocument({
                  ...apiDocObj,
                  EntityId: servReqRes?.data?.success?.id,
                })
            );
          }
          setIsLoading(false);
          setShowModel(false);
          //fetchData()
        }
      })
      .catch((errServAdd) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      setServiceTypes([]);
      setProperties([]);
      setUnits([]);
    };
  }, []);

  return (
    <>
      <Offcanvas
        className="offcanvas serviceRequestForm"
        show={showModel}
        onHide={() => setShowModel(false)}
        placement={"end"}
        scroll={true}
      >
        <Offcanvas.Header className="canvasHeader" closeButton>
          <Offcanvas.Title>Add Service Request</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddServiceRequestForm
            ServiceTypes={ServiceTypes}
            properties={properties}
            units={units}
            isLoading={isLoading}
            onClose={() => setShowModel(false)}
            onConfirm={handleSubmitAddServiceRequest}
          />
        </Offcanvas.Body>
      </Offcanvas>
      <TopFixedBar
        rowCount={rowCount}
        rowCountLabel="Service Requests"
        searchPlaceholder="Search by Description"
        addButtonLabel="Add Service Request"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        addCallback={() => setShowModel(true)}
        currentSortDirection={currentSortDirection}
        handleSort={handleSort}
        SelectInitialOptions={SelectInitialOptions}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        isSelectDropdown={isSelectDropdown}

      />
      {!isSelectDropdown &&
        <TopFixedSubBar
          currentSortDirection={currentSortDirection}
          handleSort={handleSort}
        />
      }

    </>
  );
};

export default TopFixedView;
