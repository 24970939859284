import React, { useState, useEffect } from "react";

type LeaseDocProps = {
  httpEntityDocumentInfoFn?: any;
  httpEntityDocumentUploadFn?: any;
  entityId: number | null | undefined;
  entityTypeId?: number | null | undefined;
  documentTypeId?: number | null | undefined;
  label?: string;
  title?: string | null | undefined;
  canUploadDocs: boolean;
  canDisplayDocs: boolean;
};

export const useLeaseDocumentHook = ({
  httpEntityDocumentInfoFn,
  httpEntityDocumentUploadFn,
  entityId,
  entityTypeId,
  documentTypeId,
  label,
  title = null,
  canUploadDocs = false,
  canDisplayDocs = false,
}: LeaseDocProps) => {
  const [downloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [doc, setDoc] = useState(null);
  const [uploadedDocs, setUploadedDocs] = useState([]);

  const fetchEntityDocumentInfo = () => {
    if (entityId && canDisplayDocs) {
      httpEntityDocumentInfoFn(entityId)
        .then((docsRes: any) => {
          setUploadedDocs(docsRes?.success);
        })
        .catch((error: any) => {});
    }
  };

  const handleUploadLeaseDocument = async () => {
    if (doc && entityId) {
      setUploading(true);
      const blob = new Blob([doc], { type: doc?.type });
      const docData = {
        EntityTypeId: entityTypeId,
        EntityId: entityId,
        DocumentTypeId: documentTypeId,
        IsMain: false,
        FileName: doc?.name,
        Document: blob,
      };
      httpEntityDocumentUploadFn(docData)
        .then((docRes: any) => {
          setDoc(null); 
          fetchEntityDocumentInfo();
          setUploading(false);
        })
        .catch((error: any) => {
          setUploading(false); 
        });
    }
  };

  useEffect(() => {
    fetchEntityDocumentInfo();
  }, [entityId]);

  return {
    AllLeaseDocuments: canDisplayDocs ? (
      <>
        {/* Here display File Thumbnail for download purpose */}
        {title && <p style={{ marginTop: "20px" }}>{title}</p>}
        {downloading ? (
          <div style={{ display: "flex", justifyContent: "center" }}></div>
        ) : (
          <p
            style={{
              border: "1px solid gray",
              borderRadius: "8px",
              marginTop: title ? "8px" : "20px",
              padding: "8px",
            }}
          >
            {label && (
              <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>{label}</span>
            )}
          </p>
        )}
      </>
    ) : null,
    UploadLeaseDocument: canUploadDocs ? (
      // Input file component here
      // Have to Restrict file type & File size too
      <p
        id="uploadDoc"
        style={{
          border: "1px solid gray",
          borderRadius: "8px",
          marginTop: title ? "8px" : "20px",
          padding: "8px",
        }}
      >
        {uploading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
          </div>
        ) : (
          <></>
        )}
      </p>
    ) : null,
  };
};
