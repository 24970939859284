import React from 'react'
import aerrow from "../../../assets/upload_file.svg"

const CircularProgress = ({size ,thickness,color,progress,uploadImage}) => {
    const radius =(size -thickness)/2
    const circumference =2*Math.PI*radius;
   
    const dashOffset =circumference-(progress/100)*circumference
  return (
   <svg className="circular-progress" width={size} height={size}>
       <circle className="background" 
       cx={size/2} 
        cy={size/2}
        r={radius}
        strokeWidth={thickness}
        />
         <circle className="progress" 
         cx={size/2} 
        cy={size/2}
        r={radius}
        strokeWidth={thickness}
        strokeDasharray={circumference}
        strokeDashoffset={dashOffset}
        stroke={color}
        />
        <image  className={uploadImage} x="35%" y="30%" xlinkHref= {aerrow} />
   </svg>
  )
}

export default CircularProgress
