import { useState, useEffect } from "react";
import {
  DocumentService,
  LookupService,
  LookupTypesEnum,
  ServiceRequestService,
} from "@propertelligent/client-api";
import { Card, Offcanvas } from "react-bootstrap";
import AddServiceRequestTenant from "./AddServiceRequestTenant";
import MainScrollView from "../../../components/servicerequest/listView/mainScrollView";
import { infiniteScrollHook } from "../../../hooks/infiniteScrollHook";
import apiHelper from "../../../utils/apiHelper";
import AddButtonWithArrow from "../../../components/firstTimeUserExperience/commonCom/AddButtonWithArrow";

const ServiceRequestsView = ({ }) => {
  const [showModal, setShowModal] = useState(false);
  const [ServiceTypes, setServiceTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    rows,
    loading,
    hasMore,
    rowCount,
    fetchData,
    currentSortDirection,
    handleSort,
    currentPage,
  } = infiniteScrollHook({
    httpFetchDataFn: ServiceRequestService.getApiServiceRequestTenantlist,
  });

  useEffect(() => {
    apiHelper(
      LookupService.getApiLookup,
      { showNotification: false },
      LookupTypesEnum.ServiceTypes
    )
      .then((response: any) => {
        setServiceTypes(response?.data?.success);
      })
      .catch((err) => { });
  }, []);

  const handleSubmitAddServiceRequest = async ({ values, docsArr }) => {
    setIsLoading(true);
    apiHelper(
      ServiceRequestService.postApiServiceRequest,
      {
        successMessage: "Service Request Added successfully",
        failureMessage: "Unable to Add Service Request",
        showNotification: true,
      },
      values
    )
      .then((servReqRes) => {
        if (servReqRes?.data?.success) {
          if (docsArr?.length > 0) {
            const uploadDocsArr = docsArr?.map(
              ({ _rawFile, ...apiDocObj }: any) =>
                apiHelper(
                  DocumentService.postApiDocument,
                  { showNotification: false },
                  {
                    ...apiDocObj,
                    EntityId: servReqRes?.data?.success?.id,
                  }
                )
            );
          }
          setIsLoading(false);
          setShowModal(false);
          // fetchData()
        }
      })
      .catch((errServAdd) => {
        console.error(errServAdd);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      setServiceTypes([]);
    };
  }, []);

  return (
    <div className="relativeContainer">
      <div className="tenantsTopBar">
        <div>
          <p className="top_header black">{rowCount} Service request</p>
        </div>
        <div className="rightSideButtonsTenant">
          <AddButtonWithArrow
            buttonname=" Add Service Request"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </div>
      </div>

      <div className="customScrollbarContainer">
        <div
          className="tenantListContainer scrollContainer"
          style={{ height: "calc(100vh - 120px)" }}
        >
          <Card style={{ marginBottom: "8px" }}>
            <MainScrollView
              rows={rows}
              loading={loading}
              hasMore={hasMore}
              fetchData={fetchData}
              currentPage={currentPage}
            />
          </Card>
        </div>
      </div>
      <Offcanvas
        className="offcanvas serviceRequestForm"
        show={showModal}
        onHide={() => setShowModal(false)}
        placement={"end"}
        scroll={true}
      >
        <Offcanvas.Header className="canvasHeader" closeButton>
          <Offcanvas.Title>Add Service Request</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddServiceRequestTenant
            ServiceTypes={ServiceTypes}
            isLoading={isLoading}
            onClose={() => setShowModal(false)}
            onConfirm={handleSubmitAddServiceRequest}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default ServiceRequestsView;
