import React, { useEffect } from 'react';

const LeaseApplication = () => {

  useEffect(() => {{
    //Fire this when the report is created
    //PubSub.publish(TopicsEnum.PDFReportCreated, null);
  }}, []);

  return (
    <div>
      <h1>Lease Application</h1>
      <p>Lease Application Goes Here!</p>
    </div>
  );
};

export default LeaseApplication;