import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../CommanStyles/style.css";

const CustomInputTextArea = ({
  isFormControl = true,
  value = undefined,
  required = false,
  disabled = false,
  label = "",
  placeholder = "",
  type = "text",
  rows = 2,
  name,
  myClassName = "",
  errorMessage = "",
  isError,
  onChange,
  onBlur = () => { },
}: any) => {
  const handleChange = (event: any) => {
    onChange(event);
  };

  let injectErrorClasses = "";

  if (typeof isError == "boolean") {
    injectErrorClasses = isError
      ? "errorBorder errorOutline"
      : "normalBorder normalOutline";
  }

  return (
    <>
      {isFormControl ? (
        <Form.Control
          className={`${injectErrorClasses} ${myClassName}`}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onBlur={onBlur}
          required={required}
          onChange={handleChange}
          disabled={disabled}
          as="textarea"
          rows={rows}
        />
      ) : (
        <input
          type={type}
          name={name}
          value={value}
          required={required}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={onBlur}
          className={`${injectErrorClasses} ${myClassName}`}
          disabled={disabled}
        />
      )}
      {isError && (
        <div className="mt-2">
          <p className="errorMessageStyles">{errorMessage}</p>
        </div>
      )}
    </>
  );
};

export default CustomInputTextArea;
