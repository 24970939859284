import React from 'react'
import { Route, Routes } from "react-router";
import TransactionView from './view';

const Transactions = () => {
    return (
        <Routes>
            <Route index element={<TransactionView />} />
        </Routes>
    )
}

export default Transactions;