import { useNavigate } from 'react-router'
import SelectType from './common/SelectType'

const AddTenant = ({ selectBasic, setSelectBasic }) => {
    const navigate = useNavigate()

    const handleNext = () => {
        navigate('/tenants/create')
    }

    return (
        <SelectType
            selectBasic={selectBasic}
            setSelectBasic={setSelectBasic}
            handleNext={handleNext}
        />
    )
}

export default AddTenant