import {
  CommunicationService,
  IntegrationsService,
} from "@propertelligent/client-api";
import { useEffect, useState } from "react";
import AddGmailAccount from "../Messaging/Email/components/AddGmailAccount";
import Compose from "../Messaging/Email/components/Compose";
import apiHelper from "../../utils/apiHelper";

const SendReminder = () => {
  const [refreshtoken, setRefreshToken] = useState<Boolean>();
  useEffect(() => {
    apiHelper(CommunicationService.getApiGmailHasRefreshToken,{
      showNotification: false,
    })
      .then((res) => {
        if (res?.data?.success == true) {
          setRefreshToken(res?.data?.success);
        }
      })
      .catch((error) => {});
  }, [refreshtoken]);

  return (
    <>
      <div className="p-3 h-100">
        {!refreshtoken ? (
          <AddGmailAccount border={false} />
        ) : (
          <>
            <Compose
              getEmailMessages={undefined}
              replyId={undefined}
              emailId={undefined}
              setReplyId={undefined}
              replytype={undefined}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SendReminder;
