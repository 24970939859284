import EmailHeader from './emailHeader';
import EmailFooter from './emailFooter';

const EmailShell = ({ EmailContentComponent }) => {

  return (
    <div id='emailContainer'>
      <EmailHeader />
        <EmailContentComponent />
      <EmailFooter />
    </div>
  );
};

export default EmailShell;