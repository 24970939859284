import { Row, Col, Button } from "react-bootstrap"
import Time_Red from "../../assets/Time_Red.png"
import Worker from "../../assets/Worker.png"
import Old_Alarm_Timer from "../../assets/Group_1000002919.png"
import Arrow_forward from "../../assets/arrow_forward_400.png"
import InProgressForm from "./inProgressForm"

const InProgressLoader = ({ setMarkCompleted, id, calculateCurrentDate, latestContractor, state, setState, handleMarkCompleteNShowVerify, showBtn, setShowBtn }) => {
	return (
		<>
			<div className="singleServiceNameDetailsLeftSide mb-4">
				<div>
					<Row>
						<Col xs={12}>
							<h4 className="singleTenantDetailsName">Contractor Detail</h4>
							<div className="singleContractorDetails">
								<span className="sub_text black">
									<img height={16} width={16} src={Worker} style={{ marginRight: "4px" }} />
									{latestContractor}
								</span>

								<span className="sub_text black">
									<img height={16} width={16} src={Time_Red} style={{ marginRight: "4px" }} />
									Assigned on : {calculateCurrentDate}
								</span>
							</div>
						</Col>
					</Row>
				</div>
			</div>
			<InProgressForm id={id} calculateCurrentDate={calculateCurrentDate} latestContractor={latestContractor} state={state} setState={setState} handleMarkCompleteNShowVerify={handleMarkCompleteNShowVerify} showBtn={showBtn} setShowBtn={setShowBtn} />
			<div className="container-fluid">
				<Button className="getStartedButton sub_text" variant="light" onClick={handleMarkCompleteNShowVerify}>
					<span>
						Mark as Completed
						<img height={16} width={16} src={Arrow_forward} />
					</span>
				</Button>
			</div>
		</>
	)
}

export default InProgressLoader
