import React, { useState } from "react";
import { ChangeEvent, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { LoginService, OpenAPI } from "@propertelligent/client-api";
import { TopBar } from "../common/TopBar";
import { MyContext } from "../../utils/context/LoginContext";
import { UserLoginData } from "../common/charts/Enums";
import OtpInput from "./OtpInput";
import { URLS, USERS, USER_ROLES } from "../../constants/constants";
import { GetImages } from "../../utils/GetImages";
import "./LoginStyles.css";
import apiHelper from "../../utils/apiHelper";
import CustomInputs from "../common/Inputs";
import { validateEmail, validatePassword } from "../../utils/validateHelper";
import { useError } from "../../utils/context/ErrorContext";
import { loginResponseHandler } from "../../services/login.service";

type Values = {
  email: string;
  password: string;
};

export const Login: React.FC<any> = () => {
  const { isLogin, setIsLogin, setUserRole } = useContext(MyContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [twoFacNotification, setTwoFacNotification] = useState<boolean>(false);
  const [loginFailNotification, setLoginFailNotification] =
    useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { errors, errorMessages, setErrors, setErrorMessages } = useError();

  const [comingFromSignUp, setComingFromSignUp] = useState<boolean>(false);
  const [comingFromLogout, setComingFromLogout] = useState(false);
  const [codeError, setCodeError] = useState<boolean>(false);
  const [resendPassword, setResendPassword] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number>(59);
  const [code, setCode] = useState<string>("");
  const [intervalId, setIntervalId] = useState(null);
  const [checked, setChecked] = useState(true);
  const [historyPageURL, setHistoryPageURL] = useState(
    window?.location?.pathname
  );
  const [isPropertyNotAssigned, setIsPropertyNotAssigned] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const [values, setValues] = useState<Values>({
    email: "",
    password: "",
  });

  const formatTime = (time: number): string => {
    return time < 10 ? `0${time}` : `${time}`;
  };

  useEffect(() => {
    state &&
      state.isComingFromSignUP &&
      setComingFromSignUp(state.isComingFromSignUP);

    if (state && state?.isComingFromLogout) {
      setComingFromLogout(!!state?.isComingFromLogout);
      setHistoryPageURL(URLS.HOME);
    }

    setTimeout(() => {
      setComingFromSignUp(false);
      setComingFromLogout(false);
    }, 3000);
  }, [state]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    setLoginFailNotification(false);

    switch (name) {
      case "email":
        setErrors({ ...errors, email: false });
        setErrorMessages({ ...errorMessages, email: "" });
        break;
      case "password":
        setErrors({ ...errors, password: false });
        setErrorMessages({
          ...errorMessages,
          password: "",
        });
        break;
      default:
        break;
    }

    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const toggleShowPassword = (): void => {
    setShowPassword((prevState) => !prevState);
  };

  const handleForgotPassClick = () => {
    navigate(URLS.FORGET_PASSWORD);
  };

  const startCountdown = () => {
    setSeconds(59);
    const id = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          setResendPassword(true);
          clearInterval(id);
          return prevSeconds;
        } else {
          return prevSeconds - 1;
        }
      });
    }, 1000);
    setIntervalId(id);
  };

  const stopCountdown = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  const handleCodeInputSubmit = (value) => {

    setCodeError(false);
    setCode(value);
    const requestObject = {
      email: values.email,
      password: values.password,
      authenticationCode: value,
    };
    if (value.length == 6) {
      setLoading(true);
      setLoginFailNotification(false);
      // Perform 2FA Login
      apiHelper(
        LoginService.postApiLogin2Fa,
        { showNotification: false },
        requestObject
      )
        .then((res: any) => {

          if (res.data.success) {

            return res;
          } else {
            setLoading(false);
          }
        })
        .then((Newdata) => {

          if (Newdata) {
            localStorage.setItem(
              UserLoginData.token,
              Newdata.data.success.token
            );
            OpenAPI.TOKEN = Newdata.data.success.token;

            localStorage.setItem(
              UserLoginData.user_name,
              Newdata.data.success.firstName +
              " " +
              Newdata.data.success.lastName
            );

            localStorage.setItem(
              UserLoginData.user_id.toString(),
              Newdata.data.success.userId
            );
            localStorage.setItem(
              UserLoginData.organization_id,
              Newdata.data.success.organizationUserInfo.organizationId
            );
            localStorage.setItem(
              UserLoginData.currency_symbol,
              Newdata.data.success.currencySymbol
            );

            localStorage.setItem(
              "userOnboarded",
              Newdata.data.success.userOnboarded
            );
            localStorage.setItem(
              "userRoleId",
              Newdata.data?.success?.userRoleId
            );

            if (
              USER_ROLES[Newdata.data?.success?.userRoleId] === USERS.TENANT
            ) {

              loginResponseHandler(Newdata.data.success);
              localStorage.setItem(
                "tenantInfo",
                JSON.stringify(Newdata.data?.success?.tenantInfo)
              );
            } else if (
              USER_ROLES[Newdata.data?.success?.userRoleId] === USERS.CONTRACTOR
            ) {
              localStorage.setItem(
                "contractorInfo",
                JSON.stringify(Newdata.data?.success?.contractorInfo)
              );
            }
            stopCountdown();

            setIsLogin(true);

            // if (data?.success?.userOnboarded == false) {
            //   navigate(URLS.QUESTIONNAIRE);
            // } else {
            //   navigate(URLS.HOME);
            // }
          }
        })
        .catch((error) => {
          setLoading(false);
          setCodeError(true);
        });
    }
  };

  const goBack = (): void => {
    setLoading(false);
    setLoggedIn(false);
    setResendPassword(false);
  };

  const submitLoginForm = (e) => {
    e.preventDefault();

    const emailError = validateEmail(values, setErrors, setErrorMessages);
    const passwordError = values.password.trim().length === 0;

    setErrors({
      ...errors,
      email: !!emailError,
      password: passwordError,
    });

    // If there are validation errors, stop the submission
    if (emailError || passwordError) {
      setLoginFailNotification(true);
      return;
    }

    setLoading(true);

    apiHelper(
      LoginService.postApiLogin,
      { successMessage: "Login successfully", showNotification: true },
      values
    )
      .then((res) => {
        if (res.data.success.twoFactorRequired) {
          setLoggedIn(true);
          startCountdown();
        } else {
          localStorage.setItem(UserLoginData.token, res.data.success.token);
          OpenAPI.TOKEN = res.data.success.token;
          localStorage.setItem(
            UserLoginData.user_name,
            `${res.data.success.firstName} ${res.data.success.lastName}`
          );
          localStorage.setItem(
            UserLoginData.user_id.toString(),
            res.data.success.userId
          );
          localStorage.setItem(
            UserLoginData.organization_id,
            res.data.success.organizationUserInfo?.organizationId
          );
          localStorage.setItem(
            UserLoginData.currency_symbol,
            res.data.success.currencySymbol
          );
          localStorage.setItem(
            UserLoginData.tenant_id,
            res.data.success.tenantInfo?.tenantId
          );
          localStorage.setItem("userOnboarded", res.data.success.userOnboarded);
          localStorage.setItem("userRoleId", res.data.success.userRoleId);

          const userRole = USER_ROLES[res.data.success.userRoleId];
          setIsLogin(true);
          setUserRole(userRole);

          if (userRole === USERS.TENANT) {
            localStorage.setItem(
              "tenantInfo",
              JSON.stringify(res.data?.success?.tenantInfo)
            );
            if (res.data.success.tenantInfo?.propertyId == null) {


              setIsPropertyNotAssigned(true);
            }
          }

          if (userRole === USERS.USER) {
            if (!res.data.success.userOnboarded) {
              navigate(URLS.QUESTIONNAIRE);
            } else {
              navigate(
                historyPageURL !== URLS.HOME ? historyPageURL : URLS.HOME
              );
            }
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setLoginFailNotification(true);
      });
  };

  const logoutFunction = () => {
    apiHelper(LoginService.putApiLoginLogout, {
      successMessage: "Successfully Logged Out",
      failureMessage: "Unable to logout",
      showNotification: true,
    })
      .then((res) => {
        localStorage.clear();
        setIsLogin(false);
        setIsPropertyNotAssigned(false);
        setTimeout(() => {
          navigate(URLS.HOME, {});
        }, 1000);
      })
      .catch((error) => { });
  };

  return (
    <>

      <div
        className="loginFullScreen"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)), url(${GetImages.background})`,
          paddingBottom: loggedIn || errors.password ? "30px" : "0px",
        }}
      >
        <TopBar
          haveAccount="Don’t have an account?"
          signInUp="Sign up"
          isPropertyNotAssigned={isPropertyNotAssigned}
        />
        <div className=" containerlogincontainer">
          {loggedIn ? (
            // Once User Logs In/ 2 FA authorization
            <div className="codeVerifier">
              <Form
                onSubmit={() => {

                  handleCodeInputSubmit(code);
                }}
              >
                <p className="top_header black mb-2">2 Step Verification</p>

                <p className="normal_text fw-500 grey_subtext1 mb-4">
                  Please enter the 6-digit verification code sent on{" "}
                  {values.email}
                </p>

                <div style={{ marginBottom: codeError ? "8px" : "16px" }}>
                  <OtpInput
                    code={code}
                    setCode={setCode}
                    status={loggedIn}
                    handleCodeInputChange={() => { }}
                    comingFrom="login"
                  />
                </div>
                {codeError && (
                  <p
                    className="errorMessageStyles"
                    style={{
                      marginBottom: "16px",
                    }}
                  >
                    Incorrect code. Please try again
                  </p>
                )}

                <div className="resendPasswordBox">
                  {resendPassword ? (
                    <p className="normal_text grey_subtext1 fw-500">
                      <span className="resendCodeText normal_text blue">Resend code</span>
                    </p>
                  ) : (
                    <p className="normal_text fw-500 grey_subtext1">
                      Resend In
                      <span className="resendCounter normal_text">
                        {formatTime(Math.floor(seconds / 60))}:
                        {formatTime(seconds % 60)}
                      </span>
                    </p>
                  )}
                </div>

                <div className="buttonContaier">
                  <Button
                    onClick={goBack}
                    className="goBackButton normal_text fw-600"
                    variant="outlined"
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    className="submitButton normal_text fw-600"
                    variant="contained"
                  >
                    {loading ? (
                      <img height={50} src={GetImages.loadingGif} />
                    ) : (
                      "Verify Code"
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          ) : isPropertyNotAssigned ? (
            // Before User Logs In
            <div className="loginFormWrapper">
              <div className="onholdWrapper text-center p-3">
                <img src={GetImages.onHold} alt="on hold" />
                <p className="normal_text grey_subtext1 mt-3">
                  You Haven’t Assign to Any Property or Unit Yet By Your
                  Landlord
                </p>
                <div style={{ cursor: "pointer" }}>
                  <p className="normal_text fw-600 blue mt-3" onClick={logoutFunction}>
                    Log Out
                  </p>
                </div>
              </div>
            </div>
          ) : (
            // Before User Logs In
            <div className="loginFormWrapper">
              {/* Login Form */}
              <Form onSubmit={submitLoginForm}>
                <p className="top_header black mb-4">Log in</p>

                {/* Email Input */}
                <CustomInputs
                  labelText="Email"
                  placeholder="Email Address"
                  name="email"
                  required
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  isError={errors.email}
                  onBlur={() =>
                    validateEmail(values.email, setErrors, setErrorMessages)
                  }
                  errorMessages={errorMessages.email}
                />

                {/* Password Input */}
                <CustomInputs
                  labelText="Password"
                  placeholder="Password"
                  name="password"
                  required
                  type="password"
                  value={values.password}
                  isPassword={true}
                  onChange={handleChange}
                  isError={errors.password}
                  onBlur={() =>
                    validatePassword(
                      values.password,
                      setErrors,
                      setErrorMessages
                    )
                  }
                  errorMessages={errorMessages.password}
                />

                {/* Login Fail Notification */}
                {loginFailNotification && (
                  <div className="errorBoxes">
                    <p className="errorMessageStyles">
                      Incorrect email or password
                    </p>
                  </div>
                )}

                {/* Remember me and Forget Password */}
                <div className="rememberAndForgetWrapper">
                  <div className="rememberMeBox">
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    <p className="normal_text grey_subtext1">Remember me</p>
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    <p
                      className="normal_text fw-600 blue"
                      onClick={handleForgotPassClick}
                    >
                      Forgot Password?
                    </p>
                  </div>
                </div>

                {/* Submit Button */}
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading || loggedIn}
                  className="loginButton sub_heading"
                >
                  {loading ? (
                    <img height={50} src={GetImages.loadingGif} />
                  ) : (
                    "Log In"
                  )}
                </Button>
              </Form>
            </div>
          )}

          {/* Redirected from sign up message */}
        </div>
      </div>
    </>
  );
};
