import { useNavigate } from "react-router";
import "../ListView/TemplateListView.css"
import { GetImages } from "../../../../utils/GetImages";
import AddTraspButton from "../../../firstTimeUserExperience/commonCom/AddTraspButton";
import useInfiniteScroll from "react-infinite-scroll-hook";
import SimpleSpinner from "../../../loader";


const TemplateCard = ({
    rows,
    list_Name,
    addNewRedirection,
    editNewRedirection="edit",
    reportType = undefined,
    loading = false,
    fetchData = undefined,
    hasMore = false,
    currentPage = 1,
    isEdit = true,
    isReport = false,
    setReportId = undefined,
    setShowConfirmation = undefined
}) => {

    const navigate = useNavigate();
    const loadMoreRecords = () => {if (!loading) {fetchData(currentPage + 1);}};

    const [sentryRef] = useInfiniteScroll({
        loading: loading,
        hasNextPage: hasMore,
        onLoadMore: loadMoreRecords,
        rootMargin: "0px 0px 100px 0px",
    });

    return (<>
        <div className="Template_Card_Main">
            <div className="Template_Card_Header normal_text">
                <p>{list_Name}</p>

                <AddTraspButton
                    onClick={(e) => {
                        navigate(addNewRedirection)
                        localStorage.setItem("ReportType", reportType)
                    }}
                    bname="Add New"
                    imageValue={GetImages.BlueAdd}
                    iconPosition="right"
                    border={false}
                />

            </div>
            {rows?.map((item, i) => (
                <div key={i} className="Template_Card_List">
                    <div className="Template_Name"
                        onClick={() => {
                            if (isEdit) {
                                navigate(`${editNewRedirection}/${item.id}`)
                            } else {
                                navigate(`/organization/reports/${item.id}`)
                            }
                            localStorage.setItem("ReportType", reportType)
                        }}
                    >
                        <p className="Template_Card_List_Name normal_text">{item.name}</p>
                    </div>
                    {isReport ? (
                        <div className="d-flex gap-3">
                            <img src={GetImages.BlueEditIcon} className="clickable"
                                onClick={() => {
                                    navigate(`edit/${item.id}`)
                                    localStorage.setItem("ReportType", reportType)
                                }}
                            />
                            <img src={GetImages.ReportDeleteIcon} className="clickable"
                                onClick={() => {
                                    setReportId(item.id)
                                    setShowConfirmation(true)
                                }} />
                        </div>
                    ) : <img src={GetImages.OpenEyeIconBlue} />}
                </div>
            ))}
            {loading && (
                <>
                    <SimpleSpinner
                        myClassName={rows?.length == 0 ? "h-50" : "h_70"}
                    />
                </>
            )}
            <div ref={sentryRef} />
        </div>
    </>)
}

export default TemplateCard
