import { useEffect, useState } from "react";
import {
  PaymentMethodEntityTypesEnum,
  PaymentService,
} from "@propertelligent/client-api";
import { UserLoginData } from "../../../components/common/charts/Enums";
import { GetImages } from "../../../utils/GetImages";
import AddTraspButton from "../../../components/firstTimeUserExperience/commonCom/AddTraspButton";
import { Offcanvas } from "react-bootstrap";
import AddEditPaymentModal from "../../../components/my-organization/AddEditPaymentModal";
import NoAccount from "../../components/paymentMethods/NoAccount";
import AccountList from "../AccountList";
import PaymentConfirmation from "../../components/paymentMethods/PaymentConfirmation";
import DateHelper from "../../../utils/DateHelper";
import apiHelper from "../../../utils/apiHelper";
import CommonConfirmation from "../../../components/common/CommonConfirmation";
import AddButtonWithArrow from "../../../components/firstTimeUserExperience/commonCom/AddButtonWithArrow";

const DuePayments = ({
  dueAccountid,
  icon,
  title,
  timeLine,
  amount,
  date,
  data,
  serviceName,
  expiringDate,
  fetchData,
}) => {
  const [id, setId] = useState(null);
  const [mode, setMode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState<any>({
    payDueAccountId: 0,
    amount: 0,
    fromPaymentMethodId: 0,
  });
  const [paymentMethodDetail, setPaymentMethodDetail] = useState({});
  const [selectedAccount, setSelectedAccount] = useState(false);
  const [selectedAccountList, setSelectedAccountList] = useState(null);
  const tenantId = Number(localStorage.getItem(UserLoginData.tenant_id));

  const calculateDaysRemaining = (date: any) => {
    if (!date) {
      return "Invalid date";
    }

    const [month, day, year] = date.split("/");
    // Check if the date parts are valid
    if (!month || !day || !year) {
      return "Invalid date format";
    }
    const formattedDate = `${year}-${month}-${day}`;

    const dueDate: any = new Date(formattedDate);
    dueDate.setHours(0, 0, 0, 0);

    const currentDate: any = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const timeDiff = dueDate - currentDate;
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (daysDiff > 0) {
      return `was due in ${Math.abs(daysDiff)} days`;
    } else if (daysDiff === 0) {
      return `was due Today ago`;
    } else if (daysDiff < 0) {
      return `was due ${Math.abs(daysDiff)} days ago`;
    } else {
      return "due today";
    }
  };

  const formatAccountNumber = (accountNumber: any) => {
    if (!accountNumber || accountNumber.length < 4) {
      return accountNumber;
    }
    return "xxxx xxxx " + accountNumber.slice(-4);
  };

  const addPaymentModalShow = () => {
    setId(null);
    setMode("add");
    setShowAddAccountModal(true);
  };

  const handleAddAccount = () => {
    setId(null);
    setMode("add");
    setShowModal(true);
  };

  const handleConfirmation = () => {
    setShowConfirmationModal(true);
    setShowModal(false);
  };

  const getPaymentMethodTenantList = (tenantId: number) => {
    apiHelper(
      PaymentService.getApiPaymentMethodTenantlist,
      { showNotification: false },
      tenantId
    )
      .then((res: any) => {
        setAccountList(res?.data?.success);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSelectedAccount = (id: any) => {
    setSelectedAccountList(id);
    apiHelper(
      PaymentService.getApiPaymentMethod,
      { showNotification: false },
      id
    )
      .then((res) => {
        setPaymentMethodDetail(res?.data?.success);
      })
      .catch((err) => {
        console.error(err);
      });
    setPaymentDetails({
      payDueAccountId: dueAccountid,
      amount: amount,
      fromPaymentMethodId: id,
    });
    setSelectedAccount(true);
  };

  useEffect(() => {
    getPaymentMethodTenantList(tenantId);
  }, [showModal, showAddAccountModal]);

  const handleDuePayments = () => {
    apiHelper(
      PaymentService.putApiPaymentDueAccountPay,
      {
        successMessage: "Paid Sucessfully",
        failureMessage: "Unable to paid",
        showNotification: true,
      },
      paymentDetails
    )
      .then((res: any) => {
        if (res?.data?.success) {
          setShowModal(false);
          setShowConfirmationModal(false);
          setShowAddAccountModal(false);
          fetchData(1);
          // getDuePaymentData(tenantId)
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Filter the accounts based on type
  const achAccounts = accountList?.filter(
    (account) => account.formatted?.PaymentMethodTypeId === "ACH"
  );
  const creditCardAccounts = accountList?.filter(
    (account) => account.formatted?.PaymentMethodTypeId === "Credit Card"
  );

  return (
    <>
      <div className="duePaymentTransactionRow BorderBottom">
        <div className="firstbox">
          <div className="CommonRoundBackground BackgroundlightRed Icon40">
            <img src={icon} alt="" />
          </div>

          <div className="commonbox">
            <p className="normal_text black">{title}</p>
            <p className="sub_text  grey_subtext1 tenant_timeline">
              {serviceName
                ? serviceName
                : timeLine
                ? calculateDaysRemaining(timeLine)
                : expiringDate
                ? `Expiring on: ${expiringDate} `
                : null}
            </p>
          </div>
        </div>
        <div className="centerCommonBox me-5">
          <div className="d-flex align-items-center gap-1">
            <p className="heading black "> {amount ? `$${amount}` : null}</p>
          </div>
          <p className="sub_text  grey_subtext1">
            {date ? DateHelper.formatDateonly(date) : null}
          </p>
        </div>
        <div className="leftcommonbox tenantBtnContainer">
          <AddTraspButton
            bname="Pay Now"
            iconPosition={undefined}
            imageValue={undefined}
            onClick={addPaymentModalShow}
            border={true}
          />
        </div>
      </div>
      {!showConfirmationModal ? (
        <Offcanvas
          className="editTanentOffcanvas"
          show={showAddAccountModal}
          onHide={() => setShowAddAccountModal(false)}
          placement={"end"}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {achAccounts.length > 0 ||
              (creditCardAccounts.length > 0 && !showModal)
                ? `${title} Payment`
                : achAccounts.length == 0 &&
                  creditCardAccounts.length == 0 &&
                  showModal
                ? "Add Payment Account"
                : null}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {achAccounts.length > 0 ||
            (creditCardAccounts.length > 0 && !showModal) ? (
              <>
                <div className="allAccountList" id="accountList">
                  <AccountList
                    title="ACH Accounts"
                    accountList={achAccounts}
                    formatAccountNumber={formatAccountNumber}
                    handleSelectedAccount={handleSelectedAccount}
                    selectedAccountList={selectedAccountList}
                    radioBtn={true}
                    icon={GetImages.achIcon}
                    onClick={undefined}
                    setId={undefined}
                    setShowConfirmation={undefined}
                    setPaymentMethod={undefined}
                  />
                  <AccountList
                    title="Credit Cards"
                    accountList={creditCardAccounts}
                    formatAccountNumber={formatAccountNumber}
                    handleSelectedAccount={handleSelectedAccount}
                    selectedAccountList={selectedAccountList}
                    radioBtn={true}
                    icon={GetImages.creditCardIcon}
                    onClick={undefined}
                    setId={undefined}
                    setShowConfirmation={undefined}
                    setPaymentMethod={undefined}
                  />
                </div>
                <div className="editFormFooter">
                  <p
                    onClick={() => setShowAddAccountModal(false)}
                    className="normal_text fw-600 blue clickable"
                  >
                    Cancel
                  </p>
                  <AddButtonWithArrow
                    buttonname="Pay"
                    onClick={handleConfirmation}
                    disabled={!selectedAccount}
                    iconDirection="right"
                    Icon={GetImages.WhiteForwardArrow}
                    type="submit"
                  />
                  {/* <button
                    onClick={handleConfirmation}
                    type="submit"
                    className="saveChangesButton1 sub_text fw-600"
                    disabled={!selectedAccount}
                  >
                    Pay
                    <img src={GetImages.WhiteForwardArrow} />
                  </button> */}
                </div>
              </>
            ) : showModal ? (
              <AddEditPaymentModal
                setShowModal={setShowModal}
                mode={mode}
                id={id}
                entityTypeId={PaymentMethodEntityTypesEnum.Tenant}
                entityId={Number(localStorage.getItem(UserLoginData.tenant_id))}
                setShowAddAccountModal={setShowAddAccountModal}
              />
            ) : (
              <NoAccount
                setShowModal={setShowModal}
                handleAddAccount={handleAddAccount}
              />
            )}
          </Offcanvas.Body>
        </Offcanvas>
      ) : (
        <Offcanvas
          className="editTanentOffcanvas"
          show={showAddAccountModal}
          onHide={() => setShowConfirmationModal(false)}
          placement={"end"}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Payment Confirmation</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <PaymentConfirmation
              icon={icon}
              title={title}
              timeLine={timeLine}
              amount={amount}
              date={date}
              calculateDaysRemaining={calculateDaysRemaining}
              setShowConfirmationModal={setShowConfirmationModal}
              formatAccountNumber={formatAccountNumber}
              paymentMethodDetail={paymentMethodDetail}
              handleDuePayments={handleDuePayments}
            />
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default DuePayments;
