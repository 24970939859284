import { SHOW_SUCCESS_ALERT, SHOW_SUCCESS_MEESAGE, OPEN_SIDE_BAR, HIDDEN_SIDE_BAR, LOADING } from "../actions/types";

const initialState = {
    isOpen: window.innerWidth > 600,
    isHidden: window.innerWidth < 400,
    isLoading: false,
}

export const CommanReducer = (state = initialState, action)=>{
    switch (action.type){
    
        case OPEN_SIDE_BAR:
            return {...state, isOpen: action.payload}
        case HIDDEN_SIDE_BAR:
            return {...state, isHidden: action.payload}
        case LOADING:
            return {...state, isLoading: action.payload}

        default:
            return initialState;
    }
}