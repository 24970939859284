import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import SeparaterLine from '../../common/SeparaterLine';
import AddTraspButton from '../../firstTimeUserExperience/commonCom/AddTraspButton';
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow';
import { GetImages } from '../../../utils/GetImages';
import { ReportFilterEntityEnum, ReportTypesEnum } from '@propertelligent/client-api';
import DateHelper from '../../../utils/DateHelper';
import CustomInputs from '../../common/Inputs';
import { useError } from '../../../utils/context/ErrorContext';

interface AddReportProps {
    title: string;
    show: boolean;
    handleClose: () => void;
    selectedValues: string[];
    selectedOption: ReportFilterEntityEnum;
    range: { startDate: string; endDate: string }[];
    reportName: string;
    inputOnchange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleAddReport: (e: React.FormEvent<HTMLFormElement>) => void;
    relativeDateString: string;
}

const AddReport: React.FC<AddReportProps> = React.memo(({
    title,
    show,
    handleClose,
    selectedValues,
    selectedOption,
    range,
    reportName,
    inputOnchange,
    handleAddReport,
    relativeDateString
}) => {
    const reportType = parseInt(localStorage.getItem("ReportType"))
    const { errors, errorMessages, setErrors } = useError()


    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='top_header black'>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className='mb-3'>
                            <div className='d-flex gap-2 align-items-center mt-4'>
                                <SeparaterLine height={"18px"} background={"#111928"} />
                                <p className='text-uppercase sub_text black'>Report name</p>
                            </div>
                        </Form.Label>
                        <CustomInputs
                            type="text"
                            placeholder="Report Name"
                            value={reportName}
                            onChange={inputOnchange}
                            focus={true}
                            required
                            isError={errors.reportName}
                            errorMessages={errorMessages.reportName}
                        />
                    </Form.Group>
                    <div className='d-flex gap-3 flex-wrap add_report_details'>
                        <div className='d-flex gap-1'>
                            <span className='sub_text fw-bold black'>Type :</span>
                            <p className='sub_text black'>
                                {reportType == ReportTypesEnum.Pnl ? 'Profit and loss' : 'Rent Roll'}
                            </p>
                        </div>
                        <div className='d-flex gap-1'>
                            <span className='sub_text fw-bold black'>Duration :</span>
                            <p className='sub_text black'>
                                {relativeDateString !== 'Custom' ? relativeDateString
                                    : `${DateHelper.convertUTCtoDateTimeSimple(range[0].startDate)} - ${DateHelper.convertUTCtoDateTimeSimple(range[0].endDate)}`
                                }
                            </p>
                        </div>
                        <div className='d-flex gap-1'>
                            <span className='sub_text fw-bold black'>Generate report for :</span>
                            <p className='sub_text black'>
                                {selectedOption == ReportFilterEntityEnum.Property ? 'Properties'
                                    : selectedOption == ReportFilterEntityEnum.Unit ? 'Units'
                                        : selectedOption == ReportFilterEntityEnum.Organization ? 'Organization'
                                            : ' '}
                            </p>
                        </div>
                    </div>
                    {Number(selectedOption) !== ReportFilterEntityEnum.Organization &&
                        <div>
                            <div className='d-flex gap-2 align-items-center mt-4 mb-3 add_report_properties'>
                                <SeparaterLine height={"18px"} background={"#111928"} />
                                <p className='text-uppercase sub_text black'>
                                    {selectedOption == ReportFilterEntityEnum.Property ? 'Properties Included'
                                        : selectedOption == ReportFilterEntityEnum.Unit ? 'Units Included'
                                            : ''}
                                </p>
                            </div>
                            <div className='d-flex flex-wrap gap-2'>
                                {selectedValues && selectedValues.map((value, i) => (
                                    <p key={i} className='selectedValueLabel normal_text' style={{ fontSize: "12px" }}>{value}</p>
                                ))}
                            </div>
                        </div>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <AddTraspButton
                    bname={"Cancel"}
                    iconPosition={undefined}
                    imageValue={undefined}
                    onClick={handleClose}
                    border={undefined}
                />
                <AddButtonWithArrow
                    buttonname={"Save"}
                    onClick={handleAddReport}
                    disabled={false}
                    iconDirection={"left"}
                    Icon={GetImages.WhiteForwardArrow}
                />
            </Modal.Footer>
        </Modal>
    )
})

export default AddReport