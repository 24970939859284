import Search from '../../../common/search'

const TemplateMainHeader = ({
  searchTerm=undefined,
  setSearchTerm=undefined,
  headerName,
  placeholder,
  isSearch = false
}) => {
  return (
    <>
      <div className="Template_View_Header">
        <p className="main_heading black">
          {headerName}
        </p>
        {isSearch &&
          <div>
            <Search placeholderName={placeholder} searchValue={searchTerm} searchFilterChange={setSearchTerm} />
          </div>
        }
      </div>
    </>
  )
}

export default TemplateMainHeader