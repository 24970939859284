import { useEffect, useState } from 'react'
import ComponentHeading from '../../common/componentHeading'
import { TenantService } from '@propertelligent/client-api'
import LeaseCards from './LeaseCards'
import DocumentDetails from '../serviceRequest/DocumentDetails'
import apiHelper from '../../../utils/apiHelper'

const LeaseDetails = () => {
    const [leaseData, setLeaseData] = useState<any>({})
    const tenantInfoString = localStorage.getItem("tenantInfo");
    const tenantInfo = JSON.parse(tenantInfoString)
    const tenantId = tenantInfo && tenantInfo.tenantId;

    const getTenantData = (tenantId: number) => {
        apiHelper(TenantService.getApiTenant1, { showNotification: false }, tenantId)
            .then((res: any) => {
                setLeaseData(res?.data?.success)
            }).catch((err) => {
                console.error("error fetching data", err);
            })
    }

    useEffect(() => {
        getTenantData(tenantId)
    }, [])

    return (
        <div className="dashboardContainer">
            <ComponentHeading title="Lease Details" button={false} onClick={undefined} />
            <LeaseCards leaseData={leaseData} />
            <DocumentDetails documentId={leaseData?.currentLeaseInfo?.leaseDocumentId} />
        </div>
    )
}

export default LeaseDetails