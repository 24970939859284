import React, { useEffect, useState } from 'react';
import ReportStatement from '../my-organization/Reports/ReportStatement';
import PrintLayout from './printLayout';

const RentRoll = () => {
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [selectedValues, setSelectedValues] = useState([]);


  useEffect(() => {
    {
      //Fire this when the report is created
      //PubSub.publish(TopicsEnum.PDFReportCreated, null);
    }
  }, []);

  return (
    <>
        <ReportStatement
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          range={range}
          setRange={setRange}
          selectedOption={undefined}
          setSelectedOption={undefined}
          selectedIds={undefined}
          setSelectedIds={undefined}
          relativeDateString={undefined}
          setRelativeDateString={undefined}
        />
    </>
  );
};

export default RentRoll;