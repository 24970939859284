import {

  OPEN_SIDE_BAR,
  HIDDEN_SIDE_BAR,
  LOADING,
} from "./types";


export const setOpenSideBar = (value) => (dispatch) => {
  dispatch({ type: OPEN_SIDE_BAR, payload: value });
};

export const setHiddenSideBar = (value) => (dispatch) => {
  dispatch({ type: HIDDEN_SIDE_BAR, payload: value });
};

export const setIsLoading = (value) => (dispatch) => {
  dispatch({ type: LOADING, payload: value });
};
