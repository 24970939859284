import RequestBreadcrumb from '../../common/RequestBreadcrumb'
import NameAvatarComponent from '../NameAvatarComponent'
import { URLS } from '../../../constants/constants'
import { GetImages } from '../../../utils/GetImages'
import LeaseDetails from '../../common/LeaseDetails'
import MoveOutDetails from './MoveOutDetails'
import FinalPayments from './FinalPayments'
import InstructionsAndInfo from './InstructionsAndInfo'
import { Button } from 'react-bootstrap'
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import { useNavigate } from 'react-router'
import { useState } from 'react'
import { DocumentService } from '@propertelligent/client-api'
import apiHelper from '../../../utils/apiHelper'
import { convertStreamAndDownload } from '../../../utils/helper'
import OrganizationNameHeader from '../../common/OrganizationNameHeader/OrganizationNameHeader'
import CustomDropDownMenu from '../../common/CustomDropDownMenu'


const MoveInAndOutContentBlocks = ({
    userData,
    submitHandler,
    initialOptions,
    formData,
    setFormData,
    pickupInstruction,
    setPickupInstruction,
    additionalInfo,
    setAdditionalInfo,
    isMoveOut = false
}) => {
    const [isUploading, setIsUploading] = useState(false)
    const navigate = useNavigate()

    const moveOutActions = [
        {
            id: 1,
            img: undefined,
            name: "Send Off Boarding Email",
            onClick: undefined,
            linkpath: undefined,
            textClass: "sub_text fw-600 blue",
        },
        {
            id: 2,
            img: undefined,
            name: "Complete Move Out",
            onClick: undefined,
            linkpath: undefined,
            textClass: "sub_text fw-600 blue",
        },
    ];

    const onboardingActions = [
        {
            id: 1,
            img: undefined,
            name: "Send Welcome Email",
            onClick: undefined,
            linkpath: undefined,
            textClass: "sub_text fw-600 blue",
        },
        {
            id: 2,
            img: undefined,
            name: "Complete Onboarding",
            onClick: undefined,
            linkpath: undefined,
            textClass: "sub_text fw-600 blue",
        },
    ];

    const downloadDocument = (id, fileName) => {
        setIsUploading(true);

        apiHelper(DocumentService.getApiDocumentBase641, { showNotification: false }, id)
            .then((docRes) => {
                //Conversion alogorithm
                convertStreamAndDownload(docRes?.data?.success, fileName);
                setIsUploading(false);
            })
            .catch((docErr) => {
                setIsUploading(false);
            });
    };

    return (
        <div className='move_out_container' id="tenantFormContainer">
            <RequestBreadcrumb
                firstName={"Tenant"}
                link={URLS.TENANTS}
                currentStep={isMoveOut ? "Moveout" : "Onboarding"}
            />
            <div className='moveOutContentSection'>
                <OrganizationNameHeader
                    SubHeaderName={isMoveOut ? "Move Out Details" : "Move In Details"}
                    OrgName={undefined}
                    OrgImg={GetImages.GrayFile}
                    element={
                        <>
                            <div className="actionsDropdownContainer clickable">
                                <CustomDropDownMenu
                                    Action={true}
                                    Element={
                                        <img
                                            src={GetImages.BlueChevronDown}
                                            alt="BlueChevronDown"
                                        />
                                    }
                                    data={isMoveOut ? moveOutActions : onboardingActions}
                                />
                            </div>
                        </>
                    }
                />
                <div className='moveOutSectionContainer'>
                    <div className="d-flex newLeftSideBox">
                        <NameAvatarComponent
                            firstName={userData?.firstName}
                            lastName={userData?.lastName}
                            heightWidth={48}
                        />
                        <div>
                            <div className="d-flex align-items-center gap-1">
                                <p className="sub_heading black mb-1">
                                    {userData && `${userData?.firstName} ${userData?.lastName}`}
                                </p>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <img height={16} width={16} src={GetImages.homeIconOrange}></img>
                                <p className="sub_text grey_subtext1">
                                    {userData && userData?.property?.address?.streetAddress}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex gap-3'>
                        <div className="mb-2 d-flex justify-content-start align-items-center gap-2">
                            <img
                                width={18}
                                height={18}
                                src={GetImages.mailIconOrange}
                            />
                            <p className="normal_text black">
                                {userData && userData?.email}
                            </p>
                        </div>

                        <div className=" d-flex justify-content-start align-items-center gap-2">
                            <img
                                width={18}
                                height={18}
                                src={GetImages.phoneIconOrange}
                            />
                            <p className="normal_text black">
                                {userData && userData?.phoneNumber}
                            </p>
                        </div>
                    </div>
                </div>
                <LeaseDetails
                    allLeaseData={undefined}
                    options={undefined}
                    selectedOption={undefined}
                    handleLeaseChange={undefined}
                    userData={undefined}
                    leaseData={[userData && userData?.currentLeaseInfo]}
                    downloadDocument={downloadDocument}
                    isUploading={isUploading}
                    isView={true}
                />

                {isMoveOut && <MoveOutDetails />}

                <form onSubmit={submitHandler}>
                    <FinalPayments
                        initialOptions={initialOptions}
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <InstructionsAndInfo
                        pickupInstruction={pickupInstruction}
                        setPickupInstruction={setPickupInstruction}
                        additionalInfo={additionalInfo}
                        setAdditionalInfo={setAdditionalInfo}
                    />

                    <div className="newTanentBottomBar gap-3">
                        <div>
                            <Button onClick={() => navigate(-1)} className="cancelButtonNewTanentBottomBar sub_text fw-600">
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <AddButtonWithArrow
                                type={"submit"}
                                buttonname={"Send Welcome Email"}
                                disabled={undefined}
                                iconDirection="left"
                                Icon={GetImages.WhiteForwardArrow}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default MoveInAndOutContentBlocks