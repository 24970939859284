import React, { useRef, useState, useEffect } from "react";

const OtpInput = ({
  code,
  setCode,
  status,
  handleCodeInputChange,
  comingFrom,
}) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  useEffect(() => {
    // Focus the first input field when the code state changes
    if (status) {
      inputRefs.current[0].focus();
    }
  }, [status]);

  const handleInputChange = (index, value) => {
    if (/^\d{0,1}$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setCode(newOtp.join(""));

      if (comingFrom === "signup" && newOtp.join("").length === 6) {
        handleCodeInputChange(newOtp.join(""));
      }

      if (value === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain").trim();

    if (/^\d{6}$/.test(pastedText)) {
      const newOtp = pastedText.split("");

      // Directly update the otp state without calling handleInputChange
      setOtp(newOtp);
      setCode(pastedText);

      // Focus the appropriate input field
      newOtp.forEach((digit, index) => {
        if (digit && index < inputRefs.current.length - 1) {
          inputRefs.current[index + 1].focus();
        }
      });

      if (comingFrom === "signup") {
        handleCodeInputChange(pastedText);
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "56px",
        border: "1px solid #E5E7EB",
        borderRadius: "4px",
        maxWidth: 256,
      }}
    >
      {otp.map((digit, index) => (
        <div key={index} style={{ width: "16.66%" }}>
          <input
            key={index}
            ref={(ref) => (inputRefs.current[index] = ref)}
            type="text"
            value={digit}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onPaste={handlePaste}
            onKeyDown={(e) => handleKeyDown(e, index)}
            maxLength={1}
            style={{
              background: "#F9FAFB",
              border: "none",
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "28px",
              width: "100%",
              height: "100%",
              outline: "none",
              boxSizing: "border-box",
              textAlign: "center",
            }}
            placeholder="0"
          />
        </div>
      ))}
    </div>
  );
};

export default OtpInput;
