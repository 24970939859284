
import { useNavigate } from 'react-router-dom';
import rightArrow from "../../assets/chevron_right.png";


const RequestBreadcrumb = ({ firstName, lastName = undefined, link, link1 = undefined, currentStep = "Detail" }) => {
  const navigate = useNavigate();
  return (
    <div className="singleTenantTopbar">
      <div
        onClick={() => {
          navigate(link);
        }}
        className="clickable"
      >
        <p className="normal_text fw-500 black">{firstName}</p>
      </div>
      <img src={rightArrow} />
      {lastName && (
        <>
          <div onClick={() => navigate(link1)} className='clickable'>
            <p className="normal_text fw-500 grey">{lastName}</p>
          </div>
          <img src={rightArrow} />
        </>
      )}
      <p className="normal_text fw-500 grey">{currentStep}</p>
    </div>
  );
};

export default RequestBreadcrumb;
