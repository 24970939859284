import { useEffect, useState } from 'react'
import DisplayNamewithLeftBorder from '../../common/DisplayNamewithLeftBorder'
import { Form } from 'react-bootstrap'
import { GetImages } from '../../../utils/GetImages'
import NewSelectAuto from '../../tenants/NewSelectAuto'
import CustomInputs from '../../common/Inputs'
import { FORM_CONTROLFIELD_TYPES } from '../../../constants/constants'
import { useError } from '../../../utils/context/ErrorContext'
import apiHelper from '../../../utils/apiHelper'
import { CollectionTypesEnum, FeeCommissionTypesEnum, OrganizationService, PaymentService } from '@propertelligent/client-api'
import { UserLoginData } from '../../common/charts/Enums'

const FeeForm = () => {
    const [securityDepositType, setSecurityDepositType] = useState<any>(FeeCommissionTypesEnum.Fixed)
    const [securityDepositTypeError, setSecurityDepositTypeError] = useState<boolean>(false)
    const [securityDepositAccount, setSecurityDepositAccount] = useState<any>(null)
    const [securityDepositAccounttError, setSecurityDepositAccountError] = useState<boolean>(false)
    const [rentDepositAccount, setRentDepositAccount] = useState<any>(null)
    const [rentDepositAccountError, setRentDepositAccountError] = useState<boolean>(false)
    const [commissionType, setCommissionType] = useState<any>(FeeCommissionTypesEnum.Fixed)
    const [commissionTypeError, setCommissionTypeError] = useState<boolean>(false)
    const [rentType, setRentType] = useState<any>(null)
    const [rentTypeError, setRentTypeError] = useState<boolean>(false)
    const [values, setValues] = useState<any>({
        securityDepositamount: "",
        monthsRent: "",
        rentLateFee: "",
        leaseApplicationFee: "",
        commissionAmount: "",
        commissionPercentage: "",
        perUnitAmount: ""
    })
    const [AccountOptions, setAccountOptions] = useState([])
    const { errors, setErrors, errorMessages, setErrorMessages, clearErrors } = useError();
    const organizationId = Number(localStorage.getItem(UserLoginData.organization_id));

    const securityDepositTypeOptions = [
        { id: FeeCommissionTypesEnum.Fixed, name: "Fixed Amount" },
        { id: FeeCommissionTypesEnum.Variable, name: "Variable" },
    ]

    const commissionTypeOptions = [
        { id: FeeCommissionTypesEnum.Fixed, name: "Fixed Amount" },
        { id: FeeCommissionTypesEnum.Variable, name: "Variable" },
        { id: FeeCommissionTypesEnum.PerUnit, name: "Per Unit" },
    ]

    const rentTypeOptions = [
        { id: CollectionTypesEnum.Gross, name: "Gross" },
        { id: CollectionTypesEnum.Net, name: "Net" },
    ]

    const securityDepositAccountOptions = [
        { id: "1", name: "Account 1" },
        { id: "2", name: "Account 2" },
        { id: "3", name: "Account 3" },
        { id: "4", name: "Account 4" },
        { id: "5", name: "Account 5" },
    ]

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value })
        setErrors((prev) => ({ ...prev, [name]: false }));
    }

    const checkEmptyFields = (): boolean => {
        let hasError = false;
        clearErrors();
        const newErrors = { ...errors };
        const newErrorMessages = { ...errorMessages };

        if (!securityDepositType) {
            setSecurityDepositTypeError(true)
        } else {
            setSecurityDepositTypeError(false)
        }

        if (!securityDepositAccount) {
            setSecurityDepositAccountError(true)
        } else {
            setSecurityDepositAccountError(false)
        }

        if (!rentDepositAccount) {
            setRentDepositAccountError(true)
        } else {
            setRentDepositAccountError(false)
        }

        if (!commissionType) {
            setCommissionTypeError(true)
        } else {
            setCommissionTypeError(false)
        }

        if (securityDepositType === FeeCommissionTypesEnum.Fixed) {
            if (values?.securityDepositamount === 0 || values?.securityDepositamount === "") {
                newErrors.securityDepositamount = true;
                hasError = true;
            } else {
                newErrors.securityDepositamount = false;
            }
        } else {
            if (values?.monthsRent === 0 || values?.monthsRent === "") {
                newErrors.monthsRent = true;
                hasError = true;
            } else {
                newErrors.monthsRent = false;
            }
        }

        if (commissionType === FeeCommissionTypesEnum.Fixed) {
            if (values?.commissionAmount === 0 || values?.commissionAmount === "") {
                newErrors.commissionAmount = true;
                hasError = true;
            } else {
                newErrors.commissionAmount = false;
            }
        } else if (commissionType === FeeCommissionTypesEnum.Variable) {
            if (values?.commissionPercentage === 0 || values?.commissionPercentage === "") {
                newErrors.commissionPercentage = true;
                hasError = true;
            } else {
                newErrors.commissionPercentage = false;
            }

            if (!rentType) {
                setRentTypeError(true)
            } else {
                setRentTypeError(false)
            }
        } else {
            if (values?.perUnitAmount === 0 || values?.perUnitAmount === "") {
                newErrors.perUnitAmount = true;
                hasError = true;
            } else {
                newErrors.perUnitAmount = false;
            }
        }

        if (values?.rentLateFee === 0 || values?.rentLateFee === "") {
            newErrors.rentLateFee = true;
            hasError = true;
        } else {
            newErrors.rentLateFee = false;
        }

        if (values?.leaseApplicationFee === 0 || values?.leaseApplicationFee === "") {
            newErrors.leaseApplicationFee = true;
            hasError = true;
        } else {
            newErrors.leaseApplicationFee = false;
        }

        setErrors(newErrors);
        setErrorMessages(newErrorMessages);

        return hasError;
    };

    const getData = () => {
        apiHelper(OrganizationService.getApiOrganizationFeeCommissions, { showNotification: false }, organizationId)
            .then((res) => {
                setSecurityDepositType(res?.data?.success?.securityDepositSetting?.feeTypeId)
                setSecurityDepositAccount(res?.data?.success?.securityDepositSetting?.depositAccountId)
                setRentDepositAccount(res?.data?.success?.rentCollectionSetting?.depositAccountId)
                setCommissionType(res?.data?.success?.pmComissionSetting?.feeTypeId)
                setRentType(res?.data?.success?.pmComissionSetting?.variableRentCollectionTypeId)
                setValues({
                    ...values,
                    securityDepositamount: res?.data?.success?.securityDepositSetting?.fixedAmount,
                    monthsRent: res?.data?.success?.securityDepositSetting?.variableMonths,
                    rentLateFee: res?.data?.success?.rentCollectionSetting?.lateFee,
                    leaseApplicationFee: res?.data?.success?.leasingSetting?.applicationFee,
                    commissionAmount: res?.data?.success?.pmComissionSetting?.fixedAmount,
                    commissionPercentage: res?.data?.success?.pmComissionSetting?.variablePercentage,
                    perUnitAmount: res?.data?.success?.pmComissionSetting?.perUnitAmount
                })
            }).catch((err) => { })
    }

    const getAccountList = () => {
        apiHelper(PaymentService.getApiPayeeAccountOrganizationlist, { showNotification: false },
            organizationId, undefined, undefined, undefined, `IsOrganizationAccount==true`)
            .then((res) => {
                const formattedOptions = res?.data?.success?.map((item: any) => ({
                    id: item?.id,
                    name: item?.accountName, // Map accountName to name
                })) || [];
                setAccountOptions(formattedOptions)
            }).catch((err) => { })
    }

    useEffect(() => {
        getData()
        getAccountList()
        clearErrors()
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (checkEmptyFields()) { }
        else {
            const FeeObj = {
                securityDepositSetting: {
                    feeTypeId: securityDepositType,
                    ...(securityDepositType === FeeCommissionTypesEnum.Variable ? {
                        variableMonths: values?.monthsRent,
                    } : {
                        variableMonths: 0,
                    }),
                    ...(securityDepositType === FeeCommissionTypesEnum.Fixed ? {
                        fixedAmount: values?.securityDepositamount,
                    } : {
                        fixedAmount: 0
                    }),
                    depositAccountId: securityDepositAccount
                },
                rentCollectionSetting: {
                    depositAccountId: rentDepositAccount,
                    lateFee: values?.rentLateFee
                },
                leasingSetting: {
                    applicationFee: values?.leaseApplicationFee
                },
                pmComissionSetting: {
                    feeTypeId: commissionType,
                    ...(commissionType === FeeCommissionTypesEnum.Variable ? {
                        variablePercentage: values?.commissionPercentage,
                        variableRentCollectionTypeId: rentType,
                    } : {
                        variablePercentage: 0,
                        variableRentCollectionTypeId: null,
                    }),
                    ...(commissionType === FeeCommissionTypesEnum.Fixed ? {
                        fixedAmount: values?.commissionAmount,
                    } : {
                        fixedAmount: 0
                    }),
                    ...(commissionType === FeeCommissionTypesEnum.PerUnit ? {
                        perUnitAmount: values?.perUnitAmount,
                    } : {
                        perUnitAmount: 0
                    })
                },
            }
            apiHelper(OrganizationService.putApiOrganizationFeeCommissions,
                {
                    successMessage: "Updated Successfully",
                    failureMessage: "Unable to Update",
                    showNotification: true
                }, organizationId, FeeObj)
                .then((res) => {
                    if (res?.data?.success) {
                        getData()
                    }
                }).catch((err) => { })
        }
    }

    return (
        <Form className='pt-1 feesCommissionForm' onSubmit={handleSubmit}>
            <div className='security_deposit_container'>
                <DisplayNamewithLeftBorder HeaderName="Security Deposit" />
                <div className='mt-3 mb-3 security_deposit_inputs'>
                    <div>
                        <NewSelectAuto
                            disabled={false}
                            setDisabled={undefined}
                            selected={securityDepositType}
                            setSelected={setSecurityDepositType}
                            data={securityDepositTypeOptions}
                            title={undefined}
                            error={securityDepositTypeError}
                            setError={setSecurityDepositTypeError}
                            fromContractor={true}
                            label={undefined}
                        />
                        {securityDepositTypeError && (<p className="errorMessage"> {errorMessages.securityDeposit} </p>)}
                    </div>
                    {securityDepositType === FeeCommissionTypesEnum.Variable ? (
                        <>
                            <CustomInputs
                                name={"monthsRent"}
                                type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                value={values?.monthsRent}
                                isFormControl={true}
                                currencySymbol="$"
                                onChange={handleChange}
                                placeholder="Enter Months"
                                myClassName="normal_text"
                                isError={errors.monthsRent}
                                errorMessages={errorMessages.monthsRent}
                                height={"48px"}
                                currencyHeight={"48px"}
                            />
                            <p className='normal_text grey_subtext1'>Months Rent</p>
                        </>
                    ) : (
                        <CustomInputs
                            name={"securityDepositamount"}
                            type={FORM_CONTROLFIELD_TYPES.NUMBER}
                            value={values?.securityDepositamount}
                            isFormControl={true}
                            currencySymbol="$"
                            onChange={handleChange}
                            placeholder="Enter Amount"
                            myClassName="normal_text"
                            isError={errors.securityDepositamount}
                            errorMessages={errorMessages.securityDepositamount}
                            height={"48px"}
                            currencyHeight={"48px"}
                        />
                    )}
                </div>
                <NewSelectAuto
                    disabled={false}
                    setDisabled={undefined}
                    selected={securityDepositAccount}
                    setSelected={setSecurityDepositAccount}
                    data={AccountOptions}
                    title={undefined}
                    label={"Security Deposit Account *"}
                    error={securityDepositAccounttError}
                    setError={setSecurityDepositAccountError}
                    fromContractor={true}
                />
                {securityDepositAccounttError && (<p className="errorMessage"> {errorMessages.securityDepositAccount} </p>)}
            </div>
            <div className='rent_collection_container'>
                <DisplayNamewithLeftBorder HeaderName="Rent Collection" />
                <div className='mt-4 mb-3 security_deposit_inputs'>
                    <CustomInputs
                        labelText={"Rent Late Fee"}
                        name={"rentLateFee"}
                        type={FORM_CONTROLFIELD_TYPES.NUMBER}
                        value={values?.rentLateFee}
                        isFormControl={true}
                        currencySymbol="$"
                        onChange={handleChange}
                        placeholder="Enter Rent Late Fee"
                        myClassName="normal_text"
                        isError={errors.rentLateFee}
                        errorMessages={errorMessages.rentLateFee}
                        height={"48px"}
                        currencyHeight={"48px"}
                        required
                    />
                    <div>
                        <NewSelectAuto
                            disabled={false}
                            setDisabled={undefined}
                            selected={rentDepositAccount}
                            setSelected={setRentDepositAccount}
                            data={AccountOptions}
                            title={undefined}
                            error={rentDepositAccountError}
                            setError={setRentDepositAccountError}
                            fromContractor={true}
                            label={"Rent Deposit Account *"}
                        />
                        {rentDepositAccountError && (<p className="errorMessage"> {errorMessages.rentDepositAccount} </p>)}
                    </div>
                </div>
            </div>
            <div className='leasing_container mb-3'>
                <DisplayNamewithLeftBorder HeaderName="Leasing" />
                <CustomInputs
                    labelText={"Lease Application Fee"}
                    name={"leaseApplicationFee"}
                    type={FORM_CONTROLFIELD_TYPES.NUMBER}
                    value={values?.leaseApplicationFee}
                    isFormControl={true}
                    currencySymbol="$"
                    onChange={handleChange}
                    placeholder="Enter Fee"
                    myClassName="normal_text"
                    isError={errors.leaseApplicationFee}
                    errorMessages={errorMessages.leaseApplicationFee}
                    height={"48px"}
                    currencyHeight={"48px"}
                    width={"160px"}
                    InputsWrapperClass={"mt-3"}
                    required
                />
            </div>
            <div className='property_management_commission_container'>
                <DisplayNamewithLeftBorder HeaderName="Property Management Commission" />
                <div className='mt-3 mb-3 security_deposit_inputs'>
                    <div>
                        <NewSelectAuto
                            disabled={false}
                            setDisabled={undefined}
                            selected={commissionType}
                            setSelected={setCommissionType}
                            data={commissionTypeOptions}
                            title={undefined}
                            error={commissionTypeError}
                            setError={setCommissionTypeError}
                            fromContractor={true}
                            label={undefined}
                        />
                        {commissionTypeError && (<p className="errorMessage"> {errorMessages.commissionType} </p>)}
                    </div>
                    {commissionType === FeeCommissionTypesEnum.Variable ? (
                        <>
                            <CustomInputs
                                name={"commissionPercentage"}
                                type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                value={values?.commissionPercentage}
                                isFormControl={true}
                                currencySymbol="%"
                                onChange={handleChange}
                                placeholder="Enter Percentage"
                                myClassName="normal_text"
                                isError={errors.commissionPercentage}
                                errorMessages={errorMessages.commissionPercentage}
                                height={"48px"}
                                currencyHeight={"48px"}
                            />
                            <p className='normal_text grey_subtext1'>Per Month of</p>
                            <div>
                                <NewSelectAuto
                                    disabled={false}
                                    setDisabled={undefined}
                                    selected={rentType}
                                    setSelected={setRentType}
                                    data={rentTypeOptions}
                                    title={undefined}
                                    error={rentTypeError}
                                    setError={setRentTypeError}
                                    fromContractor={true}
                                    label={undefined}
                                />
                                {rentTypeError && (<p className="errorMessage"> {errorMessages.rentType} </p>)}
                            </div>
                            <p className='normal_text grey_subtext1'>Rents</p>
                        </>
                    ) : commissionType === FeeCommissionTypesEnum.PerUnit ? (
                        <>
                            <CustomInputs
                                name={"perUnitAmount"}
                                type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                value={values?.perUnitAmount}
                                isFormControl={true}
                                currencySymbol="$"
                                onChange={handleChange}
                                placeholder="Enter Amount"
                                myClassName="normal_text"
                                isError={errors.perUnitAmount}
                                errorMessages={errorMessages.perUnitAmount}
                                height={"48px"}
                                currencyHeight={"48px"}
                            />
                            <p className='normal_text grey_subtext1'>Per Unit</p>
                        </>
                    ) : (
                        <>
                            <CustomInputs
                                name={"commissionAmount"}
                                type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                value={values?.commissionAmount}
                                isFormControl={true}
                                currencySymbol="$"
                                onChange={handleChange}
                                placeholder="Enter Amount"
                                myClassName="normal_text"
                                isError={errors.commissionAmount}
                                errorMessages={errorMessages.commissionAmount}
                                height={"48px"}
                                currencyHeight={"48px"}
                            />
                            <p className='normal_text grey_subtext1'>Per Month</p>
                        </>
                    )}
                </div>
            </div>
            <div className="basic_details_footer">
                <button type="submit" className="saveChangesButton normal_text fw-600">
                    Save Changes <img src={GetImages.WhiteForwardArrow} />
                </button>
            </div>
        </Form>
    )
}

export default FeeForm