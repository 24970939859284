import React, { createContext, useContext, useEffect, useState } from "react";
const PropertyContext = createContext(null);

const PropertyProvider = ({ children }) => {
  const [propertycount, setPropertyCount] = useState(0);

  useEffect(() => {
    const value = localStorage.getItem("propertycount");
    if (value == null) {
      localStorage.setItem("propertycount", JSON.stringify(0));
      setPropertyCount(0);
    } else {
      localStorage.setItem("propertycount", value);
      setPropertyCount(JSON.parse(value));
    }
  }, []);

  return (
    <PropertyContext.Provider value={[propertycount, setPropertyCount]}>
      {children}
    </PropertyContext.Provider>
  );
};

function useProperty() {
  const context = useContext(PropertyContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export { useProperty, PropertyProvider };
