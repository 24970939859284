import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EditCustomComponent from "../tenants/EditCustomComponent";
import { ServiceRequestService } from "@propertelligent/client-api";
import { GetImages } from "../../utils/GetImages";
import apiHelper from "../../utils/apiHelper";
import CommonConfirmation from "../common/CommonConfirmation";
import { validateFirstName, validatePositiveNumber } from "../../utils/validateHelper";
import { useError } from "../../utils/context/ErrorContext";

const EditEstimateForm = ({
  id,
  setShowModal,
  associatedContractors,
  contractorsList,
  serviceReqId,
  handleRemoveContractor,
  handleAddContractor,
  assId,
  handleUpdateContractor,
  showEditConfirmation,
  setShowEditConfirmation,
  getAssociatedContractors,
}) => {
  const { errors, setErrors, errorMessages, setErrorMessages } = useError();
  const dispatch: any = useDispatch();

  const [values, setValues] = useState({
    contractorName: "",
    estimate: "",
    id: null,
  });

  useEffect(() => {
    const contractor = associatedContractors.find(
      (contractor) => contractor?.userId === id
    );
    if (contractor) {
      setValues({
        contractorName: contractor?.contractorName,
        estimate: contractor?.estimatedCost,
        id: contractor?.id,
      });
    }
  }, [id]);

  const formDetails = [
    {
      label: "Contractor Name",
      placeholder: "Enter Contractor Name",
      value: values.contractorName,
      name: "contractorName",
      blurFunction: () => validateFirstName(values.contractorName, setErrors),
      errorMessages: errorMessages.contractorName,
      isError: errors.contractorName,
      disabled: true,
      required: true
    },
    {
      label: "Estimate",
      placeholder: "Enter Estimate",
      value: values.estimate,
      name: "estimate",
      blurFunction: () => validatePositiveNumber(values.estimate, setErrors),
      errorMessages: errorMessages.estimate,
      isError: errors.estimate,
      required: true
    },
  ];

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "contractorName":
        setErrors({ ...errors, contractorName: false });
        setErrorMessages({
          ...errorMessages,
          contractorName: "Contractor Name is required",
        });
        break;
      case "Estimate":
        setErrors({ ...errors, estimate: false });
        setErrorMessages({
          ...errorMessages,
          estimate: "Estimate is required",
        });
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const editSubmitHandler = (e) => {
    e.preventDefault();
  };

  const handleFunction = () => {
    setShowEditConfirmation(true);
  };

  const handleClose = () => {
    setShowEditConfirmation(false);
    setShowModal(false);
  };

  let obj = {
    serviceRequestId: Number(serviceReqId),
    associateContractorId: Number(assId),
  };

  const handleRemove = () => {
    handleRemoveContractor(obj);
    setShowModal(false);
  };

  const associateContractorsToServiceRequest = () => {
    let Obj = {
      id: Number(assId),
      serviceRequestId: Number(serviceReqId),
      userId: Number(id),
      estimatedCost: Number(values.estimate),
      contractorName: values.contractorName,
    };

    apiHelper(
      ServiceRequestService.postApiServiceRequestAssociate,
      {
        successMessage: "Estimate Update successfully",
        failureMessage: "Unable to update estimate",
        showNotification: true,
      },
      Obj
    )
      .then((response: any) => {
        if (response?.data?.success) {
          const updatedContractor = { ...Obj, id: response?.data?.success?.id };
          handleUpdateContractor(updatedContractor);
          getAssociatedContractors();
          handleClose();
        }
      })
      .catch((error) => { });
  };

  return (
    <div>
      <form onSubmit={editSubmitHandler}>
        <div className="editTenantEstimatentainer">
          {formDetails.map((item) => (
            <EditCustomComponent
              key={item.label}
              label={item.label}
              placeholder={item.placeholder}
              value={item.value}
              name={item.name}
              onChangeFunction={handleChange}
              onBlurFunction={item.blurFunction}
              errorMessages={item.errorMessages}
              isError={item.isError}
              disabled={item.disabled}
              required={item.required}
            />
          ))}
        </div>

        <div className="editEstimateFormFooter">
          <p onClick={handleRemove} className="normal_text fw-600 red clickable">
            Remove Contractor
          </p>
          <div className="d-flex justify-content-between align-items-center w-50">
            <p
              onClick={() => setShowModal(false)}
              className="normal_text fw-600 blue clickable"
            >
              Cancel
            </p>
            <button
              type="submit"
              className="saveEditTenantButton sub_text fw-600 white"
              onClick={() => handleFunction()}
            >
              Save Changes <img src={GetImages.WhiteForwardArrow} />
            </button>
          </div>
        </div>
      </form>
      {showEditConfirmation && (
        <CommonConfirmation
          show={showEditConfirmation}
          onConfirm={associateContractorsToServiceRequest}
          onClose={handleClose}
          heading="Update Estimate"
          subHeading={`On confirming, you will update the estimate given by contractor "${values.contractorName}".`}
          confirmLabel={"Confirm & Proceed"}
        />
      )}
    </div>
  );
};

export default EditEstimateForm;
