import React, { useCallback, useEffect, useState } from "react";
import {  Form} from "react-bootstrap";
import Delete from "../../../assets/deleteIconRed.svg";
import Copy from "../../../assets/copy.png";
import { QuestionTypeEnum } from "@propertelligent/client-api";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import AddButtonWithArrow from "../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import { GetImages } from "../../../utils/GetImages";
import CustomSwitchToggle from "../../common/Switch";
import CustomInputs from "../../common/Inputs";

const QuestionHistoryEdit = ({ postData, setPostData }) => {
  const [showFollowUpInput, setShowFollowUpInput] = useState(false);
  const handleAddQuestion = () => {
    const newQuestion = {
      questionText: "",
      isRequired: false,
      questionTypeId: QuestionTypeEnum.SingleChoice,
      choices: [
        { id:1,optionText: "Click to change" },
        { id:2 ,optionText: "Click to change" },
      ],
      followUpQuestionText: "",
      followUpQuestionChoiceId: 1,
    };
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData?.leaseApplicationTemplateSections,
        Questionnaire: {
          ...prevData?.leaseApplicationTemplateSections?.Questionnaire,
          questions: [
            ...(prevData?.leaseApplicationTemplateSections?.Questionnaire?.questions || []), // Add fallback to ensure questions is an array
            newQuestion,
          ],
        },
      },
    }));
  };

  const handleQuestionChange = (index, newText, newType) => {
    const updatedQuestions = [
      ...postData.leaseApplicationTemplateSections.Questionnaire.questions,
    ];
    updatedQuestions[index].questionText = newText;
    updatedQuestions[index].questionTypeId = Number(newType);
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaire: {
          ...prevData.leaseApplicationTemplateSections.Questionnaire,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleAddOption = (index) => {
    const updatedQuestions = [
      ...postData?.leaseApplicationTemplateSections?.Questionnaire?.questions,
    ];
    const newOption = {
      id: updatedQuestions[index]?.choices?.length + 1,
      optionText: `Option ${updatedQuestions[index]?.choices?.length + 1}`,
    };
    updatedQuestions[index]?.choices?.push(newOption);
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData?.leaseApplicationTemplateSections,
        Questionnaire: {
          ...prevData?.leaseApplicationTemplateSections?.Questionnaire,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleRemoveOption = (index) => {
    const updatedQuestions = [
      ...postData?.leaseApplicationTemplateSections?.Questionnaire?.questions,
    ];
    updatedQuestions[index].choices.pop();
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaire: {
          ...prevData?.leaseApplicationTemplateSections?.Questionnaire,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleOnClick = (qIndex, optIndex, onFocus) => {
    const updatedQuestions = [
      ...postData?.leaseApplicationTemplateSections?.Questionnaire?.questions,
    ];
    updatedQuestions[qIndex].choices.forEach((choice, index) => {
      updatedQuestions[qIndex].choices[index].isOnHover = false;
    });
    updatedQuestions[qIndex].choices[optIndex].isOnHover = onFocus;
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaire: {
          ...prevData.leaseApplicationTemplateSections.Questionnaire,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleOnClickElement = (qIndex, optIndex, questionTypeId: any) => {
    const updatedQuestions = [
      ...postData?.leaseApplicationTemplateSections?.Questionnaire?.questions,
    ];
    updatedQuestions[qIndex].choices[optIndex].isRadio =
      !updatedQuestions[qIndex].choices[optIndex].isRadio;
    updatedQuestions[qIndex].followUpQuestionChoiceId = optIndex + 1;
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaire: {
          ...prevData.leaseApplicationTemplateSections.Questionnaire,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleChangeFollowup = (index, e) => {
    const updatedQuestions = [
      ...postData.leaseApplicationTemplateSections.Questionnaire.questions,
    ];
    updatedQuestions[index].followUpQuestionText = e.target.value;
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaire: {
          ...prevData.leaseApplicationTemplateSections.Questionnaire,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleChangeQuestionText = (index, e) => {
    const updatedQuestions = [
      ...postData.leaseApplicationTemplateSections.Questionnaire.questions,
    ];
    updatedQuestions[index].questionText = e.target.value;
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaire: {
          ...prevData.leaseApplicationTemplateSections.Questionnaire,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleChangeText = (e, qIndex, optIndex) => {
    const updatedQuestions = [
      ...postData.leaseApplicationTemplateSections.Questionnaire.questions,
    ];
    updatedQuestions[qIndex].choices[optIndex].optionText = e.target.value;
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaire: {
          ...prevData.leaseApplicationTemplateSections.Questionnaire,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleShowFollowUpInput = () => {
    setShowFollowUpInput((old) => !old);
  };

  const deleteQuestion = useCallback(
    (index) => {
      const updatedQuestions =
        postData?.leaseApplicationTemplateSections?.Questionnaire?.questions.filter(
          (_, i) => i !== index
        );
      setPostData((prevData) => ({
        ...prevData,
        leaseApplicationTemplateSections: {
          ...prevData.leaseApplicationTemplateSections,
          Questionnaire: {
            ...prevData.leaseApplicationTemplateSections.Questionnaire,
            questions: updatedQuestions,
          },
        },
      }));
    },
    [postData, setPostData]
  );
  const copyQuestion = useCallback(
    (index) => {
      const questionToCopy = {
        ...postData?.leaseApplicationTemplateSections?.Questionnaire?.questions[index],
      };
      const updatedQuestions = [
        ...postData?.leaseApplicationTemplateSections?.Questionnaire?.questions,
        questionToCopy,
      ];

      setPostData((prevData) => ({
        ...prevData,
        leaseApplicationTemplateSections: {
          ...prevData.leaseApplicationTemplateSections,
          Questionnaire: {
            ...prevData.leaseApplicationTemplateSections.Questionnaire,
            questions: updatedQuestions,
          },
        },
      }));
    },
    [postData, setPostData]
  );

  const handleToggleChange = (index) => {
    const updatedQuestions = [
      ...postData?.leaseApplicationTemplateSections?.Questionnaire?.questions,
    ];
    updatedQuestions[index].isRequired = !updatedQuestions[index].isRequired;
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaire: {
          ...prevData.leaseApplicationTemplateSections.Questionnaire,
          questions: updatedQuestions,
        },
      },
    }));
  };

  return (
    <>
      <section className="question-history">
        <p className="sub_heading greyHeading">Questionnaire</p>
        {postData?.leaseApplicationTemplateSections?.Questionnaire?.questions.map(
          (question, index) => (
            <div className="questionnaire_edit" key={index}>
              <div className="questionnaire_edit_option">
                <div className="questionnaire_edit_option_left ">
                  <div>
                    <img src={GetImages.GripDots} alt={GetImages.GripDots} />
                  </div>

                  <CustomInputs
                    type="text"
                    value={question.questionText}
                    isError={false}
                    placeholder="Add Question text here"
                    myClassName="mb-0"
                    width={"100%"}
                    onChange={(e) => handleChangeQuestionText(index, e)}
                  />
                </div>

                <div className="questionnaire_edit_option_right">
                  <Form.Select
                    value={question?.questionTypeId}
                    onChange={(e) =>
                      handleQuestionChange(
                        index,
                        question.questionText,
                        e.target.value
                      )
                    }
                    aria-label="Default select example"
                  >
                    <option value={QuestionTypeEnum.SingleChoice}>
                      Single choice
                    </option>
                    <option value={QuestionTypeEnum.MultipleChoice}>
                      Multiple choice
                    </option>
                    <option value={QuestionTypeEnum.ShortAnswer}>
                      Short answer
                    </option>
                    <option value={QuestionTypeEnum.Paragraph}>
                      Pharagraph
                    </option>
                  </Form.Select>
                </div>
              </div>

              {question?.questionTypeId === QuestionTypeEnum.SingleChoice && (
                <Form className="radio-buttons">
                  {question?.choices?.map((option, optionIndex) => (
                    <div key={optionIndex}>
                      <div
                        className="form-check"
                        onClick={() => {
                          handleOnClickElement(
                            index,
                            optionIndex,
                            question?.questionTypeId
                          );
                        }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name={`flexRadioDefault${index}`}
                          id={`flexRadioDefault${index}-${optionIndex}`}
                        />
                        {option?.isOnHover ? (
                          <Form.Control
                            type="text"
                            value={option?.optionText}
                            placeholder="option one"
                            readOnly={!option?.isOnHover}
                            onBlur={() => {
                              handleOnClick(index, optionIndex, false);
                            }}
                            onFocus={() => {
                              handleOnClick(index, optionIndex, true);
                            }}
                            onChange={(e) =>
                              handleChangeText(e, index, optionIndex)
                            }
                          />
                        ) : (
                          <label
                            className="form-check-label"
                            htmlFor={`flexRadioDefault${index}-${optionIndex}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleOnClick(index, optionIndex, true);
                            }}
                          >
                            {option?.optionText}
                          </label>
                        )}
                      </div>
                    </div>
                  ))}
                </Form>
              )}

              {question?.questionTypeId === QuestionTypeEnum.MultipleChoice && (
                <Form className="radio-buttons">
                  {question?.choices?.map((option, optionIndex) => (
                    <div key={optionIndex}>
                      <div
                        className="form-check"
                        onClick={() => {
                          handleOnClickElement(
                            index,
                            optionIndex,
                            question.questionTypeId
                          );
                        }}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name={`flexCheckboxDefault${index}`}
                          id={`flexCheckboxDefault${index}-${optionIndex}`}
                        />
                        {option?.isOnHover ? (
                          <Form.Control
                            type="text"
                            value={option?.optionText}
                            placeholder="option one"
                            readOnly={!option?.isOnHover}
                            onBlur={() => {
                              handleOnClick(index, optionIndex, false);
                            }}
                            onFocus={() => {
                              handleOnClick(index, optionIndex, true);
                            }}
                            onChange={(e) =>
                              handleChangeText(e, index, optionIndex)
                            }
                          />
                        ) : (
                          <label
                            className="form-check-label"
                            htmlFor={`flexCheckboxDefault${index}-${optionIndex}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleOnClick(index, optionIndex, true);
                            }}
                          >
                            {option?.optionText}
                          </label>
                        )}
                      </div>
                    </div>
                  ))}
                </Form>
              )}

              <div className="optionsection">
                <div className="options_addRemove">
                  {question?.questionTypeId !== QuestionTypeEnum.ShortAnswer &&
                    question?.questionTypeId !== QuestionTypeEnum.Paragraph && (
                      <>
                        <AddTraspButton
                          width={0}
                          textclass={"sub_text fw-600 blue"}
                          bname={"+ Add Option"}
                          iconPosition={"right"}
                          onClick={() => handleAddOption(index)}
                          border={false}
                          imageValue={undefined}
                        />
                        <AddTraspButton
                          width={0}
                          textclass={"sub_text fw-600 grey"}
                          bname={"- Remove Option"}
                          iconPosition={"right"}
                          onClick={() => handleRemoveOption(index)}
                          border={false}
                          disabled={question?.choices?.length <= 1}
                          imageValue={undefined}
                        />
                      </>
                    )}
                </div>
                <div className="options_buttons">
                  <div className="d-flex align-items-center gap-1">
                    <p className="sub_text  fw-600 black">Required</p>
                    <CustomSwitchToggle
                      key={index}
                      onClick={() => handleToggleChange(index)}
                      checked={question.isRequired}
                    />
                  </div>
                  <AddTraspButton
                    width={0}
                    bname={undefined}
                    imageValue={Copy}
                    iconPosition={"right"}
                    onClick={() => copyQuestion(index)}
                    border={false}
                  />
                  <AddTraspButton
                    width={0}
                    bname={undefined}
                    imageValue={Delete}
                    iconPosition={"right"}
                    onClick={() => deleteQuestion(index)}
                    border={false}
                  />
                </div>
              </div>

              {question?.questionTypeId == QuestionTypeEnum.SingleChoice && (
                <div className="pb-3">
                  <AddButtonWithArrow
                    buttonname={"Ask follow up question"}
                    onClick={handleShowFollowUpInput}
                    disabled={undefined}
                    iconDirection={"right"}
                    Icon={GetImages.WhiteAdd}
                  />
                </div>
              )}
              {showFollowUpInput && (
                <>
                  {postData.leaseApplicationTemplateSections.Questionnaire.questions[index]
                    ?.questionTypeId == QuestionTypeEnum.SingleChoice && (
                      <>
                        <div className="followup_quetion">
                          {" "}
                          <p className="sub_text  fw-600 grey_subtext1">
                            Ask follow up question when user selects
                          </p>
                          <Form className="radio-buttons">

                            {postData.leaseApplicationTemplateSections.Questionnaire.questions[
                              index
                            ].choices.map((option, optionIndex) => (
                              <div className="form-check" key={optionIndex}>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id={`flexRadioDefault${optionIndex}`}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`flexRadioDefault${optionIndex}`}
                                >
                                  {option?.optionText}
                                </label>
                              </div>
                            ))}
                          </Form>
                        </div>
                        <CustomInputs
                          type="text"
                          placeholder="Follow-Up Question"
                          width={"100%"}
                          value={question?.followUpQuestionText}
                          onChange={(e) => {
                            handleChangeFollowup(index, e);
                          }}
                        />
                      </>
                    )}
                </>
              )}
            </div>
          )
        )}
        <div className="addRemove">
          <AddTraspButton
            bname={"Add another Question"}
            iconPosition={undefined}
            onClick={handleAddQuestion}
            border={false}
          />
        </div>
      </section>
    </>
  );
};

export default QuestionHistoryEdit;
