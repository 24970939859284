import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GetImages } from "../../utils/GetImages";
import DisplayNamewithLeftBorder from "../common/DisplayNamewithLeftBorder";
import { Col, Form, Row } from "react-bootstrap";
import CustomInputs from "../common/Inputs";
import AddButtonWithArrow from "../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import AddPayee from "./Modals/AddPayee";
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton";
import { FileUploader } from "react-drag-drop-files";
import apiHelper from "../../utils/apiHelper";
import { DocumentEntityTypesEnum, DocumentService, DocumentTypesEnum, EntityType, PaidFlowTypesEnum, PaymentService, PropertyService, TenantService, UnitService } from "@propertelligent/client-api";
import { UserLoginData } from "../common/charts/Enums";
import { useError } from "../../utils/context/ErrorContext";
import DynamicAddMoreForm from "../common/DynamicAddMoreForm";
import LastTransaction from "./Modals/LastTransaction";
import GenericSearchAbleDropdown from "../common/GenericSearchAbleDropdown";
import DateHelper from "../../utils/DateHelper";
import { useSearchParams } from "react-router-dom";
import { PaymentContext } from "../../utils/context/PaymentContext";

const AddPaymentForm = ({ onConfirm = (x) => { } }) => {
  const [paymentMode, setPaymentMode] = useState("Payables");
  const [show, setShow] = useState(false);
  const [transactionModal, setTransactionModal] = useState(false);
  const [duePaymentModal, setDuePaymentModal] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState("");
  const [propertyData, setPropertyData] = useState<any>([]);
  const [unitData, setUnitData] = useState<any>([]);
  const [tenantData, setTenantData] = useState<any>([]);
  const [isPropertyDisabled, setIsPropertyDisabled] = useState<boolean>(true);
  const [isUnitDisabled, setIsUnitDisabled] = useState<boolean>(true);
  const [payeeAccountId, setPayeeAccountId] = useState("");
  const { errors, setErrors, errorMessages, setErrorMessages, clearErrors } = useError();
  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
  const [paymentTypeId, setPaymentTypeId] = useState()
  const [fileName, setFileName] = useState<any>([]);
  const [mainImage, setMainImage] = useState<any>();
  const [fileType, setFileType] = useState("image");
  const [uploadDocument, setUploadDocument] = useState<any>(null);
  const [selectEntityId, setSelectEntityId] = useState<any>();
  const [selectEntityTypeId, setSelectEntityTypeId] = useState<any>();
  const [refrenceNum, setRefrenceNum] = useState("")
  const [paidFlowTypeId, setPaidFlowTypeId] = useState(undefined)
  const [paymentId, setPaymentId] = useState()
  const { id } = useParams()
  const [searchParams] = useSearchParams();
  const { selectedItems, setSelectedItems } = useContext(PaymentContext);
  // const entityId = searchParams.get("entityId");
  // const entityTypeId = searchParams.get("entityTypeId");

  const navigate = useNavigate();

  const accountOptions = [
    {
      // id: 1,
      name: "Organization"
    },
    {
      // id: 2,
      name: "Property"
    },
    {
      // id: 3,
      name: "Unit"
    },
  ];
  const headers = ["Amount ($)", "Due Date", "Grace Period", "Description", ""]
  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
  const [formData, setFormData] = useState<any>([
    {
      id: 1,
      dueDate: "",
      amount: 0,
      gracePeriods: 0,
      description: "",
    },
  ]);

  const getPaymentDueAccount = () => {
    apiHelper(PaymentService.getApiPaymentDueAccount, { showNotification: false }, id)
      .then((res) => {
        const successData = res?.data?.success;
        if (successData) {
          setFormData([
            {
              ...formData,
              id: successData?.id,
              dueDate: DateHelper.convertUTCtoDateTime2(successData?.dueDate),
              amount: successData?.amount,
              gracePeriods: successData?.gracePeriodInDays,
              description: successData?.description,
            }
          ])
          setSelectEntityId(successData?.entityId)
          setSelectEntityTypeId(successData?.entityTypeId)
        }
        if (successData?.referenceNumber === "") {
          setRefrenceNum("")
        } else {
          setRefrenceNum(successData?.referenceNumber)
        }
        setPaymentTypeId(successData?.paymentTypeId)
        setSelectedAttachment(successData?.formatted?.EntityTypeId)
        setPayeeAccountId(successData?.payeeAccountId)
        if (successData?.formatted?.PaidFlowTypeId === "Payable") {
          setPaidFlowTypeId(1)
        } else {
          setPaidFlowTypeId(2)
        }
      }).catch((err) => {
      })
  }

  useEffect(() => {
    if (id) {
      getPaymentDueAccount()
    }
  }, [id]);

  const getTenantsData = async () => {
    try {
      const res = await apiHelper(TenantService.getApiTenantOrganizationlookup, { showNotification: false }, orgId);
      setTenantData(res?.data?.success);
    } catch (error) {
    }
  };

  const handleClose = () => {
    setShow(false);
    setTransactionModal(false)
    setDuePaymentModal(false)
  };

  useEffect(() => {
    if (selectEntityId === undefined) {
      setSelectEntityId("")
      setSelectEntityTypeId("")
    }
  }, [selectEntityId])

  const handleSubmitPaymentAccountRecord = async (value: string = "save") => {
    if (checkEmptyFields()) {
    } else if (id) {
      formData.map((item, index: any) => {
        const updatedValues = {
          id: Number(id),
          amount: Number(formData[index].amount),
          description: formData[index].description,
          dueDate: new Date(formData[index].dueDate).toISOString(),
          entityId: Number(selectEntityId),
          entityTypeId: Number(selectEntityTypeId),
          payeeAccountId: Number(payeeAccountId) || 0,
          gracePeriodInDays: Number(formData[index].gracePeriods),
          referenceNumber: refrenceNum,
          paymentTypeId: 8,
          paidFlowTypeId: paidFlowTypeId
        };
        apiHelper(
          PaymentService.putApiPaymentDueAccount,
          {
            successMessage: "Payment updated successfully",
            failureMessage: "Unable to update payment",
            showNotification: true,
          },
          updatedValues
        )
          .then((res: any) => {
            if (res?.data?.success) {
              if (uploadDocument) {
                const docData = {
                  EntityTypeId: DocumentEntityTypesEnum.Payment,
                  EntityId: res?.data?.success?.id,
                  DocumentTypeId: DocumentTypesEnum.PaymentReference,
                  IsMain: true,
                  FileName: fileName,
                  Document: uploadDocument,
                };

                apiHelper(DocumentService.postApiDocument, { showNotification: false }, docData)
                  .then((res) => {
                  })
                  .catch((error) => { });
              }
            }
            setSelectedAttachment(" ")
            setRefrenceNum("")
            setUploadDocument(null)
          })
          .catch((error) => { });
      })
    } else if (paymentMode === "Receivables") {
      await apiHelper(
        PaymentService.getApiPayeeAccountOrganizationlist,
        { showNotification: false },
        orgId, undefined, undefined, undefined, `IsOrganizationAccount==true`
      )
        .then((res: any) => {
          if (res?.data?.success) {
            setPayeeAccountsArr(res?.data?.success);
            setPaymentTypeId(res?.data?.success?.paymentTypeId)
            setPayeeAccountId(res?.data?.success[0]?.id)
            formData.map((item, index: any) => {
              const updatedValues = {
                amount: Number(formData[index].amount),
                description: formData[index].description,
                dueDate: new Date(formData[index].dueDate).toISOString(),
                entityId: Number(selectEntityId),
                entityTypeId: Number(selectEntityTypeId),
                payeeAccountId: Number(res?.data?.success[0]?.id) || 0,
                gracePeriodInDays: Number(formData[index].gracePeriods),
                referenceNumber: refrenceNum,
                paymentTypeId: 8,
                paidFlowTypeId: PaidFlowTypesEnum.Receiveable
              };
              if (paymentMode === "Receivables") {
                delete updatedValues.referenceNumber
              }
              apiHelper(
                PaymentService.postApiPaymentDueAccount,
                {
                  successMessage: "Payment added successfully",
                  failureMessage: "Unable to add payment",
                  showNotification: true,
                },
                updatedValues
              )
                .then((res: any) => {

                  if (res?.data?.success) {
                    const responseData = Array.isArray(res?.data?.success)
                      ? res?.data?.success
                      : [res?.data?.success];
                    setSelectedItems(prevSelectedItems => [
                      ...prevSelectedItems,
                      ...responseData
                    ]);
                    setPaymentId(res?.data?.success?.id)
                    if (uploadDocument) {
                      const docData = {
                        EntityTypeId: DocumentEntityTypesEnum.Payment,
                        EntityId: res?.data?.success?.id,
                        DocumentTypeId: DocumentTypesEnum.PaymentReference,
                        IsMain: true,
                        FileName: fileName,
                        Document: uploadDocument,
                      };
                      apiHelper(DocumentService.postApiDocument, { showNotification: false }, docData)
                        .then((res) => {
                          if (res?.data?.success && formData.length === index + 1) {
                            if (value === "settle") {
                              navigate(`/payment/settle-payment/${payeeAccountId}`, { state: { selectedPayment: payeeAccountId, paymentMode, selectedItems } });
                            }
                          }
                        })
                        .catch((error) => { });
                    }
                  }
                  setFormData([{
                    ...formData,
                    id: 1,
                    dueDate: "",
                    amount: 0,
                    gracePeriods: 0,
                    description: "",
                  }])
                  setSelectedAttachment("Organization")
                  setRefrenceNum("")
                  setUploadDocument(null)

                })
                .catch((error) => { });
            })
          }
        })
        .catch((error) => {
          JSON.stringify(error)
        });
    } else {
      formData.map((item, index: any) => {
        const updatedValues = {
          amount: Number(formData[index].amount),
          description: formData[index].description,
          dueDate: new Date(formData[index].dueDate).toISOString(),
          entityId: Number(selectEntityId),
          entityTypeId: Number(selectEntityTypeId),
          payeeAccountId: Number(payeeAccountId) || 0,
          gracePeriodInDays: Number(formData[index].gracePeriods),
          referenceNumber: refrenceNum,
          paymentTypeId: 8,
          paidFlowTypeId: PaidFlowTypesEnum.Payable
        };
        apiHelper(
          PaymentService.postApiPaymentDueAccount,
          {
            successMessage: "Payment added successfully",
            failureMessage: "Unable to add payment",
            showNotification: value === "save" ? true : false,
          },
          updatedValues
        )
          .then((res: any) => {
            if (res?.data?.success) {
              const responseData = Array.isArray(res?.data?.success)
                ? res?.data?.success
                : [res?.data?.success];
              setSelectedItems(prevSelectedItems => [
                ...prevSelectedItems,
                ...responseData
              ]);

              if (uploadDocument) {
                const docData = {
                  EntityTypeId: DocumentEntityTypesEnum.Payment,
                  EntityId: res?.data?.success?.id,
                  DocumentTypeId: DocumentTypesEnum.PaymentReference,
                  IsMain: true,
                  FileName: fileName,
                  Document: uploadDocument,
                };

                apiHelper(DocumentService.postApiDocument, { showNotification: false }, docData)
                  .then((res) => {
                    if (res?.data?.success && formData.length == index + 1) {
                      if (value === "settle") {
                        navigate(`/payment/settle-payment/${payeeAccountId}`, { state: { selectedPayment: payeeAccountId, paymentMode } });
                      }
                    }
                  })
                  .catch((error) => { });
              } else {
                if (formData.length == index + 1) {
                  if (value === "settle") {
                    navigate(`/payment/settle-payment/${payeeAccountId}`, { state: { selectedPayment: payeeAccountId, paymentMode } });
                  }
                }
              }
            }
            setFormData({
              ...formData,
              id: 1,
              dueDate: "",
              amount: 0,
              gracePeriods: 0,
              description: "",
            });
            setSelectedAttachment(" ")
            setRefrenceNum("")
            setUploadDocument(null)
          })
          .catch((error) => { });
      })
    }
  };

  useEffect(() => {
    getPayeeList();
    getTenantsData();
    const entityId = searchParams.get("entityId");
    const entityTypeId = searchParams.get("entityTypeId");
    setSelectEntityId(entityId)
    setSelectEntityTypeId(entityTypeId)
  }, []);

  const getPayeeList = async () => {
    let FullyQualifiedFilters: `IsOrganizationAccount==true`
    await apiHelper(
      PaymentService.getApiPayeeAccountOrganizationlist,
      { showNotification: false },
      orgId, undefined, undefined, undefined, FullyQualifiedFilters
    )
      .then((res: any) => {
        if (res?.data?.success) {
          setPayeeAccountsArr(res?.data?.success);
          setPaymentTypeId(res?.data?.success?.paymentTypeId)
        }
      })
      .catch((error) => { });
  };

  const getPropertyList = async () => {
    await apiHelper(PropertyService.getApiPropertyOrganizationlookup, { showNotification: false }, orgId)
      .then((res) => {
        setPropertyData(res?.data?.success);
        setIsPropertyDisabled(false);
        if (EntityType.Property === Number(selectEntityTypeId)) {
          setSelectedAttachment("Property")
        }
      })
      .catch((error) => { });
  };

  const getUnitList = async () => {
    await apiHelper(UnitService.getApiUnitOrganizationlookup, { showNotification: false }, orgId)
      .then((res) => {
        setUnitData(res?.data?.success);
        setIsUnitDisabled(false);
        if (EntityType.Unit === Number(selectEntityTypeId)) {
          setSelectedAttachment("Unit")
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getPropertyList();
    getUnitList();
  }, [selectEntityId, selectEntityTypeId])

  const handleAddPayment = () => {
    setFormData([
      ...formData,
      {
        id: formData.length + 1,
        amount: 0,
        dueDate: '',
        gracePeriods: 0,
        description: '',
      },
    ]);
  };


  const handleReferenceNumberChange = (e) => {
    setRefrenceNum(e.target.value)
    setErrors({ ...errors, refrenceNum: false })
  };

  const handleImageChange = (selectedFile: File | null) => {
    setErrors({ ...errors, document: false })
    if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
      setFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadDocument(blob);
      setMainImage(blob);
    } else {
      setFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadDocument(blob);
      setFileType("file");
      fetchImageBlob();
    }
  };

  const fetchImageBlob = async () => {
    try {
      const response = await fetch(GetImages.pdfImage);
      const blob = await response.blob();
      setMainImage(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const checkEmptyFields = (): boolean => {
    clearErrors();
    const newErrors = { ...errors };
    const newErrorMessages = { ...errorMessages };
    let hasErrors = false;
    formData.forEach((payment, index) => {
      if (!payment?.dueDate) {
        newErrors[`dueDate-${index}`] = true;
        newErrorMessages[`dueDate-${index}`] = "Due date is required";
        hasErrors = true;
      } else {
        newErrors[`dueDate-${index}`] = false;
      }

      if (!payment.gracePeriods) {
        newErrors[`gracePeriods-${index}`] = true;
        newErrorMessages[`gracePeriods-${index}`] = "Grace period is required";
        hasErrors = true;
      } else {
        newErrors[`gracePeriod-${index}`] = false;
      }

      if (!payment.amount) {
        newErrors[`amount-${index}`] = true;
        newErrorMessages[`amount-${index}`] = "Amount is required";
        hasErrors = true;
      } else {
        newErrors[`amount-${index}`] = false;
      }

      if (!payment.description) {
        newErrors[`description-${index}`] = true;
        newErrorMessages[`description-${index}`] = "Description is required";
        hasErrors = true;
      } else {
        newErrors[`description-${index}`] = false;
      }

      // if (!uploadDocument && !id) {
      //   newErrors.document = true;
      // }
    });

    // if (refrenceNum === "" && paymentMode === "Payables" && !id) {
    //   newErrors.refrenceNum = true;
    //   hasErrors = true;
    // } else {
    //   newErrors.refrenceNum = false;
    // }

    if (selectedAttachment === "" && paymentMode === "Payables") {
      newErrors.attachment = true;
      hasErrors = true;
    } else {
      newErrors.attachment = false;
    }

    if (selectEntityId === undefined || selectEntityId === "") {
      if (selectEntityTypeId === EntityType.Tenant || !selectedAttachment) {
        newErrors.tenant = true;
      } else if (selectEntityTypeId === EntityType.Property || selectedAttachment === "Property") {
        newErrors.property = true;
      } else if (selectEntityTypeId === EntityType.Unit || selectedAttachment === "Unit") {
        newErrors.unit = true;
      }
      hasErrors = true
    }
    if (payeeAccountId === "") {
      newErrors.payee = true;
      newErrorMessages.payee = "Please select Payee Account"
    }
    setErrors(newErrors);
    setErrorMessages(newErrorMessages);
    return hasErrors;
  };

  useEffect(() => {
    if (selectedAttachment === "Organization") {
      setSelectEntityId(orgId)
      setSelectEntityTypeId(EntityType.Organization)
    }
  }, [selectedAttachment])

  useEffect(() => {
    if (checkEmptyFields()) {
      clearErrors()
    }
  }, [paymentMode])

  const handleDeleteDocument = () => {
    setMainImage(null);
    setUploadDocument(null);
  };
  return (
    <>
      <div className="addPayment">
        <div className="newTenantFormTopBar d-flex align-items-center">
          <img
            onClick={() => { navigate(-1) }}
            height={28}
            width={28}
            src={GetImages.leftChevronBlack}
            className="clickable"
          />
          <p className="main_heading black">{id ? "Edit Payment" : "Add Payment"}</p>
        </div>

        <DisplayNamewithLeftBorder
          HeaderName="BASIC DETAIL"
          className="sub_text ps-1"
        />
        {
          !id &&
          <div className="monthhead pt-3">
            <button
              onClick={() => setPaymentMode("Payables")}
              className={
                paymentMode === "Payables"
                  ? "overviewSelectedButton normal_text fw-500 black"
                  : "overviewUnselectedButton normal_text fw-500 black"
              }
            >
              <img src={GetImages.Payables} alt="payables" />
              Payables
            </button>
            <button
              onClick={() => setPaymentMode("Receivables")}
              className={
                paymentMode === "Receivables"
                  ? "overviewSelectedButton normal_text fw-500 black"
                  : "overviewUnselectedButton normal_text fw-500 black"
              }
            >
              <img src={GetImages.Receivables} alt="Receivables" />
              Receivables
            </button>
          </div>
        }
        <div className="settlePaymentLeft">
          <Form className="paymentForm">
            {paymentMode === "Payables" && (
              <Row className="paymentinputrow p-0">
                <div className="d-flex flex-column">
                  <div className="selectPayee">
                    <div>
                      <Col className="paymentinputCol p-0">
                        <Form.Label className="normal_text  grey_subtext1">
                          Select Payee*
                        </Form.Label>
                        <Form.Group className="mb-2">
                          <GenericSearchAbleDropdown
                            fieldName={"accountName"}
                            initialOptions={payeeAccountsArr}
                            name={"accountName"}
                            isFormControl={true}
                            value={payeeAccountId}
                            onChange={(e) => {
                              setPayeeAccountId(e.target.value)
                              setErrors({ ...errors, payee: false })
                            }}
                            placeholder="Select Payee"
                            myClassName="normal_text"
                            style={{ height: "46px" }}
                            isError={errors.payee}
                            errorMessage={errorMessages.payee}
                          />
                        </Form.Group>
                      </Col>
                    </div>
                    {
                      payeeAccountId && payeeAccountId !== "" ?
                        <>
                          <div className="transactionBtn">
                            <AddTraspButton bname="View Last 5 Transactions" border={true} type="button" onClick={() => { setTransactionModal(true) }} />
                            <AddTraspButton bname="View All Dues" border={true} type="button" onClick={() => { setDuePaymentModal(true) }} />
                          </div>
                        </>
                        : ""
                    }
                    {transactionModal &&
                      <LastTransaction
                        onClose={handleClose}
                        heading={"Last 5 Transactions"}
                        show={true}
                        payeeAccountId={payeeAccountId}
                        duePayment={false}
                      />
                    }
                    {duePaymentModal &&
                      <LastTransaction
                        payeeAccountId={payeeAccountId}
                        onClose={handleClose}
                        heading={"Due Payments"}
                        show={true}
                        duePayment={true} />
                    }

                  </div>

                  <Form.Label className="sub_text  grey_subtext1">
                    Can’t see the payee? To create new,{" "}
                    <span className="navigateMe" onClick={() => setShow(true)}>
                      {" "}
                      click here
                    </span>
                    {show ? (
                      <AddPayee
                        onClose={handleClose}
                        heading="Add Payee"
                        show={true}
                      />
                    ) : (
                      ""
                    )}
                  </Form.Label>
                </div>
              </Row>
            )}

            <div>
              <Row className="paymentinputrow d-flex align-items-center">
                {
                  paymentMode === "Payables" && (
                    <Col className="paymentinputCol p-0 ol-xl-3 col-md-6 col-12 c">
                      <Form.Group controlId="paymentMethodSelect">
                        <Form.Label className="normal_text black">
                          Attach Payment to
                        </Form.Label>
                        <GenericSearchAbleDropdown
                          fieldName={"name"}
                          initialOptions={accountOptions}
                          name={"accountOptions"}
                          isFormControl={true}
                          value={selectedAttachment}
                          onChange={(e) => {
                            setSelectedAttachment(e.target.value)
                            setErrors({ ...errors, attachment: false })
                          }}
                          placeholder=" Attach Payment to"
                          myClassName="normal_text"
                          style={{ height: "46px" }}
                          isError={errors.attachment}
                          errorMessage={errorMessages.attachment}
                        />
                      </Form.Group>
                    </Col>
                  )
                }

                {paymentMode === "Receivables" && (
                  <Col className="paymentinputCol ol-xl-3 col-md-6 col-12 c">
                    <Form.Group className="mb-2">
                      <Form.Label className="normal_text  grey_subtext1">
                        Select  Tenant
                      </Form.Label>
                      <GenericSearchAbleDropdown
                        fieldName={"name"}
                        initialOptions={tenantData}
                        name={"lastName"}
                        isFormControl={true}
                        value={selectEntityId}
                        onChange={(e) => {
                          setSelectEntityId(e.target.value);
                          setSelectEntityTypeId(EntityType.Tenant)
                          setErrors({ ...errors, tenant: false })
                        }}
                        placeholder="Select Tenant"
                        myClassName="normal_text"
                        style={{ height: "46px" }}
                        isError={errors.tenant}
                        errorMessage={errorMessages.tenant}
                      />
                    </Form.Group>
                  </Col>
                )}

                {selectedAttachment === "Property" && paymentMode !== "Receivables" ? (
                  <Col className="paymentinputCol col-xl-3 col-md-6 col-12" >
                    <Form.Group controlId="paymentMethodSelect">
                      <Form.Label className="normal_text black">
                        Select Property
                      </Form.Label>
                      <GenericSearchAbleDropdown
                        fieldName={"name"}
                        initialOptions={propertyData}
                        name={"propertyData"}
                        isFormControl={true}
                        value={selectEntityId}
                        onChange={(e) => {
                          setSelectEntityId(e.target.value);
                          setErrors({ ...errors, property: false })
                          setSelectEntityTypeId(EntityType.Property)
                        }}
                        placeholder="Select Tenant"
                        myClassName="normal_text"
                        style={{ height: "46px" }}
                        isError={errors.property}
                        errorMessage={errorMessages.property}
                      />
                    </Form.Group>
                  </Col>
                ) : ""}

                {selectedAttachment === "Unit" && paymentMode !== "Receivables" ? (
                  <Col className="paymentinputCol ol-xl-3 col-md-6 col-12 c">
                    <Form.Group controlId="paymentMethodSelect">
                      <Form.Label className="normal_text black">
                        Select Unit
                      </Form.Label>
                      <GenericSearchAbleDropdown
                        fieldName={"name"}
                        initialOptions={unitData}
                        name={"unitData"}
                        isFormControl={true}
                        value={selectEntityId}
                        onChange={(e) => {
                          setSelectEntityId(e.target.value);
                          setErrors({ ...errors, unit: false })
                          setSelectEntityTypeId(EntityType.Unit)
                        }}
                        placeholder="Select Tenant"
                        myClassName="normal_text"
                        style={{ height: "46px" }}
                        isError={errors.unit}
                        errorMessage={errorMessages.unit}
                      // onClick={getUnitList}
                      />
                    </Form.Group>
                  </Col>
                ) : ""}

                <Col className="paymentinputCol px-2 ol-xl-3 col-md-6 col-12 c">
                  <div
                    className="d-flex flex-column justify-content-center w-100"
                    style={{ maxWidth: "240px", marginBottom: "11px" }}
                  >
                    {
                      !uploadDocument &&
                      <>
                        <div className="labelContainer">
                          <p className="normal_text black mb-2">Upload Document</p>
                        </div>

                        <FileUploader
                          classes="documentUploaderContractor w-100"
                          handleChange={handleImageChange}
                          children={
                            <>
                              <div className="fileUploaderTenantDocument normal_text w-100">
                                <img
                                  width={24}
                                  height={24}
                                  src={GetImages.upload}
                                />
                                <p>
                                  Drag & Drop or{" "}
                                  <span className="normal_text blue"> Browse</span>
                                </p>
                              </div>
                            </>
                          }
                        />
                      </>
                    }
                    {errors.document && (
                      <div style={{ marginTop: "8px" }}>
                        <p className="errorMessageStyles">{errorMessages.document}</p>
                      </div>
                    )}
                  </div>
                  {uploadDocument && (
                    <div className="d-flex justify-content-between documentPreview" style={{ marginTop: "28px" }}>
                      <div className="d-flex gap-1">
                        <img width={21} height={24} src={GetImages.pdfImage} />
                        <p className="normal_text grey_subtext1">{fileName ? fileName : "Document"}</p>
                      </div>
                      <div onClick={handleDeleteDocument}>
                        <img width={20} height={20} src={GetImages.deleteIconRed} />
                      </div>

                    </div>
                  )}
                </Col>

                {paymentMode === "Payables" && (
                  <Col className="paymentinputCol col-xl-3 col-md-6 col- " style12={{ padding: "0 10px" }}>
                    <Form.Group controlId="payeeAccountSelect">
                      <CustomInputs
                        myClassName="mb-0"
                        labelText="Reference No."
                        isFormControl={true}
                        placeholder="Enter Reference No."
                        name="refrenceNo"
                        value={refrenceNum}
                        onChange={handleReferenceNumberChange}
                        isError={errors.refrenceNum}
                        errorMessages={errorMessages.refrenceNum}
                        InputsWrapperClass="mb-0"
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>
            </div>

            <div className="rentLedgerContainer py-3">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-baseline gap-2">
                  <DisplayNamewithLeftBorder
                    HeaderName="PAYMENT DETAILS"
                    className="sub_text black mb-3"
                  />
                </div>
              </div>

              <DynamicAddMoreForm
                headers={headers}
                data={formData}
                setData={setFormData}
                initialOptions={undefined}
                handleAddPayment={handleAddPayment}
              />
            </div>
            <Row className="paymentinputrow p-0">
              <Col>
                <div className="tenantFormFooter">
                  <div className="footerRightButtons d-flex justify-content-between">
                    <AddTraspButton
                      type="button"
                      bname={"Save Payment"}
                      onClick={handleSubmitPaymentAccountRecord}
                      border={"1px solid #1A56DB"}
                    />
                    <AddButtonWithArrow
                      type="button"
                      buttonname={"Settle Payment"}
                      onClick={() => {
                        handleSubmitPaymentAccountRecord("settle")
                      }}
                      iconDirection="left"
                      Icon={GetImages.WhiteForwardArrow}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddPaymentForm;
