import { useNavigate } from "react-router";
import ServiceIcon from "../../common/serviceTypeImage";
import NameAvatarComponent from "../../tenants/NameAvatarComponent";

const CardView = (data) => {
  const navigate = useNavigate();
  const disable = false;

  
  return (
    <>
      <div key={data.item?.id} className="contractorRowContainer clickable" onClick={() => { navigate(`contractor/${data.item.id}`); }}>
              <div className="nameDetails d-flex flex-row gap-2">
                <div style={{ position: "relative" }}>
                  <NameAvatarComponent
                    firstName={data.item.firstName}
                    lastName={data.item.lastName}
                    heightWidth={40}
                  />
                  {data.item.openServiceRequestCount > 0 && (
                    <div className="notificationCircle sub_text fw-500">
                      {data.item.openServiceRequestCount}
                    </div>
                  )}
                </div>
                <div>
                  <p className="sub_heading black">{`${data.item.firstName} ${data.item.lastName}`}</p>
                  <div className="leftSideBox">
                    <p className="sub_text text-nowrap grey_subtext1">{data.item.email}</p>
                    <p className="sub_text text-nowrap grey_subtext1">•</p>
                    <p className="sub_text text-nowrap grey_subtext1">{data.item.phoneNumber}</p>
                  </div>
                  <p className="sub_text text-nowrap grey_subtext1">
                    {data.item.isInHouse === true ? "In House" : "Third Party"}
                  </p>
                </div>
              </div>
              <div className="d-flex gap-2 align-items-center justify-content-start serviceDetails ">
                <ServiceIcon
                  serviceType={
                    data.item?.formatted?.ServiceTypesIds.length > 0
                      ? data.item?.formatted?.ServiceTypesIds[0]
                      : "interior"
                  }
                />
                <p className="sub_text fw-500 black">
                  {data.item?.formatted?.ServiceTypesIds.length > 0
                    ? data.item?.formatted?.ServiceTypesIds[0]
                    : "Interior"}
                </p>
                <div className="seperator"></div>
                <p className="sub_text black">
                  {" "}
                  {data.item?.formatted?.ServiceSubTypesIds.length > 0
                    ? data.item?.formatted?.ServiceSubTypesIds[0]
                    : "Furnishing"}
                </p>
              </div>
            </div>
    </>
  );
};

export default CardView;


