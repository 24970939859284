import { ChangeEvent, useEffect, useState } from 'react'
import OrganizationNameHeader from '../../common/OrganizationNameHeader/OrganizationNameHeader'
import CustomTopBar from '../../../layout/CustomTopBar'
import { Form } from 'react-bootstrap'
import { validateEmail, validateFirstName, validateLastName, validateNumber, validatePassword } from '../../../utils/validateHelper'
import { useError } from '../../../utils/context/ErrorContext'
import EditCustomComponent from '../../tenants/EditCustomComponent'
import { FORM_CONTROLFIELD_TYPES } from '../../../constants/constants'
import './inviteUser.css'
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import apiHelper from '../../../utils/apiHelper'
import { LoginService, OnboardingService } from '@propertelligent/client-api'

const InviteUser = () => {
    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: ""
    });
    const [headerInfo, setHeaderInfo] = useState<any>()
    const params = new URLSearchParams(location.search);
    const orgUserInviteCode = params.get("ic");

    const { errors, setErrors, setErrorMessages, errorMessages, clearErrors } = useError();

    const formDetails = [
        {
            label: "First Name",
            placeholder: "Enter First Name",
            value: values.firstName,
            name: "firstName",
            blurFunction: () => validateFirstName(values.firstName, setErrors),
            errorMessages: errorMessages.firstName,
            isError: errors.firstName,
            type: FORM_CONTROLFIELD_TYPES.TEXT,
            required: true
        },
        {
            label: "Last Name",
            placeholder: "Enter Last Name",
            value: values.lastName,
            name: "lastName",
            blurFunction: () => validateLastName(values.lastName, setErrors),
            errorMessages: errorMessages.lastName,
            isError: errors.lastName,
            type: FORM_CONTROLFIELD_TYPES.TEXT,
            required: true
        },
        {
            label: "Email",
            placeholder: "Enter Email",
            value: values.email,
            name: "email",
            blurFunction: () => validateEmail(values.email, setErrors, setErrorMessages),
            errorMessages: errorMessages.email,
            isError: errors.email,
            type: FORM_CONTROLFIELD_TYPES.EMAIL,
            required: true
        },
        {
            label: "Phone Number",
            placeholder: "Enter Phone no.",
            value: values.phoneNumber,
            name: "phoneNumber",
            blurFunction: () => validateNumber(values.phoneNumber, setErrors),
            errorMessages: errorMessages.phoneNumber,
            isError: errors.phoneNumber,
            type: FORM_CONTROLFIELD_TYPES.TELEPHONE,
            required: true
        },
        {
            label: "Password",
            placeholder: "********",
            value: values.password,
            name: "password",
            blurFunction: () => validatePassword(values.password, setErrors, setErrorMessages),
            errorMessages: errorMessages.password,
            isError: errors.password,
            type: FORM_CONTROLFIELD_TYPES.PASSWORD,
            required: true
        },
        {
            label: "Confirm Password",
            placeholder: "********",
            value: values.confirmPassword,
            name: "confirmPassword",
            blurFunction: () => validateConfirmPassword(),
            errorMessages: errorMessages.confirmPassword,
            isError: errors.confirmPassword,
            type: FORM_CONTROLFIELD_TYPES.PASSWORD,
            required: true
        },
    ];

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setErrors({ ...errors, [name]: false });
        setErrorMessages((prev) => ({ ...prev, [name]: "" }));
        setValues({ ...values, [name]: value });
    };

    const checkEmptyFields = (): boolean => {
        let hasErrors = false;
        clearErrors();

        const fields = ["email", "firstName", "lastName", "phoneNumber", "password", "confirmPassword"];
        fields.forEach((field) => {
            if (values[field as keyof typeof values].trim() === "") {
                setErrors((prevErrors) => ({ ...prevErrors, [field]: true }));
                hasErrors = true;
            }
        });

        if (values.password !== values.confirmPassword) {
            setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: true }));
            setErrorMessages((prev) => ({ ...prev, confirmPassword: "Passwords do not match" }));
            hasErrors = true;
        }

        return hasErrors;
    };

    const getOrganizationHeaderInfo = () => {
        apiHelper(OnboardingService.getApiOnboardingOrgUserOrganizationHeaderInfo, { showNotification: false }, orgUserInviteCode)
            .then(({ data }) => {
                setHeaderInfo(data?.success)
                // fetchDoc(data?.success?.logoImageId)
            });
    }

    useEffect(() => {
        getOrganizationHeaderInfo()
        clearErrors();
    }, []);

    const validateConfirmPassword = () => {
        if (values.password !== values.confirmPassword) {
            setErrors((prev) => ({ ...prev, confirmPassword: true }));
            setErrorMessages((prev) => ({ ...prev, confirmPassword: "Passwords do not match" }));
        } else {
            setErrors((prev) => ({ ...prev, confirmPassword: false }));
            setErrorMessages((prev) => ({ ...prev, confirmPassword: "" }));
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (checkEmptyFields()) {
            return;
        } else {
            const payload = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phoneNumber: values.phoneNumber,
                password: values.password,
            };

            apiHelper(LoginService.postApiLoginAcceptOrganizationUserInvite,
                {
                    successMessage: "User Added Successfully",
                    failureMessage: "Unable to Add User",
                    showNotification: true,
                }, "eabd0b4d-c6b0-407f-843a-006872945fa9", payload).then(({ data }) => {
                    // setValues({
                    //     firstName: "",
                    //     lastName: "",
                    //     email: "",
                    //     phoneNumber: "",
                    //     password: "",
                    //     confirmPassword: ""
                    // });
                });
        }
    }

    return (
        <>
            <CustomTopBar size={undefined} userRoleId={undefined} showUserName={false} />
            <div className='inviteUserContainer'>
                <OrganizationNameHeader
                    OrgName={headerInfo?.name}
                    OrgImg={`data:image/*;base64,${headerInfo?.logoImageBase64}`}
                    SubHeaderName="User Signup"
                />

                <div className='inviteUserFormContainer'>
                    <p className='sub_heading fw-normal mb-4'>Verify your information below, set password and click join to accept the invitation.</p>
                    <Form onSubmit={submitHandler}>
                        <div className="d-flex inviteUserInputContainer">
                            {formDetails.map((item) => {
                                return (
                                    <EditCustomComponent
                                        key={item.label}
                                        label={item.label}
                                        placeholder={item.placeholder}
                                        value={item.value}
                                        name={item.name}
                                        onChangeFunction={handleChange}
                                        onBlurFunction={item.blurFunction}
                                        errorMessages={item.errorMessages}
                                        isError={item.isError}
                                        width="314px"
                                        type={item.type}
                                        required={item.required}
                                    />
                                );
                            })}
                        </div>
                        <div className='d-flex justify-content-center'>
                            <AddButtonWithArrow buttonname={"JOIN"} type={"submit"} />
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default InviteUser