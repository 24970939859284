import React from "react";
import PI, { PhoneInputProps } from "react-phone-input-2";
import "../CommanStyles/style.css";

const ReactPhoneInput: React.FC<PhoneInputProps> = (PI as any).default || PI;

const CustomPhoneNo = ({
  isReactPkg = true, required = false,
  label = "", type = "text", placeholder = "",
  value, name, focus = false, countryCode = "us",
  onChange, onBlur = () => {}, myClassName = "",
  disabled = false, errorMessage = "", isError,
  ...rest
}: any) => {

  const handleChange = (event: any) => {
    onChange(event);
  };

  let injectErrorClasses = ''; 

  if(typeof isError == "boolean"){
    injectErrorClasses = isError ? "errorBorder errorOutline" : "normalBorder normalOutline";
  }

  return (
    <>
      {isReactPkg ? (
        <ReactPhoneInput
          country={countryCode}
          value={value}
          onChange={handleChange}
          copyNumbersOnly={false}
          onBlur={onBlur}
          containerClass="phoneInputContainer normal_text"
          containerStyle={{
            border: isError ? "2px solid #F05252" : "1px solid #E5E7EB",
            marginBottom: isError ? "8px" : "16px",
          }}
          buttonClass="phoneInputButton"
          inputClass="phoneInputClass"
          disabled={disabled}
          {...rest}
        />
      ) : (
        <input
          type={type}
          name={name}
          value={value}
          required={required}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={onBlur}
          className={`${injectErrorClasses} ${myClassName}`}
          style={{
            border: isError ? "2px solid #F05252" : "1px solid #E5E7EB",
            outlineColor: isError ? "#E02424" : "#1A56DB",
          }}
          disabled={disabled}
        />
      )}
      {isError && (
        <div style={{ marginBottom: "16px" }}>
          <p className="errorMessageStyles">{errorMessage}</p>
        </div>
      )}
    </>
  );
};

export default CustomPhoneNo;
