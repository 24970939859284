import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import "./tenantonboard.css";
import { OnboardingForms } from "../../../typings/onboarding";
import { OnboardingStepsEnums } from "../../../constants/onboarding";
import CustomInputs from "../../common/Inputs";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import DisplayNamewithLeftBorder from "../../common/DisplayNamewithLeftBorder";


const StepFour: React.FC<any> = ({
  formData,
  errors,
  setFormData,
  handleRemoveError,
}: {
  formData: any;
  errors: { [key: string]: any };
  setFormData: React.Dispatch<React.SetStateAction<OnboardingForms>>;
  handleRemoveError: (
    field: string,
    form: keyof typeof OnboardingStepsEnums,
    error?: { key: string; subKey?: string }
  ) => void;
}) => {
  const handleContactDetailChange = useCallback((index: number, key: string, value: string) => {
    const updatedContactDetails = [...formData];
    updatedContactDetails[index][key] = value;
    setFormData({ ...formData, References: updatedContactDetails });
    handleRemoveError(key, "References", { key });
  }, [formData]);

  const generateBasicDetails = (index: number) => [
    {
      id: 1,
      name: "firstName",
      labelText: "First Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData[index]?.firstName,
      placeholder: "Enter first name",
      myClassName: "containerTextField",
      isError: errors[`[${index}]`]?.firstName?.length > 0,
      errorMessages: errors[`[${index}]`]?.firstName,
    },
    {
      id: 2,
      name: "lastName",
      labelText: "Last Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData[index]?.lastName,
      placeholder: "Enter last name",
      myClassName: "containerTextField",
      isError: errors[`[${index}]`]?.lastName?.length > 0,
      errorMessages: errors[`[${index}]`]?.lastName,
    },
    {
      id: 3,
      name: "email",
      labelText: "Email",
      type: FORM_CONTROLFIELD_TYPES.EMAIL,
      value: formData[index]?.email,
      placeholder: "Enter email",
      myClassName: "containerTextField",
      isError: errors[`[${index}]`]?.email?.length > 0,
      errorMessages: errors[`[${index}]`]?.email,
    },
    {
      id: 4,
      name: "phoneNumber",
      labelText: "Phone",
      type: FORM_CONTROLFIELD_TYPES.NUMBER,
      value: formData[index]?.phoneNumber,
      placeholder: "Enter phone no",
      myClassName: "containerTextField",
      isError: errors[`[${index}]`]?.phoneNumber?.length > 0,
      errorMessages: errors[`[${index}]`]?.phoneNumber,
    },
  ];

  const Radiodata = (index) => [
    {
      id: 1,
      label: "Employer",
      value: "Employer",
      checked: formData[index]?.referenceType === "Employer",
    },
    {
      id: 2,
      label: "Co-Worker",
      value: "Co-Worker",
      checked: formData[index]?.referenceType === "Co-Worker",
    }, {
      id: 3,
      label: "Current or Previous Landlord(s)",
      value: "Current or Previous Landlord(s)",
      checked: formData[index]?.referenceType === "Current or Previous Landlord(s)",
    }
  ]

  return (
    <>
      <div className="onboarding-stepper">
        <div className="onboarding-steppercard">
          <p className="main_heading pb-2">References</p>
          {formData?.map((items, index) => (
            <div key={index} className="onboardingstepper-form flexColumn gap-3 ">
              <DisplayNamewithLeftBorder HeaderName={"Reference Type"} />
              <div className="flexRow gap-2">
                {Radiodata(index)?.map((item, ind) => (
                  <div key={ind}>
                    <Form.Check
                      checked={items.referenceType == item.value}
                      label={item.label}
                      name={`referenceType-${index}`}
                      type="radio"
                      id={`info-radio-employer-${index}-${item.value}`}
                      value={item.value}
                      onChange={(e) => handleContactDetailChange(index, "referenceType", e.target.value)}
                    />
                  </div>
                ))}
              </div>
              <div key={index} className={`social-worker`}>
                {generateBasicDetails(index)?.map((item, i) => (
                  <div key={i}>
                    <CustomInputs
                      name={`${item.name}-${index}`}
                      type={item.type}
                      value={item.value}
                      labelText={item.labelText}
                      isFormControl={true}
                      onChange={(e) => handleContactDetailChange(index, item.name, e.target.value)}
                      placeholder={item.placeholder}
                      myClassName={item.myClassName}
                      isError={item.isError}
                      errorMessages={item.errorMessages}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default StepFour;




