import { useEffect, useState } from 'react'
import RangeDatePicker from '../common/formControls/RangeDatePicker'
import { StaticRange } from 'react-date-range';
import { Range } from 'react-date-range';
import { subDays, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import CustomDropDownMenu from '../common/CustomDropDownMenu';
import { GetImages } from '../../utils/GetImages';
import FilterDropdown from './filterDropdown';
import TransactionTable from './TransactionTable';
import apiHelper from '../../utils/apiHelper';
import { OrganizationService } from '@propertelligent/client-api';
import { UserLoginData } from '../common/charts/Enums';
import DateHelper from '../../utils/DateHelper';
import AddTraspButton from '../firstTimeUserExperience/commonCom/AddTraspButton';

const TransactionView = () => {
  const [relativeDateString, setRelativeDateString] = useState("")
  const [selectedType, setSelectedType] = useState([]);
  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
  const [fullyQualifiedSortFilters, setFullyQualifiedSortFilters] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [filterData, setFilterData] = useState()
  const [fullyQualifiedFilters, setFullyQualifiedFilters] = useState(null);
  const [organizationDataTX, setOrganizationDataTX] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [propertyDataTX, setPropertyDataTX] = useState([]);
  const [isEdit, setIsEdit] = useState(false)
  const [show, setShow] = useState(false)
  const [selectedPropertyId, setSelectedPropertyId] = useState(null)

  const [selectedUnitId, setSelectedUnitId] = useState(null)
  const [range, setRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [formValidated, setFormValidated] = useState({
    isSelectedProperties: false,
    isSelectDuration: false
  })

  console.log("fullyQualifiedFilters", fullyQualifiedFilters);

  const customRanges: StaticRange[] = [
    {
      label: 'Last Month',
      isSelected: () => false,
      range: () => ({
        startDate: startOfMonth(subMonths(new Date(), 1)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      }),
    },
    {
      label: 'Last 3 Months',
      isSelected: () => false,
      range: () => ({
        startDate: startOfMonth(subMonths(new Date(), 4)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      }),
    },
    {
      label: 'Last 6 Months',
      isSelected: () => false,
      range: () => ({
        startDate: startOfMonth(subMonths(new Date(), 7)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      }),
    },
    {
      label: 'Last Year',
      isSelected: () => false,
      range: () => ({
        startDate: new Date(new Date().getFullYear() - 1, 0, 1),
        endDate: new Date(new Date().getFullYear() - 1, 11, 31),
      }),
    },
    {
      label: 'Custom Range',
      isSelected: () => false,
      range: () => ({
        startDate: range[0].startDate,
        endDate: range[0].endDate,
      }),
    },
  ];

  const data = [
    {
      id: 1,
      img: undefined,
      //   onClick: () => setEmailCat("all"),
      name: "All",
      linkpath: undefined
    },
    {
      id: 2,
      img: undefined,
      name: "Read",
      //   onClick: () => setEmailCat("read"),
      linkpath: undefined
    },
    {
      id: 3,
      img: undefined,
      name: "Unread",
      //   onClick: () => setEmailCat("unread"),
      linkpath: undefined
    },
  ]

  console.log("range....", range);


  const onChangeHandler = (value) => {
    setRange(value);
    setFormValidated({
      ...formValidated,
      isSelectDuration: false,
    });
  }

  const filters = [
    {
      category: 'View By',
      options: ['Organisation', 'Property', 'Unit']
    },
    {
      category: 'Categories',
      options: ['Rent', 'Tax', 'Insurance', 'Maintenance']
    },
    {
      category: 'Payment Method',
      options: ['Online', 'Cash', 'Cheque', 'ACH']
    },
    {
      category: 'Payee',
      options: ['City tax collector', 'Electricity board', 'Water department']
    },
    {
      category: 'Payer',
      options: ['City tax collector', 'Electricity board', 'Water department']
    }
  ];

  const [selectedFilters, setSelectedFilters] = useState({
    'View By': [],
    'Categories': [],
    'Payment Method': [],
    'Payee': [],
    'Payer': [],
  });

  const handleFilterChange = (category, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [category]: prevFilters[category]
        ? prevFilters[category].includes(value)
          ? prevFilters[category].filter((item) => item !== value)
          : [...prevFilters[category], value]
        : [value],
    }));
  };

  const filterResponse = () => {

    apiHelper(
      OrganizationService.getApiOrganizationTransactionOrganizationFilters,
      { showNotification: false },
      orgId
    ).then((res) => {
      if (res?.data?.success) {
        setFilterData(res?.data?.success)
        console.log("checking filters data.....", res);
      }
    }).catch((err) => {
      console.log("Error fetching property data:", err);
    });
  };

  useEffect(() => {
    filterResponse()
  }, [])

  const handleTypeChange = (event, column) => {
    const selectedValue = event.target.value;
    setSelectedType((prevSelected) => {
      const updatedState = {
        ...prevSelected,
        [column]: selectedValue,
      };
      const result = Object.entries(updatedState)
        .map(([key, value]) => `${key} ${value}`)
        .join(",");
      setFullyQualifiedSortFilters(result);

      return updatedState;
    });
  };

  console.log({ selectedCategories });
  console.log({ fullyQualifiedFilters });



  const handleCategoryChange = (event, column) => {
    const category = event.target.value;
    console.log("column,category", column, category)
    if (category) {


      console.log("11111");


      setSelectedCategories((prevSelected) => {


        console.log("22222");


        const updatedColumnCategories = prevSelected[column] || [];
        if (updatedColumnCategories.includes(category)) {

          console.log("3333");

          const updatedCategories = updatedColumnCategories.filter(
            (c) => c !== category
          );
          const newSelectedCategories = {
            ...prevSelected,
            [column]:
              updatedCategories.length > 0 ? updatedCategories : undefined,
          };

          Object.keys(newSelectedCategories).forEach((key) => {
            if (
              newSelectedCategories[key] === undefined ||
              newSelectedCategories[key].length === 0
            ) {
              delete newSelectedCategories[key];
            }
          });

          console.log({ newSelectedCategories });


          return newSelectedCategories;
        } else {

          console.log({
            ...prevSelected,
            [column]: [...updatedColumnCategories, category],
          });


          return {
            ...prevSelected,
            [column]: [...updatedColumnCategories, category],
          };
        }
      });
    }
  };

  console.log("fulllyQualifiedFilter==>", fullyQualifiedFilters);
  useEffect(() => {

    console.log("hello11111");


    const filterClauses = Object.entries(selectedCategories)
      .map(([column, values]) => {
        if (values && (values as string[]).length > 0) {
          const valueClauses = (values as string[])
            .map((value) => `${column}=="${value}"`)
            .join(" or ");
          return valueClauses ? `(${valueClauses})` : "";
        } else {
          return null;
        }
      })
      .filter(Boolean);

    const newFullyQualifiedFilters =
      filterClauses.length > 0 ? filterClauses.join(" and ") : null;


    console.log({ newFullyQualifiedFilters });


    setFullyQualifiedFilters(newFullyQualifiedFilters);
  }, [selectedCategories, fullyQualifiedFilters]);

  const organizationsTXResponse = () => {
    apiHelper(
      OrganizationService.getApiOrganizationTransactionOrganizationlistTxGroups,
      { showNotification: false },
      orgId, undefined, undefined, undefined,
      undefined,
      undefined,
      relativeDateString !== "" ? DateHelper.getDateFilterType(relativeDateString) : undefined,
      relativeDateString !== "" ? DateHelper.getClientDate() : undefined,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].startDate.toISOString()}` : null,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].endDate.toISOString()}` : null,
    )
      .then((res) => {
        if (res?.data?.success) {
          setOrganizationDataTX(res?.data?.success);
        }
      })
      .catch((err) => {
        console.log("Error fetching organization transactions:", err);
      });
  };

  const organizationsResponse = (id) => {
    apiHelper(
      OrganizationService.getApiOrganizationTransactionOrganizationlist,
      { showNotification: false },
      id, undefined, undefined, undefined,
      fullyQualifiedFilters, undefined,
      relativeDateString !== "" ? DateHelper.getDateFilterType(relativeDateString) : undefined,
      relativeDateString !== "" ? DateHelper.getClientDate() : undefined,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].startDate.toISOString()}` : null,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].endDate.toISOString()}` : null,
    )
      .then((res) => {
        if (res?.data?.success) {
          setOrganizationData(res?.data?.success);
        }
      })
      .catch((err) => {
        console.log("Error fetching organization details:", err);
      });
  };
  const propertyResponse = (id) => {
    if (!id) return;
    apiHelper(
      OrganizationService.getApiOrganizationTransactionPropertylist,
      { showNotification: false }, id, undefined, undefined, undefined,
      fullyQualifiedFilters, undefined,
      relativeDateString !== "" ? DateHelper.getDateFilterType(relativeDateString) : undefined,
      relativeDateString !== "" ? DateHelper.getClientDate() : undefined,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].startDate.toISOString()}` : null,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].endDate.toISOString()}` : null,


    ).then((res) => {
      if (res?.data?.success) {
        setPropertyData(res?.data?.success);
      }
    }).catch((err) => {
      console.log("Error fetching property data:", err);
    });
  };

  const unitResponse = (id) => {
    if (!id) return;
    apiHelper(
      OrganizationService.getApiOrganizationTransactionUnitlist,
      { showNotification: false },
      id, undefined, undefined, undefined,
      fullyQualifiedFilters,
      undefined,
      relativeDateString !== "" ? DateHelper.getDateFilterType(relativeDateString) : undefined,
      relativeDateString !== "" ? DateHelper.getClientDate() : undefined,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].startDate.toISOString()}` : null,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].endDate.toISOString()}` : null,
    ).then((res) => {
      if (res?.data?.success) {
        setUnitData(res?.data?.success);
      }
    }).catch((err) => {
      console.log("Error fetching property data:", err);
    });
  };
  const propertyResponseTX = (id) => {
    if (!id) return;
    apiHelper(
      OrganizationService.getApiOrganizationTransactionPropertylistTxGroups,
      { showNotification: false },
      id, undefined, undefined, undefined, undefined, undefined,
      relativeDateString !== "" ? DateHelper.getDateFilterType(relativeDateString) : undefined,
      relativeDateString !== "" ? DateHelper.getClientDate() : undefined,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].startDate.toISOString()}` : null,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].endDate.toISOString()}` : null,
    )
      .then((res) => {
        if (res?.data?.success) {
          setPropertyDataTX(res?.data?.success);
        }
      })
      .catch((err) => {
        console.log("Error fetching property transaction data:", err);
      });
  };

  useEffect(() => {
    organizationsTXResponse();
  }, []);
  return (
    <>
      <div className='mb-3'>
        <div className="transactionHeader border-bottom-0 d-flex flex-column">
          <div className="d-flex justify-content-between">
            <p className="top_header black">Transactions</p>
            <div className="monthhead gap-3">
              <div>
                <AddTraspButton bname={isEdit ? "Save" : "Edit"} border={true} onClick={() => setIsEdit(!isEdit)} />
              </div>
              <div>
                <RangeDatePicker
                  range={range}
                  customRanges={customRanges}
                  onChangeHandler={onChangeHandler}
                  relativeDateString={relativeDateString}
                  setRelativeDateString={setRelativeDateString}
                  fetchData={organizationsTXResponse}
                  applyButton={true}
                />
              </div>
              <div>
                <CustomDropDownMenu
                  data={[]}
                  show={show}
                  Element={
                    <div className="d-flex align-items-center normal_text gap-2 px-2" onClick={() => setShow(!show)}>
                      <img src={GetImages.Filter} className="iconstyle" alt="Filter" />
                      <p>Filter</p>
                    </div>
                  }
                  content={
                    <FilterDropdown
                      show={show}
                      setShow={setShow}
                      filters={filterData}
                      selectedCategories={selectedCategories}
                      handleCategoryChange={handleCategoryChange}
                      organizationsResponse={() => organizationsResponse(orgId)}
                      propertyResponse={() => propertyResponse(selectedUnitId)}
                      unitResponse={() => unitResponse(selectedPropertyId)}
                    />
                  }
                />
              </div>
            </div>
          </div>

          <div className='mb-3'>
            <TransactionTable
              organizationDataTX={organizationDataTX}
              isEdit={isEdit}
              organizationData={organizationData}
              propertyData={propertyData}
              unitData={unitData}
              propertyDataTX={propertyDataTX}
              organizationsResponse={organizationsResponse}
              propertyResponse={propertyResponse}
              unitResponse={unitResponse}
              setSelectedPropertyId={setSelectedPropertyId}
              setSelectedUnitId={setSelectedUnitId}
              propertyResponseTX={propertyResponseTX}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default TransactionView