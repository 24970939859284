import { useEffect, useState } from 'react'
import RequestBreadcrumb from '../../common/RequestBreadcrumb'
import { Form } from 'react-bootstrap'
import TemplateMainHeader from '../Templete/CommonComp/TemplateMainHeader'
import DisplayNamewithLeftBorder from '../../common/DisplayNamewithLeftBorder'
import { FORM_CONTROLFIELD_TYPES } from '../../../constants/constants'
import CustomInputs from '../../common/Inputs'
import { GetImages } from '../../../utils/GetImages'
import { useError } from '../../../utils/context/ErrorContext'
import apiHelper from '../../../utils/apiHelper'
import { OrganizationService } from '@propertelligent/client-api'
import { UserLoginData } from '../../common/charts/Enums'

const index = () => {
    const { errors, setErrors, errorMessages, setErrorMessages, clearErrors } = useError();
    const [values, setValues] = useState({
        rentDueDay: "",
        gracePeriods: "",
        monthlyPnl: ""
    })
    const organizationId = Number(localStorage.getItem(UserLoginData.organization_id));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value })
        setErrors((prev) => ({ ...prev, [name]: false }));
    }

    const checkEmptyFields = (): boolean => {
        let hasError = false;
        clearErrors();
        const newErrors = { ...errors };
        const newErrorMessages = { ...errorMessages };

        if (values?.rentDueDay === "") {
            newErrors.rentDueDay = true;
            hasError = true;
        } else {
            newErrors.rentDueDay = false;
        }

        if (values?.gracePeriods === "") {
            newErrors.gracePeriods = true;
            hasError = true;
        } else {
            newErrors.gracePeriods = false;
        }

        if (values?.monthlyPnl === "") {
            newErrors.monthlyPnl = true;
            hasError = true;
        } else {
            newErrors.monthlyPnl = false;
        }
        setErrors(newErrors);
        setErrorMessages(newErrorMessages);
        return hasError;
    };

    useEffect(() => {
        getData()
        clearErrors()
    }, [])

    const getData = () => {
        apiHelper(OrganizationService.getApiOrganizationProcessingDays, { showNotification: false }, organizationId)
            .then((res) => {
                setValues({
                    ...values,
                    rentDueDay: res?.data?.success?.rentDueDay,
                    gracePeriods: res?.data?.success?.gracePeriod,
                    monthlyPnl: res?.data?.success?.pnlProcessingDay
                })
            }).catch((err) => { })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (checkEmptyFields()) {
        } else {
            const updateObject = {
                rentDueDay: Number(values?.rentDueDay),
                gracePeriod: Number(values?.gracePeriods),
                pnlProcessingDay: Number(values?.monthlyPnl)
            }
            apiHelper(OrganizationService.putApiOrganizationProcessingDays,
                {
                    successMessage: "Updated Successfully",
                    failureMessage: "Unable to Update",
                    showNotification: true
                }, organizationId, updateObject)
                .then((res) => {
                    if (res?.data?.success) {
                        getData()
                    }
                }).catch((err) => { })
        }
    }

    return (
        <div className='basic_details fees_and_commissions_container'>
            <div className="basic_detail_breadcrumb">
                <RequestBreadcrumb
                    firstName={"Dashboard"}
                    link={"/"}
                    currentStep={"Payment Processing Dates"}
                />
            </div>
            <div className='basic_details_container'>
                <TemplateMainHeader
                    searchTerm={undefined}
                    placeholder={undefined}
                    setSearchTerm={undefined}
                    headerName={"Payment Processing Dates"}
                />
                <Form className='pt-1 feesCommissionForm' onSubmit={handleSubmit}>
                    <div className='security_deposit_container'>
                        <DisplayNamewithLeftBorder
                            HeaderName="Rent Collection"
                        />
                        <div className='mt-3 mb-3 d-flex align-items-center gap-3'>
                            <CustomInputs
                                labelText={"Rent Due Day"}
                                name={"rentDueDay"}
                                type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                value={values?.rentDueDay}
                                isFormControl={true}
                                onChange={handleChange}
                                placeholder="Enter Day"
                                myClassName="normal_text"
                                isError={errors.rentDueDay}
                                errorMessages={errorMessages.rentDueDay}
                                height={"48px"}
                                currencyHeight={"48px"}
                                width={"150px"}
                                required
                            />
                            <p className='normal_text grey_subtext1 mt-2'>of Month</p>
                        </div>
                        <CustomInputs
                            labelText={"Rent Grace Period"}
                            name={"gracePeriods"}
                            type={FORM_CONTROLFIELD_TYPES.NUMBER}
                            value={values?.gracePeriods}
                            isFormControl={true}
                            currencySymbol="Days"
                            onChange={handleChange}
                            placeholder="Enter Grace Period"
                            myClassName="normal_text"
                            isError={errors.gracePeriods}
                            errorMessages={errorMessages.gracePeriods}
                            height={"48px"}
                            currencyHeight={"48px"}
                            width={"205px"}
                            required
                        />
                    </div>
                    <div>
                        <DisplayNamewithLeftBorder
                            HeaderName="Statements"
                        />
                        <div className='mt-3 mb-3 d-flex align-items-center gap-3'>
                            <CustomInputs
                                labelText={"Generate Monthly PNL *"}
                                name={"monthlyPnl"}
                                type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                value={values?.monthlyPnl}
                                isFormControl={true}
                                onChange={handleChange}
                                placeholder="Enter Day"
                                myClassName="normal_text"
                                isError={errors.monthlyPnl}
                                errorMessages={errorMessages.monthlyPnl}
                                height={"48px"}
                                currencyHeight={"48px"}
                                width={"170px"}

                            />
                            <p className='normal_text grey_subtext1 mt-2'>of Month</p>
                        </div>
                    </div>
                    <div className="basic_details_footer">
                        <button type="submit" className="saveChangesButton normal_text fw-600">
                            Save Changes <img src={GetImages.WhiteForwardArrow} />
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default index