import React from "react";
import "./tenantonboard.css";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import {
  OnboardingBasicDetailsForm,
  OnboardingForms,
} from "../../../typings/onboarding";
import { OnboardingStepsEnums } from "../../../constants/onboarding";
import { DocumentEntityTypesEnum, DocumentTypesEnum } from "@propertelligent/client-api";
import CustomInputs from "../../common/Inputs";
import DragAndDropUploader from "../../common/CustomUploader/DragAndDropUploader";
import { validateFirstName, validateNumber } from "../../../utils/validateHelper";
import { useError } from "../../../utils/context/ErrorContext";


const StepOne: React.FC<any> = ({
  formData,
  errors,
  handleRemoveError,
  setFormData,
  docsArr,
  setDocsArr,
  isEdit,
  leaseApplicationid
}: {
  formData: OnboardingBasicDetailsForm;
  errors: { [key: string]: any };
  handleRemoveError: (
    field: string,
    form: keyof typeof OnboardingStepsEnums,
    error?: { key: string; subKey?: string }
  ) => void;
  setFormData: React.Dispatch<React.SetStateAction<OnboardingForms>>;
  docsArr: any;
  setDocsArr: any;
  isEdit: boolean,
  leaseApplicationid: number

}) => {
  const { setErrors, errorMessages } = useError()

  const handleChange = (key: string, value: string) => {
    setFormData((prevFormsData) => ({
      ...prevFormsData,
      BasicDetails: { ...prevFormsData.BasicDetails, [key]: value },
    }));

    handleRemoveError(key, "BasicDetails", {
      key: key,
    });
  };

  const handleUploadServiceRequestDocument = async (selectedFile) => {
    if (selectedFile) {
      const newDoc = {
        EntityTypeId: DocumentEntityTypesEnum.LeaseApplication,
        EntityId: leaseApplicationid,
        DocumentTypeId: DocumentTypesEnum.Identification,
        IsMain: false,
        FileName: selectedFile?.name,
        Document: new Blob([selectedFile]),
        _rawFile: selectedFile,
      };
      setDocsArr((prevState) => [...prevState, newDoc]);
    }
  };

  const handleDelete = (fileNameToDelete) => {
    const updatedDocsArr = docsArr.filter((doc) => doc.FileName !== fileNameToDelete);
    setDocsArr(updatedDocsArr);
  };

  const BasicDetails = [
    {
      id: 1,
      name: "name",
      labelText: "First Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.firstName,
      isFormControl: true,
      blurFunction: () => validateFirstName(formData.firstName, setErrors),
      onChange: (e) => { handleChange("firstName", e.target.value) },
      placeholder: "Enter first name",
      myClassName: "containerTextField",
      isError: errors?.firstName,
      errorMessages: errorMessages?.firstName,
    },
    {
      id: 2,
      name: "lastName",
      labelText: "Last Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.lastName,
      isFormControl: true,
      blurFunction: () => validateFirstName(formData.lastName, setErrors),
      onChange: (e) => handleChange("lastName", e.target.value),
      placeholder: "Enter last name",
      myClassName: "containerTextField",
      isError: errors?.lastName,
      errorMessages: errorMessages?.lastName,
    },
    {
      id: 3,
      name: "email",
      labelText: "Email",
      type: FORM_CONTROLFIELD_TYPES.EMAIL,
      value: formData?.email,
      isFormControl: true,
      onChange: (e) => handleChange("email", e.target.value),
      placeholder: "Enter email",
      myClassName: "containerTextField",
      isError: errors?.email,
      errorMessages: errorMessages?.email,
    },
    {
      id: 4,
      name: "phoneNumber",
      labelText: "Phone",
      type: FORM_CONTROLFIELD_TYPES.NUMBER,
      value: formData?.phoneNumber,
      isFormControl: true,
      onChange: (e) => handleChange("phoneNumber", e.target.value),
      onBlur: () => validateNumber(formData?.phoneNumber, setErrors),
      placeholder: "Enter phone number",
      myClassName: "containerTextField",
      isError: errors?.phoneNumber,
      errorMessages: errorMessages?.phoneNumber,
    },
    {
      id: 5,
      name: "password",
      labelText: "Password",
      type: FORM_CONTROLFIELD_TYPES.PASSWORD,
      value: formData?.password,
      isFormControl: true,
      onChange: (e) => handleChange("password", e.target.value),
      placeholder: "Enter Password",
      myClassName: "containerTextField",
      isError: errors?.password,
      errorMessages: errorMessages?.password,
      disabled: isEdit
    },
    {
      id: 6,
      name: "confirmPassword",
      labelText: "Confirm Password",
      type: FORM_CONTROLFIELD_TYPES.PASSWORD,
      value: formData?.confirmPassword,
      isFormControl: true,
      onChange: (e) => handleChange("confirmPassword", e.target.value),
      placeholder: "Enter confirm Password",
      myClassName: "containerTextField",
      isError: errors?.confirmPassword,
      errorMessages: errorMessages?.confirmPassword,
      disabled: isEdit
    }
  ]

  return (
    <>
      <div className="onboarding-stepper">
        <div className="onboarding-steppercard">
          <p className="main_heading py-2">Basic Details</p>
          <div className="onboardingstepper-form">
            <div className="basic-form" style={{ width: "96%" }}>
              {BasicDetails?.map((item) => (
                <div key={item?.id}>
                  <CustomInputs
                    name={item.name}
                    type={item.type}
                    value={item.value}
                    labelText={item.labelText}
                    isFormControl
                    onChange={item.onChange}
                    disabled={item?.disabled}
                    placeholder={item.placeholder}
                    myClassName={item.myClassName}
                    isError={item.isError}
                    errorMessages={item.errorMessages}
                    width={"322px"}
                  />
                </div>
              ))}
            </div>
            <DragAndDropUploader
              handleChange={handleUploadServiceRequestDocument}
              docsArr={docsArr}
              handleDelete={handleDelete}
              name="  Upload State Issued ID (e.g. License, Passport etc)"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StepOne;
