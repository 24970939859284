import { useState, useEffect } from 'react';
import { CustomSelect } from '../formControls';
import { ContractorService, PropertyService, TenantService, UnitService, ServiceRequestService, OrganizationService, EntityType } from "@propertelligent/client-api";
// import { mapArrayFields } from '../../../utils/helper';

const GenericSearchAbleDropdown = ({
  entityType = null,
  fieldName,
  name,
  isFormControl = false,
  initialOptions = [],
  required = false,
  disabled = false,
  isMulti = false,
  label = '',
  value,
  placeholder = '',
  myClassName = "",
  isError = false,
  errorMessage = '',
  onChange,
  callbackRowsPipe = null,
  defaultOption = null,
  style = undefined,
  onClick = () =>{}
}) => {
  const [options, setOptions] = useState(initialOptions);

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);


  let httpFetchDataFn = null;
  if (entityType === EntityType.Contractor) {
    httpFetchDataFn = ContractorService.getApiContractorOrganizationlookup
  }
  else if (entityType === EntityType.Property) {
    httpFetchDataFn = PropertyService.getApiPropertyOrganizationlookup
  }
  else if (entityType === EntityType.Tenant) {
    httpFetchDataFn = TenantService.getApiTenantOrganizationlookup
  }
  else if (entityType === EntityType.Unit) {
    httpFetchDataFn = UnitService.getApiUnitOrganizationlookup
  }
  else if (entityType === EntityType.ServiceRequest) {
    httpFetchDataFn = ServiceRequestService.getApiServiceRequest
  }
  else if (entityType === EntityType.Organization) {
    httpFetchDataFn = OrganizationService.getApiOrganization
  }
  const fetchData = () => {
    httpFetchDataFn()
      .then((res) => {
        if (res?.success) {
          if (callbackRowsPipe) {
            const updatedRows = callbackRowsPipe(res?.success);
            setOptions(updatedRows);
          }
          else
            setOptions(res?.success);
        }
      })
      .catch((e) => { })
  }
  useEffect(() => {
    if (httpFetchDataFn) {
      fetchData();
    }
  }, [])


  return (
    <CustomSelect
      fieldName={fieldName} options={options}
      name={name} isFormControl={isFormControl}
      value={value} required={required}
      onChange={onChange} isMulti={isMulti}
      placeholder={placeholder} disabled={disabled}
      isError={isError} errorMessage={errorMessage}
      myClassName={myClassName}
      defaultOption={defaultOption}
      style={style} onClick={onClick}
    />
  );
};

export default GenericSearchAbleDropdown;
