import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { GetImages } from '../../utils/GetImages'
import AddTraspButton from '../firstTimeUserExperience/commonCom/AddTraspButton'
import AddButtonWithArrow from '../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import { Col, Row, Form } from 'react-bootstrap'
import CustomInputs from '../common/Inputs'
import { FORM_CONTROLFIELD_TYPES } from '../../constants/constants'
import PaymentListCard from './PaymentListCard'
import EmptyData from '../common/EmptyData'
import { PaymentContext } from '../../utils/context/PaymentContext'
import PaymentDetail from './Modals/PaymentDetail'
import apiHelper from '../../utils/apiHelper'
import { PaymentService, TenantService } from '@propertelligent/client-api'
import Electrical from '../../assets/electrical_services.svg'
import Rent from "../../assets/rent.svg"
import { UserLoginData } from '../common/charts/Enums'
import { useError } from '../../utils/context/ErrorContext'


const SettlePayment = () => {
    const location = useLocation();
    const { selectedPayment, paymentMode } = location.state || {};
    const [currentPaymentMode, setCurrentPaymentMode] = useState(paymentMode || 'Payables');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [show, setShow] = useState(false)
    const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
    const [tenantData, setTenantData] = useState([])
    const [payeeName, setPayeeName] = useState("")
    const { id } = useParams()
    const navigate = useNavigate()
    const [payablesData, setPayablesData] = useState()
    const [paymentMethod, setPaymentMethod] = useState('Cash');
    const { selectedItems, setSelectedItems, selectedOption, payeeAccountsArr, paymentData, setPaymentData } = useContext(PaymentContext);
    const tenantId = localStorage.getItem("tenantId")
    const { errors, setErrors, errorMessages, setErrorMessages, clearErrors } = useError();
    const [formData, setFormData] = useState(
        {
            id: 1,
            date: "",
            transactionId: "",
            checkNum: "",
            paymentMethodId: "",
        },
    )

    const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));

    const checkEmptyFields = (): boolean => {
        clearErrors();
        const newErrors = { ...errors };
        const newErrorMessages = { ...errorMessages };
        let hasErrors = false;

        if (formData[0]?.date === "") {
            newErrors.dueDate = true;
            hasErrors = true;
        }

        if (paymentMethod === "") {
            newErrors.paymentMethod = true;
        }
        setErrors(newErrors);
        setErrorMessages(newErrorMessages);
        return hasErrors;
    };

    const transactionId = formData?.transactionId || crypto.randomUUID().replaceAll('-', '').toUpperCase();

    const handleSubmit = (e) => {
        e.preventDefault()
        if (checkEmptyFields()) {
        } else
            if (paymentMode === "Payables") {
                selectedItems.map((item) => {
                    const payload = {
                        payDueAccountId: Number(item?.id),
                        amount: Number(item?.amount),
                        fromPaymentMethodId: Number(formData?.paymentMethodId),
                        checkNumber: formData?.checkNum,
                        paymentDate: new Date(formData?.date).toISOString(),
                        transactionId: transactionId,
                    };
                    if (paymentMethod !== "Check" && paymentMethod !== "Cash") {
                        delete payload.checkNumber;
                    }
                    apiHelper(PaymentService.putApiPaymentDueAccountPay, {
                        successMessage: "Payment settled successfully",
                        failureMessage: "Unable to settle successfully",
                        showNotification: true,
                    }, payload)
                        .then((res) => { })
                        .catch((err) => {
                            console.log("err", err);
                        });
                });
            } else if (paymentMode === "Receivables") {
                Object.entries(selectedOption).forEach(([id, value]) => {
                    const item = payeeAccountsArr.find((acc) => acc.id === Number(id));
                    if (!item) return;
                    const payload = {
                        payDueAccountId: Number(item?.id),
                        amount: Number(item?.amount),
                        fromPaymentMethodId: Number(formData?.paymentMethodId),
                        checkNumber: formData?.checkNum,
                        paymentDate: new Date(formData?.date).toISOString(),
                        transactionId: transactionId,
                    };
                    apiHelper(PaymentService.putApiPaymentDueAccountPay, {
                        successMessage: "Payment settled successfully",
                        failureMessage: "Unable to settle payment",
                        showNotification: true,
                    }, payload)
                        .then((res) => { })
                        .catch((err) => {
                            console.log("err", err);
                        });
                });
            }
    }



    const handleEdit = () => {
        setShow(true)
    }

    const getOutstandingTotal = () => {
        const totalOutstandingAmount = payeeAccountsArr.reduce((total, item) => total + item.amount, 0);
        const totalAmount = getTotalPayableAmount()
        return totalOutstandingAmount - totalAmount;
    };

    const getTotalPayableAmount = () => {
        const payingAmount = selectedItems.reduce((total, item) => total + item.amount, 0);
        return payingAmount
    }

    const getTenantId = () => {
        apiHelper(
            TenantService.getApiTenant1,
            { showNotification: false },
            tenantId
        )
            .then((res) => {
                setPayeeName(res?.data?.success?.accountName)
                setTenantData(res?.data?.success)
            })
            .catch((error) => {
            });
    };

    const getPayeeAccount = () => {
        apiHelper(PaymentService.getApiPayeeAccount, { showNotification: false }, id)
            .then((res) => {
                if (res?.data?.success) {
                    setPayablesData(res?.data?.success)
                }
            }).catch((err) => {
                console.log("error ===>", JSON.stringify(err));
            })
    }

    useEffect(() => {
        getTenantId();
        PaymentMethodList();
        getPayeeAccount();
    }, []);

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const PaymentMethodList = () => {
        apiHelper(
            PaymentService.getApiPaymentMethodOrganizationlist,
            { showNotification: false },
            orgId
        )
            .then((res) => {
                if (res?.data?.success) {
                    setFormData({ ...formData, paymentMethodId: res?.data?.success[0]?.id })
                    setPaymentMethodsArr(res?.data?.success);
                }
            })
            .catch((ptErr) => { });
    }

    const renderPaymentFields = () => {
        switch (paymentMethod) {
            case 'Cash':
                return;

            case 'Check':
                return (
                    <>
                        <Row className="paymentinputrow">
                            <Col className='paymentinputCol'>
                                <Form.Group controlId="ChequeNo">
                                    <CustomInputs
                                        labelText="Check No."
                                        placeholder="Enter Check No."
                                        name="checkNum"
                                        type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                        isFormControl
                                        myClassName="normal_text w-50"
                                        height="37px"
                                        value={formData?.checkNum}
                                        isError={errors.checkNo}
                                        errorMessages={errorMessages.checkNo}
                                        onChange={(e) => {
                                            setFormData({ ...formData, checkNum: e.target.value })
                                            setErrors({ ...errors, checkNo: false })
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                );

            default:
                return (
                    <>
                        <Row className="paymentinputrow">
                            <Col className='paymentinputCol'>
                                <Form.Group controlId="payeeAccountSelect">
                                    <CustomInputs
                                        labelText="Transaction ID"
                                        placeholder="Enter Transaction ID"
                                        name="transactionId"
                                        type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                        isFormControl
                                        myClassName="normal_text  w-50"
                                        height="37px"
                                        value={formData?.transactionId}
                                        isError={errors.transactionId}
                                        errorMessages={errorMessages.transactionId}
                                        onChange={(e) => {
                                            setFormData({ ...formData, transactionId: e.target.value })
                                            setErrors({ ...errors, transactionId: false })
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                );

        }
    };

    return (
        <>
            <div className='m-3 '>
                <div className="newTenantFormTopBar d-flex align-items-center">
                    <img
                        onClick={() => {
                            navigate(-1);
                            setPaymentData([])
                            setSelectedItems([])
                        }}
                        height={28}
                        width={28}
                        src={GetImages.leftChevronBlack}
                        className="clickable"
                    />
                    <p className="main_heading black">Settle Payment</p>
                </div>

                <div className='settlePaymentCard'>
                    <div className="settlePaymentLeft">
                        {selectedPayment ? (
                            <PaymentListCard
                                tenantData={tenantData}
                                data={[selectedPayment]}
                                selectedItems={selectedItems}
                                paymentMode={currentPaymentMode}
                                onCardClick={undefined}
                                payablesData={payablesData}
                            />
                        ) : (
                            <p>No Data Available</p>
                        )}

                        <Form className="paymentForm" onSubmit={handleSubmit}>
                            <div className="paymentMode BorderBottom">
                                <span className='sub_heading black mb-1'>Select Payment Mode</span>
                                {/* <div className="paymentRadioButton">
                                    {accountOptions.map((option) => (
                                        <div className={paymentMethod === option.name ? "paymentTypeBtnActive" : "paymentTypeBtn"} key={option.id}>
                                            <AddTraspButton
                                                bname={option.name}
                                                imageValue={GetImages[option.name]}
                                                textclass="normal_text black fw-500"
                                                className="paymentTypeBtn"
                                                iconPosition="right"
                                                IconClassName="Icon32"
                                                onClick={() => setPaymentMethod(option.name)}
                                            />
                                        </div>
                                    ))}
                                </div> */}
                            </div>

                            <Row className="paymentinputrow">
                                <Col>
                                    <Form.Group controlId="paymentMethodSelect">
                                        <Form.Label className="normal_text black">
                                            Payment Method *
                                        </Form.Label>
                                        <Form.Select
                                            required
                                            value={paymentMethod}
                                            onChange={handlePaymentMethodChange}
                                        >
                                            <option value="">Select Payment Method</option>
                                            {paymentMethodsArr.map((item) => (
                                                <option key={item.id} value={item.name}>
                                                    {item.accountName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className="invalid-feedback"
                                        >
                                            Please select a Payment Method.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                            </Row>
                            {paymentMethod && renderPaymentFields()}
                            <Row className="paymentinputrow">
                                <Col className='paymentinputCol'>
                                    <Form.Group controlId="dueDateInput">
                                        <CustomInputs
                                            name="dueDate"
                                            labelText="Date of Payment"
                                            type={FORM_CONTROLFIELD_TYPES.DATE}
                                            isFormControl
                                            placeholder="Select Date"
                                            myClassName="normal_text  w-50"
                                            height="37px"
                                            value={formData?.date}
                                            isError={errors.dueDate}
                                            errorMessages={errorMessages.dueDate}
                                            onChange={(e) => {
                                                setFormData({ ...formData, date: e.target.value })
                                                setErrors({ ...errors, dueDate: false })
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <div className="editFormFooter ">
                                <AddButtonWithArrow
                                    buttonname="Confirm Payment"
                                    disabled={undefined}
                                    onClick={() => {
                                        setShow(false)
                                        setShowConfirmation(true)
                                    }}
                                />
                            </div>
                        </Form>
                    </div>


                    <div className='settlePaymentRight'>
                        <div className='borderWithRadius'>
                            <div className='d-flex justify-content-between p-3 BorderBottom'>
                                <p className='heading'>
                                    Payment Overview
                                </p>
                                <AddTraspButton bname="Edit" width="unset" id="editPayment" onClick={() => handleEdit()} />
                                {
                                    show && selectedPayment &&
                                    (
                                        <PaymentDetail
                                            onClose={() => setShow(false)}
                                            heading={"Edit Payment Details"}
                                            show={true}
                                            paymentMode={paymentMode}
                                            payeAccountId={selectedPayment}
                                            handleClick={undefined}
                                            isEdit={true}
                                            selectedItemId={selectedItems.id}
                                        />
                                    )
                                }
                            </div>

                            {paymentMode === "Payables" && selectedItems.length === 0 ? (
                                <EmptyData
                                    mainText="No Due Payments"
                                    button={undefined}
                                    ImageSrc={GetImages.DuePayments}
                                />
                            ) : paymentMode === "Payables" ? (
                                selectedItems && selectedItems.map((item, index) => {
                                    return (
                                        <div key={index} className="Last_transaction">
                                            <div className="d-flex gap-1">
                                                <img src={paymentMode === "Payables" ? Electrical : Rent} />
                                                <div className="d-flex flex-column">
                                                    <p className="normal_text">
                                                        {`${item.description} for ${item.payerPayeeName}`}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex gap-3">
                                                <p className="normal_text fw-500">${item.amount}</p>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                Object.entries(selectedOption).map(([id, value], index) => {
                                    const item = payeeAccountsArr.find((acc) => acc.id === Number(id));
                                    if (!item) return null;
                                    return (
                                        <div key={index} className="Last_transaction">
                                            <div className="d-flex gap-1">
                                                <img src={Rent} alt="Receivables Icon" />
                                                <div className="d-flex flex-column">
                                                    <p className="normal_text">
                                                        {`${item?.formatted?.PaymentTypeId} - ${value}`}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex gap-3">
                                                <p className="normal_text fw-500">${item.amount}</p>
                                            </div>
                                        </div>
                                    );
                                })
                            )}

                            <div className="d-flex w-100 flex-column p-3 BorderBottom">
                                <div className='d-flex justify-content-between'>
                                    <p className='normal_text'>You're Paying</p>
                                    <p className='normal_text fw-600'>${getTotalPayableAmount()}</p>
                                </div>

                                <div className='PerformanceOverview_Tag'>
                                    <p className='normal_text'>Outstanding amount due in 30 days</p>
                                    <p className='normal_text fw-600'>${getOutstandingTotal()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SettlePayment