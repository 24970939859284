import { FAIL_GET_ALL_PROPERTY, REQUEST_GET_ALL_PROPERTY, SUCCESS_GET_ALL_PROPERTY, SUCCESS_GET_PROPERTY } from "../actions/types";




export const propertyListReducer =(state = [], action)=>{
switch (action.type){
 
        case REQUEST_GET_ALL_PROPERTY:
            return { loading: true };
        case SUCCESS_GET_ALL_PROPERTY:
        return {
            ...state, success: true, loading: false,
          propertyList: action.payload,
        };
        case FAIL_GET_ALL_PROPERTY:
        return { loading: true, error: action.payload }
        default:
      return state;
}
}


export const SinglePropertyListReducer =(state = {}, action)=>{
    switch (action.type){
        case SUCCESS_GET_PROPERTY:
            return {
              ...state,
              singleProperty: action.payload,
            };
            default:
          return state;
    }
    }

