import React, { useState ,useEffect} from "react";
import "./CommonStyle.css";
import { GetImages } from "../../../utils/GetImages";
interface CounterBoxProps {
  initialValue?: number;
}

const CounterBox = ({ setUnitVal, unitval, type , initialValue }) => {
  const [count, setCount] = useState<number>(initialValue || 0);


  const increment = () => {
    setCount(count + 1);
    if (type == "bathroom") {
      setUnitVal({ ...unitval, numberOfBathrooms: count + 1 });
    } else if (type == "bedroom") {
      setUnitVal({ ...unitval, numberOfBedrooms: count + 1 });
    }
  };

  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
      if (type == "bathroom") {
        setUnitVal({ ...unitval, numberOfBathrooms: count - 1 });
      } else if (type == "bedroom") {
        setUnitVal({ ...unitval, numberOfBedrooms: count - 1 });
      }
    }
  };

  return (
    <div className="mainIncreDecre">
      <div className="itemCount iconstyle48" onClick={decrement}>
        <img className="iconstyle11 " src={GetImages.BlackMinus} />
      </div>
      <div className="itemCount iconstyle48">
        <p className="countNum m-0">{count}</p>
      </div>

      <div className="itemCount iconstyle48" onClick={increment}>
        <img className="iconstyle11" src={GetImages.BlackAdd} alt={GetImages.BlackAdd}/>
      </div>
    </div>
  );
};

export default CounterBox;
