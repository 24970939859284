import React, { useEffect, useState } from "react";
import { PaymentService } from "@propertelligent/client-api";
import { Offcanvas } from "react-bootstrap";
import EmptyData from "../../common/EmptyData";
import { getKeyByValu } from "./helper";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import PayForm from "../../common/Payment/PayForm";
import DateHelper from "../../../utils/DateHelper";
import { GetImages } from "../../../utils/GetImages";
import SendReminder from "../../tenants/SendReminder";
import apiHelper from "../../../utils/apiHelper";

const Transactions = ({
  sorting,
  rows,
  paymentMethodsArr,
  fetchData,
  fetchAndFormatExpensebycategory,
}) => {
  const [showModel, setShowModel] = useState(false);
  const [dueAccountId, setDueAccountId] = useState(-1);

  const [showReminderModel, setShowReminderModel] = useState(false);

  useEffect(() => {}, [rows?.length]);

  const sortedTransactions = rows?.sort(
    (a, b) => new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime()
  );
  const recentFiveTransactions = sortedTransactions?.slice(0, 5);

  const handleSubmitPayPaymentDueAccount = (values: any) => {
    if (dueAccountId > 0) {
      Object.assign(values, {
        payDueAccountId: dueAccountId,
      });

      apiHelper(PaymentService.putApiPaymentDueAccountPay,{successMessage:"Payment Added Successfully",failureMessage:"Unable to add payment",showNotification:true},values)
        .then((res: any) => {
          if (res?.success) {
            setShowModel(false);
            fetchData();
            fetchAndFormatExpensebycategory();
          }
        })
        .catch((error) => {
        });
    }
  };
  return (
    <>
      <div className="rounded-2  px-3  w-100">
        {recentFiveTransactions?.length === 0 ? (
          <>
            <EmptyData
              mainText={undefined}
              subText="Data Not Available"
              button={undefined}
            />
          </>
        ) : sorting === "last-five" ? (
          recentFiveTransactions?.map((item, index) => (
            <div
              key={item.id}
              className={
                index !== recentFiveTransactions?.length - 1
                  ? `transactionRow BorderBottom`
                  : `transactionRow`
              }
            >
              <div className="firstbox">
                {item.isDueToOrganization == true ? (
                  <div className="CommonRoundBackground BackgroundlightGreen Icon40">
                    <img src={GetImages.GreenArrowOutward} />
                  </div>
                ) : (
                  <div className="CommonRoundBackground BackgroundlightRed Icon40">
                    <img src={GetImages.RedArrowOutward} />
                  </div>
                )}

                <div className="commonbox">
                  <p className="normal_text fw-500 black">{item.description}</p>
                  <p className="sub_text  grey_subtext1">{item.payerPayeeName}</p>
                </div>
              </div>
              <div className="leftcommonbox">
                <div className="d-flex align-items-center gap-1">
                  <p className="heading black ">
                    {" "}
                    $ {item.formatted.AmountRaw}
                  </p>
                  {getKeyByValu(item?.paymentDueAccountStatusId) === "Due" ? (
                    <AddTraspButton
                      bname={
                        item?.isDueToOrganization ?  "Send Reminder" : "Pay"
                      }
                      iconPosition={undefined}
                      imageValue={undefined}
                      onClick={() => {
                        if (!item?.isDueToOrganization === true) {
                          setShowModel(true);
                          setDueAccountId(item.id);
                        } else {
                          setShowReminderModel(true);
                        }
                      }}
                      border={true}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <p className="sub_text  grey_subtext1">
                  {item.formatted.DueDateDateOnly}
                </p>
              </div>
            </div>
          ))
        ) : (
          rows?.map((item) => (
            <div key={item.id} className="transactionRow">
              <div className="firstbox">
                <div className="imgcommonstyle">
                  <img
                    src={GetImages.incomingRentIconGreen}
                    className="w-100 h-100"
                  />
                </div>
                <div className="commonbox">
                  <p className="normal_text black">{item.description}</p>
                  <p className="sub_text  grey_subtext1">{item.payerPayeeName}</p>
                </div>
              </div>
              <div className="leftcommonbox">
                <div className="d-flex align-items-center">
                  <p className="heading black"> ${item.amount}</p>
                </div>
                <p className="sub_text  grey_subtext1">
                  {DateHelper.convertUTCtoDateTime(item.dueDate)}
                </p>
              </div>
            </div>
          ))
        )}
      </div>

      <Offcanvas
        className="offcanvas"
        show={showReminderModel}
        onHide={() => setShowReminderModel(false)}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Reminder Message</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SendReminder />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="offcanvas"
        show={showModel}
        onHide={() => setShowModel(false)}
        placement={"end"}
        scroll={true}
      >
        <Offcanvas.Header className="canvasHeader" closeButton>
          <Offcanvas.Title>Add Payment</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <PayForm
            paymentMethodsArr={paymentMethodsArr}
            onClose={() => setShowModel(false)}
            onConfirm={(values: any) =>
              handleSubmitPayPaymentDueAccount(values)
            }
          />
        </Offcanvas.Body>
      </Offcanvas>

    </>
  );
};

export default Transactions;
