import { useEffect, useRef, useState } from 'react'
import html2pdf from 'html2pdf.js';
import RequestBreadcrumb from '../../common/RequestBreadcrumb'
import { GetImages } from '../../../utils/GetImages'
import PropertiesList from './common/PropertiesList'
import PnlStatementTable from './PnlStatementTable'
import RentRollStatementTable from './RentRollStatementTable'
import AddReport from './AddReport'
import { useNavigate, useParams } from 'react-router'
import { AnalyticsService, EntityType, OrganizationService, ReportFilterEntityEnum, ReportTypesEnum } from '@propertelligent/client-api'
import apiHelper from '../../../utils/apiHelper'
import { UserLoginData } from '../../common/charts/Enums'
import { useError } from '../../../utils/context/ErrorContext'
import DateHelper from '../../../utils/DateHelper'
import DurationHeader from './common/DurationHeader'
import OrganizationNameHeader from '../../common/OrganizationNameHeader/OrganizationNameHeader'
import CustomDropDownMenu from '../../common/CustomDropDownMenu'
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import { useGeneratePdfContext } from '../../../utils/context/GeneratePdfContext'
import { useReactToPrint } from 'react-to-print'
import PrintLayout from '../../generatePdfDocument/printLayout'

const ReportStatement = ({
    selectedValues,
    setSelectedValues = undefined,
    range,
    setRange = undefined,
    selectedOption,
    setSelectedOption = undefined,
    selectedIds,
    setSelectedIds = undefined,
    relativeDateString,
    setRelativeDateString = undefined,
    reportId = undefined,
    reportName = undefined,
    setReportName = undefined,
    onDataChange = (value: any) => { },

}) => {
    const [show, setShow] = useState(false)
    const [organizationName, setOrganizationName] = useState("")
    const [organizationImage, setOrganizationImage] = useState("")
    const [isPrint, setISPrint] = useState(false)
    const { statementData, setStatementData, setReportStatementId, setOrgDetails, orgDetails, setSubHeaderName, subHeaderName } = useGeneratePdfContext();
    const navigate = useNavigate()
    const contentRef = useRef<HTMLDivElement>(null);
    const reportType = parseInt(localStorage.getItem("ReportType"))
    const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id))
    const { id } = useParams()

    const reactToPrintFn = useReactToPrint({ contentRef });

    const { errors, errorMessages, setErrors, setErrorMessages, clearErrors } = useError()

    const handleDownloadPdf = () => {
        setISPrint(true)
        const element = contentRef.current; // Get the content to download

        const options = {
            margin: 0.25,
            filename: `${reportName || 'report'}.pdf`,
            image: { type: 'pdf', quality: 0.50 },
            html2canvas: { scale: 1.10 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        };

        if (element) {
            html2pdf()
                .from(element)
                .set(options)
                .save()
        }
        setTimeout(() => {
            setISPrint(false)
        }, 200)
    };

    useEffect(() => {
        if (id !== undefined) {
            return
        } else {
            if ((reportType === null || isNaN(reportType)) || relativeDateString == "") {
                navigate(-1);
            }
        }
    }, [navigate, reportType, range, id, reportId]);

    const actions = [
        {
            id: 1,
            img: `${GetImages.PdfIcon}`,
            name: "Download as Pdf",
            onClick: handleDownloadPdf,
            linkpath: undefined,
            textClass: 'sub_text fw-600 blue'

        },
        {
            id: 2,
            img: `${GetImages.ExcelIcon}`,
            name: "Download as Excel",
            onClick: undefined,
            linkpath: undefined,
            textClass: 'sub_text fw-600 blue'
        },
        {
            id: 3,
            img: `${GetImages.EmailIcon}`,
            name: "Email",
            onClick: undefined,
            linkpath: undefined,
            textClass: 'sub_text fw-600 blue'
        },
        {
            id: 4,
            img: `${GetImages.Print}`,
            name: "Print",
            onClick: () => {
                setISPrint(true);
                setTimeout(() => {
                    reactToPrintFn()
                    setISPrint(false)
                });
            },
            linkpath: undefined,
            textClass: 'sub_text fw-600 blue'
        }
    ]

    const checkEmptyFields = () => {
        clearErrors()
        if (reportName === "") {
            setErrors({ ...errors, reportName: true })
            setErrorMessages({ ...errorMessages, reportName: "Report Name is required" })
        } else {
            setErrors({ ...errors, reportName: false })
        }
    }

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setReportName("");
    };

    const handleAddReport = (e) => {
        e.preventDefault();
        checkEmptyFields();
        if (reportName == "") {
            console.error("Report Name is required")
        }
        const reportObj = {
            id: reportId ? reportId : 0,
            entityId: orgId,
            entityTypeId: EntityType.Organization,
            name: reportName,
            description: "",
            reportTypeId: reportType,
            queryData: {
                reportFilterEntityId: Number(selectedOption),
                entityIds: Number(selectedOption) == ReportFilterEntityEnum.Organization ? [orgId] : selectedIds,
                dateFilter: {
                    dateFilterTypeId: DateHelper.getDateFilterType(relativeDateString),
                    clientDate: DateHelper.getClientDate(),
                    startDate: relativeDateString === "Custom Range" || relativeDateString === "Custom" ? range[0].startDate : null,
                    endDate: relativeDateString === "Custom Range" || relativeDateString === "Custom" ? range[0].endDate : null,
                },
                entityNames: Number(selectedOption) == ReportFilterEntityEnum.Organization ? [] : selectedValues
            }
        }

        const apiConfig = reportId ? {
            apiMethod: AnalyticsService.putApiReport,
            successMessage: "Report Updated Successfully",
            failureMessage: "Unable to Update Report",
            apiParams: [reportId, reportObj]
        } : {
            apiMethod: AnalyticsService.postApiReport,
            successMessage: "Report Added Successfully",
            failureMessage: "Unable to Add Report",
            apiParams: [reportObj]
        };

        apiHelper(apiConfig.apiMethod, {
            successMessage: apiConfig.successMessage,
            failureMessage: apiConfig.failureMessage,
            showNotification: true
        }, ...apiConfig.apiParams)
            .then((response: any) => {
                setSelectedOption(ReportFilterEntityEnum.Organization)
                setSelectedValues([])
                setSelectedIds([])
                setRelativeDateString("")
                setRange([
                    {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: 'selection',
                    }])
                if (response?.data?.success) {
                    navigate('/organization/reports')
                }
            })
            .catch((Err) => {
                console.error(Err);
            });
    }

    const inputOnchange = (e) => {
        setReportName(e.target.value)
    }

    useEffect(() => {
        if (id) {
            setReportStatementId(id);
        }
    }, [id, setReportStatementId]);

    const getStatementData = () => {
        let clientDate = DateHelper.getClientDate();
        if (reportType === ReportTypesEnum.Pnl) {
            apiHelper(AnalyticsService.getApiReportPnl, { showNotification: false }, id, clientDate)
                .then((response: any) => {
                    setStatementData(response?.data?.success)
                    setSelectedValues(response?.data?.success?.queryData?.entityNames)
                    onDataChange(response?.data?.success);

                })
                .catch((Err) => {
                    console.error(Err);
                });
        } else {
            apiHelper(AnalyticsService.getApiReportRentRoll, { showNotification: false }, id, clientDate)
                .then((response: any) => {
                    setStatementData(response?.data?.success)
                    setSelectedValues(response?.data?.success?.queryData?.entityNames)
                })
                .catch((Err) => {
                    console.error(Err);
                });
        }
    }

    useEffect(() => {
        if (id !== undefined) {
            getStatementData()
        } else {
            createStatementData()
        }
        clearErrors()
    }, [])

    const createStatementData = () => {
        const statementObject = {
            reportFilterEntityId: Number(selectedOption),
            entityIds: Number(selectedOption) == ReportFilterEntityEnum.Organization ? [orgId] : selectedIds,
            entityNames: Number(selectedOption) == ReportFilterEntityEnum.Organization ? [] : selectedValues,
            dateFilter: {
                dateFilterTypeId: DateHelper.getDateFilterType(relativeDateString),
                clientDate: DateHelper.getClientDate(),
                startDate: relativeDateString == "Custom" ? range[0].startDate : null,
                endDate: relativeDateString == "Custom" ? range[0].endDate : null
            }
        }

        if (reportType === ReportTypesEnum.Pnl) {
            apiHelper(AnalyticsService.postApiReportPnl, { showNotification: false }, statementObject)
                .then((response: any) => {
                    setStatementData(response?.data?.success)
                })
                .catch((Err) => {
                    console.error(Err);
                });
        } else {
            apiHelper(AnalyticsService.postApiReportRentRoll, { showNotification: false }, statementObject)
                .then((response: any) => {
                    setStatementData(response?.data?.success)
                })
                .catch((Err) => {
                    console.error(Err);
                });
        }
    }

    const getHeaderInfo = () => {
        apiHelper(OrganizationService.getApiOrganizationHeaderInfo, { showNotification: false }, orgId)
            .then((response: any) => {
                setOrganizationName(response?.data?.success?.name)
                setOrganizationImage(`data:image/*;base64,${response?.data?.success?.logoImageBase64}`)
                setOrgDetails(response?.data?.success)
            })
            .catch((Err) => {
                console.error(Err);
            });
    }

    useEffect(() => {
        getHeaderInfo()
        setSubHeaderName(reportType === ReportTypesEnum.Pnl ? "Profit And Loss Statement" : "Rent Roll Statement")
    }, [orgId])

    return (
        <>
            <div className='Template_View_Main Report_statement' style={{ overflow: "auto", scrollbarWidth: "none" }}>
                {
                    !isPrint ?
                        <>
                            <div className="report_fixed_header">
                                <RequestBreadcrumb
                                    firstName={"Reports"}
                                    link={"/organization/reports"}
                                    lastName={"Report Filters"}
                                    link1={"/organization/reports/filters"}
                                    currentStep={
                                        reportType === ReportTypesEnum.Pnl
                                            ? "Profit And Loss Statement"
                                            : "Rent Roll"
                                    }
                                />
                                <OrganizationNameHeader
                                    SubHeaderName={subHeaderName}
                                    OrgName={organizationName}
                                    OrgImg={organizationImage}
                                    element={
                                        <>
                                            <div className="actionsDropdownContainer clickable">
                                                <CustomDropDownMenu
                                                    Action={true}
                                                    Element={
                                                        <img
                                                            src={GetImages.BlueChevronDown}
                                                            alt="BlueChevronDown"
                                                        />
                                                    }
                                                    data={actions}
                                                />
                                            </div>
                                            {!id && !reportId &&
                                                <AddButtonWithArrow
                                                    buttonname="Add Reports"
                                                    onClick={handleShow}
                                                    disabled={undefined}
                                                    iconDirection={undefined}
                                                    Icon={undefined}
                                                />
                                            }
                                            {
                                                reportId &&
                                                <AddButtonWithArrow
                                                    buttonname="Update Report"
                                                    onClick={handleShow}
                                                    disabled={undefined}
                                                    iconDirection={undefined}
                                                    Icon={undefined}
                                                />
                                            }
                                        </>
                                    }
                                />
                                {!isPrint &&
                                    <div className="duration_header">
                                        <DurationHeader
                                            startDate={range[0].startDate}
                                            endDate={range[0].endDate}
                                            statementData={statementData}
                                        />
                                        {
                                            selectedValues?.length > 0 &&
                                            <PropertiesList selectedValues={selectedValues} selectedOption={selectedOption} />
                                        }
                                    </div>
                                }
                            </div>
                        </> : ""
                }
                <div className={isPrint ? "application-section-pdf" : "application-section"} ref={contentRef}>
                    <PrintLayout isPrint={isPrint}>
                        <div className='generatePdfView'>
                            {isPrint &&
                                <div className="duration_header_print">
                                    <DurationHeader
                                        startDate={range[0].startDate}
                                        endDate={range[0].endDate}
                                        statementData={statementData}
                                    />
                                    {
                                        selectedValues?.length > 0 &&
                                        <PropertiesList selectedValues={selectedValues} selectedOption={selectedOption} />
                                    }
                                </div>
                            }
                            <div className={isPrint ? 'report_table_container_pdf' : 'report_table_container'} >
                                {
                                    reportType === ReportTypesEnum.Pnl ? <PnlStatementTable statementData={statementData} isPrint={isPrint} />
                                        : <RentRollStatementTable statementData={statementData} isPrint={isPrint} />
                                }
                            </div>
                        </div>
                    </PrintLayout>
                </div>
            </div>

            {
                !isPrint ?
                    <AddReport
                        title={reportId ? "Update Report Filter" : "Add Report Filter"}
                        show={show}
                        handleClose={handleClose}
                        selectedValues={selectedValues}
                        selectedOption={selectedOption}
                        range={range}
                        reportName={reportName}
                        handleAddReport={handleAddReport}
                        inputOnchange={inputOnchange}
                        relativeDateString={relativeDateString}
                    />
                    :
                    ""
            }
        </>
    )
}

export default ReportStatement