import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import mainlogo from "../../assets/MainLogo.png";
import { URLS } from "../../constants/constants";
import "./TopbarStyles.css";

export const TopBar = ({
  haveAccount,
  signInUp = null,
  isPropertyNotAssigned = false,
}) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (signInUp === "Sign up") {
      navigate(URLS.SIGN_UP);
    } else if (signInUp === "Log in") {
      navigate(URLS.LOGIN);
    }
  };

  return (
    <>
      <div className="topbarContainer">
        <div>
          <img src={mainlogo} alt="Main Logo" />
        </div>
        {!isPropertyNotAssigned ? (
          <div className="rightSideOfTopBar">
            {signInUp && (
              <>
                <p className="sub_heading black fw-normal">{haveAccount}</p>

                <Button
                  variant="outlined"
                  className="signUpLogInButton sub_text"
                  onClick={handleNavigation}
                >
                  {signInUp}
                </Button>
              </>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};
