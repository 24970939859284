import { useEffect, useState } from 'react'
import apiHelper from '../../utils/apiHelper'
import { TenantService } from '@propertelligent/client-api'
import MoveInAndOutContentBlocks from './common/MoveInAndOutContentBlocks'
import { useParams } from 'react-router'
import { Spinner } from 'react-bootstrap'

const MoveOut = () => {
    const [pickupInstruction, setPickupInstruction] = useState("")
    const [additionalInfo, setAdditionalInfo] = useState("")
    const [userData, setUserData] = useState([])
    const [loadingStates, setLoadingStates] = useState({
        userData: true,
    });
    const { id } = useParams()
    const initialOptions = [
        { name: "Security Deposit", value: "Security Deposit" },
        { name: "Final rent payment", value: "Final rent payment" },
        { name: "Electricity payment", value: "Electricity payment" },
        { name: "Bills", value: "Bills" },
        { name: "Others", value: "Others" },
    ]

    const [formData, setFormData] = useState([
        {
            id: 1,
            paymentType: 'Security Deposit',
            amount: 200,
            dueDate: '2024-04-18',
            gracePeriods: 3,
            description: 'Security Deposit',
        },
        {
            id: 2,
            paymentType: 'Final rent payment',
            amount: 200,
            dueDate: '2024-04-18',
            gracePeriods: 3,
            description: 'Final rent payment',
        },
    ]);

    const submitHandler = (e) => {
        e.preventDefault();
    }

    const getTenantsData = async () => {
        const tenantsResponse = await apiHelper(TenantService.getApiTenant1,
            { showNotification: false },
            Number(id)
        );

        if (tenantsResponse?.data?.success) {
            setUserData({
                ...tenantsResponse?.data?.success,
            });
            setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                userData: false,
            }));
        }
    };

    useEffect(() => {
        getTenantsData()
    }, [])

    return (
        <>
            {loadingStates.userData && ( // Check loading state
                <div className="loading-container">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}
            <MoveInAndOutContentBlocks
                userData={userData}
                submitHandler={submitHandler}
                initialOptions={initialOptions}
                formData={formData}
                setFormData={setFormData}
                pickupInstruction={pickupInstruction}
                setPickupInstruction={setPickupInstruction}
                additionalInfo={additionalInfo}
                setAdditionalInfo={setAdditionalInfo}
                isMoveOut={true}
            />
        </>
    )
}

export default MoveOut