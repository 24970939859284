import React, { useState, useEffect } from "react";
import OpenItemsContractorComponent from "./OpenItemsContractorComponent";
import RecentServiceRequestComponent from "./RecentServiceRequestComponent";
import RecentTransactionComponent from "./RecentTransactionComponent";
import Transactions from "../properties/components/transactions";

import {
  PaymentService,
  ServiceRequestService,
} from "@propertelligent/client-api";
import RecentFiveTransactionsComponent from "./RecentFiveTransactionsComponent";
import RecentFiveServceRequest from "./RecentFiveServceRequest";
import apiHelper from "../../utils/apiHelper";

const ContractorOverview = ({ id }) => {
  const [serviceRequestData, setServiceRequestData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response =
          await apiHelper(ServiceRequestService.getApiServiceRequestContractorlist,{showNotification:false},id)
        setServiceRequestData(response?.data?.success);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="overviewContainer">
      {/* <div className="openActionItemsContractor d-flex flex-column">
        <p className="heading black">Open Action Items</p>
        <div className="openActionContractorsContainer  d-flex flex-column">
          <OpenItemsContractorComponent inProgress={false} />
          <OpenItemsContractorComponent inProgress={true} />
        </div>
      </div> */}
      <div className=" pt-0 flex-wrap serviceRequestTransactionOverviewContainer">
        <div className="recentServiceRequestContainer">
          <p className="heading mb-3">
            Recent 5 service request
          </p>
          <RecentServiceRequestComponent data={serviceRequestData} />
        </div>
        <div className="recentTransactionContainer">
          <p className="heading mb-3">Recent 5 Transactions </p>
          <RecentFiveTransactionsComponent id={id} />
        </div>
      </div>
    </div>
  );
};

export default ContractorOverview;
