import DisplayNamewithLeftBorder from '../../common/DisplayNamewithLeftBorder';
import { useError } from '../../../utils/context/ErrorContext';
import DynamicAddMoreForm from '../../common/DynamicAddMoreForm';

const FinalPayments = ({ initialOptions, formData, setFormData }) => {
    const headers = ["Payment Type", "Amount ($)", "Due Date", "Grace Period", "Description", ""]

    const handleAddPayment = () => {
        setFormData([
            ...formData,
            {
                id: formData.length + 1,
                paymentType: '',
                amount: 0,
                dueDate: '',
                gracePeriod: 0,
                description: '',
            },
        ]);
    };

    return (
        <div className="ledgerNotesSection leaseDetailsSection">
            <div className="rentLedgerContainer p-3">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-baseline gap-2">
                        <DisplayNamewithLeftBorder
                            HeaderName="Final Payments"
                        />
                    </div>
                </div>

                <DynamicAddMoreForm
                    headers={headers}
                    data={formData}
                    setData={setFormData}
                    initialOptions={initialOptions}
                    handleAddPayment={handleAddPayment} />
            </div>
        </div>
    );
};

export default FinalPayments;
