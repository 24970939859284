import React, { useCallback, useState } from 'react'
import DisplayNamewithLeftBorder from '../../common/DisplayNamewithLeftBorder'
import { LeaseApplicationService } from '@propertelligent/client-api';
import AddTraspButton from '../../firstTimeUserExperience/commonCom/AddTraspButton';
import CustomSwitchToggle from '../../common/Switch';
import { GetImages } from '../../../utils/GetImages';
import AddNote from '../../tenants/AddNote';
import apiHelper from '../../../utils/apiHelper';

const ApplicationCard = ({ cardHeaderName, component, editButton = null, leaseApplication,
  editable,
  landlord,
  sectionName,
  handleEditClick,
  sectionToggles,
  setSectionToggles,
  fetchLeaseData
}) => {
  const [addnote, setAddNote] = useState(false);
  const [displayNotes, setDisplayNotes] = useState("");
  const [notemessage ,setNoteMessage] =useState("")
  const handleToggle = useCallback((section) => {
    
    setSectionToggles((prevToggles) => {
      const updatedToggles = { ...prevToggles };
      updatedToggles[section] = {
        ...updatedToggles[section],
        approved: !updatedToggles[section]?.approved,
        notes: updatedToggles[section]?.notes,
      };

      const updatedToggleState = updatedToggles[section].approved;
      const dataToSend = {
        approved: updatedToggleState,
        notes: updatedToggles[section].notes,
      };
      apiHelper(LeaseApplicationService.postApiLeaseApplicationReview, { showNotification: false }, leaseApplication, section, dataToSend)
        .then((response) => {

        })
        .catch((error) => {

        });


      return updatedToggles;
    });
  }, [setSectionToggles, leaseApplication]);

  const ReviewsNotes = (type) => {
    setAddNote(!addnote);
    setDisplayNotes(type);
  };
  const handleCloseClick = () => { setAddNote(false); };

  const handleSaveNote = (note, type, toggleNotes) => {
    const dataToSend = {
      Notes: note,
      approved: toggleNotes.approved,
    };

    apiHelper(LeaseApplicationService.postApiLeaseApplicationReview,  {
      successMessage: notemessage,
      failureMessage: "Unable to Add Note",
      showNotification: true,
    }, leaseApplication, type, dataToSend)
      .then((response) => {
        fetchLeaseData()
      })
      .catch((error) => {
        console.error("Error saving note:", error);
      });    
  };

  return (
    <>
      <div className="common_application_card p-2">
        <div className={` ${(editable) || (landlord) ? ' common_application_card_header p-3 d-flex justify-content-between align-items-center' : " common_application_card_header p-3"} `}>
          <DisplayNamewithLeftBorder HeaderName={cardHeaderName} />
          {((editable) || (landlord)) && (
            <div>
              {(editable) && (
                <AddTraspButton
                  width={0}
                  bname={"Edit"}
                  onClick={handleEditClick}
                  border={false} />
              )}
              {(landlord) &&
                <>
                  <div className="options_buttons">

                    <div className="d-flex align-items-center gap-1">
                      <CustomSwitchToggle
                        labelClassName={sectionToggles[sectionName]?.approved ? "sub_text fw-600 blue" : "sub_text fw-600 red"}
                        onClick={() => handleToggle(sectionName)}
                        checked={sectionToggles[sectionName]?.approved}
                        label={sectionToggles[sectionName]?.approved ? "Approved" : "Rejected"}
                        className={"d-flex flex-row-reverse align-items-center gap-3 "}
                      />
                    </div>
                    <AddTraspButton
                      width={0}
                      bname="Add Note"
                      onClick={() => {
                        ReviewsNotes(`${sectionName}`);
                      }}
                      border={false}
                      imageValue={GetImages.folder}
                      iconPosition="right"
                      IconClassName="Icon32"
                      textclass="sub_text fw-600 blue"
                    />
                  </div>
                </>
              }
            </div>
          )}
        </div>
        <div className="common_application_card_detail">
          {component}
          {addnote && displayNotes === sectionName ? (
            <div>
              <AddNote
                onClose={handleCloseClick}
                toggle={sectionToggles[sectionName]}
                type={displayNotes}
                onSave={handleSaveNote}
                setNoteMessage={setNoteMessage}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default ApplicationCard