import {
    REQUEST_GET_SERVICE_REQUEST, SUCCESS_GET_SERVICE_REQUEST, FAIL_GET_SERVICE_REQUEST,
    REQUEST_PUT_SERVICE_REQUEST, SUCCESS_PUT_SERVICE_REQUEST, FAIL_PUT_SERVICE_REQUEST,
    RESET_SERVICE_REQUEST,
} from "../actions/types"

const initialState = {
  currentRecord: {},  
  data: [],
  loading: false,
  error: false,
  success: false,
  limit: 10,
  currentPage: 1,
  totalPages: 1,
  total: 1,
}

export const ServiceRequestReducer = (state = initialState, action) =>{
    switch (action.type){
        case REQUEST_GET_SERVICE_REQUEST:
            return {...state, loading: true};

        case SUCCESS_GET_SERVICE_REQUEST:
            return {...state, loading: false, success: true, currentRecord: action?.payload};

        case FAIL_GET_SERVICE_REQUEST:
            return {...state, loading: true, error: action.payload }
        /*--------------------------------------------------------------------------------- */

        case REQUEST_PUT_SERVICE_REQUEST:
            return {...state, loading: true};

        case SUCCESS_PUT_SERVICE_REQUEST:
            return {...state, loading: false, success: true, currentRecord: action?.payload};

        case FAIL_PUT_SERVICE_REQUEST:
            return {...state, loading: true, error: action.payload }
        /*--------------------------------------------------------------------------------- */

        case RESET_SERVICE_REQUEST:
            return {...initialState};

        default:
            return state;
    }
}