import "./NewQuestionnaireStyle.css";
import { GetImages } from "../../utils/GetImages";
import { TopBar } from "../common/TopBar";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { UserLoginData } from "../common/charts/Enums";
import { OnboardingService } from "@propertelligent/client-api";
import { useNavigate } from "react-router";
import ErrorAlert from "../tenants/ErrorAlert";
import SuccessAlert from "../tenants/SuccessAlert";
import apiHelper from "../../utils/apiHelper";
const NewQuestionnaire = () => {
  const navigate = useNavigate();
  const [onboardingValues, setOnboardingValues] = useState({
    userId: parseInt(localStorage.getItem(UserLoginData.user_id)),
    OnboardingUserTypeId: null,
    NumberOfUnitsManage: null,
    IsUsingSoftware: null,
    propertyManageTypeId: 1,
  });
  const [questionaireError, setQuestionaireError] = useState(false);
  const [questionaireComplete, setQuestionaireComplete] = useState(false);
  const [allQuestionError, setAllQuestionError] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitQuestionnaire = () => {
    if (
      onboardingValues.OnboardingUserTypeId === null ||
      onboardingValues.NumberOfUnitsManage === null ||
      onboardingValues.IsUsingSoftware == null
    ) {
      setAllQuestionError(true);
    } else {
      setLoading(true);
      apiHelper(OnboardingService.putApiOnboardingUserOnboarding,{successMessage:"Questionnaire Complete",failureMessage:"Some Error Occured",showNotification:true},onboardingValues)
        .then((res: any) => {
          localStorage.setItem("userOnboarded", "true");
          setLoading(false)
          setTimeout(() => {
            navigate("/");
          }, 2000);
        })
        .catch((error) => {
          setAllQuestionError(true);
          setLoading(false);
          setTimeout(() => {
            setAllQuestionError(false);

            navigate("/");
          }, 2000);
        });
    }
  };

  useEffect(() => {
    const onboardingStatus = localStorage.getItem("userOnboarded");
    if (onboardingStatus == "true") {
      navigate("/");
    }
  }, []);

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)), url(${GetImages.background})`,
      }}
      className="questionnaireContainer"
    >
      <TopBar haveAccount="Have an account?" />
      <div className="questionnaireQuestonContainer p-4 d-flex flex-column gap-4">
        <p className="top_header black">Just few more questions</p>
        <div className="w-100 singleQuestionContainer d-flex flex-column">
          <p className="sub_heading text-start">
            <span className="me-1">1.</span> Are you a
          </p>
          <div className="w-100 flex-wrap gap-3 d-flex align-item-center">
            <div
              onClick={() => {
                if (onboardingValues.OnboardingUserTypeId === 1) {
                  setOnboardingValues({
                    ...onboardingValues,
                    OnboardingUserTypeId: null,
                  });
                } else {
                  setAllQuestionError(false);
                  setOnboardingValues({
                    ...onboardingValues,
                    OnboardingUserTypeId: 1,
                  });
                }
              }}
              className={
                onboardingValues.OnboardingUserTypeId === 1
                  ? "questionnaireButtonStylesSelected flex-1 clickable"
                  : "questionnaireButtonStyles flex-1 clickable"
              }
            >
              Landlord
            </div>
            <div
              onClick={() => {
                if (onboardingValues.OnboardingUserTypeId === 2) {
                  setOnboardingValues({
                    ...onboardingValues,
                    OnboardingUserTypeId: null,
                  });
                } else {
                  setAllQuestionError(false);

                  setOnboardingValues({
                    ...onboardingValues,
                    OnboardingUserTypeId: 2,
                  });
                }
              }}
              className={
                onboardingValues.OnboardingUserTypeId === 2
                  ? "questionnaireButtonStylesSelected flex-1 clickable"
                  : "questionnaireButtonStyles flex-1 clickable"
              }
            >
              Property manager
            </div>
          </div>
        </div>
        <div className="w-100 singleQuestionContainer d-flex flex-column">
          <p className="sub_heading text-start">
            <span className="me-1">2.</span> How many units you are managing?
          </p>
          <div className="w-100 gap-3 flex-wrap d-flex align-item-center">
            <div
              onClick={() => {
                if (onboardingValues.NumberOfUnitsManage === 1) {
                  setOnboardingValues({
                    ...onboardingValues,
                    NumberOfUnitsManage: null,
                  });
                } else {
                  setAllQuestionError(false);

                  setOnboardingValues({
                    ...onboardingValues,
                    NumberOfUnitsManage: 1,
                  });
                }
              }}
              className={
                onboardingValues.NumberOfUnitsManage === 1
                  ? "questionnaireButtonStylesSelected flex-1 clickable"
                  : "questionnaireButtonStyles flex-1 clickable"
              }
            >
              1-10
            </div>
            <div
              onClick={() => {
                if (onboardingValues.NumberOfUnitsManage === 10) {
                  setOnboardingValues({
                    ...onboardingValues,
                    NumberOfUnitsManage: null,
                  });
                } else {
                  setAllQuestionError(false);

                  setOnboardingValues({
                    ...onboardingValues,
                    NumberOfUnitsManage: 10,
                  });
                }
              }}
              className={
                onboardingValues.NumberOfUnitsManage === 10
                  ? "questionnaireButtonStylesSelected flex-1 clickable"
                  : "questionnaireButtonStyles flex-1 clickable"
              }
            >
              11-50{" "}
            </div>
            <div
              onClick={() => {
                if (onboardingValues.NumberOfUnitsManage === 50) {
                  setOnboardingValues({
                    ...onboardingValues,
                    NumberOfUnitsManage: null,
                  });
                } else {
                  setAllQuestionError(false);

                  setOnboardingValues({
                    ...onboardingValues,
                    NumberOfUnitsManage: 50,
                  });
                }
              }}
              className={
                onboardingValues.NumberOfUnitsManage === 50
                  ? "questionnaireButtonStylesSelected flex-1 clickable"
                  : "questionnaireButtonStyles flex-1 clickable"
              }
            >
              51-100
            </div>
            <div
              onClick={() => {
                if (onboardingValues.NumberOfUnitsManage === 100) {
                  setOnboardingValues({
                    ...onboardingValues,
                    NumberOfUnitsManage: null,
                  });
                } else {
                  setAllQuestionError(false);

                  setOnboardingValues({
                    ...onboardingValues,
                    NumberOfUnitsManage: 100,
                  });
                }
              }}
              className={
                onboardingValues.NumberOfUnitsManage === 100
                  ? "questionnaireButtonStylesSelected flex-1 clickable"
                  : "questionnaireButtonStyles flex-1 clickable"
              }
            >
              100+{" "}
            </div>
          </div>
        </div>

        <div className="w-100 singleQuestionContainer d-flex flex-column">
          <p className="sub_heading text-start">
            <span className="me-1">3.</span> Do you use any property management
            software or tools?
          </p>
          <div className="w-100">
            <div className="w-100 flex-wrap gap-3 d-flex align-item-center">
              <div
                onClick={() => {
                  if (onboardingValues.IsUsingSoftware === true) {
                    setOnboardingValues({
                      ...onboardingValues,
                      IsUsingSoftware: null,
                    });
                  } else {
                    setAllQuestionError(false);

                    setOnboardingValues({
                      ...onboardingValues,
                      IsUsingSoftware: true,
                    });
                  }
                }}
                className={
                  onboardingValues.IsUsingSoftware === true
                    ? "questionnaireButtonStylesSelected flex-1 clickable"
                    : "questionnaireButtonStyles flex-1 clickable"
                }
              >
                Yes
              </div>
              <div
                onClick={() => {
                  if (onboardingValues.IsUsingSoftware === false) {
                    setOnboardingValues({
                      ...onboardingValues,
                      IsUsingSoftware: null,
                    });
                  } else {
                    setAllQuestionError(false);

                    setOnboardingValues({
                      ...onboardingValues,
                      IsUsingSoftware: false,
                    });
                  }
                }}
                className={
                  onboardingValues.IsUsingSoftware === false
                    ? "questionnaireButtonStylesSelected flex-1 clickable"
                    : "questionnaireButtonStyles flex-1 clickable"
                }
              >
                No{" "}
              </div>
            </div>
            <div>
              {allQuestionError && (
                <p className="errorMessage">Please answer all the questions</p>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end align-items-center">
          <Button onClick={submitQuestionnaire} className="continueButton sub_heading">
            {loading ? (
              <img className="px-4" height={40} src={GetImages.loadingGif} />
            ) : (
              <p style={{ padding: "14px 32px" }}>Continue</p>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewQuestionnaire;
