import { PayDueAccountStatusEnum, PropertyTypesEnum } from "@propertelligent/client-api";



export function getKeyByValue(value: number): string | undefined {
    const keys = Object.keys(PropertyTypesEnum).filter((key) => PropertyTypesEnum[key as keyof typeof PropertyTypesEnum] === value);
    return keys.length > 0 ? keys[0] : undefined;
  }


export function getKeyByValu(value: number): string | undefined {
  const keys = Object.keys(PayDueAccountStatusEnum).filter((key) => PayDueAccountStatusEnum[key as keyof typeof PayDueAccountStatusEnum] === value);
  return keys.length > 0 ? keys[0] : undefined;
}


 
  