import React from 'react';
import { Form } from 'react-bootstrap';

type RadioButtonProps = {
  label: string;
  type?: 'radio' | 'checkbox';
  value: any;
  checked: any;
  checkBoxClass?: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  id?: string;
};

const RadioButton = ({ label, type = "radio", value, checked, onChange, name, id = undefined, checkBoxClass = undefined }: RadioButtonProps) => {
  const className = `${checked && type !== 'checkbox' ? 'checked-label' : checkBoxClass} sub_heading fw-normal grey_subtext1 clickable`;

  return (
    <div className='custom_radio'>
      <Form.Check
        required
        id={id}
        type={type}
        label={label}
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        className={className}
      />
    </div>
  );
};

export default RadioButton;
