import { useState } from 'react';
import DisplayNamewithLeftBorder from '../../common/DisplayNamewithLeftBorder';
import CustomInputs from '../../common/Inputs';
import { Form } from 'react-bootstrap';

const MoveOutDetails = () => {
    const [moveOutDate, setMoveOutDate] = useState("2023-06-29");
    const [moveOutReason, setMoveOutReason] = useState(
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut enim vitae risus rutrum lobortis"
    );

    return (
        <div className="ledgerNotesSection leaseDetailsSection">
            <div className="rentLedgerContainer">
                <div className="d-flex justify-content-between">
                    <div className='d-flex align-items-baseline gap-2'>
                        <DisplayNamewithLeftBorder
                            HeaderName="Move Out Details"
                        />
                    </div>
                </div>

                <div className="mt-3">
                    <div className="moveOutDataContainer">
                        <div>
                            <CustomInputs
                                type='date'
                                labelText={"Move Out Date"}
                                value={moveOutDate}
                                onChange={(e) => setMoveOutDate(e.target.value)}
                            />
                        </div>

                        <div className='flex-grow-1'>
                            <p className="normal_text black fw-400 pb-2">Move Out Reason</p>
                            <Form.Control
                                as="textarea"
                                placeholder="Add Move out reason...."
                                style={{ height: '46px' }}
                                value={moveOutReason}
                                onChange={(e) => setMoveOutReason(e.target.value)}
                                rows={1}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoveOutDetails