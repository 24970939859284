import React, { useEffect, useState } from 'react'
import { GetImages } from '../../../utils/GetImages'
import AddTraspButton from '../../firstTimeUserExperience/commonCom/AddTraspButton'
import NewSelectAuto from '../NewSelectAuto'
import { infiniteScrollHook } from '../../../hooks/infiniteScrollHook'
import { EntityType, LeaseAgreementDataAttributesEnum, LeaseAgreementService, LeaseAgreementStatusTypesEnum, LeaseApplicationService, PaidFlowTypesEnum, PaymentService, PaymentTypesEnum, TemplateService, TemplateTypeEnum, TenantService } from '@propertelligent/client-api'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import apiHelper from '../../../utils/apiHelper'
import DynamicAddMoreForm from '../../common/DynamicAddMoreForm'
import { convertENUMtoJSONArray } from '../../../utils/helper'
import { FORM_CONTROLFIELD_TYPES } from '../../../constants/constants'
import { GET_ERRORS } from '../../../redux/actions/types'
import CustomInputs from '../../common/Inputs'
import { useError } from '../../../utils/context/ErrorContext'
import DisplayNamewithLeftBorder from '../../common/DisplayNamewithLeftBorder'
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import OrganizationNameHeader from '../../common/OrganizationNameHeader/OrganizationNameHeader'
import RadioButton from '../../common/RadioButton'
import { useParams } from 'react-router'
import AddPaymentForm from '../../payments/AddPaymentForm'


const LeaseAgreementSettings = ({ setInitiateLeaseAgreement, previewLeaseAgreement, setPreviewLeaseAgreement }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTemplateid, setSelectedTemplateid] = useState()
    const [selectedOption, setSelectedOption] = useState()
    const [selectedTemplate, setSelectedTemplate] = useState<any>()
    const [filteredInputs, setFilteredInputs] = useState([]);
    const [OrgDetail, setOrgDetail] = useState<any>()
    const { id } = useParams();

    const [postLeaseAgreement, setPostLeaseAgreement] = useState({
        tenantId: id,
        unitId: Number,
        templateId: Number,
        dataAttributes: {},

    })

   
    const { setErrors, errorMessages } = useError()
    const headers = ["Payment Type", "Amount ($)", "Due Date", "Grace Period", "Description", ""]
    const [formData, setFormData] = useState([
        {
            id: 1,
            paymentType: "",
            amount: 0,
            dueDate: "",
            gracePeriods: 3,
            description: "",
        },
       
       
    ]);

    const dataAttribute = convertENUMtoJSONArray(LeaseAgreementDataAttributesEnum);
    const InputArray = dataAttribute?.map((attribute) => ({
        id: attribute.id,
        name: attribute.name,
        labelText: attribute.name.replace(/([A-Z])/g, " $1").trim(),
        type: /Date|Day|Period/i.test(attribute.name) ? FORM_CONTROLFIELD_TYPES.DATE : FORM_CONTROLFIELD_TYPES.TEXT,
        value: formData[attribute.name],
        isFormControl: true,
        onChange: (e) => handleChange(attribute.name, e.target.value),
        placeholder: `Enter ${attribute.name.replace(/([A-Z])/g, " $1").trim()}`,
        myClassName: "containerTextField",
        isError: GET_ERRORS[attribute.name],
        errorMessages: errorMessages[attribute.name],
    }));
    const handleChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
        setPostLeaseAgreement((prevState) => ({
            ...prevState,
            dataAttributes: {
                ...prevState.dataAttributes,
                [fieldName]: value,
            },
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: null,
        }));
    };

    const handleAddPayment = () => {
        setFormData([
            ...formData,
            {
                id: formData.length + 1,
                paymentType: '',
                amount: 0,
                dueDate: '',
                gracePeriods: 0,
                description: '',
            },
        ]);
    };
    const {
        loading,
        rows,
        fetchData,
        hasMore,
        currentPage
    } = infiniteScrollHook({
        httpFetchDataFn: TemplateService.getApiTemplateOrganizationlist,
        FullyQualifiedFilters: `templateTypeId == ${TemplateTypeEnum.LeaseAgreement}`
    });

    const loadMoreRecords = () => { if (!loading) { fetchData(currentPage + 1); } };
    const [sentryRef] = useInfiniteScroll({
        loading: loading,
        hasNextPage: hasMore,
        onLoadMore: loadMoreRecords,
        rootMargin: "0px 0px 100px 0px",
    });

    const getSingleTenantData = () => {
        apiHelper(TenantService.getApiTenant1, { showNotification: false }, id)
            .then((res) => {
               
                setPostLeaseAgreement((prev) => ({
                    ...prev, unitId: res?.data?.success?.currentLeaseApplicationInfo?.unitId, templateId: selectedTemplateid
                }))
            })
            .catch((error) => { });
    };

    const getTempleteData = () => {
        apiHelper(TemplateService.getApiTemplate, { showNotification: false }, Number(selectedTemplateid))
            .then((res) => {
                if (res?.data?.success) {
                    const templateData = res.data.success;
               
                    setSelectedTemplate(templateData);
                    const uniqueAttributes = [
                        ...new Set(
                            templateData?.leaseAgreementTemplateSections?.flatMap((section) => section.dataAttributes || [])
                        ),
                    ];
                    const matchedInputs = InputArray.filter((input) =>
                        uniqueAttributes.includes(input.id)
                    );
                    setFilteredInputs(matchedInputs);
                }
            }).catch((err) => { console.error("Error fetching template data:", err); });
    };

    useEffect(() => {
        if (selectedTemplateid) { getTempleteData() }
        apiHelper(LeaseApplicationService.getApiLeaseApplicationOrganizationHeaderInfo, { showNotification: false }, selectedTemplateid).then((res) => {
            setOrgDetail(res?.data?.success)
        }).catch((error) => { })
        getSingleTenantData()
    }, [selectedTemplateid])



    const sendLeaseAgreement = () => {
         formData.map((item, index: any) => {
        const updatedValues = {
          amount: Number(formData[index].amount),
          description: formData[index].description,
          dueDate: new Date(formData[index]?.dueDate)?.toISOString(),
          entityId: Number(id),
          entityTypeId: EntityType.Tenant,
          payeeAccountId:  1595,
          gracePeriodInDays: Number(formData[index].gracePeriods),
        //   referenceNumber: refrenceNum,
          paymentTypeId: Number(formData[index].paymentType),
         paidFlowTypeId: PaidFlowTypesEnum.Payable
        };
       
        apiHelper(
          PaymentService.postApiPaymentDueAccount,
          {
            successMessage: "Payment Added Successfully",
            failureMessage: "Unable to Add Payment",
            showNotification: true,
          },
          updatedValues
        ).then((res)=>console.log("response?.data?.success",res?.data?.success)).catch((error)=>console.log("error",error))
    })
        apiHelper(LeaseAgreementService.postApiLeaseAgreement, { showNotification: true }, postLeaseAgreement)
            .then((response) => {
                if (response?.data?.success) {
                    console.log("Lease Agreement posted successfully:", response.data);
                    // Perform additional actions if needed
                } else {
                    console.error("Error posting Lease Agreement:", response.data);
                }
            })
            .catch((err) => {
                console.error("API error:", err);
            });
    };

    const replacePlaceholders = (htmlString, dataAttributes) => {
        
        const regex = /\[([^\]]+)\]/g;

        return htmlString

            .replace(/<span class="close-btn">×<\/span>/g, "")
            .replace(regex, (_, key) => {

                const value = dataAttributes[key.trim()] || `[${key}]`;
                // return `<span style="color: blue;">${value}</span>`;
                return `${value}`;
            }); };


    return (
        <>
            {(previewLeaseAgreement && selectedTemplateid) ? <div className='leaseAgreementSettings_main p-4 d-flex flex-column gap-3'>
                <OrganizationNameHeader OrgName={OrgDetail?.name} OrgImg={`data:image/*;base64,${OrgDetail?.logoImageBase64}`} SubHeaderName="Lease Agreement" />
                <div className='d-flex flex-column gap-2 '>
                    <div className='d-flex flex-column gap-3'>
                        <p className='normal_text lh-lg'> The word "Landlord" as used in this Lease means all of the landlords above listed. In all instances in which the Landlord may exercise rights or perform obligations under this Lease, it may do so through its authorized agents or representatives.
                        </p>
                        <p className='normal_text lh-lg'>The word "Tenant" as used in this Lease means all of the tenants above listed.
                        </p>
                    </div>
                    <div>
                        {selectedTemplate?.leaseAgreementTemplateSections.map((section, index) => (
                            <div key={index} style={{ marginBottom: "20px" }}>
                                <div><p className='main_heading text-uppercase'>{index + 1}. {section.sectionName}:</p>

                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: replacePlaceholders(section.sectionData, postLeaseAgreement.dataAttributes),
                                        }}
                                    />

                                </div>

                            </div>
                        ))}
                    </div>
                </div>
                <div className="signature-card p-0 m-0">
                    <RadioButton label={"I confirm that I have read and reviewed the document."}
                        type="checkbox" value={undefined} checked={true}
                        onChange={() => { }}
                        name={undefined} />
                    <div className="signature-sign authority_form ">
                        <div className="d-flex flex-column ">
                            <div className="d-flex gap-2 ">
                                <img src={GetImages.signImg} alt="" />
                                <p className="signature">Tenant Signature </p>
                            </div>
                            <div className="signature_border"></div>
                        </div>
                        <div className="d-flex flex-column ">
                            <p className="normal_text black py-3">23 May 2024</p>
                            <div className="signature_border"></div>
                        </div>
                    </div>
                </div>
            </div>
                :
                <div className='leaseAgreementSettings_main p-4 d-flex flex-column gap-3' >
                    <AddTraspButton
                        onClick={() => setInitiateLeaseAgreement(false)}
                        bname="Lease Agreement Settings"
                        iconPosition="right"
                        imageValue={GetImages.leftChevronBlack}
                        border={false}
                        textclass="main_heading fw-600 black"
                        IconClassName="Icon20"
                        width={0}
                        className="p-0 pb-3"
                    />
                    <div>
                        <DisplayNamewithLeftBorder HeaderName={"Select Lease template"} />
                        <NewSelectAuto
                            disabled={undefined}
                            setDisabled={undefined}
                            selected={selectedTemplateid}
                            setSelected={setSelectedTemplateid}
                            setSelectedOption={setSelectedOption}
                            selectedOption={selectedOption}
                            data={rows && rows}
                            title={"Wait till the data fetches"}
                            type="templete"
                            error={false}
                            setError={() => { }}
                            fromContractor={true}
                            label={undefined}
                        />
                    </div>
                    {selectedTemplateid && (
                        <>
                            <DisplayNamewithLeftBorder HeaderName={"Enter Data attributes"} />
                            <div className="basic-form" >
                                {filteredInputs.map((item) => (
                                    <div key={item.id}>
                                        <CustomInputs
                                            name={item.name}
                                            type={item.type}
                                            value={item.value}
                                            labelText={item.labelText}
                                            isFormControl
                                            onChange={item.onChange}
                                            placeholder={item.placeholder}
                                            myClassName={item.myClassName}
                                            isError={item.isError}
                                            errorMessages={item.errorMessages}
                                            width={"322px"}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div>
                                <DisplayNamewithLeftBorder HeaderName={"Payments Required"} />
                          
                                <DynamicAddMoreForm
                                    headers={headers}
                                    data={formData}
                                    setData={setFormData}
                                    initialOptions={convertENUMtoJSONArray(PaymentTypesEnum)}
                                    handleAddPayment={handleAddPayment} />
                            </div>
                        </>
                    )}
                </div>}
                {/*tenantFormFooter  */}
            <div className="application-section-bttom-view  w-100">
                <AddTraspButton bname={"Cancel"} iconPosition={undefined} onClick={() => { if (previewLeaseAgreement) { setPreviewLeaseAgreement(!previewLeaseAgreement) } }} border={true} />
                <AddButtonWithArrow
                    buttonname={isLoading ? "Saving..." : previewLeaseAgreement ? "Send Lease Agreement" : "Preview"}
                    onClick={() => {
                        if (previewLeaseAgreement) {
                            sendLeaseAgreement();
                        } else {
                            setPreviewLeaseAgreement(!previewLeaseAgreement);
                        }
                    }}
                    disabled={undefined}
                    iconDirection={"left"}
                    Icon={GetImages.WhiteForwardArrow}
                />
                {/* <AddButtonWithArrow buttonname={isLoading ? "Saving..." : previewLeaseAgreement ? "Send Lease Agreement" : "Preview"} onClick={() => setPreviewLeaseAgreement(!previewLeaseAgreement)} disabled={undefined} iconDirection={"left"} Icon={GetImages.WhiteForwardArrow} /> */}
            </div>
        </>
    )
}

export default LeaseAgreementSettings