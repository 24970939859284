import { useEffect, useRef } from "react";
import { GetImages } from "../../utils/GetImages";

const Search = ({ placeholderName, searchValue, searchFilterChange }) => {
  const inputEl = useRef(null);
  const changeTimeout = useRef(null);

  useEffect(() => {
    inputEl.current.value = searchValue;
    inputEl.current.focus();
  }, [searchValue]);

  function onChange(e) {
    window.clearTimeout(changeTimeout.current);
    changeTimeout.current = window.setTimeout(() => {
      const value = e?.target?.value || ""; // Ensure value is a string
      searchFilterChange(value);
    }, 500);
  }

  return (
    <div className="searchInputContainer">
      <img src={GetImages.Search} alt="serchSVG" />
      <input
        ref={inputEl}
        className="serchInput sub_text  grey_subtext1"
        onChange={onChange}
        placeholder={placeholderName}
      />
    </div>
  );
};

export default Search;
