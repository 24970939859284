import React, { useEffect, useState } from 'react';
import { GetImages } from '../../utils/GetImages';
import SeparaterLine from '../common/SeparaterLine';
import EmptyData from '../common/EmptyData';
import apiHelper from '../../utils/apiHelper';
import { OrganizationService } from '@propertelligent/client-api';
import { UserLoginData } from '../common/charts/Enums';
import DateHelper from '../../utils/DateHelper';
import SimpleSpinner from '../loader';
import { useGeneratePdfContext } from '../../utils/context/GeneratePdfContext';
import AddTraspButton from '../firstTimeUserExperience/commonCom/AddTraspButton';

const TransactionTable = ({
  organizationDataTX,
  isEdit,
  organizationData,
  propertyData,
  unitData,
  organizationsResponse,
  propertyResponse,
  unitResponse,
  setSelectedPropertyId,
  setSelectedUnitId,
  propertyResponseTX,
  propertyDataTX

}) => {
  const [expandedOrganisationIndex, setExpandedOrganisationIndex] = useState(null);
  const [expandedPropertyIndex, setExpandedPropertyIndex] = useState(null);
  const [expandedUnitIndex, setExpandedUnitIndex] = useState(null);
  // const [organizationData, setOrganizationData] = useState([]);
  // const [propertyData, setPropertyData] = useState([]);
  // const [unitData, setUnitData] = useState([]);
  // const [propertyDataTX, setPropertyDataTX] = useState([]);
  // const [selectedPropertyId, setSelectedPropertyId] = useState(null)

  // const [selectedUnitId, setSelectedUnitId] = useState(null)
  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
  const { selectedValues, setSelectedValues } = useGeneratePdfContext();


  // useEffect(() => {
  //   propertyResponse(selectedPropertyId)
  //   unitResponse(selectedUnitId)
  //   organizationsResponse(orgId)
  // }, [fullyQualifiedFilters])


  // const organizationsResponse = (id) => {
  //   apiHelper(
  //     OrganizationService.getApiOrganizationTransactionOrganizationlist,
  //     { showNotification: false },
  //     id, undefined, undefined, undefined,
  //     fullyQualifiedFilters, undefined,
  //     relativeDateString !== "" ? DateHelper.getDateFilterType(relativeDateString) : undefined,
  //     relativeDateString !== "" ? DateHelper.getClientDate() : undefined,
  //     relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].startDate.toISOString()}` : null,
  //     relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].endDate.toISOString()}` : null,
  //   )
  //     .then((res) => {
  //       if (res?.data?.success) {
  //         setOrganizationData(res?.data?.success);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Error fetching organization details:", err);
  //     });
  // };
  // const propertyResponse = (id) => {
  //   if (!id) return;
  //   apiHelper(
  //     OrganizationService.getApiOrganizationTransactionPropertylist,
  //     { showNotification: false }, id, undefined, undefined, undefined,
  //     fullyQualifiedFilters, undefined,
  //     relativeDateString !== "" ? DateHelper.getDateFilterType(relativeDateString) : undefined,
  //     relativeDateString !== "" ? DateHelper.getClientDate() : undefined,
  //     relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].startDate.toISOString()}` : null,
  //     relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].endDate.toISOString()}` : null,


  //   ).then((res) => {
  //     if (res?.data?.success) {
  //       setPropertyData(res?.data?.success);
  //     }
  //   }).catch((err) => {
  //     console.log("Error fetching property data:", err);
  //   });
  // };
  // const propertyResponseTX = (id) => {
  //   if (!id) return;
  //   apiHelper(
  //     OrganizationService.getApiOrganizationTransactionPropertylistTxGroups,
  //     { showNotification: false },
  //     id
  //   )
  //     .then((res) => {
  //       if (res?.data?.success) {
  //         setPropertyDataTX(res?.data?.success);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Error fetching property transaction data:", err);
  //     });
  // };
  // const unitResponse = (id) => {
  //   if (!id) return;
  //   apiHelper(
  //     OrganizationService.getApiOrganizationTransactionUnitlist,
  //     { showNotification: false },
  //     id, undefined, undefined, undefined,
  //     fullyQualifiedFilters,
  //     undefined,
  //     relativeDateString !== "" ? DateHelper.getDateFilterType(relativeDateString) : undefined,
  //     relativeDateString !== "" ? DateHelper.getClientDate() : undefined,
  //     relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].startDate.toISOString()}` : null,
  //     relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].endDate.toISOString()}` : null,
  //   ).then((res) => {
  //     if (res?.data?.success) {
  //       setUnitData(res?.data?.success);
  //     }
  //   }).catch((err) => {
  //     console.log("Error fetching property data:", err);
  //   });
  // };


  return (
    <>
      <div className='d-flex flex-wrap gap-2  mb-3'>
        {selectedValues && selectedValues.map((value, i) => (
          <p key={i} className='selectedValueLabel normal_text '>
            {value}
            <AddTraspButton
              imageValue={GetImages.Close}
              iconPosition="right"
              className="closeBtn"
              onClick={() => {
                setSelectedValues(prevSelected => prevSelected.filter(item => item !== value));
              }}
            />
          </p>
        ))}
      </div>
      <table className="collapsible-table">
        <thead>
          <tr className='transaction-table-header normal_text fw-600'>
            <th>Description</th>
            <th>From</th>
            <th>To</th>
            <th>Method</th>
            <th>Reference</th>
            <th>Category</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Transaction ID</th>
          </tr>
        </thead>
        {organizationDataTX.length > 0 ? (
          <tbody className='h-auto'>
            {organizationDataTX.map((org, orgIndex) => {
              return (
                <React.Fragment key={orgIndex}>
                  <tr className='p-12' onClick={() => {
                    if (org?.entityId === orgId) {
                      setExpandedOrganisationIndex(expandedOrganisationIndex === orgIndex ? null : orgIndex)
                      organizationsResponse(org?.entityId)
                    }
                    else {
                      setExpandedPropertyIndex(expandedPropertyIndex === orgIndex ? null : orgIndex);
                      propertyResponseTX(org?.entityId);
                      propertyResponse(org?.entityId);
                      setSelectedPropertyId(org?.entityId)
                    }
                  }}>
                    <td colSpan={4} style={{ width: "48%", borderRight: "0px" }} className="px-2 p-12">
                      {org?.formatted?.OrganizationGroupTransactionsTypeId === "Organization" ?
                        <p className='d-flex align-items-center gap-1'>  {expandedOrganisationIndex === orgIndex ? <div className='toggle-btn'>-</div> : expandedPropertyIndex === orgIndex ? <div className='toggle-btn'>-</div> : <div className='toggle-btn'>+</div>} {" "} <span className=' normal_text fw-500'>{org.description}</span></p>
                        : <>
                          <p className='d-flex align-items-center gap-1'>  {expandedOrganisationIndex === orgIndex ? <div className='toggle-btn'>-</div> : expandedPropertyIndex === orgIndex ? <div className='toggle-btn'>-</div> : <div className='toggle-btn'>+</div>} {" "}  <img src={GetImages.DarkBlueHome} /> <span className=' normal_text fw-500'>{org.description}</span></p>
                        </>}
                    </td>
                    <td colSpan={5} style={{ width: "50%", borderLeft: "0px" }} className="px-3 p-12">
                      <div className="d-flex gap-2 justify-content-end">
                        <p className="normal_text grey_subtext1 transaction-income">
                          Income: <span className="black normal_text fw-600">${org?.formatted?.Income}</span>
                        </p>
                        <SeparaterLine height={"26px"} background={"#e5e7eb"} />
                        <p className="normal_text grey_subtext1 transaction-income">
                          Expenses: <span className="black normal_text fw-600">${org?.formatted?.Expense}</span>
                        </p>
                        <SeparaterLine height={"26px"} background={"#e5e7eb"} />
                        <p className="normal_text grey_subtext1 transaction-income">
                          Net Income: <span className="black normal_text fw-600">${org?.formatted?.NetIncome}</span>
                        </p>
                      </div>
                    </td>
                  </tr>

                  {expandedOrganisationIndex !== null && expandedOrganisationIndex === orgIndex ? organizationData.map((item, txIndex) => {
                    return (
                      <tr key={txIndex}>
                        <td style={{ borderLeft: "none", borderBottom: "none", borderTop: "none" }}>
                          <div className="normal_text ps-3 h-100" >
                            <span style={{ borderLeft: "1px solid #E5E7EB" }} className='p-2 d-flex gap-1 align-items-baseline h-100'>
                              {item?.description}
                            </span>
                            <hr style={{ margin: "-1px", backgroundColor: "#ddd", opacity: "0.1" }} />
                          </div>

                        </td>
                        <td className="p-2 normal_text">{item?.paymentMethodAccountName}</td>
                        <td className="p-2 normal_text">{item?.payeeAccountName}</td>
                        <td className="p-2 normal_text">{item?.formatted?.PaymentMethodTypeId}</td>
                        <td className="p-2 normal_text">{item?.reference}</td>
                        <td className="p-2 normal_text">{item?.formatted?.TranactionCreditDebitTypeId}</td>
                        <td className="p-2 normal_text">${item?.amount}</td>
                        <td className="p-2 normal_text">{item?.formatted?.PaymentDateDateOnly}</td>
                        <td className="p-2 normal_text">{item?.transactionId}</td>
                      </tr>
                    );
                  }) :
                    expandedPropertyIndex !== null && expandedPropertyIndex === orgIndex && propertyData.map((item, txIndex) => {
                      return (
                        <tr key={txIndex} aria-colspan={9}>
                          <td style={{ borderLeft: "none", borderBottom: "none", borderTop: "none" }}>
                            <div className="normal_text ps-3 h-100" >
                              <span style={{ borderLeft: "1px solid #E5E7EB" }} className='p-2 d-flex gap-1 align-items-baseline h-100'>
                                {item?.description}
                              </span>
                              <hr style={{ margin: "-1px", backgroundColor: "#ddd", opacity: "0.1" }} />
                            </div>
                          </td>
                          <td className="p-2 normal_text">{item?.paymentMethodAccountName}</td>
                          <td className="p-2 normal_text">{item?.payeeAccountName}</td>
                          <td className="p-2 normal_text">{item?.formatted?.PaymentMethodTypeId}</td>
                          <td className="p-2 normal_text">{item?.reference}</td>
                          <td className="p-2 normal_text">{item?.formatted?.TranactionCreditDebitTypeId}</td>
                          <td className="p-2 normal_text">${item?.amount}</td>
                          <td className="p-2 normal_text">{item?.formatted?.PaymentDateDateOnly}</td>
                          <td className="p-2 normal_text">{item?.transactionId}</td>
                        </tr>
                      );
                    })
                  }

                  {expandedPropertyIndex === orgIndex && propertyDataTX.map((item, index) => {
                    return (
                      <>
                        <tr onClick={() => {
                          setExpandedUnitIndex(expandedUnitIndex === index ? null : index);
                          if (item?.entityId) {
                            unitResponse(item?.entityId);
                            setSelectedUnitId(item?.entityId)
                          }
                        }
                        }>
                          <td colSpan={4} style={{ width: "48%", borderRight: "0px", borderLeft: "none", borderBottom: "none", borderTop: "none" }} >
                            <div className="ps-3 h-100">
                              <span style={{ borderLeft: "1px solid #E5E7EB", background: "#F9FAFB" }} className='p-2 d-flex gap-1 h-100 align-items-center'>
                                {expandedUnitIndex === index ? <div className='toggle-btn'>-</div> : <div className='toggle-btn'>+</div>}
                                <img src={GetImages.UnitIconBlue} className='iconstyle16' />
                                <p className='normal_text fw-500 d-flex align-items-center'> {item.description}</p>
                              </span>
                              <hr style={{ margin: "0px", backgroundColor: "#ddd", opacity: "0.1" }} />
                            </div>
                          </td>
                          <td colSpan={5} style={{ width: "50%", borderLeft: "0px", background: "#F9FAFB" }} className="px-3">
                            <div className="d-flex gap-2 justify-content-end">
                              <p className="normal_text grey_subtext1 transaction-income">
                                Income: <span className="black normal_text fw-600">${item?.formatted?.Income}</span>
                              </p>
                              <SeparaterLine height={"26px"} background={"#e5e7eb"} />
                              <p className="normal_text grey_subtext1 transaction-income">
                                Expenses: <span className="black normal_text fw-600">${item?.formatted?.Expenses}</span>
                              </p>
                              <SeparaterLine height={"26px"} background={"#e5e7eb"} />
                              <p className="normal_text grey_subtext1 transaction-income">
                                Net Income: <span className="black normal_text fw-600">${item?.formatted?.NetIncome}</span>
                              </p>
                            </div>
                          </td>
                        </tr>
                        {expandedUnitIndex !== null && expandedUnitIndex === index && unitData.map((item, txIndex) => {
                          return (
                            <tr key={txIndex}>
                              <td style={{ borderLeft: "none", borderBottom: "none", borderTop: "none" }}>
                                <div className="normal_text unit-indent h-100" >
                                  <span style={{ borderLeft: "1px solid #E5E7EB" }} className='p-2 d-flex gap-1 align-items-baseline h-100'>
                                    {item?.description}
                                  </span>
                                  <hr style={{ margin: "-1px", backgroundColor: "#ddd", opacity: "0.1" }} />
                                </div>
                              </td>
                              <td className="p-2 normal_text">{item?.paymentMethodAccountName}</td>
                              <td className="p-2 normal_text">{item?.payeeAccountName}</td>
                              <td className="p-2 normal_text">{item?.formatted?.PaymentMethodTypeId}</td>
                              <td className="p-2 normal_text">{item?.reference}</td>
                              <td className="p-2 normal_text">{item?.formatted?.TranactionCreditDebitTypeId}</td>
                              <td className="p-2 normal_text">${item?.amount}</td>
                              <td className="p-2 normal_text">{item?.formatted?.PaymentDateDateOnly}</td>
                              <td className="p-2 normal_text">{item?.transactionId}</td>
                            </tr>
                          );
                        })

                        }
                      </>
                    );

                  })}

                </React.Fragment>
              );
            })}
          </tbody>
        ) : (
          <tbody >
            <tr >
              <td colSpan={9}>
                <SimpleSpinner />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </>
  );
};

export default TransactionTable;
