import { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { OrganizationUserService } from "@propertelligent/client-api";
import FilterComponent from "../contractors/FilterComponent";
import UsersRowComponent from "./UsersRowComponent";
import { GetImages } from "../../utils/GetImages";
import searchIcon from "../../assets/searchIcon.png";
import { infiniteScrollHook } from "../../hooks/infiniteScrollHook";
import useInfiniteScroll from "react-infinite-scroll-hook";
import EmptyData from "../common/EmptyData";
import CustomInputs from "../common/Inputs";

const ManageUsers = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");

  const loadMoreRecords = () => {
    if (!loading) {
      fetchData(currentPage + 1);
    }
  };

  const {
    rows,
    loading,
    hasMore,
    rowCount,
    fetchData,
    currentSortDirection,
    handleSort,
    currentPage,
  } = infiniteScrollHook({
    httpFetchDataFn: OrganizationUserService.getApiOrganizationUserOrganizationlist,
    FullyQualifiedFilters: `User.FirstName.ToLower().Contains("${searchValue.toLowerCase()}")or User.LastName.ToLower().Contains("${searchValue.toLowerCase()}")`,
  });

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasMore,
    onLoadMore: loadMoreRecords,
    rootMargin: "0px 0px 100px 0px",
  });

  return (
    <>
      <div>
        <div
          style={{
            position: "fixed",
            zIndex: "98",
            width: "calc(100vw - 267px)",
          }}
        >
          <div className="tenantsTopBar" style={{ padding: "24px 24px 24px 0" }}>
            <div>
              <p className="top_header black">{rowCount} Users</p>
            </div>

            <div className="rightSideButtonsTenant">
              <div className="searchInputContainer">
                <img src={searchIcon} />
                <CustomInputs
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search by User Name"
                  id="customInputBorder"
                />
              </div>

              <div>
                <Button
                  onClick={(e) => {
                    navigate("create");
                  }}
                  className="inviteButton sub_text fw-600"
                >
                  Add Users
                </Button>
              </div>
            </div>
          </div>
          <div className="subTopBar1">
            <FilterComponent />
            <div>
              <div className="subTopBarComponents" onClick={handleSort}>
                <img src={GetImages.sort} />
                <p className="normal_text">
                  {currentSortDirection === "asc"
                    ? "Sort by: A-Z"
                    : "Sort by: Z-A"}
                </p>
              </div>
            </div>
          </div>
        </div>

        {rows?.length == 0 && !loading ? (
          <EmptyData
            mainText={"No users found"}
            subText={undefined}
            button={undefined}
          />
        ) : (
          <div
            style={{
              marginTop: "170px",
              zIndex: "102",
              position: "absolute",
              width: "calc(100vw - 267px)",
            }}
          >
            <div
              className="tenantListContainer d-flex flex-column gap-2"
              style={{ height: "calc(100vh - 228px)" }}
            >
              <UsersRowComponent
                rows={rows}
                loading={loading}
                fetchData={fetchData}
                sentryRef={sentryRef}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ManageUsers;
