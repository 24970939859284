import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { GetImages } from '../../../utils/GetImages'
import apiHelper from '../../../utils/apiHelper';
import { DateFilterTypesEnum, PaymentService } from '@propertelligent/client-api';
import { useParams } from 'react-router';
import DateHelper from '../../../utils/DateHelper';
import { PaymentContext } from '../../../utils/context/PaymentContext';
import EmptyData from '../../common/EmptyData';

const LastTransaction = ({
  onClose,
  heading,
  show,
  duePayment,
  subheading = undefined,
  payeeAccountId
}) => {

  const [rev_monthValue, setRev_MonthValue] = useState(30);
  const [monthFilter, setMonthFilter] = useState(null)
  const { payeeAccountsArr, setPayeeAccountsArr, selectedItems, setSelectedItems, selectedOption, setSelectedOption } = useContext(PaymentContext);

  const month = [
    { value: 4, label: "All" },
    { value: 30, label: "30 Days" },
    { value: 3, label: "3 months" },
    { value: 6, label: "6 months" }
  ]


  const setMonthFilters = (month: any) => {
    setRev_MonthValue(month);

    let clientDate = DateHelper.getClientDate();

    const filters =
      month == 30
        ? DateFilterTypesEnum.LastMonth
        : month == 3
          ? DateFilterTypesEnum.Last3Months
          : month == 6
            ? DateFilterTypesEnum.Last6Months
            : null;
    setMonthFilter(filters);
  };

  const payeeList = () => {
    let clientDate = DateHelper.getClientDate();
    if (monthFilter !== null) {
      apiHelper(
        PaymentService.getApiPaymentDueAccountPayeelist,
        { showNotification: false },
        payeeAccountId,
        undefined, 5, undefined, undefined, undefined,
        monthFilter,
        clientDate)
        .then((res) => {
          if (res?.data?.success) {
            setPayeeAccountsArr(res?.data?.success);
          }
        })
        .catch((ptErr) => { });
    } else {
      apiHelper(PaymentService.getApiPaymentDueAccountPayeelist, { showNotification: false }, payeeAccountId)
        .then((res) => {
          if (res?.data?.success) {
            setPayeeAccountsArr(res?.data?.success);
          }
        })
        .catch((ptErr) => { });
    }
  }
  useEffect(() => {
    payeeList()
  }, [payeeAccountId, monthFilter])


  return (
    <>
      <div className="p-3">
        <Modal
          className="commonConfirmationModal d-flex align-items-center justify-content-center"
          show={show}
          onHide={onClose}
        >
          <div className="d-flex flex-column align-items-between justify-content-between h-100 p-3">
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <p className="heading black">{heading}</p>
                <p className="sub_text black">{subheading}</p>
                <img
                  className="clickable"
                  onClick={() => onClose()}
                  src={GetImages.closeIconBlack}
                />
              </div>
            </div>

            <div className='my-3 d-flex flex-column gap-2'>

              {
                duePayment &&
                <>
                  <div className="monthhead">
                    {month.map(button => (
                      <button
                        key={button.value}
                        onClick={() => setMonthFilters(button.value)}
                        className={rev_monthValue === button.value ? "monthSelectedButton sub_text fw-500 blue" : "monthButton sub_text fw-500 grey_subtext1"}
                      >
                        {button.label}
                      </button>
                    ))}
                  </div>
                </>
              }
              <div className='my-2 borderWithRadius'>
                {
                  payeeAccountsArr && payeeAccountsArr.length > 0 ? (
                    payeeAccountsArr.map((item) => {
                      return (
                        <div className="Last_transaction gap-5" key={item.id || item.payerPayeeName}> {/* Use a unique key */}
                          <div className="d-flex flex-column">
                            <p className="normal_text fw-500">
                              {item.payerPayeeName}
                            </p>
                            <p className="sub_text grey_subtext1">
                              Paid on: {DateHelper.formatDateonly(item?.dueDate)}
                            </p>
                          </div>
                          <div className='d-flex gap-3'>
                            <p className='normal_text fw-500'>${item.amount}</p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <EmptyData mainText="No Due Payments" ImageSrc={GetImages.DuePayments} button={undefined} />
                  )
                }
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default LastTransaction