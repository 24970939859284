import TemplateMainHeader from '../Templete/CommonComp/TemplateMainHeader'
import RequestBreadcrumb from '../../common/RequestBreadcrumb'
import FeeForm from './FeeForm'

const index = () => {
    return (
        <div className='basic_details fees_and_commissions_container'>
            <div className="basic_detail_breadcrumb">
                <RequestBreadcrumb
                    firstName={"Dashboard"}
                    link={"/"}
                    currentStep={"Fees and Commissions"}
                />
            </div>
            <div className='basic_details_container'>
                <TemplateMainHeader
                    searchTerm={undefined}
                    placeholder={undefined}
                    setSearchTerm={undefined}
                    headerName={"Fees and Commissions"}
                />
                <FeeForm />
            </div>
        </div>
    )
}

export default index