import { useEffect, useState } from "react";
import ComponentHeading from "../../common/componentHeading";
import AccountList from "../../common/AccountList";
import { PaymentMethodEntityTypesEnum, PaymentService } from "@propertelligent/client-api";
import { UserLoginData } from "../../../components/common/charts/Enums";
import SimpleSpinner from "../../../components/loader";
import { GetImages } from "../../../utils/GetImages";
import { Offcanvas } from "react-bootstrap";
import AddEditPaymentModal from "../../../components/my-organization/AddEditPaymentModal";
import { infiniteScrollHook } from "../../../hooks/infiniteScrollHook";
import useInfiniteScroll from "react-infinite-scroll-hook";
import apiHelper from "../../../utils/apiHelper";
import CommonConfirmation from "../../../components/common/CommonConfirmation";

const PaymentMethods = () => {
  const [accountList, setAccountList] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [mode, setMode] = useState("");
  const [id, setId] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");

  const tenantId = Number(localStorage.getItem(UserLoginData.tenant_id));

  const formatAccountNumber = (accountNumber: any) => {
    if (!accountNumber || accountNumber.length < 4) {
      return accountNumber;
    }
    return "xxxx xxxx " + accountNumber.slice(-4);
  };

  const addPaymentModalShow = () => {
    setShowModal(true);
    setMode("add");
  };

  const editPaymentModalShow = (AccId: any) => {
    setId(AccId);
    setShowModal(true);
    setMode("edit");
  };

  const { rows, loading, hasMore, rowCount, fetchData, currentPage } =
    infiniteScrollHook({
      httpFetchDataFn: PaymentService.getApiPaymentMethodTenantlist,
    });

  const loadMoreRecords = () => {
    if (!loading) {
      fetchData(currentPage + 1);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasMore,
    onLoadMore: loadMoreRecords,
    rootMargin: "0px 0px 100px 0px",
  });

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const handleDelete = () => {
    setShowConfirmation(false);
    apiHelper(
      PaymentService.deleteApiPaymentMethod,
      {
        successMessage: "Account deleted successfully",
        failureMessage: "Unable to delete account",
        showNotification: true,
      },
      Number(id)
    )
      .then((res) => {
        if (res?.data?.success) {
          fetchData(1);
        }
      })
      .catch((error) => { });
  };

  const handleClose = () => {
    setShowConfirmation(false);
  };

  const achAccounts = accountList?.filter(
    (account) => account.formatted?.PaymentMethodTypeId === "ACH"
  );

  const creditCardAccounts = accountList?.filter(
    (account) => account.formatted?.PaymentMethodTypeId === "Credit Card"
  );

  return (
    <>
      <div className="dashboardContainer">
        <ComponentHeading
          title="Payment Methods"
          button={true}
          onClick={addPaymentModalShow}
        />

        <div className="allAccountList">
          <AccountList
            // title="Credit Cards"
            accountList={rows}
            formatAccountNumber={formatAccountNumber}
            handleSelectedAccount={undefined}
            selectedAccountList={undefined}
            radioBtn={false}
            icon={GetImages.achIcon}
            onClick={editPaymentModalShow}
            setId={setId}
            setShowConfirmation={setShowConfirmation}
            setPaymentMethod={setPaymentMethod}
          />

          <div ref={sentryRef} />
        </div>

        {loading && (
          <>
            {" "}
            <SimpleSpinner myClassName={rows?.length == 0 ? "h-50" : "h_70"} />
          </>
        )}
      </div>

      <Offcanvas
        className="editTanentOffcanvas"
        show={showModal}
        onHide={() => setShowModal(false)}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {mode === "add" ? "Add Payment Account" : "Edit Payment Account"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddEditPaymentModal
            setShowModal={setShowModal}
            fetchData={fetchData}
            mode={mode}
            id={id}
            entityTypeId={PaymentMethodEntityTypesEnum.Tenant}
            entityId={tenantId}
          />
        </Offcanvas.Body>
      </Offcanvas>


      {showConfirmation && (
        <CommonConfirmation
          show={true}
          onConfirm={handleDelete}
          onClose={handleClose}
          heading="Delete Account"
          subHeading={`Do you want to delete this ${paymentMethod} account?`}
          confirmLabel="Delete"
        />
      )}
    </>
  );
};

export default PaymentMethods;
