import { useCallback, useEffect, useMemo, useState } from "react";
import QuestionHeader from "../QuestionHeader";
import { LeaseApplicationTemplateSectionVM, QuestionTypeEnum, TemplateEntityTypesEnum, TemplateService, TemplateTypeEnum, TemplateVM } from "@propertelligent/client-api";
import { useNavigate, useParams } from "react-router";
import "./AddTemplate.css"
import RequestBreadcrumb from "../../../common/RequestBreadcrumb";
import AddTraspButton from "../../../firstTimeUserExperience/commonCom/AddTraspButton";
import AddButtonWithArrow from "../../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import { GetImages } from "../../../../utils/GetImages";
import QuestionOptional from "../QuestionOptional";
import EmploymentHistoryEdit from "../EmploymentHistoryEdit";
import Reference from "../RefrenceEdit";
import QuestionHistoryEdit from "../QuestionEditTemplete";
import FeeTemplete from "../FeeTemplete";
import ConfirmSignature from "../ConfirmSignature";
import BoardingReview from "../../../TenantOnboard/RenderSteps/BoardingReview";
import apiHelper from "../../../../utils/apiHelper";
import { UserLoginData } from "../../../common/charts/Enums";


const AddTemplate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeSection, setActiveSection] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [postData, setPostData] = useState<any | undefined>(
    {
      name: "Tenant Application 2",
      description: "Application for prospective tenants",
      templateTypeId: TemplateTypeEnum.LeaseApplication,
      entityTypeId: TemplateEntityTypesEnum.Organization,
      entityId: parseInt(localStorage.getItem(UserLoginData.organization_id)),
      isInUse: false,
      applicationFee: 50,
      leaseApplicationTemplateSections: {
        CreditCheck: { requiredRange: 2 },
        CurrentAddress: { requiredRange: 2 },
        RentIncomeCheck: { requiredRange: 2 },
        EmploymentHistory: { requiredRange: 2 },
        References: { requiredRange: 2 },
        Questionnaire:
          { questions: [{ questionTypeId: QuestionTypeEnum.SingleChoice, questionText: "Do you smoke?", isRequired: false, choices: [{ id: 1, optionText: "Click to Change" }], followUpQuestionChoiceId: 1, followUpQuestionText: "Please provide details?" }] },
        SignatureConfirmation: { clause: "Are You agree for everyting" },
        FeeDetails: { clause: " Fee is non refundable" }
      } as LeaseApplicationTemplateSectionVM,
    })

  useEffect(() => { if (id) { getTempleteData(); } }, [id]);

  const buttonClose = () => {
    navigate("/templates");
  }

  const handleSubmit = useCallback(async () => {
    try {

      setIsLoading(true);
      const apiCall = id
        ? apiHelper(TemplateService.putApiTemplate, {
          successMessage: "Template Updated",
          failureMessage: "Unable to Add Template",
          showNotification: true,
        }, Number(id), postData)
        : apiHelper(TemplateService.postApiTemplate, {
          successMessage: "Template Created",
          failureMessage: "Unable to updated Template",
          showNotification: true,
        }, postData)
      const res = await apiCall;
      if (res?.data?.success) { navigate("/organization/templates"); }
    } catch (error) {
      console.error("Error occurred while submitting data:", error);
    } finally { setIsLoading(false); }
  }, [id, postData, navigate]);

  const getTempleteData = () => {

    apiHelper(TemplateService.getApiTemplate, {
      showNotification: false,
    }, Number(id))
      .then((res) => { if (res?.data?.success) { setPostData(res?.data?.success); } })
      .catch((err) => { });
  };

  const duplicateTemplete = () => {
    if (id) {
      apiHelper(TemplateService.postApiTemplateDuplicate, {
        successMessage: "Template Duplicated",
        failureMessage: "Unable to Duplicate Template",
        showNotification: true,
      }, Number(id))
        .then((res) => {
          if (res?.data?.success) { navigate(`/organization/templates/edit/${res?.data?.success.id}`); }
        })
        .catch((err) => { });
    }
  };

  const deleteTemplete = () => {
    if (id) {
      apiHelper(TemplateService.deleteApiTemplate, {
        successMessage: "Template Deleted",
        failureMessage: "Unable to Delete Template",
        showNotification: true,
      }, Number(id))
        .then((res) => {
          navigate("/organization/templates");
        })
        .catch((err) => {

        });
    }
  };
  const HandleEditFeature = (sectionName: string) => {
    if (sectionName) {
      setActiveSection(sectionName);
    } else {
      setActiveSection("");
    }
  };

  const handleSectionToggle = useCallback((sectionName: string) => {
    setPostData((prevData) => {
      const newData = { ...prevData };
      newData.leaseApplicationTemplateSections = newData.leaseApplicationTemplateSections || {};
      if (newData.leaseApplicationTemplateSections[sectionName]) {

        delete newData.leaseApplicationTemplateSections[sectionName];
      } else {

        newData.leaseApplicationTemplateSections[sectionName] = getDefaultSectionDetails(sectionName);
      }

      return newData;
    });
  },
    [setPostData] // Dependencies
  );

  const getDefaultSectionDetails = (sectionName: string) => {
    switch (sectionName) {
      case "RentIncomeCheck":
      case "CreditCheck":
      case "CurrentAddress":
      case "EmploymentHistory":
      case "References":
        return { requiredRange: 2 };
      case "Questionnaire":
        return {
          questions: [
            {
              questionTypeId: 1,
              questionText: "Do you smoke?",
              choices: [
                { id: 1, optionText: "Click to Change" },
                { id: 2, optionText: "Click to Change" },
              ],
              followUpQuestionChoiceId: 1,
              followUpQuestionText: "Please provide details?",
            },
          ],
          requiredRange: 8,
        };
      case "FeeDetails":
        return { clause: "Fee is non refundable" };
      case "SignatureConfirmation":
        return { clause: "Are You agree for everyting" };
      default:
        return {};
    }
  };

  const data = useMemo(() => [
    {
      id: 1,
      name: "Current Address",
      disbale: postData?.leaseApplicationTemplateSections?.["CurrentAddress"] ? true : false,
      editable: false,
      onClick: () => handleSectionToggle("CurrentAddress"),
      editClick: undefined,
    },
    {
      id: 2,
      name: "Credit Check",
      disbale: postData?.leaseApplicationTemplateSections?.["CreditCheck"] ? true : false,
      editable: false,
      onClick: () => handleSectionToggle("CreditCheck"),
      editClick: undefined,
    },
    {
      id: 3,
      name: "Rent Income Check",
      disbale: postData?.leaseApplicationTemplateSections?.["RentIncomeCheck"] ? true : false,
      editable: false,
      onClick: () => handleSectionToggle("RentIncomeCheck"),
      editClick: undefined,
    },
    {
      id: 4,
      name: "Employment History",
      disbale: postData?.leaseApplicationTemplateSections?.["EmploymentHistory"] ? true : false,
      editable: true,
      onClick: () => handleSectionToggle("EmploymentHistory"),
      editClick: () => HandleEditFeature("EmploymentHistory"),
    },
    {
      id: 5,
      name: "References",
      disbale: postData?.leaseApplicationTemplateSections?.["References"] ? true : false,
      editable: true,
      onClick: () => handleSectionToggle("References"),
      editClick: () => HandleEditFeature("References"),
    },
    {
      id: 6,
      name: "Questionnaire",
      disbale: postData?.leaseApplicationTemplateSections?.["Questionnaire"] ? true : false,
      editable: true,
      onClick: () => handleSectionToggle("Questionnaire"),
      editClick: () => HandleEditFeature("Questionnaire"),

    },
    {
      id: 7,
      name: "Signature Confirmation",
      editable: true,
      disbale: postData?.leaseApplicationTemplateSections?.["SignatureConfirmation"] ? true : false,
      onClick: () => handleSectionToggle("SignatureConfirmation"),
      editClick: () => HandleEditFeature("SignatureConfirmation"),
    },
    {
      id: 8,
      name: "Fee Details",
      editable: true,
      disbale: postData?.leaseApplicationTemplateSections?.["FeeDetails"] ? true : false,
      onClick: () => handleSectionToggle("FeeDetails"),
      editClick: () => HandleEditFeature("FeeDetails"),
    },
  ], [postData, handleSectionToggle, id]);



  return (
    <>
      <div className="application-section">
        <RequestBreadcrumb
          firstName={"Template"}
          link={"/organization/templates"}
          currentStep={"Questionnaire template"}
        />
        <div className="application-section-main">
          <QuestionHeader postData={postData} setPostData={setPostData} duplicateTemplete={duplicateTemplete} deleteTemplete={deleteTemplete} />
          <section className="application_review">
            <div className="application_review_left">
              <QuestionOptional
                data={data}
                postData={postData}
              />
            </div>
            <div className="application_review_right">
              {activeSection === "EmploymentHistory" && (
                <EmploymentHistoryEdit
                  postData={postData}
                  setPostData={setPostData}
                />
              )}
              {activeSection === "References" && (
                <Reference
                  postData={postData}
                  setPostData={setPostData}
                />
              )}
              {activeSection === "Questionnaire" && (
                <QuestionHistoryEdit
                  postData={postData}
                  setPostData={setPostData}
                />
              )}
              {activeSection === "FeeDetails" && (
                <FeeTemplete
                  postData={postData}
                  setPostData={setPostData}
                />
              )}
              {activeSection === "SignatureConfirmation" && (
                <ConfirmSignature
                  postData={postData}
                  setPostData={setPostData}
                />
              )}
              {!activeSection && (
                <>
                  <div className="application-preview-header">
                    <p className="sub_heading black"> Application Preview: Not Actual Data</p>
                    <p className="normal_text black"> <i>
                      Please note, this preview is for visualization purposes only and
                      does not contain real data.
                    </i></p>
                  </div>
                  <BoardingReview
                    postData={postData}
                    setPostData={setPostData}
                    editable={false}
                    isEditClick={undefined}
                    formData={undefined}
                    setFormData={undefined}
                    steps={undefined} />
                </>
              )}
            </div>
          </section>
          <div className="application-section-bttom-view">
            <AddTraspButton bname={"Cancel"} iconPosition={undefined} onClick={buttonClose} border={true} />
            <AddButtonWithArrow buttonname={isLoading ? "Saving..." : "Save"} onClick={handleSubmit} disabled={undefined} iconDirection={"left"} Icon={GetImages.WhiteForwardArrow} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTemplate;
