import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { Form, Offcanvas } from "react-bootstrap";
import {
  OpenAPI,
  PaymentMethodEntityTypesEnum,
  PaymentMethodTypesEnum,
  PaymentService,
} from "@propertelligent/client-api";
import { UserLoginData } from "../common/charts/Enums";
import Context from "../firstTimeUserExperience/Context";
import Callout from "plaid-threads/Callout";
import Button from "plaid-threads/Button";
import Link from "../firstTimeUserExperience/Link";
import { usePaginationHook } from "../../hooks/usePaginationHook";
import PaymentAccountViewComponent from "./PaymentAccountViewComponent";
import AddEditPaymentModal from "./AddEditPaymentModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import SimpleSpinner from "../loader";
import { GetImages } from "../../utils/GetImages";
import { infiniteScrollHook } from "../../hooks/infiniteScrollHook";
import useInfiniteScroll from "react-infinite-scroll-hook";
import EmptyData from "../common/EmptyData";
import apiHelper from "../../utils/apiHelper";
import CommonConfirmation from "../common/CommonConfirmation";

const PaymentAccount = () => {
  const organization_id = Number(
    localStorage.getItem(UserLoginData.organization_id)
  );

  const {
    itemId,
    accessToken,
    linkToken,
    linkSuccess,
    isItemAccess,
    backend,
    linkTokenError,
    dispatch,
  } = useContext(Context);

  const { rows, loading, hasMore, rowCount, fetchData, currentPage } =
    infiniteScrollHook({
      httpFetchDataFn: PaymentService.getApiPaymentMethodOrganizationlist,
    });

  const loadMoreRecords = () => {
    if (!loading) {
      fetchData(currentPage + 1);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasMore, // This can be dynamic based on your API response
    onLoadMore: loadMoreRecords,
    rootMargin: "0px 0px 100px 0px", // Adjust root margin as per your requirement
  });
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [mode, setMode] = useState("");
  const [connected, setConnected] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("plaid");
  const [selectedManualMethod, setSelectedManualMethod] = useState("ACH");
  const [accountingNumber, setAccountingNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [id, setId] = useState(null);
  const [values, setValues] = useState({
    cardHolderName: "",
    cardNumber: "",
    cvvNumber: "",
    billingZipCode: "",
    expMonth: "",
  });
  const [errors, setErrors] = useState({
    cardHolderName: false,
    cardNumber: false,
    cvvNumber: false,
    billingZipCode: false,
    expMonth: false,
    routingNumber: false,
    accountingNumber: false,
  });
  const [paymentMethod, setPaymentMethod] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    cardHolderName: "CardHolder Name is required",
    cardNumber: "Card Number is required",
    cvvNumber: "CVV Number is required",
    billingZipCode: "Zip Code is required",
    expMonth: "Expiry Date is required",
    routingNumber: "Routing Number is required",
    accountingNumber: "Account Number is required",
  });
  const navigate = useNavigate();
  const submitTimeError = () => {
    if (selectedMethod === "manual" && selectedManualMethod === "ACH") {
      let updatedError = { ...errors };
      if (accountingNumber.trim() === "") {
        updatedError = { ...updatedError, accountingNumber: true };
      }
      if (routingNumber.trim() === "") {
        updatedError = { ...updatedError, routingNumber: true };
      }

      setErrors(updatedError);
      return accountingNumber.trim() === "" || routingNumber.trim() === "";
    } else if (
      selectedMethod === "manual" &&
      selectedManualMethod === "creditCard"
    ) {
      let updatedError = { ...errors };

      if (values.billingZipCode.trim() === "") {
        updatedError = { ...updatedError, billingZipCode: true };
      }
      if (values.cardHolderName.trim() === "") {
        updatedError = { ...updatedError, cardHolderName: true };
      }
      if (values.cardNumber.trim() === "") {
        updatedError = { ...updatedError, cardNumber: true };
      }
      if (values.cvvNumber.trim() === "") {
        updatedError = { ...updatedError, cvvNumber: true };
      }
      if (values.expMonth.trim() === "") {
        updatedError = { ...updatedError, expMonth: true };
      }

      setErrors(updatedError);
      return (
        values.billingZipCode.trim() === "" ||
        values.cardHolderName.trim() === "" ||
        values.cardNumber.trim() === "" ||
        values.cvvNumber.trim() === "" ||
        values.expMonth.trim() === ""
      );
    } else {
      return false;
    }
  };

  const getInfo = useCallback(async () => {
    const response = await fetch(`${OpenAPI.BASE}/api/PlaidAccount/info`, {
      method: "POST",
    });
    if (!response.ok) {
      dispatch({ type: "SET_STATE", state: { backend: false } });
      return { paymentInitiation: false };
    }
    const data = await response.json();
    const paymentInitiation: boolean =
      data.products.includes("payment_initiation");
    dispatch({
      type: "SET_STATE",
      state: {
        products: data.products,
        isPaymentInitiation: paymentInitiation,
      },
    });
    return { paymentInitiation };
  }, [dispatch]);

  const generateToken = useCallback(
    async (isPaymentInitiation) => {
      const loginToken = localStorage.getItem(UserLoginData.token);
      const path = isPaymentInitiation
        ? `${OpenAPI.BASE}/api/create_link_token_for_payment`
        : `${OpenAPI.BASE}/api/PlaidAccount/create-link-token`;
      const response = await fetch(path, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      });
      if (!response.ok) {
        dispatch({ type: "SET_STATE", state: { linkToken: null } });
        return;
      }
      const data = await response.json();
      if (data) {
        if (data.error != null) {
          dispatch({
            type: "SET_STATE",
            state: {
              linkToken: null,
              linkTokenError: data.error,
            },
          });
          return;
        }
        dispatch({ type: "SET_STATE", state: { linkToken: data.link_token } });
      }
      // Save the link_token to be used later in the Oauth flow.
      localStorage.setItem("link_token", data.link_token);
    },
    [dispatch]
  );

  useMemo(() => {
    const init = async () => {
      const { paymentInitiation } = await getInfo();

      if (window.location.href.includes("?oauth_state_id=")) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: localStorage.getItem("link_token"),
          },
        });
        return;
      }
      generateToken(paymentInitiation);
    };
    init();
  }, [dispatch, generateToken, getInfo]);

  const addPaymentModalShow = () => {
    setId(null);
    setMode("add");
    setShowModal(true);
  };



  const addPaymentHandler = (e) => {
    e.preventDefault();
    if (submitTimeError()) {
    } else {
      if (selectedMethod === "manual" && selectedManualMethod === "ACH") {
        const payload = {
          achPaymentMethod: {
            accountNumber: accountingNumber,
            routingNumber: routingNumber,
          },
          accountName: "DummyName",
          paymentMethodTypeId: PaymentMethodTypesEnum.ACH,
          entityTypeId: PaymentMethodEntityTypesEnum.Organization,
          entityId: Number(localStorage.getItem(UserLoginData.organization_id)),
        };

        apiHelper(
          PaymentService.postApiPaymentMethod,
          {
            successMessage: "Payment Method Added Succssfully",
            failureMessage: "Unable to add payment method",
            showNotification: true,
          },
          payload
        )
          .then((res) => {
            setAccountingNumber("");
            setRoutingNumber("");
          })
          .catch((error) => {});
      } else if (
        selectedMethod === "manual" &&
        selectedManualMethod === "creditCard"
      ) {
        const creditCardPayload = {
          cardHolderName: values.cardHolderName,
          cardNumber: values.cardNumber.split(" ").join(""),
          cvvNumber: values.cvvNumber,
          billingZipCode: values.billingZipCode,
          expMonth: Number(values.expMonth.split("/")[0]),
          expYear: Number(values.expMonth.split("/")[1]),
        };

        const payload = {
          accountName: "DummyName",
          paymentMethodTypeId: PaymentMethodTypesEnum.CreditCard,
          entityTypeId: PaymentMethodEntityTypesEnum.Organization,
          entityId: Number(localStorage.getItem(UserLoginData.organization_id)),
          creditCardPaymentMethod: creditCardPayload,
        };
        apiHelper(
          PaymentService.postApiPaymentMethod,
          {
            successMessage: "Payment Method Added Succssfully",
            failureMessage: "Unable to add payment method",
            showNotification: true,
          },
          payload
        )
          .then((res) => {
            setValues({
              cardHolderName: "",
              cardNumber: "",
              cvvNumber: "",
              billingZipCode: "",
              expMonth: "",
            });
          })
          .catch((error) => {});
      } else if (selectedMethod === "plaid") {
        navigate("/");
      }
    }
  };

  const handleDelete = () => {
    setShowConfirmation(false);
    apiHelper(
      PaymentService.deleteApiPaymentMethod,
      {
        successMessage: "Account deleted successfully",
        failureMessage: "Unable to add account",
        showNotification: true,
      },
      id
    )
      .then((res) => {


        if (res?.data?.success) {
          fetchData(1);
        }
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      <Form onSubmit={addPaymentHandler} style={{width:"calc(100vw - 268px)"
      }}>
        <div className="basicDetailsContainer" style={{ padding: "24px" }}>
          <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
            <div>
              <div className="main_heading black">Payment account</div>
              <div className="normal_text black pt-1">
                These are the Organization Accounts to receive and make
                payments.
              </div>
            </div>
            <button
              type="button"
              onClick={addPaymentModalShow}
              className="addPaymentButton"
            >
              <img src={GetImages.BlueAdd} width={16} height={16} />
              <p className="sub_text fw-600 blue">Add Payment Method</p>{" "}
            </button>
          </div>

          {/* {selectedMethod === "plaid" && (
            <>
              {!linkSuccess ? (
                <div>
                  <div className="connectPlaidContainer d-flex justify-content-between align-items-center">
                    <div className="d-flex leftSideContainerPlaid">
                      <img width={40} height={40} src={GetImages.plaidIcon} />
                      <div>
                        <p className="sub_heading black mb-1">
                          Connect your Plaid account.
                        </p>
                        <p className="sub_text grey_subtext1">
                          We just link your account, your data is completely
                          secured
                        </p>
                      </div>
                    </div>
                    {!backend ? (
                      <Callout warning>
                        Unable to fetch link_token: please make sure your
                        backend server is running and that your .env file has
                        been configured with your
                        <code>PLAID_CLIENT_ID</code> and{" "}
                        <code>PLAID_SECRET</code>.
                      </Callout>
                    ) : 
                    linkToken == null && backend ? (
                      <Callout warning>
                        <div>
                          Unable to fetch link_token: please make sure your
                          backend server is running and that your .env file has
                          been configured correctly.
                        </div>
                        <div>
                          Error Code: <code>{linkTokenError.error_code}</code>
                        </div>
                        <div>
                          Error Type: <code>{linkTokenError.error_type}</code>{" "}
                        </div>
                        <div>Error Message: {linkTokenError.error_message}</div>
                      </Callout>
                    ) : linkToken === "" ? (
                      <div>
                        <Button large disabled>
                          Loading...
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Link />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  {
                    <>
                      {isItemAccess ? (
                        <div className="connectPlaidContainer d-flex justify-content-between align-items-center">
                          <div className="d-flex leftSideContainerPlaid">
                            <div style={{ position: "relative" }}>
                              <img
                                width={40}
                                height={40}
                                src={GetImages.plaidIcon}
                              />
                              <img
                                style={{
                                  position: "absolute",
                                  left: 40,
                                  top: -4,
                                }}
                                width={16}
                                height={16}
                                src={GetImages.plaidRight}
                              />{" "}
                            </div>

                            <div>
                              <p className="sub_heading black mb-1">
                                You are connected with Plaid account
                              </p>
                              <p className="sub_text grey_subtext1">
                                We just link your account, your data is
                                completely secured
                              </p>
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="saveEditTenantButton2 sub_text fw-600"
                          >
                            Disconnect <img src={GetImages.blueForwardArrow} />
                          </button>
                        </div>
                      ) : (
                        <h4>
                          <Callout warning>
                            Unable to create an item. Please check your backend
                            server
                          </Callout>
                        </h4>
                      )}
                    </>
                  }
                </>
              )}
            </>
          )} */}
        </div>

        <div
          style={{ overflow: "hidden", padding: "0px" }}
          className="tenantListContainer"
        >
          {rows?.length == 0 && !loading ? (
            <EmptyData
              mainText={"No payment accounts found"}
              subText={undefined}
              button={undefined}
            />
          ) : (
            <div
              className="tenantListContainer d-flex flex-column gap-2"
              style={{ padding: " 0 24px" }}
            >
              <PaymentAccountViewComponent
                setShowModal={setShowModal}
                rows={rows}
                showModal={showModal}
                id={id}
                setId={setId}
                mode={mode}
                setMode={setMode}
                setShowConfirmation={setShowConfirmation}
                setPaymentMethod={setPaymentMethod}
              />
            </div>
          )}

          {loading && (
            <>
              <SimpleSpinner
                myClassName={rows?.length == 0 ? "h-50" : "h_70"}
              />
            </>
          )}
          <div ref={sentryRef} />
        </div>
      </Form>

      {showConfirmation && (
        <CommonConfirmation
          show={true}
          onConfirm={handleDelete}
          onClose={handleClose}
          heading="Delete Account"
          subHeading={`Do you want to delete this ${paymentMethod} account?`}
          confirmLabel="Delete"
        />
      )}

      <Offcanvas
        className="editTanentOffcanvas"
        show={showModal}
        onHide={() => setShowModal(false)}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {mode === "add" ? "Add Payment Account" : "Edit Payment Account"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddEditPaymentModal
            fetchData={fetchData}
            setShowModal={setShowModal}
            mode={mode}
            id={id}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default PaymentAccount;
