import { useState } from "react";
import Adduser from "./Adduser";
import BasicDetailsComponent from "./BasicDetails/BasicDetailsComponent";
import EditUser from "./EditUser";
import { Route, Routes, useLocation } from "react-router";
import ManageUsers from "./ManageUsers";
import OrganizationSidebar from "./OrganizationSidebar";
import PaymentAccount from "./PaymentAccount";
import Reports from "./Reports";
import "./organizationStyles.css";
import "./Templete/template.css"
import "./Reports/reports.css"
import TempleteView from "./Templete/TempleteView";
import AddTemplate from "./Templete/AddTemplate/AddTemplate";
import ReportFilters from "./Reports/ReportFilters";
import { Range } from 'react-date-range';
import { ReportFilterEntityEnum } from "@propertelligent/client-api";
import ReportStatement from "./Reports/ReportStatement";
import { useGeneratePdfContext } from "../../utils/context/GeneratePdfContext";
import AddLeaseAgreementTemplate from "./Templete/LeaseTemplate/AddLeaseAgreementTemplate";
import FeesAndCommissions from "./FeesAndCommissions/index"
import PaymentProcessingDates from "./PaymentProcessingDates/index"

export const MyOrganization = () => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(location.pathname);
  const { selectedValues, setSelectedValues, selectedOption, setSelectedOption } = useGeneratePdfContext();
  const [selectedIds, setSelectedIds] = useState([])
  const [relativeDateString, setRelativeDateString] = useState("")
  const [reportName, setReportName] = useState("")
  const [range, setRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [reportId, setReportId] = useState("")

  const resetForm = () => {
    setSelectedOption(ReportFilterEntityEnum.Organization)
    setSelectedValues([])
    setSelectedIds([])
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      }
    ])
    setRelativeDateString("")
    setReportId("")
    setReportName("")
  }

  return (
    <>
      <div className="organizationContentContainer d-flex">
        <OrganizationSidebar selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
        <Routes> 
          <Route path="/templates/create" element={<AddTemplate />} />
          <Route path="/templates/create-lease-agreement" element={<AddLeaseAgreementTemplate />} />          
          <Route path="/templates/lease-agreement/:id" element={<AddLeaseAgreementTemplate />} />
          <Route path="/templates/edit/:id" element={<AddTemplate />} />
          <Route path="/basic-details" element={<BasicDetailsComponent />} />
          <Route path="/manage-users/create" element={<Adduser />} />
          <Route path="/manage-users/edit/:id" element={<EditUser />} />
          <Route path="/payment-account" element={<PaymentAccount />} />
          <Route path="/manage-users" element={<ManageUsers />} />
          <Route path="/templates" element={<TempleteView />} />
          <Route path="/fees-and-commissions" element={<FeesAndCommissions />} />
          <Route path="/payment-processing-dates" element={<PaymentProcessingDates />} />
          <Route path="/reports" element={<Reports resetForm={resetForm} />} />
          <Route path="/reports/filters"
            element={
              <ReportFilters
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                range={range}
                setRange={setRange}
                relativeDateString={relativeDateString}
                setRelativeDateString={setRelativeDateString}
              />
            }
          />
          <Route path="/reports/statement" element={
            <ReportStatement
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              range={range}
              setRange={setRange}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              relativeDateString={relativeDateString}
              setRelativeDateString={setRelativeDateString}
              reportId={reportId}
              reportName={reportName}
              setReportName={setReportName}
            />
          }
          />
          <Route path="/reports/:id" element={
            <ReportStatement
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              range={range}
              selectedOption={selectedOption}
              selectedIds={selectedIds}
              relativeDateString={relativeDateString}
            />
          }
          />
          <Route path="/reports/edit/:id" element={
            <ReportFilters
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              range={range}
              setRange={setRange}
              relativeDateString={relativeDateString}
              setRelativeDateString={setRelativeDateString}
              setReportId={setReportId}
              setReportName={setReportName}
            />
          }
          />
        </Routes>
      </div>

    </>
  );
};
