import React, { ReactNode, useMemo, useCallback } from "react";
import { ServiceRequestService } from "@propertelligent/client-api";
import OverViewUnit from "./overView";
import ServiceRequestList from "../common/ServiceRequestList";
import Transactions from "../properties/components/transactions";

interface TabsProps {
  id: string;
  tenantId: number | null;
  paymentMethodsArr: any[];
  payeeAccountsArr: any[];
  transactionRows: any[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  fetchData: any;
  tenantApiData: any;
  tenantLoading: boolean;
  expensebycategoryArr: any;
  fetchAndFormatExpensebycategory: any;
  fin_monthValue: any;
  setFin_MonthValue: any;
  loadingStates: any;
  setLoadingStates: any;
}

const Tabs: React.FC<TabsProps> = React.memo(
  ({
    id,
    tenantId,
    paymentMethodsArr,
    payeeAccountsArr,
    transactionRows,
    activeIndex,
    setActiveIndex,
    fetchData,
    tenantApiData,
    tenantLoading,
    expensebycategoryArr,
    fetchAndFormatExpensebycategory,
    fin_monthValue,
    setFin_MonthValue,
    loadingStates,
    setLoadingStates,
  }) => {
    const tabsArr = useMemo(
      () => [
        { id: 1, name: "Overview" },
        { id: 3, name: "Service Requests" },
        { id: 4, name: "Transactions" },
      ],
      []
    );

    const handleClick = useCallback(
      (index: number) => {
        setActiveIndex(index);
      },
      [setActiveIndex]
    );

    const checkActive = useCallback(
      (index: number, className: string) =>
        activeIndex === index ? className : "",
      [activeIndex]
    );

    return (
      <>
        {/* <div className="p-tabs" style={{ gap: "20px" }}>
          {tabsArr.map((tab) => (
            <button
              key={`tabBtn${tab.id}`}
              className={`tab sub_text  grey_subtext1 ${checkActive(
                tab.id,
                "active"
              )}`}
              onClick={() => handleClick(tab.id)}
            >
              {tab.name}
            </button>
          ))}
        </div> */}

        <div className={`panel ${checkActive(1, "active")}`}>
          <OverViewUnit
            id={id}
            tenantId={tenantId}
            rows={transactionRows}
            paymentMethodsArr={paymentMethodsArr}
            payeeAccountsArr={payeeAccountsArr}
            httpFetchDataFn={fetchData}
            tenantData={tenantApiData}
            loading={tenantLoading}
            expensebycategoryArr={expensebycategoryArr}
            fetchAndFormatExpensebycategory={fetchAndFormatExpensebycategory}
            fin_monthValue={fin_monthValue}
            setFin_MonthValue={setFin_MonthValue}
          />
        </div>
        {/* <div className={`panel ${checkActive(2, "active")}`}>
          <DocumentsComponent 
            id={id} 
            EntityTypeId={DocumentEntityTypesEnum.Unit} 
            DocumentTypeId={DocumentTypesEnum.Other} 
          />
        </div> */}
        <div className={`panel ${checkActive(3, "active")}`}>
          <ServiceRequestList
            setTotalServiceRequest={null}
            id={id}
            httpFetchDataFn={ServiceRequestService.getApiServiceRequestUnitlist}
            loadingStates={loadingStates}
            setLoadingStates={setLoadingStates}
          />
        </div>
        <div className={`panel ${checkActive(4, "active")}`}>
          <Transactions
            rows={transactionRows}
            sorting={undefined}
            fetchData={undefined}
            paymentMethodsArr={paymentMethodsArr}
            fetchAndFormatExpensebycategory={undefined}
          />
        </div>
        {/* </div> */}
      </>
    );
  }
);

export default Tabs;
