import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Worker from "../../../assets/Worker.png"

const ContractorDetail = ({ contractorName }) => {

    
    return (
        <div>
            <div className="singleServiceNameDetailsLeftSide pb-3" style={{ backgroundColor: "#ebf5ff", padding: "2px" }}>
                <div>
                    <Row style={{ width: 'auto' }}>
                        <Col xs={12}>
                            <h4 className="singleTenantDetailsName p-0">Contractor Details</h4>
                            <div className="singleContractorDetails" style={{ paddingLeft: "6px" }}>
                                <span className="sub_text black">
                                    <img height={16} width={16} src={Worker} style={{ marginRight: "4px" }} />
                                    {contractorName}
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default ContractorDetail