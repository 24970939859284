import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CommunicationService, } from '@propertelligent/client-api';
import NameAvatarComponent from '../../../tenants/NameAvatarComponent';
import EmptyData from '../../../common/EmptyData';
import CustomTooltip from '../../../common/CustomTooltip';
import { GetImages } from '../../../../utils/GetImages';
import Compose from './Compose';
import apiHelper from '../../../../utils/apiHelper';


const ShowEmail = ({ emailId, onGetEmailList, setEmailId }) => {
  const [emailMessage, setEmailMessage] = useState([])
  const [important, setImportant] = useState(false)
  const [replyId, setReplyId] = useState<String>()
  const [replytype, setReplyType] = useState<String>("")
  const [isCollapsed, setIsCollapsed] = useState(emailMessage.length > 1);
  const composeRef = useRef(null);
  const scrollDown =useRef(null)
 
  useEffect(() => {
    if (composeRef.current) {
      composeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
   
  }, [replyId]); // Trigger scroll when replyId changes

  useMemo(() => {
    setReplyId(null)
  }, [emailId]);
  const OnDeleteEmail = (id) => {

    if (emailId !== id) {
      apiHelper(CommunicationService.deleteApiGmailDeleteEmailMessage,{successMessage:"Delete Email",failureMessage:"Unable to delete email",showNotification:true},id)
      .then((res) => {
        getEmailMessages()
        onGetEmailList()
      }).catch((error) => {});

    } else
      apiHelper(CommunicationService.deleteApiGmailDeleteEmailThread,{successMessage:"Delete Email", failureMessage:"Unable to delete email",showNotification:true},emailId)
      .then((res) => {
        setEmailId(null)
        getEmailMessages()
        onGetEmailList()
      }).catch((error) => {})
  }
  const onForwardEmail =(id)=>{
    setReplyId(id) 
    setReplyType("forward")
  }

  const OnMakeImportant = (isImportant, id) => {
    if (emailId !== id) {
      let da = isImportant ? false : true

      setImportant(da)
      apiHelper(CommunicationService.postApiGmailToggleMessageImportance,{showNotification:false},id, da)
      .then((res) => {
        getEmailMessages()
        onGetEmailList()
      }).catch((error) => {})
    }
    let da = isImportant ? false : true

    setImportant(da)
    apiHelper(CommunicationService.postApiGmailToggleThreadImportance,{showNotification:false},id, da)
    .then((res) => {
      getEmailMessages()
      onGetEmailList()
    }).catch((error) => {})
  }

  const getEmailMessages = () => {
    apiHelper(CommunicationService.getApiGmailEmailThread,{showNotification:false},emailId)
    .then((res) => {
      const allresults: any = res?.data?.success;
      setEmailMessage(allresults.messages);
    }).catch((error) => {});
  }

  useEffect(() => {
    getEmailMessages()
    scrollDown?.current?.scrollIntoView()
  }, [emailId, important, emailMessage?.length
    // emailMessage?.isImportant
  ])

  const BackendContent = ({ body }) => {
    const ref = useRef(null);
    const [height, setHeight] = useState("0px");

    const onLoad = () => {
      if (ref.current) {
        setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
      }
    };

    return (
      <iframe
        ref={ref}
        onLoad={onLoad}
        id="myFrame"
        width="100%"
        height={height}
        scrolling="no"
        frameBorder="0"
        style={{
          maxWidth: 640,
          width: "100%",
          overflow: "auto",
        }}
        srcDoc={`<!DOCTYPE html><html><head><title>Email Content</title>
        <style>
          body {
            margin: 0;
            padding: 0;
          }
          .bodyclass {
            /* Add your custom styles here */
          }
        </style>
        </head><body class="bodyclass">
          ${body}
          <script>
      var links = document.querySelectorAll('a');
      links.forEach(function(link) {
        link.addEventListener('click', function(event) {
          event.preventDefault(); // Prevent default link behavior
          var url = this.getAttribute('href');
          window.open(url, '_blank'); 
        });
      });
    </script>
        </body></html>`}
      />
    );


  };


  const data = [
    {
      id: 1,
      img: GetImages.EmailForward,
      name: "Forward",
      linkpath: undefined
    },
    {
      id: 2,
      img: GetImages.BlackDelete,
      name: "Delete",
      linkpath: undefined
    },
  ]


  return (
    <>
      <div className="composeContainer">
        {emailId == null ? (
          <EmptyData
            mainText={undefined}
            subText="Click on the chat from left to start the conversation"
            button={undefined}
          />
        ) : (
          <>
            {emailMessage?.map((item, index) => (
              <>
                <div className="ShowEmailSection" key={index}>
                  <div
                    className={
                      emailMessage?.length - 1 === index
                        ? ` gap-4 ShowEmailSectionInner `
                        : ` gap-4 ShowEmailSectionInner BorderBottom`
                    }
                  >
                    <div className="userIcon ">
                      <NameAvatarComponent
                        firstName={
                          item.fromName.split(" ")?.length > 0 &&
                          item.fromName.split(" ")[0]
                        }
                        lastName={
                          item.fromName.split(" ")?.length > 1
                            ? item.fromName.split(" ")[1]
                            : ""
                        }
                        heightWidth={40}
                      />
                    </div>
                    <div className="email_Deatils_section gap-5">
                      <div className="email_details_header ">
                        <div className="email_sender_detials ">
                          <p className="sub_heading text-start">
                            {item?.fromName}
                          </p>
                          <p className="sub_text  grey_subtext1">
                            {item?.displayDate}
                          </p>
                        </div>
                        <div className="email_action ">
                          <CustomTooltip
                            id="tooltip-disabled"
                            placement="Marked as important"
                            tooltip="custom-tooltip"
                            Element={
                              <img
                                src={
                                  !item?.isImportant
                                    ? GetImages.BlueStar
                                    : GetImages.FilledBlueStar
                                }
                                onClick={() =>
                                  OnMakeImportant(item?.isImportant, item.id)
                                }
                                className="iconstyle"
                                alt="Important"
                              />
                            }
                          />
                          <img
                            src={GetImages.BlueReply}
                            onClick={() => {
                              setReplyId(item.id);
                              setReplyType("reply");
                            }}
                            className="iconstyle"
                            alt="Reply"
                          />

                          <Dropdown>
                            <Dropdown.Toggle
                              style={{ outline: "none" }}
                              variant="none"
                              id="filterDropdown"
                              className="m-0 p-0"
                            >
                              <img
                                src={GetImages.BlueMore}
                                className="iconstyle"
                                alt="More"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="m-2">
                              {data?.map((it, index) => (
                                <>
                                  <Dropdown.Item
                                    key={it.id}
                                    onClick={() =>
                                      it.id == 2
                                        ? OnDeleteEmail(item.id)
                                        : onForwardEmail(item.id)
                                    }
                                  >
                                    <div className="text-decoration-none d-flex flex-row align-items-center text-dark gap-2">
                                      {it.img && (
                                        <img
                                          src={it.img}
                                          width={20}
                                          height={20}
                                          alt="Black Email Icon"
                                        />
                                      )}
                                      <p className="sub-text">{it.name}</p>
                                    </div>
                                  </Dropdown.Item>
                                  {index !== data.length - 1 && (
                                    <Dropdown.Divider />
                                  )}
                                </>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <p className="normal_text grey_subtext1">{item?.subject}</p>
                      <BackendContent body={item.body} />
                    </div>
                  </div>
                </div>
                <div ref={scrollDown} />
              </>
            ))}

            {replyId && (
              <>
                <div className="w-100 BorderTop mt-1 ShowEmailSection">
                  <Compose
                    getEmailMessages={getEmailMessages}
                    emailId={emailId}
                    replyId={replyId}
                    setReplyId={setReplyId}
                    replytype={replytype}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    
    </>
  );
}

export default ShowEmail
