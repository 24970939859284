import { Route, Routes, useNavigate, useParams } from "react-router";
import LeaseApplication from "./leaseApplication";
import LeaseAgreement from "./leaseAgreement";
import ProfitAndLossStatement from "./profitAndLossStatement";
import RentRoll from "./rentRoll";
import { useEffect } from "react";
import { OpenAPI } from "@propertelligent/client-api";
import { PubSub } from "../../utils/pubsub";
import { PdfReportData } from "./pdfReportData";
import './generatePdf.css'
import PrintLayout from "./printLayout";
import { useGeneratePdfContext } from "../../utils/context/GeneratePdfContext";


export const GeneratePdfDocument = () => {
  const query = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const { id } = useParams()
  const { orgDetails, subHeaderName } = useGeneratePdfContext()

  useEffect(() => {
    let documentType = query.get('documentType');
    if (documentType) {
      navigate("/generatePdfDocument/" + documentType);
    }
    const handlePdfData = (data: PdfReportData) => {
      OpenAPI.TOKEN = data.token;
      OpenAPI.BASE = '';
      window.setTimeout(() => { window['pdfReady'] = true; }, 30000);
    };

    PubSub.subscribe("SERVER_PDF_DATA", (data: PdfReportData) => {
      OpenAPI.TOKEN = data.token;
      OpenAPI.BASE = '';
      window.setTimeout(() => { window['pdfReady'] = true; }, 30000);
    });
  }, []);


  useEffect(() => {
    if (location.pathname.startsWith('/generatePdfDocument')) {
      const timer = setTimeout(() => {
        window.print();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [location.pathname]);

  return (
    <>
      <PrintLayout   isPrint={false}>
        <Routes>
          <Route path="/lease-application" element={<LeaseApplication />} />
          <Route path="/lease-agreement/:id" element={<LeaseAgreement />} />
          <Route path="/pnl/:id" element={<ProfitAndLossStatement />} />
          <Route path="/rent-roll/:id" element={<RentRoll />} />
        </Routes>
      </PrintLayout>
    </>
  )
};
