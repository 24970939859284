import { Alert } from "react-bootstrap"
import { GetImages } from "../../../utils/GetImages"
import "./notification.css"
import { NotificationPayload, NotificationType } from "../../../utils/pubsub";

type NotificationComponentProps = {
	payload: NotificationPayload;
	setNotification: (value: boolean) => void;
  };

const Notification : React.FC<NotificationComponentProps>= ({ payload, setNotification }) => {
    let variantType = payload.type == NotificationType.Success ? "success" : "danger";
	return (
		<div className="notification">
			<Alert variant={variantType}>
				<img width="24px" height="24px" src={GetImages[variantType]} />
				<p>{payload.message}</p>
				<img onClick={() => setNotification(false)} src={GetImages.closeIconBlack} />
			</Alert>
		</div>
	)
}

export default Notification