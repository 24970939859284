import Forward from "../../../assets/arrow_forward.png";
const AddButtonWithArrow = ({
  buttonname,
  onClick =(value:any)=>{},
  disabled = undefined,
  iconDirection=undefined,
  Icon = undefined,
  id=undefined,
  type= undefined,
  className=undefined
}) => {
  return (
    <>
      <button
        type={type}
        className={`getStartedButtonadd ${className ? className :""}`}
        onClick={onClick}
        disabled={disabled}
        id={id}
      >
        {iconDirection == "right" ? (
          <>
            <img className="iconstyle11" src={Icon} alt={buttonname} />
            <p className="sub_text white fw-600">{buttonname} </p>
          </>
        ) : iconDirection == "left" ? (
          <>
            <p className="sub_text white fw-600">{buttonname} </p>
            <img className="iconstyle11" src={Icon} alt={buttonname} />
          </>
        ) : (
          <p className="sub_text white fw-600">{buttonname} </p>
        )}
      </button>
    </>
  );
};

export default AddButtonWithArrow;
