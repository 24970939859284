import { Route, Routes } from "react-router";
import { GetImages } from "../../../utils/GetImages";
import MyProfileView from "./MyProfileView";

export const MyProfile = () => {
  return (
    <Routes>
    <Route index element={<MyProfileView/>} />
  </Routes>

  );
};
