import React, { memo, useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Offcanvas, Spinner } from "react-bootstrap";
import { EntityType, PaymentService, UnitService } from "@propertelligent/client-api";
import { getProperty } from "../../redux/actions/propertyAction";
import Tabs from "./components/tabs";
import { getKeyByValue } from "./components/helper";
import UnitTanentForm from "../firstTimeUserExperience/UnitTanentForm";
import { GetImages } from "../../utils/GetImages";
import AddButtonWithArrow from "../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import { AvatarWithLoading } from "../common/avator";
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton";
import { UserLoginData } from "../common/charts/Enums";
import { URLS } from "../../constants/constants";
import { MenuContext } from "../../utils/context/MenuContext";
import apiHelper from "../../utils/apiHelper";

const PropertyDetails = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { id } = useParams();
  const [showModel, setShowModel] = useState(false);
  const [showPaymentModel, setShowPaymentModel] = useState(false);
  const [unitdata, setUnitData] = useState([]);
  const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
  const [loadingStates, setLoadingStates] = useState({
    overview: true,
    unit: true,
    serviceRequest: true,
  }); // Loading state
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(1);
  const { singleProperty } = useSelector((state: any) => state?.singleProperty);


  const fetchUnitList = async () => {
    try {
      const res = await apiHelper(
        UnitService.getApiUnitPropertylist,
        { showNotification: false },
        Number(id)
      );
      setUnitData(res?.data?.success);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index, className) => activeIndex === index ? className : "";

  useEffect(() => {
    if (
      !loadingStates.overview &&
      !loadingStates.unit &&
      !loadingStates.serviceRequest
    ) {
      setLoading(false);
    }
  }, [loadingStates]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch property details
        await dispatch(getProperty(id));

        // Fetch unit list
        await fetchUnitList();

        const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
        const propertyId = parseInt(id);

        // Fetch payment methods
        const paymentMethods = await apiHelper(
          PaymentService.getApiPaymentMethodPropertylist,
          { showNotification: false },
          propertyId
        );
        if (paymentMethods?.data?.success) {
          setPaymentMethodsArr(paymentMethods?.data?.success);
        }

        // Fetch payee accounts
        const payeeAccounts = await apiHelper(
          PaymentService.getApiPayeeAccountOrganizationlist,
          { showNotification: false },
          orgId
        );
        if (payeeAccounts?.data?.success) {
          setPayeeAccountsArr(payeeAccounts?.data?.success);
        }
        // All data fetched, stop loading
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleSubmitPaymentAccountRecord = ({ values, payvalues }) => {
    const {
      description,
      dueDate,
      amount,
      entityId,
      payeeAccountId,
      gracePeriodInDays,
      paymentDueAccountStatusId,
      isRecordPayment,
      paymentTypeId,
    } = values;
    const { fromPaymentMethodId } = payvalues;

    const updatedValues = {
      amount,
      description,
      dueDate: new Date(dueDate).toISOString(),
      entityId,
      entityTypeId: EntityType.Property,
      payeeAccountId,
      gracePeriodInDays,
      paymentDueAccountStatusId,
      fromPaymentMethodId,
      isRecordPayment,
      paymentTypeId,
    };

    apiHelper(
      PaymentService.postApiPaymentDueAccount,
      {
        successMessage: "Payment added successfully",
        failureMessage: "Unable to add payment",
        showNotification: true,
      },
      updatedValues
    )
      .then((res: any) => {
        if (res?.data?.success) {
          setShowModel(false);
          setTimeout(() => {
            navigate(URLS.PROPERTIES);
          }, 2000);
        }
      })
      .catch((error) => { });
  };

  const { isOpen } = useContext(MenuContext);

  return (
    <>
      {loading && ( // Check loading state
        <div className="loading-container">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      <div
        style={{ display: loading == true && "none" }}
        className="property-details-container"
      >
        <div className={`sticky-top-property ${isOpen ? "sidebar-open" : "sidebar-closed"}`}
        >
          <div className="property-details-bredcrum">
            <nav
              className=" normal_text black"
              style={
                {
                  "--bs-breadcrumb-divider": `url("${GetImages.BlackChevronRight}")`,
                } as React.CSSProperties
              }
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item ">
                  <Link
                    className="normal_text black text-decoration-none fw-500"
                    to={URLS.PROPERTIES}
                  >
                    Property
                  </Link>
                </li>

                <li
                  className="breadcrumb-item active normal_text grey_subtext1 wrapemailSubject"
                  aria-current="page"
                >
                  {singleProperty?.address?.streetAddress}
                </li>
              </ol>
            </nav>
          </div>
          <div className="about-property">
            <div className="propertyFirstColumn">
              <div className="p-image">
                <AvatarWithLoading
                  docImage={singleProperty?.mainImageBase64}
                  docNumber={singleProperty?.mainImageId}
                  avatar={true}
                  Size="Icon60"
                />
              </div>
              <div className="property-details">
                <p className="sub_text  grey_subtext1">
                  {getKeyByValue(Number(singleProperty?.propertyTypeId))}
                </p>
                <p className="heading black  propertyAddress">
                  {singleProperty?.address?.streetAddress}
                </p>
              </div>
            </div>

            <div className="property-button">
              <AddButtonWithArrow
                buttonname="Add New Unit"
                onClick={() => setShowModel(true)}
                disabled={undefined}
                iconDirection="right"
                Icon={GetImages.WhiteAdd}
              />
              <AddTraspButton
                onClick={() => navigate(URLS.CREATE_PAYMENT + `?entityId=${id && Number(id)}&entityTypeId=${EntityType.Property}`)}
                bname="Add Payment"
                iconPosition="right"
                border={true}
              />
            </div>
            <Offcanvas
              className="offcanvas"
              show={showModel}
              onHide={() => setShowModel(false)}
              placement={"end"}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Add Unit</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <UnitTanentForm
                  id={singleProperty?.id}
                  setShowModel={setShowModel}
                  unitId={undefined}
                  isEdit={undefined}
                  setISEdit={undefined}
                  canvasbody={true}
                />
              </Offcanvas.Body>
            </Offcanvas>
          </div>

          <div className="p-tabs">
            <button
              className={`tab normal_text grey_subtext1 ${checkActive(
                1,
                "active "
              )}`}
              onClick={() => handleClick(1)}
            >
              Overview
            </button>
            <button
              className={`tab normal_text grey_subtext1 ${checkActive(
                2,
                "active"
              )}`}
              onClick={() => handleClick(2)}
            >
              Units
            </button>
            <button
              className={`tab normal_text grey_subtext1 ${checkActive(
                3,
                "active"
              )}`}
              onClick={() => handleClick(3)}
            >
              Service Requests
            </button>
          </div>
        </div>
        <div
          className="w-100 property-bottom-container"
          style={{
            marginTop: "194px",
            paddingTop: "20px",
          }}
        >
          <div
            style={{
              height: " calc(100vh - 260px)",
              // overflow: "auto",
            }}
            className="panels w-100 property-bottom-container-inside"
          >
            <Tabs
              id={singleProperty?.id}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              loadingStates={loadingStates}
              setLoadingStates={setLoadingStates}
              checkActive={checkActive}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(PropertyDetails);
