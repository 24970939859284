import Forward from "../../../assets/blueforwardarrow.png";
import { GetImages } from "../../../utils/GetImages";

const AddTraspButton = ({
  bname,
  iconPosition = undefined,
  imageValue = null,
  onClick = undefined,
  border = undefined,
  disabled = undefined,
  textclass = "normal_text fw-600 blue",
  width = undefined,
  id = undefined,
  className = undefined,
  IconClassName = "iconstyle",
  type="submit"
}:any) => {
  return (
    <>
      <button
        type={type}
        onClick={onClick}
        className={`addtrasparent ${className}`}
        id={id}
        style={{
          border: !border ? "none" : "",
          boxShadow: !border ? "none" : "",
          background: !border ? "none" : "",
          minWidth: width,
        }}
        disabled={disabled}
      >
        {iconPosition === "right" && imageValue !== null && (
          <img src={imageValue} className={` ${IconClassName}`} />
        )}
        <p className={textclass}> 
          {bname}
        </p>
        {iconPosition === "left" && imageValue !== null && (
          <img src={GetImages.blueForwardArrow} className={` ${IconClassName}`} />
        )}
      </button>
    </>
  );
};

export default AddTraspButton;
