import React, { ReactElement } from "react";
import './styles.css';

type props = {
    statusText: string; icon?: ReactElement; color: string; isLarge?: boolean; disabled?: boolean; isLong?: boolean;
}

const StatusTag: React.FC<props> = ({ statusText, icon, color, isLarge = true, disabled = false, isLong = false}) => {
    return (
      <>
        <div className={`sub_text fw-500 simpleBox ${color}ColorForBox ${disabled ? 'disableBg': ''} ${isLong ? 'isLong': ''}`}>
           {icon && (!disabled) && <>{icon}</>}
           <span className={`sub_text fw-500 ${color}ColorForText ${icon ? 'ml-5': ''} ${!isLarge ? 'small_text':''} ${disabled ? 'disableColor': ''}`}>{statusText}</span>
        </div>
      </>
    );
  };
  
  export default StatusTag;
  