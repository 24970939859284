import { useState } from 'react'
import AddTraspButton from '../../firstTimeUserExperience/commonCom/AddTraspButton';
import { GetImages } from '../../../utils/GetImages';
import CustomInputs from '../Inputs';
import { Form } from 'react-bootstrap';

const EditorToolBar = ({ editorRef, handleFileChange }) => {
    const [activeCommand, setActiveCommand] = useState<string | null>(null);

    const fontSizes = Array.from({ length: 14 }, (_, i) => i + 1); // Sizes 1 to 14

    const applyCommand = (command, value = null) => {
        if (activeCommand === command) {
            setActiveCommand(null);
        } else {
            setActiveCommand(command);
        }
        document.execCommand(command, false, value);
    };

    const changeFontSize = (e) => {
        const size = e.target.value;
        if (size) applyCommand("fontSize", size);
    };

    const handleInsertImage = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (editorRef.current) {
                const img = document.createElement("img");
                img.src = e.target.result as string;
                img.alt = file.name;
                img.style.maxWidth = "100%";
                img.style.margin = "10px 0";

                editorRef.current.appendChild(img);
            }
        };
        reader.readAsDataURL(file);
    };

    const onFileChange = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            handleInsertImage(files[0]);
        }
    };

    return (
        <div className="Editor_toolbar">
            <select className="toolbar-select" onChange={changeFontSize}>
                {fontSizes.map((size) => (
                    <option key={size} value={size}>
                        {size}
                    </option>
                ))}
            </select>
            <div className="divider"></div>
            <input
                type="color"
                className="toolbar-color-picker"
                onChange={(e) => applyCommand("foreColor", e.target.value)}
            />
            <div className="divider"></div>
            <AddTraspButton
                type={"button"}
                width={0}
                bname={<b>B</b>}
                textclass={activeCommand === "bold" ? "sub_heading fw-600" : "sub_heading grey fw-600"}
                className="px-1 toolbar-button"
                onClick={() => applyCommand("bold")}
                border={false}
            />
            <AddTraspButton
                type={"button"}
                width={0}
                bname={<i>I</i>}
                textclass={activeCommand === "italic" ? "sub_heading fw-600" : "sub_heading grey fw-600"}
                className="px-1 toolbar-button"
                onClick={() => applyCommand("italic")}
                border={false}
            />
            <AddTraspButton
                type={"button"}
                width={0}
                bname={<s>S</s>}
                textclass={activeCommand === "strikeThrough" ? "sub_heading fw-600" : "sub_heading grey fw-600"}
                className="p-0"
                onClick={() => applyCommand("strikeThrough")}
                border={false}
            />
            <AddTraspButton
                type={"button"}
                width={0}
                bname={<u>U</u>}
                className="p-0"
                textclass={activeCommand === "underline" ? "sub_heading fw-600" : "sub_heading grey fw-600"}
                onClick={() => applyCommand("underline")}
                border={false}
            />
            <div className="divider"></div>
            <AddTraspButton
                type={"button"}
                width={0}
                bname={undefined}
                className="p-0"
                imageValue={GetImages.GreyLeftAlign}
                iconPosition={"right"}
                onClick={() => applyCommand("justifyLeft")}
                border={false}
            />
            <AddTraspButton
                type={"button"}
                width={0}
                bname={undefined}
                className="p-0"
                imageValue={GetImages.GreyCenterAlign}
                iconPosition={"right"}
                onClick={() => applyCommand("justifyCenter")}
                border={false}
            />
            <AddTraspButton
                type={"button"}
                width={0}
                bname={undefined}
                className="p-0"
                imageValue={GetImages.GreyRightAlign}
                iconPosition={"right"}
                onClick={() => applyCommand("justifyRight")}
                border={false}
            />
            <div className="divider"></div>
            <AddTraspButton
                type={"button"}
                width={0}
                bname={undefined}
                className="p-0"
                imageValue={GetImages.GreyNumberList}
                iconPosition={"right"}
                onClick={() => applyCommand("insertOrderedList")}
                border={false}
            />
            <AddTraspButton
                type={"button"}
                width={0}
                bname={undefined}
                className="p-0"
                imageValue={GetImages.GreyDotList}
                iconPosition={"right"}
                onClick={() => applyCommand("insertUnorderedList")}
                border={false}
            />
            <div className="divider"></div>
            <AddTraspButton
                type={"button"}
                width={0}
                bname={undefined}
                className="p-0"
                imageValue={GetImages.GreyLink}
                iconPosition={"right"}
                onClick={(url) => applyCommand("createLink", url)}
                border={false}
            />
            <AddTraspButton
                type={"button"}
                width={0}
                bname={"X"}
                className="p-0"
                imageValue={undefined}
                iconPosition={"right"}
                onClick={() => applyCommand("unlink")}
                textclass={activeCommand === "unlink" ? "sub_heading fw-600" : "sub_heading grey fw-600"}
                border={false}
            />
            <Form.Group controlId="formFile" className="mb-0">
                <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={onFileChange}
                    style={{ display: "none" }}
                />
                <AddTraspButton
                    type={"button"}
                    width={0}
                    bname={undefined}
                    className="p-0"
                    imageValue={GetImages.GreyImageicon}
                    iconPosition={"right"}
                    onClick={() => document.getElementById("formFile").click()}
                    border={false}
                />
            </Form.Group>
            <div className="divider"></div>
            <div className="attachment-container">
                <label htmlFor="file-input">
                    <img src={GetImages.BlackAttachemnet} className="iconstyle24" />
                    <CustomInputs
                        type="file"
                        id="file-input"
                        multiple
                        onChange={handleFileChange}
                        myClassName="email-file-input"
                    />
                </label>
            </div>
        </div>
    )
}

export default EditorToolBar