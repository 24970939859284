import { ServiceRequestService } from "@propertelligent/client-api";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router";
import ServiceRequestDetailHeader from "./ServiceRequestDetailHeader";
import { ServiceNotes } from "../../../components/servicerequest/serviceNotes";
import ContractorDetail from "./ContractorDetail";
import UploadedImageView from "./UploadedImageView";
import apiHelper from "../../../utils/apiHelper";

const ServiceRequestDetails = () => {
  const { id } = useParams();
  const [currentRecord, setCurrentRecord] = useState<any>();

  const getServiceRequestDetail = () => {
    apiHelper(
      ServiceRequestService.getApiServiceRequest1,
      { showNotification: false },
      Number(id)
    )
      .then((res) => {
        setCurrentRecord(res?.data?.success);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getServiceRequestDetail();
  }, [id]);

  return (
    <Container fluid>
      <ServiceRequestDetailHeader serviceReqInfo={currentRecord} />
      <div style={{ borderBottom: "1px solid #E5E7EB" }}>
        <ServiceNotes serviceReqInfo={currentRecord?.description} />
      </div>
      <div
        style={{
          border: "1px solid #E5E7EB",
          padding: "24px",
          height: "calc(100vh - 310px)",
          overflow: "auto",
        }}
      >
        <ContractorDetail contractorName={currentRecord?.assignedTo} />
        <UploadedImageView />
      </div>
    </Container>
  );
};

export default ServiceRequestDetails;
