import { useState } from "react";
import "./Screens.css";
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton";
import { GetImages } from "../../utils/GetImages";
import AddButtonWithArrow from "../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import DisplayNamewithLeftBorder from "../common/DisplayNamewithLeftBorder";

const AddNote = ({ onClose, type, onSave, toggle, edit = true, setNoteMessage }) => {
  const [note, setNote] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [noteContent, setNoteContent] = useState(toggle?.notes || "");
  const handleSaveClick = () => {
    // setNoteMessage("Note Added successfully")
    setNoteContent(note);
    onSave(note, type, toggle);
    setEditMode(false);
    setNote("");
    onClose();
  };

  const handleEditClick = () => {
    setNoteMessage("Note Edited successfully")
    setEditMode(true);
    setNote(noteContent);
  };

  const handleDeleteClick = () => {
    setNoteMessage("Note Deleted successfully")
    setNoteContent("");
    onSave("", type, toggle);
    setEditMode(false);
  };

  return (
    <>
      <div className="approved-app-form">
        <div className="InnerBody NotesBody">
          <div className={` ${edit ? 'common_application_card_header d-flex justify-content-between align-items-center' : "common_application_card_header"} `}>
            <DisplayNamewithLeftBorder HeaderName={"NOTES"} className="ps-2 my-3 leftborder sub_text" />
            {noteContent && (
              <div className="d-flex gap-3">
                <img
                  src={GetImages.BlueEditIcon}
                  className="clickable"
                  onClick={handleEditClick}
                />
                <img
                  src={GetImages.ReportDeleteIcon}
                  className="clickable"
                  onClick={handleDeleteClick}
                />
              </div>
            )}
          </div>
          {editMode || !noteContent ? (
            <>
              <textarea
                className="w-100 mb-3 py-3"
                rows={2}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Add note here..."
              ></textarea>
              <div className="d-flex align-items-center gap-1 float-end">
                <AddTraspButton
                  border={true}
                  onClick={() => {
                    setEditMode(false);
                    setNote("");
                  }}
                  bname="Cancel"
                />
                <AddButtonWithArrow
                  // className="px-5"
                  Icon={GetImages.ArrowForward}
                  iconDirection={"left"}
                  buttonname="Save"
                  onClick={handleSaveClick}
                />
              </div>
            </>
          ) : (
            <p>{noteContent}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default AddNote;

