import "./../tenantStyles.css";
import TopFixedBar from "../../common/listPageLayout/TopFixedBar";
import TopFixedSubBar from "../../common/listPageLayout/TopFixedSubBar";
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router';


const TopFixedView = ({ rowCount, searchValue, setSearchValue, handleSort, currentSortDirection, SelectInitialOptions, selectedOption, setSelectedOption, isSelectDropdown }) => {

  return (
    <>
      <TopFixedBar
        rowCount={rowCount}
        rowCountLabel="Tenants"
        searchPlaceholder="Search by Tenant Name"
        addButtonLabel="Add Tenant"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        navigateTo="tenantType"
        SelectInitialOptions={SelectInitialOptions}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        isSelectDropdown={isSelectDropdown}
        currentSortDirection={currentSortDirection}
        handleSort={handleSort}
      />

      {!isSelectDropdown &&
        <TopFixedSubBar
          currentSortDirection={currentSortDirection}
          handleSort={handleSort}
        />}

    </>
  );
};

export default TopFixedView;
