import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { DocumentEntityTypesEnum, DocumentService, DocumentTypesEnum, LeaseAgreementService, LeaseApplicationService, TemplateService, TemplateTypeEnum, TenantLeaseTypesEnum, TenantService, UnitService, } from "@propertelligent/client-api";
import { UserLoginData } from "../common/charts/Enums";
import { GetImages } from "../../utils/GetImages";
import { URLS } from "../../constants/constants";
import "./tenantStyles.css";
import apiHelper from "../../utils/apiHelper";
import { validateEmail, validateFirstName, validateLastName, validateNumber } from "../../utils/validateHelper";
import { useError } from "../../utils/context/ErrorContext";
import RequestBreadcrumb from "../common/RequestBreadcrumb";
import TenantBasicDetails from "./common/TenantBasicDetails";
import SelectLeaseAndUploader from "../common/SelectLeaseAndUploader";
import { Button } from "react-bootstrap";
import AddButtonWithArrow from "../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import PropertyUnitSelect from "./common/PropertyUnitSelect";


const CreateForm = ({ selectBasic, fetchData }) => {
  const navigate = useNavigate();
  const [unitSelectError, setUnitSelectError] = useState<boolean>(false);
  const [templateSelectError, setTemplateSelectError] = useState<boolean>(false);
  const [templeteData, setTempleteData] = useState<any>([]);
  const [allUnitData, setAllUnitData] = useState<any>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [isPropertyDisabled, setIsPropertyDisabled] = useState<boolean>(true);
  const [selectedUnit, setSelectedUnit] = useState<any>(null);
  const [selectedOption, setSelectedOption] = useState(TenantLeaseTypesEnum.FixedTermLease);
  const [leaseDetails, setLeaseDetails] = useState<any>({
    startDate: "",
    endDate: "",
    amount: "",
    securityDeposit: ""
  })
  const [fileName, setFileName] = useState<any>("");
  const [agreementFileName, setAgreementFileName] = useState<any>("");
  const [uploadDocument, setUploadDocument] = useState<any>();
  const [uploadAgreement, setUploadAgreement] = useState<any>();
  const { errors, setErrors, errorMessages, setErrorMessages, clearErrors } = useError();
  const [values, setValues] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    unitId: selectedUnit,
    userId: null,
  });

  const checkEmptyFields = (): boolean => {
    let hasError = false;
    clearErrors();
    const newErrors = { ...errors };

    if (values.email.trim() === "") {
      newErrors.email = true;
      hasError = true;
    }

    if (values.firstName.trim() === "") {
      newErrors.firstName = true;
      hasError = true;
    }

    if (values.lastName.trim() === "") {
      newErrors.lastName = true;
      hasError = true;
    }

    if (values.phoneNumber.trim() === "") {
      newErrors.phoneNumber = true;
      hasError = true;
    }

    if (!selectedUnit) {
      setUnitSelectError(true);
      hasError = true;
    }

    if (leaseDetails.startDate.trim() === "") {
      newErrors.startDate = true;
      hasError = true;
    }

    if (leaseDetails.endDate.trim() === "") {
      newErrors.endDate = true;
      hasError = true;
    }

    if (!leaseDetails.amount) {
      newErrors.rent = true;
      hasError = true;
    }

    if (!leaseDetails.securityDeposit) {
      newErrors.securityDeposit = true;
      hasError = true;
    }

    if (!selectedTemplate) {
      setTemplateSelectError(true);
    }
    setErrors(newErrors);
    return hasError; // Return true if there are errors
  };

  const organization_id = Number(localStorage.getItem(UserLoginData.organization_id));

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: false }));
    setErrorMessages((prev) => ({ ...prev, [name]: "" }))
  };

  const submitTimeError = () => {
    if (selectBasic == "1") {
      return (
        errors.firstName || errors.lastName || errors.email ||
        unitSelectError || errors.phoneNumber ||
        values.firstName.trim() === "" || values.lastName.trim() === "" ||
        values.phoneNumber.trim() === "" || values.email.trim() === "" ||
        !selectedUnit || templateSelectError || !selectedTemplate
      )
    } else {
      if (selectedOption === TenantLeaseTypesEnum.FixedTermLease) {
        return (
          errors.firstName || errors.lastName || errors.email ||
          unitSelectError || errors.phoneNumber ||
          errors.startDate || errors.endDate || errors.rent || errors.securityDeposit ||
          values.firstName.trim() === "" || values.lastName.trim() === "" ||
          values.phoneNumber.trim() === "" || values.email.trim() === "" ||
          leaseDetails.amount === "" || !selectedUnit || leaseDetails.securityDeposit === ""
        );
      } else if (selectedOption == TenantLeaseTypesEnum.MonthToMonth) {
        return (
          errors.firstName || errors.lastName || errors.email ||
          unitSelectError || errors.phoneNumber ||
          errors.startDate || errors.rent || errors.securityDeposit ||
          values.firstName.trim() === "" || values.lastName.trim() === "" ||
          values.phoneNumber.trim() === "" || values.email.trim() === "" ||
          leaseDetails.startDate.trim() === "" || leaseDetails.amount === "" || !selectedUnit ||
          leaseDetails.securityDeposit === ""
        );
      }
    }
  }

  const formSubmitHandler = (e) => {
    checkEmptyFields();
    e.preventDefault();
    const userId = Number(localStorage.getItem(UserLoginData.user_id));
    const newValues = { ...values, userId: userId, unitId: selectedUnit };
    if (selectBasic == "2") {
      if (selectBasic == "2" && submitTimeError()) {
      } else {
        apiHelper(TenantService.postApiTenant, {
          successMessage: "Tenant Invited Successfully",
          failureMessage: "Unable to invite tenant",
          showNotification: true,
        },
          newValues
        ).then((res) => {
          if (res?.data?.success) {
            const tenantId = res?.data?.success?.id;
            const unitId = newValues.unitId;
            apiHelper(TenantService.putApiTenantAssociate, { showNotification: false }, tenantId, unitId)
              .then((res1) => {
                if (res1) {
                  let formattedEndDate = null;
                  const formattedStartDate = new Date(leaseDetails.startDate).toISOString();
                  if (leaseDetails.endDate) {
                    formattedEndDate = new Date(leaseDetails.endDate).toISOString();
                  }

                  const requestBody = {
                    unitId: unitId,
                    tenantId: tenantId,
                    dataAttributes: {
                      LeaseType: `${selectedOption}`,
                      SecurityDeposit: leaseDetails.securityDeposit,
                      Rent: leaseDetails.amount,
                      StartDate: formattedStartDate,
                      EndDate: formattedEndDate
                    }
                    // tenantLeaseTypeId: Number(selectedOption),
                    // leaseStart: formattedStartDate,
                    // leaseEnd: formattedEndDate,
                    // securityDeposit: leaseDetails.securityDeposit,
                    // rent: Number(leaseDetails.amount),
                  };

                  apiHelper(LeaseAgreementService.postApiLeaseAgreement, { showNotification: false }, requestBody)
                    .then((res2) => {
                      if (res2?.data?.success) {
                        const documentPromises = [];

                        if (uploadDocument) {
                          const leaseApplicationData = {
                            EntityTypeId: DocumentEntityTypesEnum.Tenant,
                            EntityId: res2?.data?.success?.id,
                            DocumentTypeId: DocumentTypesEnum.LeaseApplication,
                            IsMain: false,
                            FileName: fileName,
                            Document: uploadDocument,
                          };
                          documentPromises.push(
                            apiHelper(DocumentService.postApiDocument, { showNotification: false }, leaseApplicationData)
                          );
                        }

                        if (uploadAgreement) {
                          const leaseAgreementData = {
                            EntityTypeId: DocumentEntityTypesEnum.Tenant,
                            EntityId: res2?.data?.success?.id,
                            DocumentTypeId: DocumentTypesEnum.LeaseAgreement,
                            IsMain: false,
                            FileName: agreementFileName,
                            Document: uploadAgreement,
                          };
                          documentPromises.push(
                            apiHelper(DocumentService.postApiDocument, { showNotification: false }, leaseAgreementData)
                          );
                        }

                        Promise.all(documentPromises)
                          .then(() => {
                            fetchData(1)
                            setUploadDocument(null);
                            setUploadAgreement(null);
                            setSelectedTemplate("");
                            setLeaseDetails({
                              startDate: "",
                              endDate: "",
                              amount: "",
                              securityDeposit: ""
                            })
                            setSelectedUnit("");
                            setValues({
                              ...values,
                              firstName: "",
                              lastName: "",
                              email: "",
                              phoneNumber: "",
                              unitId: null,
                            });
                            // setTimeout(() => {
                            //   navigate(URLS.TENANTS);
                            // }, 2000);
                          })
                          .catch((error) => { });
                      }
                    })
                    .catch((error) => { });
                }
              })
              .catch((error) => { });
          }
        })
          .catch((error) => {
            alert("error");
          });
      }
    } else {
      if (selectBasic == "1" && submitTimeError()) {
      } else {
        apiHelper(TenantService.postApiTenant, {
          successMessage: "Tenant Invited Successfully",
          failureMessage: "Unable to invite tenant",
          showNotification: true,
        }, newValues).then((res) => {
          if (res?.data?.success) {
            const inviteObj = {
              tenantId: res?.data?.success?.id,
              unitId: newValues.unitId,
              templateId: selectedTemplate,
            }
            apiHelper(LeaseApplicationService.postApiLeaseApplication, {
              successMessage: "Tenant Invited Successfully",
              failureMessage: "Unable to invite tenant",
              showNotification: true,
            }, inviteObj).then((res1) => {
              if (res1) {
                fetchData(1)
                setSelectedTemplate("");
                setSelectedUnit("");
                setValues({
                  ...values,
                  firstName: "",
                  lastName: "",
                  email: "",
                  phoneNumber: "",
                  unitId: null,
                });
                setTimeout(() => {
                  navigate(URLS.TENANTS);
                }, 2000);
              }
            }).catch((err) => { })
          }
        }).catch((err) => { })
      }
    }
  };

  useEffect(() => {
    getTempleteList();
    return () => {
      clearErrors();
    };
  }, []);

  useEffect(() => {
    if (checkEmptyFields) {
      clearErrors()
      setTemplateSelectError(false)
      setUnitSelectError(false)
    }
  }, [])

  const getTempleteList = async () => {
    apiHelper(TemplateService.getApiTemplateOrganizationlist, { showNotification: false },
      organization_id, undefined, undefined, undefined, `templateTypeId == ${TemplateTypeEnum.LeaseApplication}`)
      .then((res) => {
        setTempleteData(res?.data?.success);
      })
      .catch((error) => { });
  };

  const formDetails = [
    {
      label: "First Name",
      placeholder: "First Name",
      value: values.firstName,
      name: "firstName",
      blurFunction: () => validateFirstName(values.firstName, setErrors),
      errorMessages: errorMessages.firstName,
      isError: errors.firstName,
      required: true
    },
    {
      label: "Last Name",
      placeholder: "Last Name",
      value: values.lastName,
      name: "lastName",
      blurFunction: () => validateLastName(values.lastName, setErrors),
      errorMessages: errorMessages.lastName,
      isError: errors.lastName,
      required: true
    },
    {
      label: "Email Address",
      placeholder: "Email Address",
      value: values.email,
      name: "email",
      blurFunction: () =>
        validateEmail(values.email, setErrors, setErrorMessages),
      errorMessages: errorMessages.email,
      isError: errors.email,
      required: true
    },
    {
      label: "Phone Number",
      placeholder: "Phone Number",
      value: values.phoneNumber,
      name: "phoneNumber",
      blurFunction: () => validateNumber(values.phoneNumber, setErrors),
      errorMessages: errorMessages.phoneNumber,
      isError: errors.phoneNumber,
      required: true
    },
  ];


  useEffect(() => {
    getUnitList()
  }, [])

  const getUnitList = () => {
    apiHelper(UnitService.getApiUnitOrganizationlookup, { showNotification: false }, organization_id)
      .then((res) => {
        setAllUnitData(res?.data?.success);
      })
      .catch((err) => { });
  }

  return (
    <div id="tenantFormContainer">
      <RequestBreadcrumb
        firstName={"Tenant"}
        link={URLS.TENANTS}
        currentStep={selectBasic === "1" ? "Onboard New Tenant" : "Add existing tenant"}
      />

      <form className="createTenantForm" onSubmit={formSubmitHandler}>
        <div className="newTenantFormContainer">
          <div className="newTenantFormTopBar d-flex align-items-center">
            <img className="clickable me-2"
              onClick={() => {
                navigate(-1);
              }}
              src={GetImages.leftChevronBlack}
            />
            <p className="main_heading black">
              {selectBasic === "1" ? "Onboard New Tenant" : "Add Existing Tenant"}
            </p>
          </div>

          <TenantBasicDetails
            formDetails={formDetails}
            handleChange={handleChange}
          />

          <PropertyUnitSelect
            selectBasic={selectBasic}
            isPropertyDisabled={isPropertyDisabled}
            setIsPropertyDisabled={setIsPropertyDisabled}
            templateSelectError={templateSelectError}
            setTemplateSelectError={setTemplateSelectError}
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
            unitSelectError={unitSelectError}
            setUnitSelectError={setUnitSelectError}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            templeteData={templeteData}
            allUnitData={allUnitData}
          />

          {selectBasic == "2" && (
            <SelectLeaseAndUploader
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              values={leaseDetails}
              setValues={setLeaseDetails}
              uploadDocument={uploadDocument}
              setUploadDocument={setUploadDocument}
              fileName={fileName}
              setFileName={setFileName}
              uploadAgreement={uploadAgreement}
              setUploadAgreement={setUploadAgreement}
              agreementFileName={agreementFileName}
              setAgreementFileName={setAgreementFileName}
            />
          )}
        </div>

        <div className="newTanentBottomBar gap-3">
          <div>
            <Button onClick={() => navigate(-1)} className="cancelButtonNewTanentBottomBar sub_text fw-600">
              Cancel
            </Button>
          </div>
          <div>
            <AddButtonWithArrow
              type={"submit"}
              buttonname={selectBasic == "1" ? "Invite Tenant" : "Add Tenant"}
              disabled={undefined}
              iconDirection="left"
              Icon={GetImages.WhiteForwardArrow}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateForm;
