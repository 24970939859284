import React, { useState } from "react";

import { TopBar } from "../common/TopBar";
import { useNavigate } from "react-router";
import "./ForgetPasswordStyles.css";
import { emailRegex } from "../common/Regex";
import { LoginService } from "@propertelligent/client-api";
import { Button, Form } from "react-bootstrap";
import { GetImages } from "../../utils/GetImages";
import apiHelper from "../../utils/apiHelper";
import CustomInputs from "../common/Inputs";

export const ForgetPassword = () => {
  const navigate = useNavigate();
  const [emailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Please enter a valid email"
  );
  const backToLogin = () => {
    navigate("/login");
  };

  function validateEmail(): boolean {
    if (emailRegex.test(emailValue)) {
      setEmailError(false);
      return false;
    } else {
      setEmailError(true);
      return true;
    }
  }

  const sendResetLink = () => {
    const emailErrorConst = validateEmail();
    setEmailError(emailErrorConst);
    if (!emailErrorConst) {
      apiHelper(LoginService.postApiLoginForgetPassword,{showNotification:false},{ email: emailValue })
        .then((res) => {
          const stateData = { key: emailValue }; // Your state data
          navigate("/checkemail", { state: stateData });
        })
        .catch((error) => {
          setErrorMessage("Email is not registered");
          setEmailError(true);
        });
    }
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)), url(${GetImages.background})`,
        }}
        className="forgetPasswordContainer"
      >
        <TopBar haveAccount="Don’t have an account?" signInUp="Sign up" />
        <div className="conatiner">
          <div className="formBox">
            <Form onSubmit={sendResetLink}>
              <p className="top_header mb-2 black">Forgot Password</p>

              <p className=" sub_heading fw-normal mb-4 grey_subtext1">
                Reset password link will be sent to your email address
              </p>

              <p className="normal_text mb-2 black">Email *</p>
              <div>
                <CustomInputs
                  myClassName="forgetPasswordInput normal_text"
                  type="email"
                  placeholder="Email address"
                  value={emailValue}
                  onChange={(e) => {
                    setEmailValue(e.target.value);
                    setEmailError(false);
                  }}
                  onBlur={validateEmail}
                />
                <div style={{ marginBottom: "24px" }}>
                  {emailError && (
                    <p className="sub_text red">{errorMessage}</p>
                  )}
                </div>
              </div>

              <Button className="sendPasswordButton sub_heading" type="submit">
                Send reset password link
              </Button>
              <p className="normal_text fw-600 blue text-center clickable" onClick={backToLogin}>
                Back to login
              </p>
            </Form>
          </div>
        </div>{" "}
      </div>
    </>
  );
};

export default ForgetPassword;
