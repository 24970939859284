export const PubSub = (function(){
    let instance; // Private variable to hold the single instance
    let topics = {};
    class PubSubClass {
        constructor() {
            if (instance) {
                return instance; // Return existing instance if it exists
            }
            topics = {};
            instance = this; // Set the instance
        }

        
        // Subscribe to an event
        subscribe(topic : string, listener) {
            if (!topics[topic]) {
            topics[topic] = [];
            }
            topics[topic].push(listener);
            return () => {
            topics[topic] = topics[topic].filter((l) => l !== listener);
            };
        }
        
        // Unsubscribe from an event
        unsubscribe(topic : string, listener) {
            if (!topics[topic]) return;
            topics[topic] = topics[topic].filter((l) => l !== listener);
        }
        
        // Publish an event
        publish(topic : string, data : NotificationPayload) {
            if (!topics[topic]) return;
            topics[topic].forEach((listener) => listener(data));
        }
    }

    var pubsubClass = new PubSubClass();
    window['PubSub'] = pubsubClass;
    return pubsubClass;
})();

export enum TopicsEnum {
    Notification, 
    Logout
}

export enum NotificationType{
    None,
    Success,
    Failure
}

export type NotificationPayload = {
    type : NotificationType;
    message: string;
};  