export const GET_ERRORS = "GET_ERRORS";

// user Action

export const USER_LOGIN ="USER_LOGIN";

// property

export const REQUEST_ADD_PROPERTY ="REQUEST_ADD_PROPERTY";
export const SUCCESS_ADD_PROPERTY ="SUCCESS_ADD_PROPERTY";
export const FAIL_ADD_PROPERTY ="FAIL_ADD_PROPERTY";

export const REQUEST_GET_PROPERTY ="REQUEST_GET_PROPERTY";
export const SUCCESS_GET_PROPERTY ="SUCCESS_GET_PROPERTY";
export const FAIL_GET_PROPERTY ="FAIL_GET_PROPERTY";

export const REQUEST_GET_ALL_PROPERTY ="REQUEST_GET_ALL_PROPERTY";
export const SUCCESS_GET_ALL_PROPERTY ="SUCCESS_GET_ALL_PROPERTY";
export const FAIL_GET_ALL_PROPERTY ="FAIL_GET_ALL_PROPERTY";

export const GET_ALL_PROPERTY = "GET_ALL_PROPERTY";
export const DELETE_PROPERTY = "DELETE_PROPERTY";
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";

// unit
export const REQUEST_GET_UNIT ="REQUEST_GET_UNIT";
export const SUCCESS_GET_UNIT ="SUCCESS_GET_UNIT";
export const FAIL_GET_UNIT ="FAIL_GET_UNIT";


//  tenant

export const ADD_UNIT ="ADD_UNIT"
export const GET_ALL_UNIT = "GET_ALL_UNIT";
export const GET_UNIT = "GET_UNIT";
export const DELETE_UNIT = "DELETE_UNIT";
export const UPDATE_UNIT = "UPDATE_UNIT";

// Service Requst
export const REQUEST_GET_SERVICE_REQUEST ="REQUEST_GET_SERVICE_REQUEST";
export const SUCCESS_GET_SERVICE_REQUEST ="SUCCESS_GET_SERVICE_REQUEST";
export const FAIL_GET_SERVICE_REQUEST ="FAIL_GET_SERVICE_REQUEST";

export const REQUEST_GET_ALL_SERVICE_REQUEST ="REQUEST_GET_ALL_SERVICE_REQUEST";
export const SUCCESS_GET_ALL_SERVICE_REQUEST ="SUCCESS_GET_ALL_SERVICE_REQUEST";
export const FAIL_GET_ALL_SERVICE_REQUEST ="FAIL_GET_ALL_SERVICE_REQUEST";

export const REQUEST_POST_SERVICE_REQUEST ="REQUEST_POST_SERVICE_REQUEST";
export const SUCCESS_POST_SERVICE_REQUEST ="SUCCESS_POST_SERVICE_REQUEST";
export const FAIL_POST_SERVICE_REQUEST ="FAIL_POST_SERVICE_REQUEST";

export const REQUEST_PUT_SERVICE_REQUEST ="REQUEST_PUT_SERVICE_REQUEST";
export const SUCCESS_PUT_SERVICE_REQUEST ="SUCCESS_PUT_SERVICE_REQUEST";
export const FAIL_PUT_SERVICE_REQUEST ="FAIL_PUT_SERVICE_REQUEST";

export const REQUEST_DELETE_SERVICE_REQUEST ="REQUEST_DELETE_SERVICE_REQUEST";
export const SUCCESS_DELETE_SERVICE_REQUEST ="SUCCESS_DELETE_SERVICE_REQUEST";
export const FAIL_DELETE_SERVICE_REQUEST ="FAIL_DELETE_SERVICE_REQUEST";

export const RESET_SERVICE_REQUEST ="RESET_SERVICE_REQUEST";

// COMMAN UTILS
export const SHOW_SUCCESS_ALERT = "SHOW_SUCCESS_ALERT";

export const SHOW_SUCCESS_MEESAGE = "SHOW_SUCCESS_MEESAGE";

export const OPEN_SIDE_BAR = "OPEN_SIDE_BAR";
export const HIDDEN_SIDE_BAR = "HIDDEN_SIDE_BAR";

export const LOADING = "LOADING";


