import React from 'react'

const DisplayNamewithLeftBorder = ({ HeaderName, className = "text-uppercase ps-2 leftborder normal_text fw-normal black",buttons=null }) => {
  return (
    <>
    <div>
      <p className={`${className}`}>
        {HeaderName}
      </p>
      </div>
    </>
  )
}

export default DisplayNamewithLeftBorder