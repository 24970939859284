import React from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { GetImages } from '../../../utils/GetImages'
import CustomInputs from '../../common/Inputs'
import AddTraspButton from '../../firstTimeUserExperience/commonCom/AddTraspButton'
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow'

const AddPayee = ({
    onClose,
    heading,
    show,
    subheading = undefined
}) => {


    const payeeType = [
        { id: 1, name: "Bank/Finance" },
        { id: 2, name: "Garbage Expense" },
        { id: 3, name: "Online" },
    ]

    return (
        <div className="p-3">
            <Modal
                className="commonConfirmationModal d-flex align-items-center justify-content-center"
                show={show}
                onHide={onClose}
            >
                <div className="d-flex flex-column align-items-between justify-content-between h-100 p-3  addPayee">
                    <div>
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="heading black">{heading}</p>
                            <p className="sub_text black">{subheading}</p>
                            <img
                                className="clickable"
                                onClick={() => onClose()}
                                src={GetImages.closeIconBlack}
                            />
                        </div>
                        <Form className='pt-3'>
                            <div>
                                <CustomInputs
                                    labelText='Payee Name'
                                    placeholder='Enter payee name'
                                    onChange={undefined}
                                    myClassName='borderWithRadius'
                                    isFormControl={true}
                                    required
                                />

                                <Row className="p-0 w-100">
                                    <Col className='p-0'>
                                        <Form.Group>
                                            <Form.Label className="sub_text black">
                                                Select Payee Type
                                            </Form.Label>
                                            <Form.Select
                                                className="normal_text  grey_subtext1 select-dropdown"
                                                required
                                            >
                                                <option value="">Select Payee</option>
                                                {payeeType.map((item) => (
                                                    <option key={item.id} value={item.name}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <Row className="paymentinputrow px-0">
                                <Col>
                                    <div className="footerRightButtons">
                                        <AddTraspButton bname="cancel"  onClick={() => onClose()}/>

                                        <AddButtonWithArrow
                                            buttonname={"Add Payee"}
                                            onClick={undefined}
                                            disabled={undefined}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AddPayee