import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { OrganizationUserService, } from "@propertelligent/client-api";
import { GetImages } from "../../utils/GetImages";
import { Button, Form } from "react-bootstrap";
import EditCustomComponent from "../tenants/EditCustomComponent";
import { URLS } from "../../constants/constants";
import apiHelper from "../../utils/apiHelper";
import RequestBreadcrumb from "../common/RequestBreadcrumb";
import { useError } from "../../utils/context/ErrorContext";
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validateName,
  validateNumber,
} from "../../utils/validateHelper";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userTypeError, setUserTypeError] = useState(false);
  const [organizationUserTypeId, setOrganizationUserTypeId] = useState(1);
  const [values, setValues] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [userStatus, setUserStatus] = useState("")
  const { errors, setErrors, setErrorMessages, errorMessages, clearErrors } = useError();

  const submitTimeError = () => {
    return (
      errors.title ||
      errors.firstName ||
      errors.lastName ||
      errors.email ||
      errors.phoneNumber ||
      userTypeError ||
      values.firstName.trim() === "" ||
      values.lastName.trim() === "" ||
      values.phoneNumber.trim() === "" ||
      values.email.trim() === "" ||
      !organizationUserTypeId
    );
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    switch (name) {
      case "email":
        setErrors({ ...errors, email: false });
        setErrorMessages((prev) => ({ ...prev, email: "" }));
        break;
      case "firstName":
        setErrors({ ...errors, firstName: false });
        setErrorMessages((prev) => ({ ...prev, firstName: "" }));
        break;
      case "lastName":
        setErrors({ ...errors, lastName: false });
        setErrorMessages((prev) => ({ ...prev, lastName: "" }));
        break;
      case "phoneNumber":
        setErrors({ ...errors, phoneNumber: false });
        setErrorMessages((prev) => ({ ...prev, phoneNumber: "" }));
        break;
      case "title":
        setErrors({ ...errors, title: false });
        setErrorMessages((prev) => ({ ...prev, title: "" }));
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const checkEmptyFields = (): boolean => {
    let hasErrors = false;
    clearErrors();
    if (values.email.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, email: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: false }));
    }

    if (values.firstName.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, firstName: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, firstName: false }));
    }

    if (values.lastName.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, lastName: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, lastName: false }));
    }

    if (values.phoneNumber.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: false }));
      setErrorMessages((prev) => ({ ...prev, phoneNumber: "" }));
    }

    if (values.title.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, title: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, title: false }));
    }

    if (!organizationUserTypeId) {
      setUserTypeError(true);
      hasErrors = true;
    }
    return hasErrors;
  };

  useEffect(() => {
    if (checkEmptyFields()) {
      clearErrors();
    }
  }, []);

  const formDetails = [
    {
      label: "Title",
      placeholder: "Title",
      value: values.title,
      name: "title",
      blurFunction: () => validateName(values.title, setErrors),
      errorMessages: errorMessages.title,
      isError: errors.title,
      type: "text",
      required: true
    },
    {
      label: "First Name",
      placeholder: "Enter First Name",
      value: values.firstName,
      name: "firstName",
      blurFunction: () => validateFirstName(values.firstName, setErrors),
      errorMessages: errorMessages.firstName,
      isError: errors.firstName,
      type: "text",
      required: true
    },
    {
      label: "Last Name",
      placeholder: "Enter Last Name",
      value: values.lastName,
      name: "lastName",
      blurFunction: () => validateLastName(values.lastName, setErrors),
      errorMessages: errorMessages.lastName,
      isError: errors.lastName,
      type: "text",
      required: true
    },
    {
      label: "Email Address",
      placeholder: "Enter Email Address",
      value: values.email,
      name: "email",
      blurFunction: () =>
        validateEmail(values.email, setErrors, setErrorMessages),
      errorMessages: errorMessages.email,
      isError: errors.email,
      type: "email",
      required: true
    },
    {
      label: "Phone number",
      placeholder: "Enter phone no.",
      value: values.phoneNumber,
      name: "phoneNumber",
      blurFunction: () => validateNumber(values.phoneNumber, setErrors),
      errorMessages: errorMessages.phoneNumber,
      isError: errors.phoneNumber,
      type: "tel",
      required: true
    },
  ];

  const editUserHandler = (e) => {
    e.preventDefault();
    checkEmptyFields();
    if (submitTimeError()) { }
    else if (userStatus === "Active") {
      const payload = {
        ...values,
        organizationUserTypeId: organizationUserTypeId,
        organizationUserId: Number(id),
      };
      apiHelper(OrganizationUserService.putApiOrganizationUser,
        {
          successMessage: "User edited successfully",
          failureMessage: "Unable to edit user",
          showNotification: true,
        }, id, payload)
        .then((res) => {
          getUserData();
        })
        .catch((error) => { });
    } else {
      apiHelper(OrganizationUserService.putApiOrganizationUserResendInvite,
        {
          successMessage: "User Invited Successfully",
          failureMessage: "Unable to Invite User",
          showNotification: true,
        }, id)
        .then((res) => {
          getUserData();
        })
        .catch((error) => { });
    }
  };

  useEffect(() => {
    getUserData();
    clearErrors();
  }, []);

  const getUserData = () => {
    apiHelper(
      OrganizationUserService.getApiOrganizationUser,
      { showNotification: false },
      Number(id)
    )
      .then((res) => {
        if (res?.data?.success) {
          setValues({
            title: res?.data?.success?.title,
            firstName: res?.data?.success?.firstName,
            lastName: res?.data?.success?.lastName,
            email: res?.data?.success?.email,
            phoneNumber: res?.data?.success?.phoneNumber,
          });
          setUserStatus(res?.data?.success?.formatted?.UserStatus)
          setOrganizationUserTypeId(res?.data?.success?.organizationUserTypeId);
        }
      })
      .catch((err) => { });
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <Form onSubmit={editUserHandler}>
        <RequestBreadcrumb
          firstName={"My Organization"}
          currentStep="User Detail"
          link={URLS.ORGANIZATION}
        />

        <div className="addContractorFormContainer">
          <div className="newTenantFormTopBar d-flex align-items-center">
            <img
              onClick={() => {
                navigate(-1);
              }}
              height={28}
              width={28}
              src={GetImages.leftChevronBlack}
              className="clickable"
            />
            <p className="newTenantFormHeading">Edit user</p>
          </div>
          <div className="newTenantDesciptionContainer mb-3">
            <p className="newTenantDescriptionText sub_text black">Select user role *</p>
          </div>
          <div style={{ marginBottom: 40 }}>
            <div className="d-flex radioButtonContainer sub_heading mb-0 ">
              <Form.Check
                className="clickable"
                name="group1"
                type="radio"
                checked={organizationUserTypeId === 1}
                onChange={() => {
                  setOrganizationUserTypeId(1);
                  setUserTypeError(false);
                }}
                id="organizationAdmin"
              />
              <label className="clickable" htmlFor="organizationAdmin">
                Organization admin{" "}
              </label>

              <Form.Check
                className="clickable"
                name="group1"
                type="radio"
                checked={organizationUserTypeId === 2}
                onChange={() => {
                  setOrganizationUserTypeId(2);
                  setUserTypeError(false);
                }}
                id="propertyManager"
              />

              <label className="clickable" htmlFor="propertyManager">
                Property manager
              </label>
            </div>
            <div>
              {userTypeError && (
                <p className="errorMessage">Please select user role </p>
              )}
            </div>
          </div>
          <div className="newTenantDesciptionContainer mb-3">
            <p className="newTenantDescriptionText sub_text black">
              Enter basic details of users{" "}
            </p>
          </div>

          <div className="d-flex addContractorInputContainer">
            {formDetails.map((item) => {
              return (
                <EditCustomComponent
                  key={item.label}
                  label={item.label}
                  placeholder={item.placeholder}
                  value={item.value}
                  name={item.name}
                  onChangeFunction={handleChange}
                  onBlurFunction={item.blurFunction}
                  errorMessages={item.errorMessages}
                  isError={item.isError}
                  width="290px"
                  type={item.type}
                  required={item.required}
                />
              );
            })}
          </div>
        </div>

        <div className="editUserBottomBar d-flex align-items-center justify-content-start gap-3">
          <Button type="submit" className="saveChangesButtonStyles">
            <p className="sub_text fw-600 blue text-center">
              {userStatus === "Invitation Pending" ? "Resend Invite" : "Save Changes"}
            </p>
            <img src={GetImages.blueForwardArrow} />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditUser;
