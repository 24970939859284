import React, { ChangeEvent, useState } from "react";
import { Card, Form } from "react-bootstrap";

const Reference = ({   postData, 
  setPostData}) => {
  // const [requiredRange, setrequiredRange] = useState(ReferenceData?.requiredRange);
  // const handleCountChange = (e) => {
  //   const newCount = parseInt(e.target.value, 10);
  //   setrequiredRange(newCount);
  //   onCountChange(newCount);
  // };
  return (
    <>
      <Card className="edit-card">
        <Card.Title>Reference</Card.Title>
        <Card.Text>
          Kindly input the number of reference you'd like to provide 
          for the requested employee history within the app
        </Card.Text>
        <p>How many reference you would like to take ?</p>
        <Card.Footer>
          <Form.Control
            type="number"
            id="inputNumber"
            placeholder="Add Numbers"
            aria-describedby="passwordHelpBlock"
            value={postData?.leaseApplicationTemplateSections?.References?.requiredRange}
            // onChange={handleCountChange}
            onChange={(e) => {
              setPostData({
                ...postData,
                leaseApplicationTemplateSections: {
                  ...postData.leaseApplicationTemplateSections,
                  References: {
                    ...postData.leaseApplicationTemplateSections.References,
                    requiredRange:Number(e.target.value),
                  },
                },
              });
            }}
          />
        </Card.Footer>
      </Card>
    </>
  );
};

export default Reference;
