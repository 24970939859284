import React from "react";
import { Form } from "react-bootstrap";
// import Select from 'react-select';
// import 'react-select/dist/react-select.css';
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure to import the Bootstrap CSS
import "./style.css";

const CustomSelect = React.memo(
  ({
    fieldName,
    isFormControl = false,
    options = [],
    value,
    required = false,
    disabled = false,
    label = "",
    placeholder = "",
    isMulti = false,
    name,
    myClassName = "",
    errorMessage = "",
    focus = false,
    isError = false,
    onChange,
    defaultOption = null,
    style = undefined,
    onClick
  }: any) => {
    const handleChange = (event: any) => {
      onChange(event);
    };

    return (
      <>
        {isFormControl ? (
          <Form.Select
            data-live-search={true}
            name={name}
            className={myClassName}
            aria-label="Default Select Option"
            required={required}
            autoFocus={focus}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            style={style}
            onClick={onClick}
          >
            <option value="" disabled>
              {defaultOption ? defaultOption : "Select an option"}
            </option>
            {options?.map((item) => (
              <option key={item.id} value={item.id}>
                {item?.[fieldName]}
              </option>
            ))}
          </Form.Select>
        ) : (
          // <Select
          //   options={options}
          //   onChange={(e)=>handleChange(e)}
          //   isMulti = {isMulti}
          //   // className={`selectContainer ${myClassName || ''}`}
          //   // className={`selectContainer ${myClassName || ''}`}
          //   classNamePrefix={`selectContainer ${myClassName || ''} css-13cymwt-control`}
          // />
          <select
            name={name}
            value={value}
            required={required}
            placeholder={placeholder}
            onChange={handleChange}
            autoFocus={focus}
            onClick={onClick}
          >
            <option value="" disabled>
              Select an Option
            </option>
            {options?.map((item) => (
              <option key={item.id} value={item.id}>
                {item?.[fieldName]}
              </option>
            ))}
          </select>
        )}
        {isError && <p className="errorMessage"> {errorMessage} </p>}
      </>
    );
  }
);

export default CustomSelect;
