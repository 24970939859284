import Empty from "../../assets/Empty.png";

const EmptyData = ({
  mainText,
  subText = undefined,
  button,
  ImageSrc = Empty,
}) => {
  return (
    <>
      <div className="EmptyScreen ">
        <div className="d-flex flex-column align-items-center justify-content-center gap-2 h-100">
          <img src={ImageSrc} />
          <h6>{mainText}</h6>
          <p className="text-center grey_subtext1">{subText}</p>
          {!button ? "" : button}
        </div>
      </div>
    </>
  );
};

export default EmptyData;
