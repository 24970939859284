import React from 'react'
import DateHelper from '../../../../utils/DateHelper'

const DurationHeader = ({ startDate, endDate, statementData }) => {
    return (
        <div className="Duration_Header">
            <div className='d-flex align-items-center gap-2 left_section'>
                <p className="sub_heading black">
                    Duration:
                </p>
                <p className='sub_heading fw-normal black'>
                    {statementData && statementData?.queryData?.dateFilter?.formatted?.DateFilterTypeId !== "Custom" ?
                        statementData?.queryData?.dateFilter?.formatted?.DateFilterTypeId
                        : statementData?.queryData?.dateFilter?.formatted?.DateFilterTypeId === "Custom" ?
                            `From  ${DateHelper.convertUTCtoDateTime(statementData?.queryData?.dateFilter?.startDate)} to ${DateHelper.convertUTCtoDateTime(statementData?.queryData?.dateFilter?.endDate)}`
                            :
                            `From  ${DateHelper.convertUTCtoDateTime(startDate)} to ${DateHelper.convertUTCtoDateTime(endDate)}`
                    }
                </p>
            </div>
        </div>
    )
}

export default DurationHeader