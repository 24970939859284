import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";

const EmploymentHistoryEdit = ({ 
  // employmentData, onCountChange, 
 
  postData,  
  setPostData,
}) => {
  // const [requiredRange, setrequiredRange] = useState(employmentData?.requiredRange);

  // const handleCountChange = (e) => {
  //   const newCount = parseInt(e.target.value, 10);
  //   setrequiredRange(newCount);
  //   onCountChange(newCount);
  // };

  return (
    <>
      <Card className="edit-card">
        <Card.Title>Employee History</Card.Title>
        <Card.Text>
          Please specify the quantity of employee histories you wish to query
          within the app by entering the corresponding number.
        </Card.Text>
        <p>How many Employee History you would like to ask?</p>
        <Card.Footer>
          <Form.Control
            type="number"
            id="inputNumber"
            placeholder="Add Numbers"
            aria-describedby="passwordHelpBlock"
            value={postData?.leaseApplicationTemplateSections?.EmploymentHistory?.requiredRange

            }
            onChange={(e) => {
              setPostData({
                ...postData,
                leaseApplicationTemplateSections: {
                  ...postData.leaseApplicationTemplateSections,
                  EmploymentHistory: {
                    ...postData.leaseApplicationTemplateSections.EmploymentHistory,
                    requiredRange:Number( e.target.value),
                  },
                },
              });
            }}
          />
        </Card.Footer>
      </Card>
    </>
  );
};

export default EmploymentHistoryEdit;
