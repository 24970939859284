import { useEffect, useState } from 'react';
import AddButtonWithArrow from '../firstTimeUserExperience/commonCom/AddButtonWithArrow';
import RadioButton from '../common/RadioButton';
import { useGeneratePdfContext } from '../../utils/context/GeneratePdfContext';
import apiHelper from '../../utils/apiHelper';
import { PropertyService, ReportFilterEntityEnum, UnitService } from '@propertelligent/client-api';
import { UserLoginData } from '../common/charts/Enums';
import MultiselectDropdown from '../common/MultiselectDropdown';
import { Form } from 'react-bootstrap';
import AddTraspButton from '../firstTimeUserExperience/commonCom/AddTraspButton';

const FilterDropdown = ({ filters = {},
  selectedCategories,
  handleCategoryChange,
  organizationsResponse = () => { },
  propertyResponse = () => { },
  unitResponse = () => { },
  setShow,
  show
}) => {
  const { selectedOption, setSelectedOption, selectedValues, setSelectedValues } = useGeneratePdfContext();
  const [multiSelectOptions, setMultiSelectOptions] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [formValidated, setFormValidated] = useState({
    isSelectedProperties: false,
    isSelectDuration: false
  });
  const organizationId = parseInt(localStorage.getItem(UserLoginData.organization_id));

  const radioOnChangeHandler = (e) => {
    setSelectedOption(e.target.value);
    setSelectedIds([]);
  };

  const radioOptions = [
    {
      label: "Organization",
      value: ReportFilterEntityEnum.Organization,
      checked: selectedOption == ReportFilterEntityEnum.Organization,
      onChange: radioOnChangeHandler,
      name: "properties"
    },
    {
      label: "Properties",
      value: ReportFilterEntityEnum.Property,
      checked: selectedOption == ReportFilterEntityEnum.Property,
      onChange: radioOnChangeHandler,
      name: "properties"
    },
    {
      label: "Units",
      value: ReportFilterEntityEnum.Unit,
      checked: selectedOption == ReportFilterEntityEnum.Unit,
      onChange: radioOnChangeHandler,
      name: "properties"
    }
  ];

  const getData = (option) => {
    if (option == ReportFilterEntityEnum.Property) {
      apiHelper(PropertyService.getApiPropertyOrganizationlookup, { showNotification: false }, organizationId)
        .then((response) => {
          setMultiSelectOptions(response?.data?.success);
        })
        .catch((Err) => {
          console.error(Err);
        });
    } else if (option == ReportFilterEntityEnum.Unit) {
      setMultiSelectOptions([]);
      apiHelper(UnitService.getApiUnitOrganizationlookup, { showNotification: false }, organizationId)
        .then((response) => {
          setMultiSelectOptions(response?.data?.success);
        })
        .catch((Err) => {
          console.error(Err);
        });
    } else {
      setMultiSelectOptions([]);
    }
  };

  useEffect(() => {
    getData(selectedOption);
  }, [selectedOption]);

  const handleCheckbox = (value, id) => {
    setSelectedValues((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    );

    setSelectedIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((item) => item !== id)
        : [...prevIds, id]
    );
    setFormValidated({
      ...formValidated,
      isSelectedProperties: false,
    });
  };

  const filterCategories = Object.keys(filters);
  const fromItems = filters["From"] || [];
  const toItems = filters["To"] || [];
  const categories = filters["Category"] || [];
  const paymentMethod = filters["PaymentMethod"] || [];

  const handleFilterApply = () => {
    unitResponse()
    propertyResponse()
    organizationsResponse()
  }

  return (
    <div className="filter-component">
      <div className="BorderBottom">
        <h4 className="heading px-3">Filter by</h4>
      </div>

      <div className="d-flex ">
        <div className="filterRow">
          <div className=" BorderLeft transaction-filter-header">
            <h5 className="normal_text fw-500 p-3 sticky-header">View By</h5>
            <div className="viewByRow pe-3">
              <div className="d-flex flex-column gap-3 ps-3">
                {radioOptions.map((option, index) => (
                  <RadioButton
                    key={index}
                    label={option.label}
                    value={option.value}
                    checked={option.checked}
                    onChange={option.onChange}
                    name={option.name}
                  />
                ))}
              </div>

              {Number(selectedOption) !== ReportFilterEntityEnum.Organization && (
                <>
                  <Form.Label>
                    <MultiselectDropdown
                      options={multiSelectOptions}
                      selectedOption={selectedOption}
                      selectedIds={selectedIds}
                      placeholder={"Select Properties"}
                      onChange={handleCheckbox}
                    />
                    {formValidated.isSelectedProperties && (
                      <div className="invalid-feedback d-block">Please select at least one property</div>
                    )}
                  </Form.Label>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="BorderLeft transaction-filter-header">
          <h5 className="normal_text fw-500 sticky-header">Categories</h5>
          {categories.map((item, index) => (
            <div key={index} className="filter-row">
              <RadioButton
                type="checkbox"
                value={item}
                name="TranactionCreditDebitTypeId"
                checked={selectedCategories["TranactionCreditDebitTypeId"]?.includes(item)}
                onChange={(e) => handleCategoryChange(e, "TranactionCreditDebitTypeId")}
                key={index}
                label={item}
                checkBoxClass="sub_text  filterCheckbox"
              />
            </div>
          ))}
        </div>
        <div className="BorderLeft transaction-filter-header">
          <h5 className="normal_text fw-500 sticky-header">Payment method</h5>
          {paymentMethod.map((item, index) => (
            <div key={index} className="filter-row">
              <RadioButton
                name="paymentMethod.PaymentMethodTypeId"
                type="checkbox"
                value={item}
                checked={selectedCategories["paymentMethod.PaymentMethodTypeId"]?.includes(item)}
                onChange={(e) => handleCategoryChange(e, "paymentMethod.PaymentMethodTypeId")}
                key={index}
                label={item}
                checkBoxClass="sub_text filterCheckbox"
              />
            </div>
          ))}
        </div>
        <div className="BorderLeft transaction-filter-header">
          <h5 className="normal_text fw-500 sticky-header">Payee</h5>
          {toItems.map((item, index) => (
            <>
              <div key={index} className="filter-row">
                <RadioButton
                  name="payeeAccount.accountName"
                  type="checkbox"
                  value={item}
                  checked={selectedCategories["payeeAccount.accountName"]?.includes(item)}
                  onChange={(e) => handleCategoryChange(e, "payeeAccount.accountName")}
                  key={index}
                  label={item}
                  checkBoxClass=" sub_text filterCheckbox"
                />
              </div>
            </>
          ))}

        </div>

        <div className="BorderLeft transaction-filter-header">
          <h5 className="normal_text fw-500 sticky-header">Payer</h5>
          {fromItems.map((item, index) => (
            <div key={index} className="filter-row">
              <RadioButton
                name="paymentMethod.accountName"
                type="checkbox"
                value={item}
                checked={selectedCategories["paymentMethod.accountName"]?.includes(item)}
                onChange={(e) => handleCategoryChange(e, "paymentMethod.accountName")}
                key={index}
                label={item}
                checkBoxClass="sub_text filterCheckbox"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="BorderTop applyFilterBtn">
        <AddTraspButton bname="Clear filter" />
        <AddButtonWithArrow buttonname="Apply filter" onClick={() => {
          setShow(false)
          handleFilterApply()
        }} />
      </div>
    </div>
  );
};

export default FilterDropdown;
