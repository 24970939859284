
const Progressbar = ({ stepname, stepnum }) => {

  return (
    <>
      <div className="titleStep">
        <p className="sub_text  grey_subtext1">{stepname}</p>
        <p className="sub_text  grey_subtext1">Step {stepnum}</p>
      </div>
      {stepnum == "1/3" ?
        <div className="progressbaradd">
          <div
            className="progressBarElementsadd"
            style={{
              background: "#1a56db",
            }}
          ></div>
          <div
            className="progressBarElementsadd"
            style={{
              background: "#E1EFFE",
            }}
          ></div>
          <div
            className="progressBarElementsadd"
            style={{
              background: "#E1EFFE",
            }}
          ></div>
        </div>
        : stepnum == "2/3" ?
          <div className="progressbaradd">
            <div
              className="progressBarElementsadd"
              style={{
                background: "#1a56db",
              }}
            ></div>
            <div
              className="progressBarElementsadd"
              style={{
                background: "#1a56db",
              }}
            ></div>
            <div
              className="progressBarElementsadd"
              style={{
                background: "#E1EFFE",
              }}
            ></div>
          </div>
          :
          <div className="progressbaradd">
            <div
              className="progressBarElementsadd"
              style={{
                background: "#1a56db",
              }}
            ></div>
            <div
              className="progressBarElementsadd"
              style={{
                background: "#1a56db",
              }}
            ></div>
            <div
              className="progressBarElementsadd"
              style={{
                background: "#1a56db",
              }}
            ></div>
          </div>

      }

    </>
  );
};

export default Progressbar;
