import { Route, Routes } from "react-router";
import EditServiceRequest from "./editServiceRequest";
import TopFixedView from "./listView/topFixedView";
import MainScrollView from "./listView/mainScrollView";
import ContentTopBottom from "../common/listPageLayout/ContentTopBottom";
import { ServiceRequestService, ServiceRequestStatusesEnum } from "@propertelligent/client-api";
import { infiniteScrollHook } from "../../hooks/infiniteScrollHook";
import { useEffect, useState } from "react";
import "./servicerequest.css";

export const ServiceRequests = () => {
  const [fulllyQualifiedFilter, SetFullyQualifiedFilter] = useState(null);
  const [fulllyQualifiedSort, SetFulllyQualifiedSort] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("")
  const initialOptions = [
    { name: "New", value: "New" },
    { name: "In Progress", value: "In Progress" },
    { name: "Fixed", value: "Fixed" },
    { name: "Completed", value: "Completed" },
  ]

  const {
    rows,
    loading,
    hasMore,
    rowCount,
    fetchData,
    currentSortDirection,
    handleSort,
    currentPage,
  } = infiniteScrollHook({
    httpFetchDataFn: ServiceRequestService.getApiServiceRequestOrganizationlist,
    FullyQualifiedFilters: fulllyQualifiedFilter,
    FullyQualifiedSorts: fulllyQualifiedSort,
  });

  useEffect(() => {
    let filter = "";

    if (selectedOption === "New") {
      filter = `ServiceRequestStatus == ${ServiceRequestStatusesEnum.Open} || ServiceRequestStatus == ${ServiceRequestStatusesEnum.Estimating}`;
    } else if (selectedOption === "In Progress") {
      filter = `ServiceRequestStatus == ${ServiceRequestStatusesEnum.InProgress}`;
    } else if (selectedOption === "Completed") {
      filter = `ServiceRequestStatus == ${ServiceRequestStatusesEnum.Completed}`;
    } else if (selectedOption === "Fixed") {
      filter = `ServiceRequestStatus == ${ServiceRequestStatusesEnum.VerificationPending} || ServiceRequestStatus == ${ServiceRequestStatusesEnum.PaymentPending}`;
    }

    if (searchValue !== "") {
      const searchFilter = `Description.ToLower().Contains("${searchValue.toLowerCase()}")`;
      filter = filter ? `${filter} && (${searchFilter})` : searchFilter;
    }

    SetFullyQualifiedFilter(filter || null);
  }, [searchValue, selectedOption]);


  useEffect(() => {
    SetFulllyQualifiedSort("RequestDate " + currentSortDirection);
  }, [currentSortDirection]);


  return (
    <Routes>
      <Route
        index
        element={
          <ContentTopBottom
            FixedTopContent={() => (
              <TopFixedView
                rowCount={rowCount}
                currentSortDirection={currentSortDirection}
                handleSort={handleSort}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                SelectInitialOptions={initialOptions}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                isSelectDropdown={true}
              />
            )}
            FixedBottomContent={() => (
              <MainScrollView
                rows={rows}
                loading={loading}
                hasMore={hasMore}
                fetchData={fetchData}
                currentPage={currentPage}
              />
            )}
          />
        }
      />
      <Route path="/edit/:id" element={<EditServiceRequest />} />
    </Routes>
  );
};
