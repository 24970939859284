import React, { createContext, useContext, useState } from 'react';

const PaymentContext = createContext(null);

export const PaymentProvider = ({ children }) => {
    const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]); 
    const [selectedOption, setSelectedOption] = useState([]);
    const [tenantId,setTenantId] = useState()
    const [paymentData , setPaymentData] = useState([])
    const [formData, setFormData] = useState<any>([
        {
          id: 1,
          dueDate: "",
          amount: 0,
          gracePeriods: 0,
          description: "",
        },
      ]);

    const values = { 
        payeeAccountsArr,
        setPayeeAccountsArr,
        selectedItems,
        setSelectedItems,
        selectedOption,
        setSelectedOption,
        tenantId,
        setTenantId,
        formData,
        setFormData,
        paymentData,
        setPaymentData
    }

    return (
        <PaymentContext.Provider value={values}>
            {children}
        </PaymentContext.Provider>
    );
};

export const usePaymentContext = () => {
    return useContext(PaymentContext);
};

export { PaymentContext };
