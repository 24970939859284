import React, { useEffect, useRef, useState } from "react";
import "./tenantonboard.css";
import { OnboardingForms } from "../../../typings/onboarding";
import BoardingReview from "./BoardingReview";
import { GetImages } from "../../../utils/GetImages";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import html2pdf from 'html2pdf.js';
import PrintLayout from "../../generatePdfDocument/printLayout";
import OrganizationNameHeader from "../../common/OrganizationNameHeader/OrganizationNameHeader";

const SignatureConfirmation: React.FC<any> = ({
  formData,
  setFormData,
  isEditClick,
  leaseApplication,
  currentStepIndex,
  setpreStepIndex,
  steps
}: {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<OnboardingForms>>;
  isEditClick: any;
  leaseApplication: any;
  currentStepIndex: any;
  setpreStepIndex: any
  steps: any;
}) => {
  useEffect(() => {
    setpreStepIndex(currentStepIndex)
  }, [currentStepIndex])
  const [isPrint, setISPrint] = useState(false)

  const contentRef = useRef()
  const handleDownloadPdf = () => {
    setISPrint(true)
    const element = contentRef.current; // Get the content to download

    const options = {
      margin: 0.25,
      filename: 'Review Application.pdf',
      image: { type: 'pdf', quality: 0.50 },
      html2canvas: { scale: 1.10 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    };

    if (element) {
      html2pdf()
        .from(element)
        .set(options)
        .save()
    }
    setTimeout(() => {
      setISPrint(false)
    }, 200)
  };

  return (
    <>
      <div className="onboarding-stepper">
        <div className="flexRow justify-content-between">
          <h4 className="main_heading">On Boarding Application Review</h4>
          <AddTraspButton onClick={() => handleDownloadPdf()} imageValue={GetImages.pdfImage} iconPosition="right" textclass="sub_text fw-600" bname={"Download Application"} border={true} />
        </div>

        <div className="onboardingstepper-form">
          <section ref={contentRef} className="onboarding-review">
            {/* <PrintLayout isPrint={isPrint}> */}
            {
              isPrint && (
                <OrganizationNameHeader SubHeaderName={"Testing"} OrgName={"Capital"}/>
              )
            }
            <BoardingReview steps={steps} setFormData={setFormData} formData={formData} editable={true} leaseApplication={leaseApplication} isEditClick={isEditClick} postData={undefined} setPostData={undefined} />
            {/* </PrintLayout> */}
          </section>
        </div>
      </div>
    </>
  );
};

export default SignatureConfirmation;
