import React, { useEffect, useState } from "react";
import {
  Row,
  Button,
  Col,
  Card,
  ListGroup,
  Badge,
  Alert,
  Form,
  CardBody,
  Offcanvas,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { GetImages } from "../../utils/GetImages";
import signImg from "../../assets/sign-img.png";
import ApprovedScreen from "./approved";
import RejectedScreen from "./rejected";
import AddNote from "./AddNote";
// import HeaderView from "../TenantOnboard/RenderSteps/HeaderView";
import {
  LeaseApplicationService,
  LeaseApplicationStatusTypesEnum,
  TemplateService,
} from "@propertelligent/client-api";
import { OnboardingForms } from "../../typings/onboarding";
import {
  DefaultFeeFormData,
  DefaultQuestionnaire,
  OnBoardingRentIncomeEnum,
} from "../../constants/onboarding";
import HeaderTanent from "./HeaderTanent";

const TempleteReview: React.FC<any> = () => {
  const [income, setIncome] = useState(true);
  const [formData, setFormData] = useState({
    firstName: "Murtaza Abdali",
    date: "",
  });
  const [selectOption, setSelectOption] = useState("Approved");
  const [Toggle, setToggle] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [addnote, setAddNote] = useState(false);
  const [displayNotes, setDisplayNotes] = useState("");
  const [toggleRentPayInfo, setToggleRentPayInfo] = useState(true);
  const [toggleCreditCheck, setToggleCreditCheck] = useState(true);
  const [toggleEmploymentHistory, setToggleEmploymentHistory] = useState(true);
  const [toggleReferences, setToggleReferences] = useState(true);
  const [toggleQuestionnaire, setToggleQuestionnaire] = useState(true);
  const [notes, setNotes] = useState("");
  const [leaseData, setLeaseData] = useState<OnboardingForms>({
    BasicDetails: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",

    },
    RentIncomeCheck: {
      payRentVia: OnBoardingRentIncomeEnum.Income,
    },
    CreditCheck: { creditPermission: "yes", socialSecurityNumber: "" },
    EmploymentHistory: [],
    References: [],
    Questionnaire: DefaultQuestionnaire,
    ApplicationFeesClause: DefaultFeeFormData,
    // SignatureConfirmation:{}
  });
  const [tanentData, setTanentData] = useState<any>({
    CreditCheck: {},
    EmploymentHistory: {},
    Questionnaire: {},
    References: {},
    RentIncomeCheck: {},
    SignatureConfirmation: {},
  });
  const [reviewLease, setReviewLease] = useState("");
  const { id } = useParams();
  const leaseApplicationId = parseInt(id);

  useEffect(() => {
    const fetchLeaseData = async () => {
      try {
        const leaseResponse =
          await LeaseApplicationService.getApiLeaseApplication1(
            leaseApplicationId
          );

        if (leaseResponse?.success?.dataSections) {
          setLeaseData((prevData) => ({
            ...prevData,
            ApplicationFeesClause: leaseResponse.success.dataSections
              .ApplicationFeesClause
              ? JSON.parse(
                leaseResponse.success.dataSections.ApplicationFeesClause
              )
              : null,
            CreditCheck: leaseResponse.success.dataSections.CreditCheck
              ? JSON.parse(leaseResponse.success.dataSections.CreditCheck)
              : null,
            EmploymentHistory: leaseResponse.success.dataSections
              .EmploymentHistory
              ? JSON.parse(leaseResponse.success.dataSections.EmploymentHistory)
              : null,
            Questionnaire: leaseResponse.success.dataSections.Questionnaire
              ? JSON.parse(leaseResponse.success.dataSections.Questionnaire)
              : null,
            References: leaseResponse.success.dataSections.References
              ? JSON.parse(leaseResponse.success.dataSections.References)
              : null,
            RentIncomeCheck: leaseResponse.success.dataSections.RentIncomeCheck
              ? JSON.parse(leaseResponse.success.dataSections.RentIncomeCheck)
              : null,
          }));

          // Fetching template tanentData based on templateId
          const templateResponse:any = await TemplateService.getApiTemplate(
            leaseResponse.success.templateId
          );
          if (templateResponse?.success?.leaseApplicationTemplateSections) {
            setTanentData(templateResponse.success.leaseApplicationTemplateSections);
          }
        }
      } catch (err) {
        console.error("Error fetching tanentData", err);
      }
    };

    fetchLeaseData();
  }, [leaseApplicationId]); // Added leaseApplicationId as a dependency
  // Added leaseApplicationId as a dependency

  const ReviewsNotes = (type) => {
    setAddNote(true);
    setDisplayNotes(type);
  };
  const handleCloseClick = () => {
    setAddNote(false);
  };

  const handleSaveNote = (note, type, toggleNotes) => {
    setNotes(note);
    const dataToSend = {
      Notes: note,
      approved: toggleNotes,
    };
    LeaseApplicationService.postApiLeaseApplicationReview(
      leaseApplicationId,
      type,
      dataToSend
    ).then((response: any) => {
      setReviewLease(response?.success);
    });
  };
  const handleToggle = (section) => {
    let updatedToggleState = false;

    switch (section) {
      case "RentIncomeCheck":
        updatedToggleState = !toggleRentPayInfo;
        setToggleRentPayInfo(!toggleRentPayInfo);
        break;
      case "EmploymentHistory":
        updatedToggleState = !toggleEmploymentHistory;
        setToggleEmploymentHistory(!toggleEmploymentHistory);
        break;
      case "References":
        updatedToggleState = !toggleReferences;
        setToggleReferences(!toggleReferences);
        break;
      case "CreditCheck":
        updatedToggleState = !toggleCreditCheck;
        setToggleCreditCheck(!toggleCreditCheck);
        break;
      case "Questionnaire":
        updatedToggleState = !toggleQuestionnaire;
        setToggleQuestionnaire(!toggleQuestionnaire);
        break;
      default:
        break;
    }

    const dataToSend = {
      approved: updatedToggleState,
      notes: "",
    };

    LeaseApplicationService.postApiLeaseApplicationReview(
      leaseApplicationId,
      section,
      dataToSend
    )
      .then((response) => { })
      .catch((error) => {
        console.error("Error toggling section:", error);
      });
  };

  const handleChangDropdown = (e) => {
    setSelectOption(e.target.value);
    setShowModel(!showModel);
  };


  return (
    <>
      <section className="application-review-body">
        <div className="process-option">
          <Col xs="auto"></Col>

          <Col xs="auto"></Col>
        </div>
        {/* <HeaderView name="Lease Application Details" /> */}

        <HeaderTanent
          selectOption={selectOption}
          handleChangDropdown={handleChangDropdown}
          showModel={showModel}
          setShowModel={setShowModel}
          leaseApplicationId={leaseApplicationId}
        />
        <div className="profile-user-detail-card">
          <div className="application-card card">
            <Row className="justify-content-between align-items-center">
              <Col xs="12">
                <Card>
                  <CardHeader>
                    <CardTitle>
                      <span>SG</span>
                      Steve George
                    </CardTitle>
                    <CardSubtitle>
                      <div className="button-grp d-flex align-items-center">
                        <button className="download-button">
                          <img src={GetImages.pdfImage} alt="" />
                          Download Application
                        </button>
                        <button className="NoteBtn">Edit Tenant</button>
                      </div>
                    </CardSubtitle>
                  </CardHeader>

                  <CardBody className="d-flex justify-content-between">
                    <Col xs="auto">
                      <Card.Text>
                        <img src={GetImages.homeIconOrange} alt="" /> 5250
                        Truman Dr, Decatur, GA 30035, United States
                      </Card.Text>
                      <Card.Text>
                        <img src={GetImages.homeIconOrange} alt="" />
                        S205 - Studio
                      </Card.Text>
                    </Col>
                    <Col xs="auto" className="p-0">
                      <div className="button-grp d-flex align-items-center">
                        <span>
                          {" "}
                          <Form.Check // prettier-ignore
                            className="rejected-switch"
                            type="switch"
                            id="custom-switch"
                            label={toggleCreditCheck ? "Rejected" : "Approved"}
                            onClick={() => handleToggle("CreditCheck")}
                          />
                        </span>
                        <button
                          className="NoteBtn"
                          onClick={() => {
                            ReviewsNotes("CreditCheck");
                          }}
                        >
                          <img src={GetImages.folder} alt="" />
                          Add Note
                        </button>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        {/*  credit Check */}

        {tanentData?.CreditCheck && (
          <Card className="application-card">
            <div className="card-header sub_text black">
              <Row className="justify-content-between align-items-center">
                <Col xs="auto">
                  <p className="sub_heading align-items-center">CreditCheck</p>
                </Col>

                <Col xs="auto" className="p-0">
                  <div className="button-grp d-flex align-items-center">
                    <span>
                      {" "}
                      <Form.Check // prettier-ignore
                        className="rejected-switch"
                        type="switch"
                        id="custom-switch"
                        label={toggleCreditCheck ? "Rejected" : "Approved"}
                        onClick={() => handleToggle("CreditCheck")}
                      />
                    </span>
                    <button
                      className="NoteBtn"
                      onClick={() => {
                        ReviewsNotes("CreditCheck");
                      }}
                    >
                      <img src={GetImages.folder} alt="" />
                      Add Note
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="user-detail-card">
              <Card className="security-card">
                <CardHeader>
                  <img width={24} height={24} src={GetImages.securityicon} />
                </CardHeader>
                <CardBody>
                  <div>
                    <Card.Title>Social Security Number</Card.Title>
                    <Card.Text>
                      {leaseData?.CreditCheck?.socialSecurityNumber}
                    </Card.Text>
                  </div>
                  <div className="extra-detail">
                    <p>
                      Authorization obtained from the tenant
                      {/* <Badge>{socialSecurityNumber?.creditPermission}</Badge> */}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </div>
            {addnote && displayNotes === "CreditCheck" ? (
              <AddNote
                onClose={handleCloseClick}
                toggle={toggleCreditCheck}
                type={displayNotes}
                onSave={handleSaveNote} setNoteMessage={undefined} />
            ) : null}
          </Card>
        )}
        {/* for income */}
        {tanentData?.RentIncomeCheck && (
          <Card className="application-card">
            <div className="card-header sub_text black">
              <Row className="justify-content-between align-items-center">
                <Col xs="auto">
                  <p className="sub_heading align-items-center">
                    RentIncomeCheck
                    {/* <Badge bg="success">{RentIncomeCheck.payRentVia}</Badge>{" "} */}
                    <Badge bg="success">Assisted</Badge>
                  </p>
                </Col>
                <Col xs="auto">
                  <div className="button-grp d-flex align-items-center">
                    <Form.Check // prettier-ignore
                      className="rejected-switch"
                      type="switch"
                      id="custom-switch"
                      label={toggleRentPayInfo ? "Rejected" : "Approved"}
                      onClick={() => handleToggle("RentIncomeCheck")}
                    />
                    <button
                      className="NoteBtn"
                      onClick={() => {
                        ReviewsNotes("RentIncomeCheck");
                      }}
                    >
                      <img src={GetImages.folder} alt="" />
                      Add Note
                    </button>
                  </div>
                </Col>
              </Row>
            </div>

            {(leaseData?.RentIncomeCheck?.payRentVia === "Both" ||
              leaseData?.RentIncomeCheck?.payRentVia === "Income") && (
                <Card>
                  <div className="card-header sub_text black">
                    <Row className="justify-content-between align-items-center">
                      <Badge bg="success">
                        {leaseData?.RentIncomeCheck?.payRentVia === "Both"
                          ? "Income"
                          : leaseData?.RentIncomeCheck?.payRentVia}
                      </Badge>
                    </Row>
                  </div>

                  <ListGroup className="application-income-list">
                    <ListGroup.Item>
                      <p>Employer Name</p>
                      {
                        leaseData?.RentIncomeCheck?.employmentVerification
                          ?.employerName
                      }
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <p>Employed Since</p>
                      {
                        leaseData?.RentIncomeCheck?.employmentVerification
                          ?.startDate
                      }
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <p>Gross Monthly Income</p> $
                      {leaseData?.RentIncomeCheck?.employmentVerification?.income}
                    </ListGroup.Item>
                  </ListGroup>

                  <Row>
                    <p>Contact Details</p>
                  </Row>
                  <ListGroup className="application-user-list rent-info-list">
                    <ListGroup.Item>
                      <img width={15} height={15} src={GetImages.OrangeUser} />
                      {leaseData?.RentIncomeCheck?.contactDetails?.firstName}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <img
                        width={14}
                        height={11}
                        src={GetImages.mailIconOrange}
                      />
                      {leaseData?.RentIncomeCheck?.contactDetails?.email}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <img
                        width={16}
                        height={16}
                        src={GetImages.phoneIconOrange}
                      />
                      {leaseData?.RentIncomeCheck?.contactDetails?.phoneNumber}
                    </ListGroup.Item>
                  </ListGroup>

                  <div className="income-file-list">
                    <h6>Proof of payment</h6>

                    <p>
                      <img width={15} height={15} src={GetImages.pdfImage} />
                      W2
                    </p>
                    <p>
                      <img width={15} height={15} src={GetImages.pdfImage} />
                      1099
                    </p>
                    <p>
                      <img width={15} height={15} src={GetImages.pdfImage} />
                      PayStubs
                    </p>
                  </div>
                </Card>
              )}

            {(leaseData?.RentIncomeCheck?.payRentVia == "Both" ||
              leaseData?.RentIncomeCheck?.payRentVia == "Assisted") && (
                <Card>
                  <div className="card-header sub_text black">
                    <Row className="justify-content-between align-items-center">
                      <Badge bg="success">Assisted</Badge>
                    </Row>
                  </div>

                  <ListGroup className="application-income-list">
                    <ListGroup.Item>
                      <p>Authority Name</p>{" "}
                      {
                        leaseData?.RentIncomeCheck?.socialWorkerDetails
                          ?.AuthorityName
                      }
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <p>Authority Income</p> ${" "}
                      {
                        leaseData?.RentIncomeCheck?.socialWorkerDetails
                          ?.AuthorityAmount
                      }
                    </ListGroup.Item>
                  </ListGroup>
                  <Row>
                    <p>Social Works Details</p>
                  </Row>
                  <ListGroup className="application-user-list rent-info-list">
                    <ListGroup.Item>
                      <img width={15} height={15} src={GetImages.OrangeUser} />
                      {leaseData?.RentIncomeCheck?.socialWorkerDetails?.firstName}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <img
                        width={14}
                        height={11}
                        src={GetImages.mailIconOrange}
                      />
                      {leaseData?.RentIncomeCheck?.socialWorkerDetails?.email}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <img
                        width={16}
                        height={16}
                        src={GetImages.phoneIconOrange}
                      />
                      {
                        leaseData?.RentIncomeCheck?.socialWorkerDetails
                          ?.phoneNumber
                      }
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              )}
            {addnote && displayNotes === "RentIncomeCheck" ? (
              <AddNote
                onClose={handleCloseClick}
                type={displayNotes}
                toggle={toggleRentPayInfo}
                onSave={handleSaveNote} setNoteMessage={undefined} />
            ) : null}
          </Card>
        )}

        {tanentData?.EmploymentHistory && (
          <Card className="application-card">
            <div className="card-header sub_text black">
              <Row className="justify-content-between align-items-center">
                <Col xs="auto">
                  <p className="sub_heading align-items-center">EmploymentHistory</p>
                </Col>
                <Col xs="auto">
                  <div className="button-grp d-flex align-items-center">
                    <Form.Check // prettier-ignore
                      className="rejected-switch"
                      type="switch"
                      id="custom-switch"
                      onClick={() => handleToggle("EmploymentHistory")}
                      label={toggleEmploymentHistory ? "Rejected" : "Approved"}
                    />
                    <button
                      className="NoteBtn"
                      onClick={() => {
                        ReviewsNotes("EmploymentHistory");
                      }}
                    >
                      <img src={GetImages.folder} alt="" />
                      Add Note
                    </button>
                  </div>
                </Col>
              </Row>
            </div>

            <ListGroup className="application-user-list employment-history-list">
              <ListGroup.Item>
                Murtaza Abdeali
                <p>May 2014 - Jun 2016</p>
              </ListGroup.Item>
              <ListGroup.Item>
                Khurram Hameed
                <p>
                  <a>murtaza@gmail.com</a>
                </p>
              </ListGroup.Item>
              <ListGroup.Item>+1-111-222-3333</ListGroup.Item>
            </ListGroup>
            {addnote && displayNotes === "EmploymentHistory" ? (
              <AddNote
                onClose={handleCloseClick}
                type={displayNotes}
                toggle={toggleEmploymentHistory}
                onSave={handleSaveNote} setNoteMessage={undefined} />
            ) : null}
          </Card>
        )}
        {/* for pay-rent-information */}

        {/*for reference */}

        {tanentData?.References && (
          <Card className="application-card">
            <div className="card-header sub_text black">
              <Row className="justify-content-between align-items-center">
                <Col xs="auto">
                  <p className="sub_heading align-items-center">References</p>
                </Col>
                <Col xs="auto">
                  <div className="button-grp d-flex align-items-center">
                    <Form.Check // prettier-ignore
                      className="rejected-switch"
                      type="switch"
                      id="custom-switch"
                      onClick={() => handleToggle("References")}
                      label={toggleReferences ? "Rejected" : "Approved"}
                    />
                    <button
                      className="NoteBtn"
                      onClick={() => {
                        ReviewsNotes("References");
                      }}
                    >
                      <img src={GetImages.folder} alt="" />
                      Add Note
                    </button>
                  </div>
                </Col>
              </Row>
            </div>

            <ListGroup className="application-user-list reference-user-list">
              <ListGroup.Item>
                <img width={15} height={15} src={GetImages.OrangeUser} />
                Murtaza Abdeali <Badge bg="success">Type: Employer</Badge>
              </ListGroup.Item>
            </ListGroup>
            <ListGroup className="application-user-list reference-user-list">
              <ListGroup.Item>
                <img width={15} height={15} src={GetImages.OrangeUser} />
                Murtaza Abdeali <Badge bg="success">Type: Employer</Badge>
              </ListGroup.Item>
              <ListGroup.Item>
                <img width={14} height={11} src={GetImages.mailIconOrange} />
                murtaza@gmail.com
              </ListGroup.Item>
              <ListGroup.Item>
                <img width={16} height={16} src={GetImages.phoneIconOrange} />
                +1-111-222-3333
              </ListGroup.Item>
            </ListGroup>
            <ListGroup className="application-user-list reference-user-list">
              <ListGroup.Item>
                <img width={15} height={15} src={GetImages.OrangeUser} />
                Murtaza Abdeali <Badge bg="success">Type: Employer</Badge>
              </ListGroup.Item>
              <ListGroup.Item>
                <img width={14} height={11} src={GetImages.mailIconOrange} />
                murtaza@gmail.com
              </ListGroup.Item>
              <ListGroup.Item>
                <img width={16} height={16} src={GetImages.phoneIconOrange} />
                +1-111-222-3333
              </ListGroup.Item>
            </ListGroup>
            {addnote && displayNotes === "References" ? (
              <AddNote
                onClose={handleCloseClick}
                type={displayNotes}
                toggle={toggleReferences}
                onSave={handleSaveNote} setNoteMessage={undefined} />
            ) : null}
          </Card>
        )}
        {/*question */}
        {tanentData?.Questionnaire && (
          <Card className="application-card">
            <div className="card-header sub_text black">
              <Row className="justify-content-between align-items-center">
                <Col xs="auto">
                  <p className="sub_heading align-items-center">Questionnaire</p>
                </Col>
                <Col xs="auto">
                  <div className="button-grp d-flex align-items-center">
                    <Form.Check // prettier-ignore
                      className="rejected-switch"
                      type="switch"
                      id="custom-switch"
                      onClick={() => handleToggle("Questionnaire")}
                      label={toggleQuestionnaire ? "Rejected" : "Approved"}
                    />
                    <button
                      className="NoteBtn"
                      onClick={() => {
                        ReviewsNotes("Questionnaire");
                      }}
                    >
                      <img src={GetImages.folder} alt="" />
                      Add Note
                    </button>
                  </div>
                </Col>
              </Row>
            </div>

            <ListGroup className="question-list">
              <ListGroup.Item>
                <div className="question-row">
                  <p> Do you smoke? </p>{" "}
                  <p>
                    {" "}
                    <Badge bg="primary">Yes</Badge>{" "}
                  </p>
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="question-row">
                  <p> Do you have or plan to have pets living with you? </p>{" "}
                  <p>
                    {" "}
                    <Badge bg="primary">Yes</Badge>{" "}
                  </p>
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="question-row">
                  <p> Have you ever been arrested? </p>{" "}
                  <p>
                    {" "}
                    <Badge bg="primary">no</Badge>{" "}
                  </p>
                </div>
              </ListGroup.Item>

              <ListGroup.Item>
                <div className="question-row">
                  <p>
                    {" "}
                    Have you ever been evicted from your residence in the past?{" "}
                  </p>{" "}
                  <p>
                    {" "}
                    <Badge bg="danger">yes</Badge>{" "}
                  </p>
                </div>
                <Alert variant="danger">
                  <span> Additional Details:</span> a elementum dui. Mauris quis
                  rutrum orci, sed vulputate velit. Cras
                </Alert>
              </ListGroup.Item>

              <ListGroup.Item>
                <div className="question-row">
                  <p> How long are you expecting to rent? </p>{" "}
                  <p>
                    {" "}
                    <Badge bg="danger">Less than a year</Badge>{" "}
                  </p>
                </div>
              </ListGroup.Item>
              <div className="note-reason">
                <h6>Please specify reason why are you looking to move</h6>
                <p>
                  I'm looking to move to find a more suitable living space that
                  meets my needs and preferences better.
                </p>
              </div>
            </ListGroup>
            {addnote && displayNotes === "Questionnaire" ? (
              <AddNote
                onClose={handleCloseClick}
                type={displayNotes}
                toggle={toggleQuestionnaire}
                onSave={handleSaveNote} setNoteMessage={undefined} />
            ) : null}
          </Card>
        )}

        {/* SIgnature */}
        {tanentData?.SignatureConfirmation && (
          <Card className="application-card">
            <div className="card-header sub_text black">
              <Row className="justify-content-between align-items-center">
                <Col xs="auto">
                  <p className="sub_heading align-items-center">Signature</p>
                </Col>
              </Row>
            </div>

            <div className="signature-card">
              <p className="italic">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                a elementum dui. Mauris quis rutrum orci, sed vulputate velit.
                Cras laoreet massa id risus pharetra suscipit. Maecenas volutpat
                id turpis sed fringilla. Quisque porttitor nisi est, nec
                dignissim mi condimentum eu. Quisque lobortis congue augue,
              </p>

              <Form.Check // prettier-ignore
                type="checkbox"
                id="confirm"
                label="I confirm that I have read and reviewed the document."
              />

              <div className="signature-sign">
                <img src={signImg} alt="" />
                <p>{formData.firstName}</p>
              </div>
            </div>
          </Card>
        )}
      </section>
    </>
  );
};

export default TempleteReview;
