import React, { useContext, useEffect, useRef, useState } from "react";
import Stepper from "./Stepper";
import { renderSteps } from "./RenderSteps";
import Footer from "./Footer";
import { OnboardingCurrentAddressForm, OnboardingForms, OnboardingRentIncomeCheckForm, OnboardingSignatureForm } from "../../typings/onboarding";
import { DefaultCurrentAddress, DefaultFeeFormData, DefaultOnboardingStepsSequence, OnboardingStepsEnums, } from "../../constants/onboarding";
import { DocumentService, LeaseApplicationReviewVM, LeaseApplicationService, LeaseApplicationStatusTypesEnum, LoginService, OnboardingService, TemplateService, TenantService, TenantVM, } from "@propertelligent/client-api";
import { loginResponseHandler } from "../../services/login.service";
import { OnboardingStepsSchemas } from "./Validations/onboardingTenantValidation";
import "./tenantOnboard.css"
import OrganizationNameHeader from "../common/OrganizationNameHeader/OrganizationNameHeader";
import CardView from "../units/listView/cardView";
import apiHelper from "../../utils/apiHelper";
import CommonConfirmation from "../common/CommonConfirmation";
import { useNavigate } from "react-router";
import { URLS } from "../../constants/constants";
import { GetImages } from "../../utils/GetImages";
import BoardingReview from "./RenderSteps/BoardingReview";
import { useError } from "../../utils/context/ErrorContext";
import CustomDropDownMenu from "../common/CustomDropDownMenu";
import { NotificationType, PubSub, TopicsEnum } from "../../utils/pubsub";
import { MyContext } from "../../utils/context/LoginContext";
import { useGeneratePdfContext } from "../../utils/context/GeneratePdfContext";
import html2pdf from 'html2pdf.js';
import PrintLayout from "../generatePdfDocument/printLayout";

const TenantOnboard: React.FC<any> = () => {
  const navigate = useNavigate()
  const [leaseApplication, setLeaseApplication] = useState<any>()
  const params = new URLSearchParams(location.search);
  const leaseApplicationToken = params.get("la");
  const tenantData = localStorage.getItem("tenantInfo");
  const tenantObject = JSON.parse(tenantData);
  const [template, setTemplate] = useState<any>()
  const [formData, setFormData] = useState<OnboardingForms>({
    BasicDetails: { firstName: "", lastName: "", email: "", phoneNumber: "", password: "", confirmPassword: "" },
    CurrentAddress: DefaultCurrentAddress,
    CreditCheck: { creditPermission: "yes", socialSecurityNumber: "", } as any,
    RentIncomeCheck: {} as OnboardingRentIncomeCheckForm,
    EmploymentHistory: [],
    References: [],
    Questionnaire: [],
    ApplicationFeesClause: DefaultFeeFormData,
    SignatureConfirmation: {} as OnboardingSignatureForm,
  });
  const [steps, setSteps] = useState(DefaultOnboardingStepsSequence);
  const [preStepIndex, setpreStepIndex] = useState(0);
  const [currentStepIndex, setCurrentStepIndex] = useState(tenantData ? 1 : 0);
  const [loading, setLoading] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const [isReLogin, setIsReLogin] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [stepFivePart2, setStepFivePart2] = useState(false);
  const [leaseApplicationid, setLeaseApplicationId] = useState(null);
  const { errors, setErrors } = useError()
  const [docsArr, setDocsArr] = useState([]);
  const [OrgDetail, setOrgDetail] = useState<any>()
  const [codeExpired, setCodeExpired] = useState<Boolean>(false)
  const [withdraw, setWithdraw] = useState(false)
  const [logout, setlogout] = useState(false)
  const { isLogin, setIsLogin } = useContext(MyContext);
  const { statementData, setStatementData, setReportStatementId, setOrgDetails, orgDetails, setSubHeaderName, subHeaderName } = useGeneratePdfContext();
  const [leaseApplicationreview, setleaseApplicationreview] = useState<LeaseApplicationReviewVM>(
    {
      leaseApplicationStatusTypeId: LeaseApplicationStatusTypesEnum.Withdrawn,
      internalNotes: "",
      tenantNotes: "",
    }
  )
  const [isPrint, setISPrint] = useState(false)
 
const contentRef= useRef()
  const handleDownloadPdf = () => {
     setISPrint(true)
    const element = contentRef.current; // Get the content to download

    const options = {
        margin: 0.25,
        filename:  'Review Application.pdf',
        image: { type: 'pdf', quality: 0.50 },
        html2canvas: { scale: 1.10 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    };

    if (element) {
        html2pdf()
            .from(element)
            .set(options)
            .save()
    }
    setTimeout(() => {
        setISPrint(false)
    }, 200)
};

  const withDrawApplication = () => {
    apiHelper(LeaseApplicationService.putApiLeaseApplicationReivew, { showNotification: false }, leaseApplicationid, leaseApplicationreview).then((res) => {
      if (res?.data?.success) {
        setWithdraw(false)
        setlogout(true)
      }
    }).catch((error) => { console.log(JSON.stringify(error)) })
  }

  const fetchData = () => {
    if (tenantData) {
      const { leaseApplicationId } = tenantObject;
      if (leaseApplicationId) {
        setLeaseApplicationId(leaseApplicationId);
        apiHelper(LeaseApplicationService.getApiLeaseApplicationOrganizationHeaderInfo, { showNotification: false }, leaseApplicationId).then((res) => {
          setOrgDetail(res?.data?.success)
          setOrgDetails(res?.data?.success)
          setSubHeaderName("Tenant Onboarding")
        }).catch((error) => { })
        apiHelper(LeaseApplicationService.getApiLeaseApplication1, { showNotification: false }, leaseApplicationId)
          .then((response: any) => {
            setLeaseApplication(response?.data?.success)

            const getValue = response?.data?.success?.dataSections
            setFormData({
              ...formData,
              BasicDetails: { firstName: response?.data?.success?.tenant?.firstName, lastName: response?.data?.success?.tenant?.lastName, email: response?.data?.success?.tenant?.email, phoneNumber: response?.data?.success?.tenant?.phoneNumber, password: response?.data?.success?.tenant?.password, confirmPassword: response?.data?.success?.tenant?.password },
              CreditCheck: JSON.parse(getValue?.CreditCheck ? getValue?.CreditCheck : null),
              CurrentAddress: JSON.parse(getValue?.CurrentAddress ? getValue?.CurrentAddress : null),
              EmploymentHistory: JSON.parse(getValue?.EmploymentHistory ? getValue?.EmploymentHistory : null),
              Questionnaire: JSON.parse(getValue?.Questionnaire ? getValue?.Questionnaire : null),
              References: JSON.parse(getValue?.References ? getValue?.References : null),
              RentIncomeCheck: JSON.parse(getValue?.RentIncomeCheck ? getValue?.RentIncomeCheck : null),
              SignatureConfirmation: JSON.parse(getValue?.SignatureConfirmation ? getValue?.SignatureConfirmation : null),
              ApplicationFeesClause: JSON.parse(getValue?.ApplicationFeesClause ? getValue?.ApplicationFeesClause : null),
            });
            apiHelper(TemplateService.getApiTemplate, { showNotification: false }, response?.data?.success?.templateId)
              .then((templete) => {
                setTemplate(templete?.data?.success)
                if (!templete?.data?.success?.templateSections) {
                  setSteps(DefaultOnboardingStepsSequence.filter((item) => item !== OnboardingStepsEnums.BasicDetails));
                } else {
                  const stepsReceived = Array.from(new Set([
                    OnboardingStepsEnums.BasicDetails,
                    ...Object.keys(templete?.data?.success?.templateSections),
                    OnboardingStepsEnums.SignatureConfirmation,
                    OnboardingStepsEnums.Thankyou,
                  ]));

                  setSteps(steps.filter((itm) => stepsReceived.includes(itm)));
                  setIsReLogin(true);
                }
              }).catch((error) => console.log("error", JSON.stringify(error)))
          }).catch((tenantErr: any) => { console.log("tenantErr", JSON.stringify(tenantErr)) });
      }
    } else {
      if (leaseApplicationToken) {
        apiHelper(OnboardingService.getApiOnboardingTenantOrganizationHeaderInfo, { showNotification: false },
          leaseApplicationToken).then((res) => {
            setOrgDetail(res?.data?.success)
            if (res?.data?.success == null) {
              setCodeExpired(true)
            }
          }).catch((error) => { console.log("error?", JSON.stringify(error)) })
      }
    }
  }

  useEffect(() => {
    if (tenantData && !leaseApplicationToken) { setCodeExpired(false) }
    fetchData()
  }, [leaseApplicationid, currentStepIndex, tenantData]);

  useEffect(() => {
    const EmpHisRequiredRange = template?.templateSections?.EmploymentHistory?.requiredRange || 1;
    const RefRequiredRange = template?.templateSections?.References?.requiredRange || 1;

    setFormData(prevFormData => ({
      ...prevFormData,
      EmploymentHistory: prevFormData?.EmploymentHistory?.length
        ? prevFormData?.EmploymentHistory
        : Array.from({ length: EmpHisRequiredRange }, () => ({
          employerName: "",
          startDate: "",
          endDate: "",
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        })),

      References: prevFormData?.References?.length
        ? prevFormData?.References
        : Array.from({ length: RefRequiredRange }, () => ({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          referenceType: "Employer",
        })),
      RentIncomeCheck: prevFormData?.RentIncomeCheck ? prevFormData?.RentIncomeCheck
        : {
          payRentVia: "Income",
          employmentVerification: {
            employerName: "",
            phoneNumber: "",
            income: "",
            startDate: "",
          },
          contactDetails: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
          },
          socialWorkerDetails: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            AuthorityAmount: "",
            AuthorityName: "",
          }
        } as OnboardingRentIncomeCheckForm,
      CurrentAddress: prevFormData?.CurrentAddress ? prevFormData?.CurrentAddress
        : {
          unitNumber: "",
          address: {
            streetAddress: "",
            city: "",
            state: "",
            zip: "",
          },
          movingDate: "",
          movingReason: ""
        } as OnboardingCurrentAddressForm,
      SignatureConfirmation: prevFormData?.SignatureConfirmation ? prevFormData?.SignatureConfirmation
        : { signatureName: "", signatureDate: "", permission: true },
      Questionnaire: prevFormData?.Questionnaire?.length ? prevFormData?.Questionnaire : template?.templateSections?.Questionnaire?.questions,
      ApplicationFeesClause: { ...prevFormData?.ApplicationFeesClause, applicationFee: template?.applicationFee }
    }));
  }, [template, currentStepIndex]);

  const handleRemoveError = (
    field: string,
    form: keyof typeof OnboardingStepsEnums,
    error?: { key: string; subKey?: string }
  ) => {
    const oldErrors = { ...errors };
    oldErrors[form] = oldErrors[form] ?? {};
    if (error?.subKey) {
      oldErrors?.[form]?.[field]?.[error.subKey] &&
        delete oldErrors[form][field][error.subKey];
    }
    else {
      oldErrors?.[form]?.[field] && delete oldErrors[form][field];
    }
    setErrors({ ...oldErrors });
  };

  const goNextStep = () => {
    if (isEdit) {
      setCurrentStepIndex(preStepIndex);
      setIsEdit(false)
      return;
    } else { setCurrentStepIndex((step) => step + 1); }
  };

  const goPrevStep = () => {
    if (isReLogin && currentStepIndex <= 1) { return; }
    else { setCurrentStepIndex((step) => step - 1); }
  };

  const isEditClick = (index) => {
    setIsEdit(true);
    setCurrentStepIndex(index);
  };

  const uploadImage = (doc) => {
    const docData = {
      EntityTypeId: doc.EntityTypeId,
      EntityId: doc.EntityId,
      DocumentTypeId: doc.DocumentTypeId,
      IsMain: doc.IsMain,
      FileName: doc.FileName,
      Document: doc.Document,
    };

    return DocumentService.postApiDocument(docData)
      .then((_res) => { })
      .catch((error) => { console.error("Error uploading document:", JSON.stringify(error)); });
  };

  const uploadAllImages = async (docArray) => {
    for (let i = 0; i < docArray.length; i++) {
      await uploadImage(docArray[i]);
    }
  };

  const goToApplicationView = () => {
    setCurrentStepIndex(null);
    setShowApp(true);
  };

  const handleSubmit = async () => {
    const currentStep = steps[currentStepIndex] as keyof typeof OnboardingStepsSchemas;

    const stepsWithDocumentUpload: OnboardingStepsEnums[] = [
      OnboardingStepsEnums.BasicDetails,
      OnboardingStepsEnums.RentIncomeCheck,
    ];

    try {
      setLoading(true);
      let cantGoNext = false;
      const data: any = formData[currentStep] || {}

      if (currentStep == OnboardingStepsEnums.BasicDetails) {
        if (isEdit && tenantData) {
          const { tenantId } = tenantObject;
          await apiHelper(TenantService.putApiTenant, { showNotification: false }, {
            id: tenantId, firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            phoneNumber: data?.phoneNumber
          } as TenantVM).then((res) => {
            fetchData()
            // cantGoNext = true

          }).catch((error) => console.log("error", error))
        } else {
          await OnboardingStepsSchemas[currentStep as keyof typeof OnboardingStepsSchemas]?.validate(data, { abortEarly: false, context: data });
          const result: any = await apiHelper(LoginService.postApiLoginAcceptLeaseApplicationInvite, { showNotification: false }, leaseApplicationToken, data as any)
            .then(async (res) => {

              if (res?.data?.success) {
                loginResponseHandler(res);
                setLeaseApplicationId(res?.data?.success.tenantInfo?.leaseApplicationId);
                if (res?.data?.success.tenantInfo?.leaseApplicationId)
                  setLeaseApplicationId(res?.data?.success.tenantInfo?.leaseApplicationId);
                fetchData()
              }
              return res;
            })
            .catch((err) => { err.body; })
          //  cantGoNext = !result.errors;
        }
      }
      else {
        await OnboardingStepsSchemas[currentStep as keyof typeof OnboardingStepsSchemas]?.validate(data, { abortEarly: false, context: data });
        cantGoNext = await apiHelper(LeaseApplicationService.postApiLeaseApplication1, { showNotification: false }, parseInt(leaseApplicationid), currentStep, data)
          .then((res) => true).catch((err) => false);
      }

      if (stepsWithDocumentUpload.includes(currentStep as OnboardingStepsEnums)) {
        await uploadAllImages(docsArr);
      }
      setLoading(false);
      // cantGoNext &&
      goNextStep();
    } catch (error: any) {
      setLoading(false);
      if (error.inner) {
        const formErrors = {};
        error.inner.forEach((error) => {
          let { path, message } = error;
          const split = path.split(".");
          message = message.split(".").reverse()[0];
          if (split.length > 1) {
            if (!formErrors[split[0]])
              formErrors[split[0]] = { [split[1]]: message };
            else formErrors[split[0]][split[1]] = message;
          } else if (!formErrors[path]) {
            formErrors[path] = message;
          }
        });
        setErrors({ ...errors, [currentStep]: formErrors });
      } else console.log("Something went wrong in the handleSubmit", error);
    }
  };

  const actions = [
    {
      id: 1,
      img: undefined,
      name: "Withdraw Application",
      onClick: () => setWithdraw(true),
      linkpath: undefined,
      textClass: 'sub_text fw-600 blue'
    },
    {
      id: 2,
      img: undefined,
      name: "Download  Application",
      onClick:()=>handleDownloadPdf(),
      linkpath: undefined,
      textClass: 'sub_text fw-600 blue'
    },
  ]

  const logoutFunction = () => {
    PubSub.publish(TopicsEnum.Logout.toString(), {
      message: "Successfully logged out",
      type: NotificationType.Success
    });
    LoginService.putApiLoginLogout()
      .then((res) => {
        localStorage.clear();
        setIsLogin(false);
        setTimeout(() => {
          navigate(URLS.HOME, {
            state: {
              isComingFromLogout: true,
            },
          });
          // navigate(URLS.HOME);
        }, 1000);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (isReLogin && leaseApplication?.leaseApplicationStatusTypeId === LeaseApplicationStatusTypesEnum.Submitted ||
      leaseApplication?.leaseApplicationStatusTypeId === LeaseApplicationStatusTypesEnum.Approved ||
      leaseApplication?.leaseApplicationStatusTypeId === LeaseApplicationStatusTypesEnum.InReview ||
      leaseApplication?.leaseApplicationStatusTypeId === LeaseApplicationStatusTypesEnum.Rejected ||
      leaseApplication?.leaseApplicationStatusTypeId === LeaseApplicationStatusTypesEnum.Closed ||
      leaseApplication?.leaseApplicationStatusTypeId === LeaseApplicationStatusTypesEnum.Withdrawn) {
      setCurrentStepIndex(null);
      setShowApp(true);
    }
  }, [isReLogin])

  return (
    <>
      {codeExpired && !tenantData ? <>
        <div className="loginFullScreen"
          style={{ backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)), url(${GetImages.background})`, }} >
          <CommonConfirmation
            show={true}
            onConfirm={() => navigate(URLS.LOGIN)}
            onClose={undefined}
            heading="Invitation Code Invalid"
            subHeading={`Lease Application Code is Invalid, is Expired, or is already been used. If used, please login with your email and password`}
            confirmLabel="Login"
            buttonCenter={true}
          />
        </div>
      </> : <>
        <div className="outer-container">
          <div className="onboarding-header ">
            {steps[currentStepIndex] !== OnboardingStepsEnums.Thankyou && (<>
              <OrganizationNameHeader OrgName={OrgDetail?.name} OrgImg={`data:image/*;base64,${OrgDetail?.logoImageBase64}`} SubHeaderName="Tenant Onboarding" />
              {showApp && (<OrganizationNameHeader OrgName={"Your Application is Under Process"} element={<div className="actionsDropdownContainer clickable">
                <CustomDropDownMenu Action={true} Element={<img src={GetImages.BlueChevronDown} alt="BlueChevronDown" />} data={actions} />
              </div>} headerNameClass="" OrgImg={GetImages.folder} SubHeaderName="" />)}
              <CardView item={leaseApplication?.unit} showTarget={false} isRedirect={false} />
              {!showApp && (<Stepper
                currentStepIndex={currentStepIndex}
                steps={steps}
                totalSteps={steps?.length - 1} />)
              }
            </>)}
          </div>
          <>
            {renderSteps({
              currentStepIndex,
              setpreStepIndex,
              errors,
              handleRemoveError,
              formData,
              setFormData,
              steps,
              isEditClick,
              isEdit,
              docsArr,
              setDocsArr,
              leaseApplicationid,
              goToApplicationView
            })}

            {steps[currentStepIndex] !== OnboardingStepsEnums.Thankyou &&
              !showApp && (
                <Footer
                  goPrevStep={goPrevStep}
                  currentStepIndex={currentStepIndex + 1}
                  stepFivePart2={stepFivePart2}
                  steps={steps}
                  stepsCount={steps.length - 1}
                  handleSubmit={handleSubmit}
                />)} </>
          {showApp && (<section className="onboarding-review "  ref={contentRef}>
            {/* <PrintLayout isPrint={isPrint}> */}
            {
              isPrint && (
                <OrganizationNameHeader SubHeaderName={"Testing"} OrgName={"Capital"}/>
              )
            }
            <BoardingReview editable={false} leaseApplication={leaseApplicationid} isEditClick={isEditClick} postData={undefined} setPostData={undefined} formData={undefined} setFormData={undefined} steps={undefined} />
          {/* </PrintLayout> */}
          </section>)}
        </div>
      </>
      }
      {
        withdraw && (
          <CommonConfirmation
            show={true}
            onConfirm={
              withDrawApplication
            }
            onClose={() => {
              setWithdraw(false)
              setleaseApplicationreview(prev => ({ ...prev, tenantNotes: "" }))
            }}
            heading="Withdraw Application"
            subHeading="Are you sure you want to withdraw your application?"
            confirmLabel="Yes Withdraw"
            cancelLabel="Cancel"
            Element={<div>
              <label className="normal_text">Please let us know why you want to withdraw (optional)</label>
              <textarea
                className="form-control mt-2"
                placeholder="Add your reason"
                value={leaseApplicationreview?.tenantNotes}
                onChange={(e) => setleaseApplicationreview(prev => ({ ...prev, tenantNotes: e.target.value }))}
                rows={4}
              />
            </div>}
          />)
      }
      {logout && (
        <CommonConfirmation
          show={true}
          onConfirm={
            logoutFunction
          }
          onClose={() => {
            setlogout(false)
            setleaseApplicationreview(prev => ({ ...prev, tenantNotes: "" }))
          }}
          heading={undefined}
          subHeading={undefined}
          confirmLabel="Logout"
          cancelLabel={null}
          Element={<div className="d-flex flex-column align-items-center justify-content-center gap-3">
            <img alt="withdraw" src={GetImages.success} className="Icon60" />
            <p className="main_heading">You have successfully withdrawn</p>

          </div>}
          buttonCenter={true}
        />

      )}

    </>
  );
};
export default TenantOnboard;