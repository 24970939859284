import React, { useCallback, } from "react";
import "./tenantonboard.css";
import { OnboardingForms, } from "../../../typings/onboarding";
import CustomInputs from "../../common/Inputs";
import { Form } from "react-bootstrap";
import { QuestionTypeEnum } from "@propertelligent/client-api";

const StepFive: React.FC<any> = ({
  formData,
  setFormData,
}: {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<OnboardingForms>>;
}) => {

  const handleOnClickElement = useCallback((qIndex, optIndex, questionTypeId, checked, value) => {
    const updatedQuestions = [...formData];

    if (questionTypeId === QuestionTypeEnum.SingleChoice) {
      updatedQuestions[qIndex].choices.forEach((choice, index) => {
        choice.isRadio = index === optIndex;
      });
    } else if (questionTypeId === QuestionTypeEnum.MultipleChoice) {
      updatedQuestions[qIndex].choices[optIndex].isRadio = checked;
    }

    updatedQuestions[qIndex].followUpQuestionChoiceId = optIndex + 1;

    setFormData(prevData => ({
      ...prevData,
      questions: updatedQuestions
    }));
  }, [formData]);

  const handleChangeFollowup = (index, e) => {
    const updatedQuestions = [...formData];

    if (!updatedQuestions[index].hasOwnProperty('shortAnswer')) {
      updatedQuestions[index].shortAnswer = '';
    }
    if (!updatedQuestions[index].hasOwnProperty('paragraph')) {
      updatedQuestions[index].paragraph = '';
    }
    if (updatedQuestions[index].questionTypeId === QuestionTypeEnum.SingleChoice) {
      updatedQuestions[index].followUpQuestionText = e.target.value;
    }

    if (updatedQuestions[index].questionTypeId === QuestionTypeEnum.ShortAnswer) {
      updatedQuestions[index].shortAnswer = e.target.value;
    } else if (updatedQuestions[index].questionTypeId === QuestionTypeEnum.Paragraph) {
      updatedQuestions[index].paragraph = e.target.value;
    }
    setFormData((prevData) => ({
      ...prevData,
      questions: updatedQuestions,
    }));
  };

  return (
    <>
      <div className="onboarding-stepper">
        <div className="onboarding-steppercard">
          <p className="main_heading">Questionnaire</p>
          <div className="onboardingstepper-form">
            <div className="question-list">
              {formData?.map((item, index) => (
                <div key={index} className="questionaire_question-row">
                  <p className="normal_text black fw-500">{item?.questionText}</p>
                  {(item?.questionTypeId === QuestionTypeEnum.SingleChoice || item?.questionTypeId === QuestionTypeEnum.MultipleChoice) && (
                    <div className="question-option">
                      {item?.choices?.map((option, optionIndex) => (
                        <Form.Check
                          key={optionIndex}
                          checked={option?.isRadio}
                          label={option.optionText}
                          name={`question-${index}`}
                          type={item?.questionTypeId === QuestionTypeEnum.SingleChoice ? "radio" : "checkbox"}
                          id={`info-${item?.questionTypeId === QuestionTypeEnum.SingleChoice ? 'radio' : 'checkbox'}-${index}-${optionIndex}`}
                          value={option.value}
                          onChange={(e) => handleOnClickElement(index, optionIndex, item?.questionTypeId, e.target.checked, e.target.value)}
                        />
                      ))}
                    </div>
                  )}

                  {item?.questionTypeId === QuestionTypeEnum.SingleChoice && item?.choices?.some(choice => choice.isRadio && choice.optionText === "Yes") && (
                    <div>
                      <CustomInputs
                        labelText={"Provide additional details"}
                        type="text"
                        placeholder="Additional Details"
                        width={"100%"}
                        value={item.followUpQuestionText}
                        onChange={(e) => handleChangeFollowup(index, e)}
                      />
                    </div>
                  )}

                  {item?.questionTypeId === QuestionTypeEnum.Paragraph && (
                    <div>
                      <CustomInputs
                        // labelText={"Write your paragraph here"}
                        type="textarea"
                        placeholder="Enter a detailed response"
                        width={"100%"}
                        value={item.paragraph} // Bind value for paragraph input
                        onChange={(e) => handleChangeFollowup(index, e)}
                      />
                    </div>
                  )}

                  {item?.questionTypeId === QuestionTypeEnum.ShortAnswer && (
                    <div>
                      <CustomInputs
                        // labelText={"Short Answer"}
                        type="text"
                        placeholder="Enter a brief response"
                        width={"100%"}
                        value={item.shortAnswer}
                        onChange={(e) => handleChangeFollowup(index, e)}
                      /> </div>)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepFive;

