import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router";
import { LoginService } from "@propertelligent/client-api";
import { UserLoginData } from "../components/common/charts/Enums";
import { MyContext } from "../utils/context/LoginContext";
import NameAvatarComponent from "../components/tenants/NameAvatarComponent";
import Localizer from "../utils/i18nConfig";
import profilePic from "../assets/profilePic.png";
import downIcon from "../assets/downArrow.png";
import logoutButton from "../assets/logoutButton.png";
import organizationSetting from "../assets/organizationSetting.png";
import { URLS, USER_ROLES, USERS } from "../constants/constants";
import "./UserName.css";
import { NotificationType, PubSub, TopicsEnum } from "../utils/pubsub";

const UserName = ({ size, userRoleId }) => {
  const { isLogin, setIsLogin } = useContext(MyContext);
  const navigate = useNavigate();

  const fullName = localStorage.getItem(UserLoginData.user_name) || "";
  const nameParts = fullName.split(/\s+/).filter(Boolean);
  
  const firstName = nameParts[0] || ""; 
  const lastName = nameParts[1] || "";  


  const navigateToOrganization = () => {
    navigate('/organization/basic-details');
  };

  const navigateToProfile = () => {
    navigate(URLS.MY_PROFILE);
  };

  const logoutFunction = () => {
        PubSub.publish(TopicsEnum.Logout.toString(), {
      message: "Successfully logged out",
      type: NotificationType.Success
    });    
    LoginService.putApiLoginLogout()
      .then((res) => {
        localStorage.clear();
        setIsLogin(false);
        setTimeout(() => {
          navigate(URLS.HOME, {
            state: {
              isComingFromLogout: true,
            },
          });
          // navigate(URLS.HOME);
        }, 1000);
      })
      .catch((error) => {});
  };

  return (
    <div className="appBarRightSide">
      <div className="profilePicStyles">
        <NameAvatarComponent
          firstName={firstName}
          lastName={lastName}
          heightWidth={28}
          fromTopBar={true}
          fontSize="12px"
          color="#1A56DB"
          backgroundColor="#E1EFFE"
        />
      </div>

      <Dropdown className="nameBox">
        <Dropdown.Toggle className="nameStyles sub_text" id="dropdown-basic">
          {size > 400 ? localStorage.getItem(UserLoginData.user_name) : ""}
          <div className="imgBox">
            <img width={16} height={16} src={downIcon} />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdownBoxUsername">
          {USERS.USER === USER_ROLES[userRoleId] ? (
            <Dropdown.Item
              className="d-flex gap-2 itemFromUsername justify-content-start align-items-center "
              onClick={navigateToOrganization}
            >
              <img width={20} height={20} src={organizationSetting} />{" "}
              <p className="normal_text fw-500 text-start">
                {" "}
                {`${Localizer.getLocalizedString("my_org")}`}{" "}
              </p>
            </Dropdown.Item>
          ) : USERS.TENANT === USER_ROLES[userRoleId] ? (
            <Dropdown.Item
              className="d-flex gap-2 itemFromUsername justify-content-start align-items-center "
              onClick={navigateToProfile}
            >
              <img width={20} height={20} src={organizationSetting} />{" "}
              <p className="normal_text fw-500 text-start">My Profile</p>
            </Dropdown.Item>
          ) : null}

          <Dropdown.Item
            className="d-flex gap-2 itemFromUsername justify-content-start align-items-center"
            onClick={logoutFunction}
          >
            <img width={20} height={20} src={logoutButton} />{" "}
            <p className="normal_text fw-500 text-start">{`${Localizer.getLocalizedString(
              "logout"
            )}`}</p>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default UserName;
