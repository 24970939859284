import React from 'react'
import TenantCards from '../../common/cards/TenantCards'

const LeaseCards = ({ leaseData }) => {
    return (
        <div className='pt-4 d-flex flex-wrap gap-0 justify-content-start align-items-center card_container row' style={{padding:"0px 28px"}}>
           <div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
           <TenantCards
                title="Lease Type"
                icon={undefined}
                userName={undefined}
                phoneNumber={undefined}
                leaseType={leaseData?.currentLeaseInfo?.formatted?.TenantLeaseTypeId}
                background="#FFFFFF"
                leaseStartDate={undefined}
                leaseEndDate={undefined}
                amount={undefined}
                border="1px solid #E5E7EB"
                borderRadius="4px"
            />
           </div>
           <div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
           <TenantCards
                title="Lease Term"
                icon={undefined}
                userName={undefined}
                phoneNumber={undefined}
                leaseType={undefined}
                background="#FFFFFF"
                leaseStartDate={leaseData?.currentLeaseInfo?.formatted?.LeaseStartDateOnly}
                leaseEndDate={leaseData?.currentLeaseInfo?.formatted?.LeaseEndDateOnly}
                amount={undefined}
                border="1px solid #E5E7EB"
                borderRadius="4px"
            />
           </div>
           <div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
            <TenantCards
                title="Rent Amount"
                icon={undefined}
                userName={undefined}
                phoneNumber={undefined}
                leaseType={undefined}
                background="#FFFFFF"
                leaseStartDate={undefined}
                leaseEndDate={undefined}
                amount={leaseData?.currentLeaseInfo?.rent}
                border="1px solid #E5E7EB"
                borderRadius="4px"
            />
            </div>
        </div>
    )
}

export default LeaseCards