import { OpenAPI } from "@propertelligent/client-api";
import { UserLoginData } from "../components/common/charts/Enums";
import { USERS, USER_ROLES } from "../constants/constants";

export const loginResponseHandler = (res: any) => {
  localStorage.setItem(UserLoginData.token, res.success.token);
  OpenAPI.TOKEN = res.success.token;

  localStorage.setItem(
    UserLoginData.user_name,
    res.success.firstName + " " + res.success.lastName
  );
  localStorage.setItem(UserLoginData.user_id.toString(), res.success.userId);
  localStorage.setItem(
    UserLoginData.organization_id,
    res.success.organizationUserInfo?.organizationId
  );
  localStorage.setItem(
    UserLoginData.currency_symbol,
    res.success.currencySymbol
  );

  localStorage.setItem("userOnboarded", res.success.userOnboarded);
  localStorage.setItem("userRoleId", res?.success?.userRoleId);

  if (USER_ROLES[res?.success?.userRoleId] === USERS.TENANT) {
    localStorage.setItem(
      "tenantInfo",
      JSON.stringify(res?.success?.tenantInfo)
    );
  } else if (USER_ROLES[res?.success?.userRoleId] === USERS.CONTRACTOR) {
    localStorage.setItem(
      "contractorInfo",
      JSON.stringify(res?.success?.contractorInfo)
    );
  }
};
