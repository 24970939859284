import React, { useState } from "react";
import "./tenantonboard.css";
import NameAvatarComponent from "../../tenants/NameAvatarComponent";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";

const BasicInfo: React.FC<any> = ({ editable, data, isEditClick }) => {

  return (
    <>
          <div className="landlordInfo_left_detail gap-2">
            <NameAvatarComponent
              firstName={data?.firstName}
              lastName={data?.lastName}
              heightWidth={40} 
            />
          </div>
         
            <div className={!editable ? 'gap-0 landlordInfo_left_detail gap-2' : `landlordInfo_left_detail gap-2`}>
            <p className="normal_text fw-bold black">{`${data?.firstName} ${data?.lastName}`}</p>
            <p className="normal_text black">{data?.email}</p>
            <p className="normal_text black">{data?.phoneNumber}</p>
            </div>
            
         
    </>
  );
};

export default BasicInfo;
