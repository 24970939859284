import { Route, Routes } from "react-router";
import ServiceRequestsView from "./ServiceRequestsView";
import ServiceRequestDetails from "./ServiceRequestDetails";

const serviceRequest = () => {
  return (
    <Routes>
      <Route index element={<ServiceRequestsView />} />
      <Route path="/edit/:id" element={<ServiceRequestDetails />} />
    </Routes>
  );
};

export default serviceRequest;
