import CustomIcon from "../components/common/CustomIcons";
import { GetImages } from "../utils/GetImages";
import Localizer from "../utils/i18nConfig";

export const BASE_API_URL = "https://dev.propertelligent.com";

export const USERS = {
  USER: "USER",
  TENANT: "TENANT",
  CONTRACTOR: "CONTRACTOR",
  LeaseApplication: "LEASEAPPLICATION"
};

export const USER_ROLES = {
  "1": USERS.USER,
  "4": USERS.TENANT,
  "6": USERS.CONTRACTOR,
  "2": USERS.LeaseApplication
};

export const CUSTOM_COLORS = {
  lightGray: "#E5E7EB",
  skyDarkBlue: "#0DBF6F",
  lightGreen: "#1A56DB",
};

export const LOCALE_OPTIONS = {
  EN: "en",
  EN_GB: "en-GB",
  EN_US: "en-US",
  FR: "fr",
};
export type Language_Type =
  (typeof LOCALE_OPTIONS)[keyof typeof LOCALE_OPTIONS];

export const URLS = {
  HOME: "/",
  UNITS: "/units",
  EDIT_UNIT: "/units/edit",
  PROPERTIES: "/properties",
  TENANTS: "/tenants",
  CREATE_TENANT: "/tenants/create",
  SERVICE_REQUESTS: "/servicerequest",
  CONTRACTORS: "/contractors",
  ORGANIZATION: "/organization",
  PAYMENT: "/payment",
  TRANSACTION: "/transaction",
  CREATE_PAYMENT: "/payment/create",
  SETTLE_PAYMENT: "/payment/settle-payment/:id",
  MESSAGING: "/messaging",
  CALCULATOR: "/calculators",
  INFO: "/info",
  FIRST_TIME_DASHBOARD: "/firsttimedashboard",
  ORGANIZATION_ACCOUNTS: "/accounting",
  PAYMENT_DUE_ACCOUNTS: "/paymentDueAccounts",
  PAYEE_ACCOUNT_MANAGEMENT: "/payeeAccounts",
  USER_DASHBOARD: "/userdashboard",
  QUESTIONNAIRE: "/questionnaire",
  FORGET_PASSWORD: "/forgetpassword",
  CHECK_MAIL: "/checkemail",
  SIGN_UP: "/signup",
  SIGNUP_AFTER_GOOGLE: "/signupaftergoogle",
  CHECK_MAIL_AFTER_SIGNUP: "/checkemailaftersignup",
  RESET_PASSWORD_BY_USERKEY: "/resetpassword/:userKey",
  TENANT_ONBOARD: "/leaseApplication",
  LOGIN: "/login",
  GENERATE_PDF_DOCUMENT: "/generatePdfDocument/*",
  GENERATE_EMAIL: "/generateEmail/*",
  TENANT_SERVICE_REQUESTS: "/servicerequest",
  TRANSACTIONS: "/transactions",
  CHAT: "/chat",
  MY_PROFILE: "/profile",
  LEASE_DETAILS: "/leaseDetails",
  PAYMENT_METHODS: "/paymentMethods",
  INVITE_USER: "/orgUser"
};

export const MAIN_ROUTES_URLS = {
  HOME: `${URLS.HOME}*`,
  UNITS: `/${URLS.UNITS}/*`,
  PROPERTIES: `/${URLS.PROPERTIES}/*`,
  TENANTS: `/${URLS.TENANTS}/*`,
  SERVICE_REQUESTS: `/${URLS.SERVICE_REQUESTS}/*`,
  CONTRACTORS: `/${URLS.CONTRACTORS}/*`,
  ORGANIZATION: `/${URLS.ORGANIZATION}/*`,
  PAYMENT: `${URLS.PAYMENT}/*`,
  TRANSACTION: `${URLS.TRANSACTION}/*`,
  CALCULATOR: `/${URLS.CALCULATOR}/*`,
  MESSAGING: `/${URLS.MESSAGING}/*`,
  INFO: `/${URLS.INFO}/*`,
  ORGANIZATION_ACCOUNTS: `/${URLS.ORGANIZATION_ACCOUNTS}/*`,
  PAYMENT_DUE_ACCOUNTS: `/${URLS.PAYMENT_DUE_ACCOUNTS}/*`,
  PAYEE_ACCOUNT_MANAGEMENT: `/${URLS.PAYEE_ACCOUNT_MANAGEMENT}/*`,
  GENERATE_PDF_DOCUMENT: `/${URLS.GENERATE_PDF_DOCUMENT}/*`,
  GENERATE_EMAIL: `/${URLS.GENERATE_EMAIL}/*`,
  TENANT_SERVICE_REQUESTS: `${URLS.TENANT_SERVICE_REQUESTS}/*`,
  TRANSACTIONS: `${URLS.TRANSACTIONS}/*`,
  CHAT: `${URLS.CHAT}/*`,
  LEASE_DETAILS: `${URLS.LEASE_DETAILS}/*`,
  PAYMENT_METHODS: `${URLS.PAYMENT_METHODS}/*`,
  MY_PROFILE: `${URLS.MY_PROFILE}/*`,
  TENANT_ONBOARD: `${URLS.TENANT_ONBOARD}`,
  INVITE_USER: `${URLS.INVITE_USER}`

};

export const FORM_CONTROLFIELD_TYPES = {
  NUMBER: "number",
  TEXT: "text",
  TEXTAREA:'textarea',
  EMAIL: "email",
  PASSWORD: "password",
  DATE: "date",
  CURRENCY: "currency",
  TELEPHONE: "tel"
};

export const generateMenuRoutes = (values, userRoleId: string | number) => {
  switch (userRoleId) {
    //USERCASE
    case 1:
      return [
        {
          id: "1",
          path: URLS.PROPERTIES,
          primaryText: `${Localizer.getLocalizedString("properties_title")} ${values.noOfProperties
            }`,
          leftIcon: GetImages.propertiesIconMenu,
          selectedIcon: GetImages.selectedPropertiesIconMenu,
        },
        {
          id: "2",
          path: URLS.UNITS,
          primaryText: `${Localizer.getLocalizedString("units_title")} ${values?.noOfUnits
            }`,
          leftIcon: GetImages.unitIconMenu,
          selectedIcon: GetImages.selectedUnitIconMenu,
        },
        {
          id: "3",
          path: URLS.TENANTS,
          primaryText: `${Localizer.getLocalizedString("tenants_title")} ${values.noOfTenants
            }`,
          leftIcon: GetImages.tenantIconMenu,
          selectedIcon: GetImages.selectedTenantIconMenu,
        },
        {
          id: "4",
          path: URLS.SERVICE_REQUESTS,
          primaryText: `${Localizer.getLocalizedString(
            "service_requests_title"
          )} ${values.noOfServiceRequests}`,
          leftIcon: GetImages.serviceRequestIconMenu,
          selectedIcon: GetImages.selectedServiceRequestIconMenu,
        },
        {
          id: "5",
          path: URLS.CONTRACTORS,
          primaryText: `${Localizer.getLocalizedString("contractors_title")} ${values.noOfContractors
            }`,
          leftIcon: GetImages.contractorIconMenu,
          selectedIcon: GetImages.selectedContractorIconMenu,
        },
        {
          id: "11",
          path: URLS.ORGANIZATION_ACCOUNTS,
          primaryText: `${Localizer.getLocalizedString("accounting_title")}`,
          leftIcon: GetImages.accountingIconMenu,
          selectedIcon: GetImages.selectedAccountingIcon,
        },
        {
          id: "12",
          path: URLS.PAYMENT,
          primaryText: `${Localizer.getLocalizedString("payment_title")}`,
          leftIcon: GetImages.tenantPaymentMethodIcon,
          selectedIcon: GetImages.selectedTenantPaymentMethodIcon,
        },
        {
          id: "13",
          path: URLS.TRANSACTION,
          primaryText: `${Localizer.getLocalizedString("transaction_title")}`,
          leftIcon: GetImages.accountingIconMenu,
          selectedIcon: GetImages.selectedAccountingIcon,
        },
      ];
    case 2:
      return [
        {
          id: "1",
          path: URLS.TENANT_ONBOARD,
          primaryText: `${Localizer.getLocalizedString("properties_title")} ${values.noOfProperties
            }`,
          leftIcon: GetImages.propertiesIconMenu,
          selectedIcon: GetImages.selectedPropertiesIconMenu,
        },
      ]
    //TENANTCASE
    case 4:
      return [
        {
          id: "1",
          path: URLS.TENANT_SERVICE_REQUESTS,
          primaryText: "Service request",
          leftIcon: GetImages.tenantServiceRequestIcon,
          selectedIcon: GetImages.selectedTenantServiceRequestIcon,
        },
        {
          id: "2",
          path: URLS.TRANSACTIONS,
          primaryText: "Transactions",
          leftIcon: GetImages.tenantTransactionIcon,
          selectedIcon: GetImages.selectedTenantTransactionIcon,
        },
        {
          id: "3",
          path: URLS.CHAT,
          primaryText: "Chats",
          leftIcon: GetImages.tenantChatIcon,
          selectedIcon: GetImages.selectedTenantChatIcon,
        },
        {
          id: "4",
          path: URLS.LEASE_DETAILS,
          primaryText: "Lease Details",
          leftIcon: GetImages.tenantLeaseIcon,
          selectedIcon: GetImages.selectedTenantLeaseIcon,
        },
        {
          id: "5",
          path: URLS.PAYMENT_METHODS,
          primaryText: "Payment Methods",
          leftIcon: GetImages.tenantPaymentMethodIcon,
          selectedIcon: GetImages.selectedTenantPaymentMethodIcon,
        },
        // {
        //   id: "6",
        //   path: "/test4",
        //   primaryText: `COMING SOON Page  ${values.noOfUnits}`,
        //   leftIcon: (
        //     <CustomIcon
        //       src={GetImages.serviceRequestIconMenu}
        //       alt={"Service Request"}
        //     />
        //   ),
        // },
      ];
    case 6:
      return [
        {
          id: "1",
          path: URLS.INFO,
          primaryText: "My info",
          leftIcon: (
            <CustomIcon
              src={GetImages.serviceRequestIconMenu}
              alt={"Service Request"}
            />
          ),
        },
        {
          id: "2",
          path: URLS.SERVICE_REQUESTS,
          primaryText: `Service Request  ${values.noOfServiceRequests}`,
          leftIcon: (
            <CustomIcon
              src={GetImages.serviceRequestIconMenu}
              alt={"Service Request"}
            />
          ),
        },
        {
          id: "3",
          path: URLS.PAYMENT_DUE_ACCOUNTS,
          primaryText: "Payment Due Accounts",
          leftIcon: (
            <CustomIcon
              src={GetImages.serviceRequestIconMenu}
              alt={"Service Request"}
            />
          ),
        },
        {
          id: "4",
          path: URLS.PAYEE_ACCOUNT_MANAGEMENT,
          primaryText: "Payee Account Management",
          leftIcon: (
            <CustomIcon
              src={GetImages.serviceRequestIconMenu}
              alt={"Service Request"}
            />
          ),
        },
      ];
    default: {
      return null;
    }
  }
};

export const tenantFields = [
  { label: "First name", placeholder: "Enter First name", name: "firstName" },
  { label: "Last name", placeholder: "Enter Last Name", name: "lastName" },
  {
    label: "Phone Number",
    placeholder: "Enter Phone Number",
    name: "phoneNumber",
  },
  { label: "Email address", placeholder: "Enter email", name: "email" },
];
// Its alternative method is written : DateHelper.convertUTCtoDateTimeSimple(myDateString, showTime);
export const convertDate = (requestDateStr) => {
  const requestDateObj = new Date(requestDateStr);

  const formattedDate = requestDateObj.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return formattedDate;
};

export const getBathroomText = (number, text) => {
  return number > 1 ? `${text}s` : text;
};

export const defaultPageNo = 1;
export const defaultPageSize = 10;
