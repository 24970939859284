import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import AddButtonWithArrow from "./AddButtonWithArrow";
import { GetImages } from "../../../utils/GetImages";

const FormFooter = ({
  buttonname,
  setNextvalue,
  nextvalue,
  handleSubmit,
  propertyValue,
  navigateto,
  showCancelButton,
  handleCancel,
  handleFinish
}) => {
  const navigate = useNavigate();

  const handleNextQuestion = (bname) => {
    if (bname === "Next" && propertyValue?.propertyTypeId) {
      setNextvalue(true);
    } else if (bname === "Create Property" || bname === "Add unit") {
      if (bname === "Create Property") {
        handleSubmit();
      } else if (bname === "Add unit") {
        navigate(navigateto);
      }
    }
  };

  const handlePrevQuestion = () => {
    setNextvalue(false);
  };

  return (
    <div className="footerSection">
      <div className="footermain d-flex justify-content-between">
        <div style={{ width: "127px" }}>
          {showCancelButton && (
            <div className="cancelButton sub_text  fw-600 " onClick={handleCancel}>
              Cancel
            </div>
          )}
        </div>
        <div className="d-flex gap-2">
          <Button
            className="goback sub_text blue"
            style={{
              display: nextvalue && propertyValue?.propertyTypeId ? "" : "none",
            }}
            onClick={handlePrevQuestion}
          >
            Go Back
          </Button>
          <AddButtonWithArrow
            buttonname={buttonname}
            disabled={undefined}
            onClick={() => {
              if (buttonname === "Finish") {
                handleFinish();
              } else {
                handleNextQuestion(buttonname);
              }
            }}
            iconDirection="left"
            Icon={GetImages.WhiteForwardArrow}
          />
        </div>
      </div>
    </div>
  );
};

export default FormFooter;
