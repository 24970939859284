import React from "react";
import { GetImages } from "../../../utils/GetImages";

const NoAccount = ({ setShowModal, handleAddAccount }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center h-100">
      <img
        src={GetImages.tenantNoAccountIcon}
        alt="NoAccount"
        className="tenantNoAccountImage"
      />
      <p className="heading black">Begin your transaction by adding account</p>
      <p className="normal_text black">
        Add your account by ACH or Credit Card{" "}
      </p>
      <div className="editFormFooter">
        <p
          onClick={() => setShowModal(false)}
          className="normal_text fw-600 blue clickable"
        >
          Cancel
        </p>
        <button
          onClick={handleAddAccount}
          type="submit"
          className="saveChangesButton1 sub_text fw-600"
        >
          Add An Account
          <img src={GetImages.WhiteForwardArrow} />
        </button>
      </div>
    </div>
  );
};

export default NoAccount;
