import React, { useEffect, useState } from 'react';
import { PubSub, TopicsEnum } from '../../utils/pubsub';
import EmailShell from './emailShell';

const EmailSignUp = () => {

  const [emailFields, setEmailFields] = useState({})
  useEffect(() => {{
    PubSub.subscribe('SERVER_EMAIL_DATA', (emailData) => {
      setEmailFields(emailData);

      window.setTimeout(() => { window['emailReady'] = true;}, 2000);
    });
  }}, []);
  
  return (
    <EmailShell EmailContentComponent={() => {
      return (
        <div>
          <p>Thank you for signing up on propertelligent.</p>
          <p>{emailFields["myField"]}</p>
        </div>
      );
    }} />
  );
};

export default EmailSignUp;