import { AvatarWithLoading } from '../../../components/common/avator'

const UnitDetails = ({ unitData }) => {
    return (
        <div className="propertyFirstColumn">
            <div className="p-image">
                <AvatarWithLoading
                    docNumber={unitData?.mainImageId}
                    avatar={true}
                    Size="Icon60"
                />
            </div>
            <div className="property-details">
                <p className="sub_text  grey_subtext1">
                    {unitData?.formatted?.UnitTypeId}
                </p>
                <p className="heading black  propertyAddress">
                    {unitData && unitData.streetAddress}
                </p>
            </div>
        </div>
    )
}

export default UnitDetails