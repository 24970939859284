import { useCallback, useEffect, useState } from 'react'
import AddressAutocomplete from '../../firstTimeUserExperience/commonCom/AddressAutocomplete'
import { FORM_CONTROLFIELD_TYPES } from '../../../constants/constants';
import { useError } from '../../../utils/context/ErrorContext';
import CustomInputs from '../../common/Inputs';
import { PropertyVM } from '@propertelligent/client-api';
import { OnboardingForms } from '../../../typings/onboarding';
import { OnboardingStepsEnums } from '../../../constants/onboarding';

const CurrentAddress = ({ formData, setFormData, handleRemoveError, errors }) => {
  const { errorMessages } = useError()
  const [propertyValue, setPropertyvalue] = useState<PropertyVM>()

  useEffect(() => {
    if (propertyValue) {
      const updatedAddress = {
        streetAddress: propertyValue?.address?.streetAddress || "",
        city: propertyValue?.address?.city || "",
        state: propertyValue?.address?.state || "",
        zip: propertyValue?.address?.zip || "",
      };

      setFormData((prevData: OnboardingForms) => ({
        ...prevData,
        CurrentAddress: {
          ...prevData.CurrentAddress,
          address: {
            ...propertyValue?.address,
            ...updatedAddress,
          },
        },
      }));

      Object.keys(updatedAddress).forEach((subKey) => {
        if (updatedAddress[subKey]) {
          handleRemoveError("address", OnboardingStepsEnums.CurrentAddress, { key: "address", subKey });
        }
      });
    }
  }, [propertyValue, setFormData]);

  const handleChange = useCallback((key: string, value: string, subKey?: string) => {
    setFormData((prevData: OnboardingForms) => ({
      ...prevData,
      CurrentAddress: {
        ...prevData.CurrentAddress,
        [key]: subKey ? { ...prevData.CurrentAddress?.[key], [subKey]: value } : value,
      },
    }));

    handleRemoveError(key, OnboardingStepsEnums.CurrentAddress, { key, subKey });
  }, [setFormData, handleRemoveError]);

  const AddressDetails = [
    {
      id: 1,
      name: "unitNumber",
      labelText: "Unit Number",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.unitNumber,
      isFormControl: true,
      onChange: (e) => handleChange("unitNumber", e.target.value),
      placeholder: "Enter unit number",
      myClassName: "containerTextField",
      isError: errors?.unitNumber,
      errorMessages: errorMessages?.unitNumber,
    },
    {
      id: 2,
      name: "streetAddress",
      labelText: "Street Address",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.address?.streetAddress,
      isFormControl: true,
      onChange: (e) => handleChange("address", e.target.value, "streetAddress"),
      placeholder: "Enter street address",
      myClassName: "containerTextField",
      isError: errors?.address?.streetAddress,
      errorMessages: errorMessages?.streetAddress,
    },
    {
      id: 3,
      name: "city",
      labelText: "City",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.address?.city,
      isFormControl: true,
      onChange: (e) => handleChange("address", e.target.value, "city"),
      placeholder: "Enter city",
      myClassName: "containerTextField",
      isError: errors?.address?.city,
      errorMessages: errorMessages?.city,
    },
    {
      id: 4,
      name: "state",
      labelText: "State",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.address?.state,
      isFormControl: true,
      onChange: (e) => handleChange("address", e.target.value, "state"),
      placeholder: "Enter state",
      myClassName: "containerTextField",
      isError: errors?.address?.state,
      errorMessages: errorMessages?.state,
    },
    {
      id: 5,
      name: "zip",
      labelText: "ZIP Code",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.address?.zip,
      isFormControl: true,
      onChange: (e) => handleChange("address", e.target.value, "zip"),
      placeholder: "Enter ZIP code",
      myClassName: "containerTextField",
      isError: errors?.address?.zip,
      errorMessages: errorMessages?.billingZipCode,
    },
    {
      id: 6,
      name: "movingDate",
      labelText: "Moving Date",
      type: FORM_CONTROLFIELD_TYPES.DATE,
      value: formData?.movingDate,
      isFormControl: true,
      onChange: (e) => handleChange("movingDate", e.target.value),
      placeholder: "Select moving date",
      myClassName: "containerTextField",
      isError: errors?.movingDate,
      errorMessages: errorMessages?.movingDate,
    },
    {
      id: 7,
      name: "movingReason",
      labelText: "Reason for Moving",
      type: FORM_CONTROLFIELD_TYPES.TEXTAREA,
      value: formData?.movingReason,
      isFormControl: true,
      onChange: (e) => handleChange("movingReason", e.target.value),
      placeholder: "Enter reason for moving",
      myClassName: "containerTextField",
      isError: errors?.movingReason,
      errorMessages: errorMessages?.movingReason,
    }
  ];

  return (
    <>
      <div className="onboarding-stepper">
        <div className="onboarding-steppercard">
          <p className="main_heading py-3">Current Address</p>
          <div className="onboardingstepper-form ">
            <div className=''>
              <AddressAutocomplete
                propertyValue={propertyValue}
                setPropertyvalue={setPropertyvalue}
                required={true}
                showSelectedValue={false}
              />
            </div>
            <div>
              <div className="w-100 basic-form py-3" >
                {AddressDetails?.map((item) => (
                  <div key={item.id}>
                    <CustomInputs
                      name={item.name}
                      type={item.type}
                      value={item.value}
                      labelText={item.labelText}
                      isFormControl={item.isFormControl}
                      onChange={item.onChange}
                      placeholder={item.placeholder}
                      myClassName={item.myClassName}
                      isError={item.isError}
                      errorMessages={item.errorMessages}
                    // width={"322px"}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CurrentAddress