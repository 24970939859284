import React from "react";
import "../tenants/tenantStyles.css";
import info from "../../assets/info.png";
import user from "../../assets/user.png";
import redUser from "../../assets/redUser.png";
import { TenantLeaseTypesEnum } from "@propertelligent/client-api";

const ProgressBar = ({ monthsRemaining, leaseType }) => {
  const isMonthToMonth =
    leaseType === TenantLeaseTypesEnum.MonthToMonth ||
    leaseType === "Month-to-Month";

  return (
    <div className="rightSideContainer">
      <div className="monthsLeftContainer">
        <div className="imgContainer">
          <img
            src={monthsRemaining > 5 || isMonthToMonth ? user : redUser}
            alt="User Status"
          />
        </div>
        <p
          style={{
            color:
              isMonthToMonth || monthsRemaining > 5 ? "#4b5563" : "#E02424",
          }}
          className="sub_text"
        >
          {isMonthToMonth ? "MTM Lease" : `${monthsRemaining} months left`}
        </p>
        <div className="imgContainer">
          <img src={info} alt="Info" />
        </div>
      </div>
      <div className="progressBarContainer">
        <div
          className="progressBarCompleted"
          style={{
            width: isMonthToMonth ?  "100%"  : monthsRemaining > 12  ?  "100%" : `${(monthsRemaining * 100) / 12}%`,
            background:
              isMonthToMonth || monthsRemaining > 5 ? "#057a55" : "#E02424",
          }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
