import { useEffect, useState } from "react";
import "./Dashboard.css";
import TopSection from "./TopSection";
import RevenueOverviewContainer from "./RevenueOverviewContainer";
import CashFlowChart from "./CashFlowChart";
import ExpenseDistributionDashboard from "./ExpenseDistributionDashboard";
import PerformanceOverview from "./PerformanceOverview";
import VacancyRate from "./VacancyRate";
import { UserLoginData } from "../components/common/charts/Enums";
import { useAnalyticsHook } from "../hooks/useAnalyticsHook";
import { AnalyticsService } from "@propertelligent/client-api";
import { Spinner } from "react-bootstrap";

const Dashboard = () => {
  const organization_id = Number(localStorage.getItem(UserLoginData.organization_id));
  const [fin_monthValue, setFin_MonthValue] = useState(-12);
  // const [allExpenseData, setAllExpenseData] = useState([]);
  const [loadingStates, setLoadingStates] = useState({
    topSectionLoading: true,
    revenueLoading: true,
    cashFlowLoading: true,
    expanseDistrubutionLoading: true,
    vacancyRateLoading: true,
    performanceOverviewLoading: true,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const isLoaded = Object.values(loadingStates).every(
      (value) => value === false
    );
    setLoading(!isLoaded);
  }, [loadingStates]);

  const data = [
    {
      name: "Flooring",
      value: 800,
      color: "#00C769",
    },
    {
      name: "Electric",
      value: 500,
      color: "#FFC400",
    },
    {
      name: "Phone",
      value: 300,
      color: "#FF1F8B",
    },
    {
      name: "Due",
      value: 100,
      color: "#5f0a57",
    },
  ];
  const customMonthField = "monthYear";

  const { expensebycategoryArr, loading: dataLoading } = useAnalyticsHook({
    httpExpensebycategoryFn:
      AnalyticsService.getApiAnalyticsOrganizationExpensebycategory,
    customMonthField,
    parentEntityId: organization_id,
    fullyQualifiedFilters: {
      fin: `Created>DateTime.UtcNow.AddMonths(${fin_monthValue})`,
    },
  });

  useEffect(() => {
    setLoadingStates((loadingStates) => ({
      ...loadingStates,
      expanseDistrubutionLoading: false,
    }));
  }, [dataLoading]);

  return (
    <>
      {loading && ( // Check loading state
        <div className="loading-container">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div style={{ overflow: "scroll", height: "100vh" }}>
        <div style={{ display: loading == true && "none" }}>
          <div className="dashboardTopBar">
            <div>
              <p className="top_header">Dashboard</p>
            </div>
          </div>
          <div>
            <TopSection
              loadingStates={loadingStates}
              setLoadingStates={setLoadingStates}
            />
          </div>
          <div className="d-flex flex-wrap dashboardBottomContainer">
            <div className="leftSideDashboardComponents">
              <RevenueOverviewContainer
                loadingStates={loadingStates}
                setLoadingStates={setLoadingStates}
              />
              <CashFlowChart
                loadingStates={loadingStates}
                setLoadingStates={setLoadingStates}
              />
              <ExpenseDistributionDashboard
                data={expensebycategoryArr}
                fin_monthValue={fin_monthValue}
                setFin_MonthValue={setFin_MonthValue}
              />
              <VacancyRate
                loadingStates={loadingStates}
                setLoadingStates={setLoadingStates}
              />
            </div>
            <div className="rightSideDashboardComponents">
              {/* <OpenActionItemsDashboard /> */}
              <PerformanceOverview
                loadingStates={loadingStates}
                setLoadingStates={setLoadingStates}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
