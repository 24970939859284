import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const CustomDropDownMenu = ({ Element, data, content = undefined, Action = false ,
  show = false}) => {
  return (
    <Dropdown show={show}>
      <Dropdown.Toggle
        style={{ outline: "none", height: Action && "20px" }}
        variant="none"
        id="filterDropdown"
        className="m-0 p-0"
      >
        {Action ? (
          <div className="d-flex gap-2 align-item-center justify-content-center pe-2 ps-2">
            <p className="sub_text fw-600 blue">Actions</p>
            {Element}
          </div>
        ) : (
          Element
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {content ? (
          // Render the custom content if provided
          <div className="custom-content">
            {content}
          </div>
        ) : (
          // Otherwise, render the menu items from data
          data?.map((item, index) => (
            <React.Fragment key={item.id}>
              <Dropdown.Item onClick={item.onClick ? item.onClick : undefined}>
                {item.linkpath === undefined ? (
                  <div className="text-decoration-none d-flex flex-row align-items-center text-dark gap-2">
                    {item.img && (
                      <img
                        src={item.img}
                        width={20}
                        height={20}
                        alt="Black Email Icon"
                      />
                    )}
                    <p className={item.textClass ? item.textClass : "sub-text"}>
                      {item.name}
                    </p>
                  </div>
                ) : (
                  <Link
                    to={item.linkpath}
                    className="text-decoration-none d-flex flex-row align-items-center text-dark gap-2"
                  >
                    {item.img && (
                      <img
                        src={item.img}
                        width={20}
                        height={20}
                        alt="Black Email Icon"
                      />
                    )}
                    <p className={item.textClass ? item.textClass : "sub-text"}>
                      {item.name}
                    </p>
                  </Link>
                )}
              </Dropdown.Item>
              {index !== data.length - 1 && <Dropdown.Divider />}
            </React.Fragment>
          ))
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropDownMenu;
