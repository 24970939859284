import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { getTenantList } from "../../../redux/actions/unitAction";
import EmptyData from "../../common/EmptyData";
import { ViewItem } from "../../units/ViewItem/ViewItem";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../../constants/constants";
import "../../units/style.css";
import Search from "../../common/search";

const UnitList = ({ loadingStates, setLoadingStates }) => {
  const { id } = useParams();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [filterunitlist, setFilterunitlist] = useState([]);
  const [unitstate, setUnitstate] = useState(1);
  const [filterSearchTerm, setFilterSearchTerm] = useState({ unitNumber: "" });
  const [unitCount, setUnitCount] = useState({ all: 0, vaccant: 0, leaseu: 0 });

  const { unitList } = useSelector((state: any) => state?.unitList);
  const { isLoading } = useSelector((state: any) => state?.Comman);

  useEffect(() => {
    if (!isLoading) {
      setLoadingStates({
        ...loadingStates,
        unit: false,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(getTenantList(id));
  }, [dispatch, filterSearchTerm?.unitNumber, unitList?.length]);

  useEffect(() => {
    setFilterunitlist(unitList);
    let vaccantunit = unitList?.filter((item) => item.tenantIds === null);
    let leasevalue = unitList?.filter(
      (item) => item.leaseRemainingMonths === null
    );
    setUnitCount({
      ...unitCount,
      all: unitList?.length,
      vaccant: vaccantunit?.length,
      leaseu: leasevalue?.length,
    });
  }, [unitList?.length]);

  const filterFunction = (value: any) => {
    if (value === "all") {
      setFilterunitlist(unitList);
    } else if (value === "vaccant") {
      
      let vaccantunit = unitList?.filter((item) => item.tenantIds === null);
      
      setFilterunitlist(vaccantunit);
    } else if (value === "lease") {
      let leasevalue = unitList?.filter(
        (item) => item.leaseRemainingMonths === null
      );

      setFilterunitlist(leasevalue);
    }
  };

  const handleItemClick = (id: string | undefined) => {
    navigate(`${URLS.EDIT_UNIT}/${id}`);
  };

  const handleInviteTenantClick = (
    event: React.MouseEvent<HTMLParagraphElement>
  ) => {
    event.stopPropagation();
    navigate(URLS.CREATE_TENANT);
  };

  return (
    <>
      {filterunitlist?.length === 0 && !isLoading ? (
        <EmptyData
          mainText={undefined}
          subText="Data Not Available"
          button={undefined}
        />
      ) : (
        <div className="single_pro_UnitList">
          <div className="pro_UnitList_header">
            <div className="monthhead ">
              <button
                onClick={() => {
                  setUnitstate(1);
                  filterFunction("all");
                }}
                className={
                  unitstate === 1
                    ? "overviewSelectedButton sub_text fw-500 blue"
                    : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
                }
              >
                All Unit({unitCount.all})
              </button>
              <button
                onClick={() => {
                  setUnitstate(2);
                  filterFunction("vaccant");
                }}
                className={
                  unitstate === 2
                    ? "overviewSelectedButton sub_text fw-500 blue"
                    : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
                }
              >
                Vacant Unit({unitCount.vaccant})
              </button>
              <button
                onClick={() => {
                  setUnitstate(3);
                  filterFunction("lease");
                }}
                className={
                  unitstate === 3
                    ? "overviewSelectedButton sub_text fw-500 blue"
                    : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
                }
              >
                Lease Near To End({unitCount.leaseu})
              </button>
            </div>
            <div className="search_filter_UnitList">
              <Search
                placeholderName="Search by Unit Number"
                searchFilterChange={(e) =>
                  setFilterSearchTerm({ unitNumber: e.target.value })
                }
                searchValue={filterSearchTerm.unitNumber}
              />
            </div>
          </div>

          <div className="tenantListContainer w-100 p-0">
            <div style={{ marginBottom: "8px" }} className="unit-item-list">
              <ViewItem
                rows={filterunitlist}
                loading={isLoading}
                handleItemClick={handleItemClick}
                handleInviteTenantClick={handleInviteTenantClick}
                showTarget={true}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UnitList;
