import "proxy-polyfill";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../src/components/GlobalCss/globalStyle.css";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux"
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { MyProvider } from "./utils/context/LoginContext";
import { PropertyProvider } from "./utils/context/PropertyProvider";
import { MenuProvider } from "./utils/context/MenuContext";
import store from "./store";
import { QuickstartProvider } from "./components/firstTimeUserExperience/Context";
import { ErrorProvider } from "./utils/context/ErrorContext";
import { OverviewProvider } from "./utils/context/OverviewContext";
import { GeneratePdfProvider } from "./utils/context/GeneratePdfContext";
import { PaymentProvider } from "./utils/context/PaymentContext";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <ErrorProvider>
    <MyProvider>
    <PaymentProvider> 
      <GeneratePdfProvider>
      <OverviewProvider>
        <Provider store={store}>
          <PropertyProvider>
            <QuickstartProvider>
              <MenuProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </MenuProvider>
            </QuickstartProvider>
          </PropertyProvider>
        </Provider>
      </OverviewProvider>
      </GeneratePdfProvider>
      </PaymentProvider> 
    </MyProvider>
  </ErrorProvider>
);
