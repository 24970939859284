// import React from "react";
// import { DocumentService } from "@propertelligent/client-api";
// import { useResolveHttpPromise } from "../../hooks/useResolveHttpPromise";

// interface Props {
//   docNumber: number;
//   avatar: boolean;
//   Size:String
// }

// export const AvatarWithLoading : React.FC<Props> = React.memo(
// ({
//   docNumber,
//   avatar = false,
//   Size
// }) => {

//   if (docNumber === 0 || docNumber === undefined) {
//     return <img
//     className={`${Size}`}
//     style={{ width: 60, height: 60, borderRadius: 4 }}
//     src="https://via.placeholder.com/60x60"
//   />;
//   }

//   const fetchDoc = async () => {
//     return await DocumentService.getApiDocumentBase641(docNumber);
//   };

//   const { isLoading, error, data } = useResolveHttpPromise({
//     httpFetchDataFn: fetchDoc,
//   });

//   if (isLoading) {
//     return null;
//   }

//   if (error) {
//     console.error("Error fetching or processing image:", error);
//     return <img
//       style={{ width: 60, height: 60, borderRadius: 4 }}
//       src="https://via.placeholder.com/60x60"
//     />
//   }

//   return (<img style={{ width: 60, height: 60, borderRadius: 4 }}
//             src={(avatar && data && data?.success) ? `data:image/*;base64,${data.success}` : "https://via.placeholder.com/60x60"}
//           />)
// });

import React from "react";
import { DocumentService } from "@propertelligent/client-api";
import { useResolveHttpPromise } from "../../hooks/useResolveHttpPromise";

interface Props {
  docImage?: any;
  docNumber?: number;
  avatar: boolean;
  Size: String;
}

export const AvatarWithLoading: React.FC<Props> = React.memo(
  ({ docImage = null, avatar = false, Size }) => {
    // if (docNumber === 0 || docNumber === undefined) {
    //   return <img
    //   className={`${Size}`}
    //   style={{ width: 60, height: 60, borderRadius: 4 }}
    //   src="https://via.placeholder.com/60x60"
    // />;
    // }

    // const fetchDoc = async () => {
    //   return await DocumentService.getApiDocumentBase641(docNumber);
    // };

    // const { isLoading, error, data } = useResolveHttpPromise({
    //   httpFetchDataFn: fetchDoc,
    // });

    // if (isLoading) {
    //   return null;
    // }

    // if (error) {
    //   console.error("Error fetching or processing image:", error);
    //   return <img
    //     style={{ width: 60, height: 60, borderRadius: 4 }}
    //     src="https://via.placeholder.com/60x60"
    //   />
    // }
    return (
      <>
        <img
          className={`${Size}`}
          style={{ width: 60, height: 60, borderRadius: 4 }}
          src={
            avatar && docImage
              ? `data:image/*;base64,${docImage}`
              : "https://via.placeholder.com/60x60"
          }
        />
      </>
    );

    // return (<img style={{ width: 60, height: 60, borderRadius: 4 }}
    //           src={(avatar && data && data?.success) ? `data:image/*;base64,${data.success}` : "https://via.placeholder.com/60x60"}
    //         />)
  }
);
