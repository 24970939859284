import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GetImages } from '../../utils/GetImages';
import Search from './search';
import { ReportFilterEntityEnum } from '@propertelligent/client-api';


const MultiselectDropdown = ({
  options,
  selectedOption,
  selectedIds,
  placeholder,
  onChange

}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleCheckboxChange = (e, id) => {
    const value = e.target.value;
    onChange(value, id)
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClose = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClose);
    } else {
      document.removeEventListener('mousedown', handleClose);
    }

    return () => {
      document.removeEventListener('mousedown', handleClose);
    };
  }, [isDropdownOpen]);

  return (
    <div className="multiselect-dropdown" ref={dropdownRef}>
      <div className="dropdown-select" onClick={toggleDropdown}>
        {selectedOption == ReportFilterEntityEnum.Property ? 'Select Properties'
          : selectedOption == ReportFilterEntityEnum.Unit ? 'Select Units'
            : placeholder}
        <img src={GetImages.Expand} alt='' className='dropdown-arrow' />
      </div>

      {isDropdownOpen && (
        <div className="dropdown-container">
          <Search
            placeholderName={"Search ..."}
            searchValue={searchTerm}
            searchFilterChange={handleSearchChange}
          />

          <div className="checkbox-list">
            {options.filter((option) => option.description.toLowerCase().includes(searchTerm.toLowerCase())).map((option) => (
              <Form.Check
                key={option.id}
                type="checkbox"
                label={option.description}
                value={option.description}
                checked={selectedIds.includes(option.id)}
                onChange={(e) => handleCheckboxChange(e, option.id)}
                className={`checkbox-item ms-0 ${selectedIds.includes(option.id) ? "checked-option" : ""}`}
                required
              />
            ))}

          </div>
        </div>
      )}
    </div>
  );
};

export default MultiselectDropdown;
