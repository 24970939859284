const AddButton = ({ onClick, bname ,className="getStartedButton sub_text white" }) => {
  return (
    <>
      <button onClick={onClick} className={className}>
        {bname}
      </button>
    </>
  );
};

export default AddButton;
