import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

const CustomSwitchToggle = ({onClick,checked ,label=undefined , labelClassName = '',className=null}) => {
    
  return (
    <Form.Check
        type="switch"
        id="custom-switch"
        checked={ checked}
        onChange={onClick}
        label={ label ?<span style={{
         order: -1, 
          marginRight: '35px', 
       }} className={labelClassName}>{label}</span>  :undefined}
         className={className}
       
      />
  )
}

export default CustomSwitchToggle