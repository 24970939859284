import React, { useCallback, useEffect, useRef, useState } from 'react'
import RequestBreadcrumb from '../../../common/RequestBreadcrumb'
import {
  TemplateEntityTypesEnum, TemplateService, TemplateTypeEnum, LeaseAgreementTemplateSectionVM, LeaseAgreementDataAttributesEnum
} from '@propertelligent/client-api'
import { UserLoginData } from '../../../common/charts/Enums'
import QuestionHeader from '../QuestionHeader'
import "../AddTemplate/AddTemplate.css"
import { useNavigate, useParams } from 'react-router'
import apiHelper from '../../../../utils/apiHelper'
import AddTraspButton from '../../../firstTimeUserExperience/commonCom/AddTraspButton'
import AddButtonWithArrow from '../../../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import { GetImages } from '../../../../utils/GetImages'
import CustomInputs from '../../../common/Inputs'
import { FORM_CONTROLFIELD_TYPES } from '../../../../constants/constants'
import "./LeaseTemplate.css"
import CustomEditor from './CustomEditor'
import EmptyData from '../../../common/EmptyData'
import { convertENUMtoJSONArray } from '../../../../utils/helper'

const AddLeaseAgreementTemplate = () => {
  const navigate = useNavigate();
  const editorRef = useRef([]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [postData, setPostData] = useState<any | undefined>(
    {
      name: "form 1 - draft ",
      description: "Lease agreement for prospective tenants",
      templateTypeId: TemplateTypeEnum.LeaseAgreement,
      entityTypeId: TemplateEntityTypesEnum.Organization,
      entityId: parseInt(localStorage.getItem(UserLoginData.organization_id)),
      isInUse: false,
      leaseAgreementTemplateSections: [] as LeaseAgreementTemplateSectionVM,
    })


  const AddSection = () => {
    const newSection = { sectionName: `Section ${postData.leaseAgreementTemplateSections.length + 1}`, sectionData: "" };
    setPostData(prevData => ({
      ...prevData,
      leaseAgreementTemplateSections: [...prevData.leaseAgreementTemplateSections, newSection]
    }));
  }


  const copySection = useCallback(
    (index) => {
      const sectionToCopy = { ...postData.leaseAgreementTemplateSections[index] };
      sectionToCopy.sectionName = `${sectionToCopy.sectionName} (Copy)`;
      setPostData((prevData) => ({
        ...prevData,
        leaseAgreementTemplateSections: [
          ...prevData.leaseAgreementTemplateSections,
          sectionToCopy,
        ],
      }));
    },
    [postData]
  );

  const deleteSection = useCallback(
    (index) => {
      setPostData((prevData) => ({
        ...prevData,
        leaseAgreementTemplateSections: prevData.leaseAgreementTemplateSections.filter(
          (_, idx) => idx !== index
        ),
      }));
    },
    [postData]
  );

  useEffect(() => { if (id) { getTempleteData(); } }, [id, editorRef]);

  const toggleSection = (index) => {
    setPostData(prevPostData => ({
      ...prevPostData,
      leaseAgreementTemplateSections: prevPostData.leaseAgreementTemplateSections.map((section, idx) =>
        idx === index ? { ...section, isOpen: !section.isOpen } : section
      ),
    }));
  };

  const handleSectionChange = useCallback((id, key, value) => {
    setPostData(prevData => {
      const updatedSections = prevData.leaseAgreementTemplateSections.map((section, idx) =>
        idx === id ? { ...section, [key]: value } : section
      );
      return {
        ...prevData,
        leaseAgreementTemplateSections: updatedSections,
      };
    });
  }, [postData]);

  const dataAttributes = [
    { name: "LessorName", icon: GetImages.selectedTenantIconMenu, placeholder: "[ Lessor Name ]" },
    { name: "LessorAddress", icon: GetImages.DarkBlueHome, placeholder: "[ Lessor Address ]" },
    { name: "LesseeName", icon: GetImages.selectedTenantIconMenu, placeholder: "[ Lessee Name ]" },
    { name: "LeaseType", icon: GetImages.BlueFile, placeholder: "[ Lease Type ]" },
    { name: "StartDate", icon: GetImages.BlueCalendar, placeholder: "[ Start Date ]" },
    { name: "EndDate", icon: GetImages.BlueCalendar, placeholder: "[ End Date ]" },
    { name: "Rent", icon: GetImages.blueDollarIcon, placeholder: "[ Rent ]" },
    { name: "SecurityDeposit", icon: GetImages.selectedTenantIconMenu, placeholder: "[ Security Deposit ]" },
    { name: "DueDay", icon: GetImages.BlueCalendar, placeholder: "[ Due Day ]" },
    { name: "UnitNumber", icon: GetImages.UnitIconBlue, placeholder: "[ Unit Number ]" },
    { name: "LateFee", icon: GetImages.blueDollarIcon, placeholder: "[ Late Fee ]" },
    { name: "GracePeriod(In Days)", icon: GetImages.BlueCalendar, placeholder: "[ Grace Period (In Days) ]" },
    { name: "NoticePeriod(In Days)", icon: GetImages.BlueCalendar, placeholder: "[ Notice Period (In Days) ]" },
  ];
  const dataAttribute = convertENUMtoJSONArray(LeaseAgreementDataAttributesEnum);
  const enrichedData = dataAttribute.map(item => {
    const matchingAttribute = dataAttributes.find(attr => attr.name === item.name);
    if (matchingAttribute) {
      return {
        ...item,
        icon: matchingAttribute.icon,
        placeholder: matchingAttribute.placeholder,
      };
    }
    return {
      ...item, 
      icon: matchingAttribute.icon, 
      placeholder: `[ ${item.name} ]`,  
    };
  });

  const buttonClose = () => { navigate("/templates"); }

  const getTempleteData = () => {
    apiHelper(
      TemplateService.getApiTemplate,
      { showNotification: false },
      Number(id)
    ).then((res) => {
      if (res?.data?.success) {
        const templateData = res.data.success;
        setPostData(templateData);
      }
    }).catch((err) => { console.error("Error fetching template data:", err); });
  };
  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      const apiCall = id
        ? apiHelper(TemplateService.putApiTemplate, {
          successMessage: "Template Updated",
          failureMessage: "Unable to Add Template",
          showNotification: true,
        }, Number(id), postData)
        : apiHelper(TemplateService.postApiTemplate, {
          successMessage: "Template Created",
          failureMessage: "Unable to updated Template",
          showNotification: true,
        }, postData)
      const res = await apiCall;
      if (res?.data?.success) { navigate("/organization/templates"); }
    } catch (error) { } finally { setIsLoading(false); }
  }, [id, postData, navigate]);

  const duplicateTemplete = () => {
    if (id) {
      apiHelper(TemplateService.postApiTemplateDuplicate, {
        successMessage: "Template Duplicated",
        failureMessage: "Unable to Duplicate Template",
        showNotification: true,
      }, Number(id))
        .then((res) => {
          if (res?.data?.success) { navigate(`/organization/templates/lease-agreement/${res?.data?.success.id}`); }
        }).catch((err) => { });
    }
  };

  const deleteTemplete = () => {
    if (id) {
      apiHelper(TemplateService.deleteApiTemplate, {
        successMessage: "Template Deleted",
        failureMessage: "Unable to Delete Template",
        showNotification: true,
      }, Number(id))
        .then((res) => {
          navigate("/organization/templates");
        })
        .catch((err) => { });
    }
  };

  return (
    <div className="application-section">
      <RequestBreadcrumb firstName={"Template"} link={"/organization/templates"} currentStep={"Lease Agreement template"} />
      <div className="application-section-main">
        <QuestionHeader postData={postData} setPostData={setPostData} duplicateTemplete={duplicateTemplete} deleteTemplete={deleteTemplete} />
      </div>
      <div className='lease-agreement-section p-2'>
        <div className="lease-agreement-section-left  ">
          <div className=''>
            <div className='d-flex justify-content-between pb-2'>
              <p className='main_heading'>Lease Agreement Sections</p>
              <p className='normal_text blue'>Expand All <span>
                <img className='iconstyle14' alt="arrow" src={GetImages?.BlueChevronDown} />
              </span> </p>
            </div>
            {postData?.leaseAgreementTemplateSections?.length !== 0 ?
              <>
                {Array.isArray(postData?.leaseAgreementTemplateSections) && postData?.leaseAgreementTemplateSections?.map((section, index) => (
                  <div key={index} className="accordion">
                    <div
                      className="accordion-header"
                    >
                      {!section?.isOpen ? <p className='main_heading black'>{section?.sectionName}</p> :
                        <CustomInputs
                          name={undefined}
                          type={FORM_CONTROLFIELD_TYPES.TEXT}
                          value={section.sectionName}
                          labelText={undefined}
                          isFormControl
                          onChange={(e) => handleSectionChange(index, "sectionName", e.target.value)}
                          containerClassName="w-100 p-0 m-0 "
                          placeholder="Add section name"
                          id="customInputBorder"
                          myClassName="m-0 border-0 outline-0 main_heading black"
                          isError={undefined}
                          errorMessages={undefined}
                        />}
                      <div className='d-flex'>
                        <div className="options_buttons">
                          <AddTraspButton
                            width={0}
                            bname={undefined}
                            imageValue={GetImages.BluePencilEdit}
                            iconPosition={"right"}
                            onClick={undefined}
                            border={false}
                          />
                          <AddTraspButton
                            width={0}
                            bname={undefined}
                            imageValue={GetImages.deleteIconRed}
                            iconPosition={"right"}
                            onClick={
                              () => deleteSection(index)
                            }
                            border={false}
                          />
                          <AddTraspButton
                            width={0}
                            bname={undefined}
                            imageValue={GetImages.CopyBlue}
                            iconPosition={"right"}
                            onClick={
                              () => copySection(index)
                            }
                            border={false}
                          />
                        </div>
                        <img onClick={() => toggleSection(index)} className='iconstyle' alt="arrow" src={GetImages?.BlueChevronDown} />
                      </div>
                    </div>

                    {section?.isOpen && (
                      <div className="accordion-content" style={{ padding: "10px" }}  >
                        <CustomEditor
                          //  onDragOver={(e: React.DragEvent) => { e.preventDefault() } }
                          enrichedData={enrichedData}
                          section={section?.sectionData}
                          setPostData={setPostData}
                          postData={postData}
                          index={index} />
                      </div>)}
                  </div>
                ))}
                <AddTraspButton bname={"Add Section"} iconPosition={"right"}
                  imageValue={GetImages.BlueAdd} onClick={AddSection} border={true} />
              </>

              : <EmptyData mainText={"No Section Available"} button={<AddTraspButton bname={"Section"} iconPosition={"right"}
                imageValue={GetImages.BlueAdd} onClick={AddSection} border={true} />} />}

          </div>
        </div>
        <div className="lease-agreement-section-right">
          <div className='px-3 py-2'>
            <p className="main_heading">Data Attributes</p>
            <p className='normal_text'><i>Click below attribute to add in the agreement:</i></p>
          </div>
          {enrichedData.map((attr, i) => (
            <div className='border grey d-flex p-3 gap-5'
              draggable
              onDragStart={(e: React.DragEvent) => {
                e.dataTransfer.clearData();
                e.dataTransfer.setData("text", attr?.placeholder);
                e.dataTransfer.setData("application/json", JSON.stringify(attr));
              }}
              key={i}>
              <img className='Icon20' alt={attr?.name} src={attr?.icon} />
              <p className='normal_text black'>{attr?.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="application-section-bttom-view">
        <AddTraspButton bname={"Cancel"} iconPosition={undefined} onClick={buttonClose} border={true} />
        <AddButtonWithArrow buttonname={isLoading ? "Saving..." : "Save"} onClick={handleSubmit} disabled={undefined} iconDirection={"left"} Icon={GetImages.WhiteForwardArrow} />
      </div>
    </div>
  )
}

export default AddLeaseAgreementTemplate

