import { useRef } from "react";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import NameAvatarComponent from "../../tenants/NameAvatarComponent";
import { UserLoginData } from "../../common/charts/Enums";
import DateHelper from "../../../utils/DateHelper";
import CustomInputs from "../../common/Inputs";
import EmptyData from "../../common/EmptyData";


const ChatRoom = ({
  firstName,
  lastName,
  chatdata,
  messageData,
  setmessageData,
  sendMessage,
  tenantId,
  chatname,
}) => {
  const scrollDown = useRef(null);

  const name = chatname?.split(" ");

  return (
    <>
      {/* <div className="chatDetails">
        {tenantId ? (
          <>
            <NameAvatarComponent
              firstName={firstName.split(" ")[0]}
              lastName={lastName.split(" ")[0]}
              heightWidth={40}
            />
            <p className="sub_heading black text-start">
              {firstName}{lastName}
            </p>
          </>
        ) : (
          <>
            <NameAvatarComponent
              firstName={name[0]}
              lastName={name[1]}
              heightWidth={40}
            />
            <p className="sub_heading black text-start">
            {chatname}
            </p>
          </>
        )}
      </div> */}
      <div className="messageSection">
        {chatdata.length > 0 ? (
          chatdata.map((item: any) => (
            <>
              <div key={item.id}
                className={`${item.createdById === parseInt(localStorage.getItem(UserLoginData.user_id))
                  ? "outgoingMessage"
                  : "incomingMessage"
                  }`}
              >
                <div className="chatDp">
                  {item.createdById ===
                    parseInt(localStorage.getItem(UserLoginData.user_id)) ? (
                    <NameAvatarComponent
                      firstName={firstName}
                      lastName={lastName}
                      heightWidth={40}
                      fromTopBar={false}
                      fontSize="16px"
                      color="white"
                      redBorder={true}
                      backgroundColor="#dc3545"
                    />
                  ) : (
                    <NameAvatarComponent
                      firstName={name[0]}
                      lastName={name[1]}
                      heightWidth={40}
                    />
                  )}
                </div>

                <div className="chatMessage">
                  <p className="sub_text black receiver">
                    {item?.messageData}
                  </p>
                  <p className="sub_text grey_subtext1 received-date">
                    {DateHelper.convertUTCtoDateTime(item?.created, true)}
                  </p>
                </div>
              </div>
              <div ref={scrollDown} />
            </>
          ))
        ) : (
          <>
            <EmptyData mainText={undefined} subText={"No Messages"} button={undefined} />
          </>
        )}
      </div>

      <div className="chatboxbottom">
        <CustomInputs
          type="text"
          name="messageData"
          value={messageData.messageData}
          onChange={(e) =>
            setmessageData({ ...messageData, messageData: e.target.value })
          }
          placeholder="Type your message here...."
          myClassName="serchInput"
          id={"customInputBorder"}
        />
        <AddTraspButton
          bname="Send"
          iconPosition={undefined}
          imageValue={undefined}
          onClick={sendMessage}
          border={false}
        />
      </div>
    </>
  );
};

export default ChatRoom;
