import NewSelectAuto from '../NewSelectAuto'
import { useError } from '../../../utils/context/ErrorContext';

const PropertyUnitSelect = ({
    selectBasic,
    isPropertyDisabled,
    setIsPropertyDisabled,
    templateSelectError,
    setTemplateSelectError,
    selectedUnit,
    setSelectedUnit,
    unitSelectError,
    setUnitSelectError,
    selectedTemplate,
    setSelectedTemplate,
    templeteData,
    allUnitData = undefined
}) => {
    const { errors, errorMessages } = useError();

    return (
        <>
            <div className="newTenantDesciptionContainer">
                <p className="newTenantDescriptionText sub_text black">
                    Select Unit
                </p>
            </div>
            <div className="newTenantSelectInputs d-flex">
                <div>
                    <NewSelectAuto
                        disabled={false}
                        setDisabled={undefined}
                        selected={selectedUnit}
                        setSelected={setSelectedUnit}
                        data={allUnitData && allUnitData}
                        title={undefined}
                        type="Unit"
                        error={unitSelectError}
                        setError={setUnitSelectError}
                        fromContractor={true}
                        label='Unit *'
                    />
                    {unitSelectError && (
                        <p className="errorMessage"> {errorMessages.unit} </p>
                    )}
                </div>
                <div>

                    {selectBasic == "1" && (
                        <>
                            <NewSelectAuto
                                disabled={undefined}
                                setDisabled={undefined}
                                selected={selectedTemplate}
                                setSelected={setSelectedTemplate}
                                data={templeteData && templeteData}
                                title={"Wait till the data fetches"}
                                type="templete"
                                error={templateSelectError}
                                setError={setTemplateSelectError}
                                fromContractor={true}
                                label='Select Template *'
                            />
                            {templateSelectError && (
                                <p className="errorMessage"> {errorMessages.template} </p>
                            )}
                        </>
                    )}
                </div>

            </div>
        </>
    )
}

export default PropertyUnitSelect