import { Contractors } from "../components/contractors";
import { MyOrganization } from "../components/my-organization";
import { Properties } from "../components/properties";
import { ServiceRequests } from "../components/servicerequest";
import { Tenants } from "../components/tenants";
import { Units } from "../components/units";
import { USER_ROLES, MAIN_ROUTES_URLS } from "../constants/constants";
import { Dashboard } from "../dashboard";
import { Messaging } from "../components/Messaging";
import { GeneratePdfDocument } from "../components/generatePdfDocument";
import TenantDashboard from "../tenantComponent/components/tenantDashboard";
import TenantServiceRequest from "../tenantComponent/components/serviceRequest";
import Transactions from "../tenantComponent/components/transactions";
import Chat from "../tenantComponent/components/chat";
import LeaseDetails from "../tenantComponent/components/leaseDetails";
import PaymentMethods from "../tenantComponent/components/paymentMethods";
import { MyProfile } from "../tenantComponent/components/myProfile";
import { GenerateEmail } from "../components/generateEmail";
import { Payment } from "../components/payments";
import Transaction from "../components/transactions";
import { OrganizationAccounts } from "../components/organizationAccounts";

export const routeList = {
  [USER_ROLES[1]]: [
    { id: "1", path: MAIN_ROUTES_URLS.HOME, element: <Dashboard /> },
    { id: "2", path: MAIN_ROUTES_URLS.UNITS, element: <Units /> },
    { id: "3", path: MAIN_ROUTES_URLS.PROPERTIES, element: <Properties /> },
    { id: "4", path: MAIN_ROUTES_URLS.TENANTS, element: <Tenants /> },
    { id: "5", path: MAIN_ROUTES_URLS.SERVICE_REQUESTS, element: <ServiceRequests />, },
    { id: "7", path: MAIN_ROUTES_URLS.CONTRACTORS, element: <Contractors /> },
    { id: "8", path: MAIN_ROUTES_URLS.ORGANIZATION, element: <MyOrganization />, },
    { id: "9", path: MAIN_ROUTES_URLS.MESSAGING, element: <Messaging /> },
    { id: "11", path: MAIN_ROUTES_URLS.ORGANIZATION_ACCOUNTS, element: <OrganizationAccounts />},
    { id: "12", path: MAIN_ROUTES_URLS.GENERATE_PDF_DOCUMENT, element: <GeneratePdfDocument /> },
    { id: "12", path: MAIN_ROUTES_URLS.GENERATE_EMAIL, element: <GenerateEmail /> },
    { id: "13", path: MAIN_ROUTES_URLS.PAYMENT, element: <Payment /> },
    { id: "14", path: MAIN_ROUTES_URLS.TRANSACTION, element: <Transaction /> },
  ],


  [USER_ROLES[4]]: [
    { id: "1", path: MAIN_ROUTES_URLS.HOME, element: <TenantDashboard /> },
    { id: "2", path: MAIN_ROUTES_URLS.TENANT_SERVICE_REQUESTS, element: <TenantServiceRequest /> },
    { id: "3", path: MAIN_ROUTES_URLS.TRANSACTIONS, element: <Transactions /> },
    { id: "4", path: MAIN_ROUTES_URLS.CHAT, element: <Chat /> },
    { id: "5", path: MAIN_ROUTES_URLS.LEASE_DETAILS, element: <LeaseDetails /> },
    { id: "6", path: MAIN_ROUTES_URLS.PAYMENT_METHODS, element: <PaymentMethods /> },
    { id: "7", path: MAIN_ROUTES_URLS.MY_PROFILE, element: <MyProfile /> }
  ],
};
