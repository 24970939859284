import React from "react";
import { Card, Col, Row, Form } from "react-bootstrap";
import debitCard from "../../../assets/debit-card.png";
import "./tenantonboard.css";
import {
  OnboardingApplicationFeeForm,
  OnboardingForms,
} from "../../../typings/onboarding";
import { OnboardingStepsEnums } from "../../../constants/onboarding";
import CustomInputs from "../../common/Inputs";
import RadioButton from "../../common/RadioButton";

const StepSix: React.FC<any> = ({
  formData,
  errors,
  setFormData,
  handleRemoveError,
}: {
  formData: OnboardingApplicationFeeForm;
  errors: { [key: string]: any };
  handleRemoveError: (
    field: string,
    form: keyof typeof OnboardingStepsEnums,
    error?: { key: string; subKey?: string }
  ) => void;
  setFormData: React.Dispatch<React.SetStateAction<OnboardingForms>>;
}) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      ApplicationFeesClause: {
        ...prev.ApplicationFeesClause,
        [name]: value,
      },
    }));
    handleRemoveError(name, OnboardingStepsEnums.ApplicationFeesClause, {
      key: name,
    });
  };

  const Radiodata = [
    {
      id: 1,
      label: "Card",
      checked: formData?.feeSubmitOptions == "Card",
    },
    {
      id: 2,
      label: "Bank",
      checked: formData?.feeSubmitOptions == "Bank",
    },
  ]

  return (
    <>
      <div className="onboarding-stepper mt-3">
        <Row>
          <Card className="onboarding-steppercard">
            <p className="main_heading py-2">Application Fees</p>
            <div className="onboardingstepper-form">
              <div className="application_fees  ">
                <div className="application_fees_left">
                  <Form>
                    <div className="flexRow py-2 gap-5">
                      {Radiodata?.map((item, i) => (
                        <div key={i}>
                          <RadioButton label={item?.label} value={item?.label} checked={item?.checked} onChange={handleChange}
                            name={"feeSubmitOptions"} />
                        </div>
                      ))}
                    </div>
                    {formData?.feeSubmitOptions == "Card" && (
                      <>
                        <Form.Group
                          className="mb-3 gap-3"
                          controlId="formBasicFname"
                        >
                          <Row >
                            <Col xs="6">
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicFname"
                              >
                                <CustomInputs
                                  type="text"
                                  labelText="Name on card"
                                  name="nameOnCard"
                                  value={formData?.nameOnCard}
                                  onChange={handleChange}
                                  placeholder="Enter first name"
                                  isError={errors?.nameOnCard}
                                  myClassName="containerTextField"
                                  errorMessages={errors?.nameOnCard}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs="6">
                              <Form.Group
                                className="mb-3  ms-3"
                                controlId="formBasicLname"
                              >
                                <CustomInputs
                                  type="text"
                                  labelText="Card Number"
                                  label_img={debitCard}
                                  name="cardNumber"
                                  value={formData?.cardNumber}
                                  onChange={handleChange}
                                  placeholder="1234  5678  9101  1121"
                                  myClassName="containerTextField"
                                  isError={errors?.cardNumber}
                                  errorMessages={errors?.cardNumber}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicFname"
                        >
                          <Row>
                            <Col xs="6">
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicFname"
                              >
                                <Form.Label>Billing zip code</Form.Label>
                                <CustomInputs
                                  type="text"
                                  name="billingZipCode"
                                  value={formData?.billingZipCode}
                                  onChange={handleChange}
                                  placeholder="12345"
                                  isError={errors?.billingZipCode}
                                  errorMessages={errors?.billingZipCode}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs="6">
                              <Row>
                                <Col xs="8">
                                  <Form.Group
                                    className="mb-3 ms-3 me-3"
                                    controlId="formBasicLname"
                                  >
                                    <Form.Label>
                                      Select expiry date
                                    </Form.Label>
                                    <CustomInputs
                                      type="date"
                                      name="expiryDate"
                                      value={formData?.expiryDate}
                                      onChange={handleChange}
                                      placeholder="Select a date"
                                      isError={errors?.expiryDate}
                                      errorMessages={errors?.expiryDate}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col xs="4">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicLname"
                                  >
                                    <Form.Label>CVV</Form.Label>
                                    <CustomInputs
                                      type="number"
                                      name="cvv"
                                      value={formData?.cvv}
                                      onChange={handleChange}
                                      placeholder="123"
                                      isError={errors?.cvv}
                                      errorMessages={errors?.cvv}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form.Group>
                      </>
                    )}
                    {formData?.feeSubmitOptions == "Bank" && (
                      <>
                        {/* when bank radio button check */}
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicFname"
                        >
                          <Row>
                            <Col xs="6">
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicFname"
                              >
                                <Form.Label>Name on card</Form.Label>
                                <CustomInputs
                                  type="text"
                                  name="nameOnCard"
                                  placeholder="Enter first name"
                                  value={formData?.nameOnCard}
                                  onChange={handleChange}
                                  isError={errors?.nameOnCard}
                                  errorMessages={errors?.nameOnCard}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs="6">
                              <Form.Group
                                className="mb-3 ms-2"
                                controlId="formBasicLname"
                              >
                                <Form.Label>Account number</Form.Label>
                                <CustomInputs
                                  type="text"
                                  name="accountNumber"
                                  value={formData?.accountNumber}
                                  onChange={handleChange}
                                  placeholder="1234  5678  9101  1121"
                                  isError={errors?.accountNumber}
                                  errorMessages={errors?.accountNumber}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicFname"
                        >
                          <Row>
                            <Col xs="6">
                              <Form.Label>Routing number</Form.Label>
                              <CustomInputs
                                type="text"
                                name="routingNumber"
                                value={formData?.routingNumber}
                                onChange={handleChange}
                                placeholder="1234  5678  9101  1121"
                                isError={errors?.routingNumber}
                                errorMessages={errors?.routingNumber}
                              />
                            </Col>
                            <Col xs="6"></Col>
                          </Row>
                        </Form.Group>
                      </>
                    )}
                    {/* when bank radio button check  end*/}
                    <Form.Group className="mb-3" controlId="formBasicFname">
                      <Form.Check // prettier-ignore
                        type="checkbox"
                        id="save"
                        checked={formData?.saveDetails}
                        onClick={(e) =>
                          handleChange({
                            target: {
                              name: "saveDetails",
                              value: !formData?.saveDetails,
                            },
                          })
                        }
                        label="Save card details"
                      />
                    </Form.Group>
                  </Form>
                </div>
                <div className="application_fees_right">
                  <Card className="amount-card">
                    <Card.Title>Amount</Card.Title>
                    <Card.Subtitle>$ {formData?.applicationFee}</Card.Subtitle>
                    <Card.Text>
                      This fee in non-refundable and will be used to process
                      your application for background checks, income
                      verification and run credit report
                    </Card.Text>
                  </Card>
                </div>
              </div>
            </div>
          </Card>
        </Row>
      </div>
    </>
  );
};

export default StepSix;
