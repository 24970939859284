import { useContext, useEffect, useState } from "react";
import AddButtonWithArrow from "../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import "./payments.css";
import EmptyData from "../common/EmptyData";
import PaymentListCard from "./PaymentListCard";
import { useNavigate } from "react-router";
import { URLS } from "../../constants/constants";
import { GetImages } from "../../utils/GetImages";
import apiHelper from "../../utils/apiHelper";
import { DateFilterTypesEnum, PaidFlowTypesEnum, PaymentService } from "@propertelligent/client-api";
import { UserLoginData } from "../common/charts/Enums";
import DateHelper from "../../utils/DateHelper";
import PaymentDetail from "./Modals/PaymentDetail";
import { PaymentContext } from "../../utils/context/PaymentContext";

const PaymentsView = () => {
  const [rev_monthValue, setRev_MonthValue] = useState(4);
  const [paymentMode, setPaymentMode] = useState("Payables");
  const [fullyQualifiedPayable, setFullyQualifiedPayable] = useState(true);
  const [fullyQualifiedReceivable, setFullyQualifiedReceivable] = useState(false);
  const [paymentCard, setPaymentCard] = useState(false);
  const [payeAccountId, setPayeAccountId] = useState<any>([]);
  const [displayData, setDisplayData] = useState([]);

  const navigate = useNavigate();

  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
  const { selectedItems, setSelectedItems } = useContext(PaymentContext);
  const [fullyQualifiedFilters, setFullyQualifiedFilter] = useState(null);
  const [monthFilter, setMonthFilter] = useState(null)

  const payeeList = () => {
    let clientDate = DateHelper.getClientDate();
    const filters = fullyQualifiedPayable
      ? `paidFlowTypeId == ${PaidFlowTypesEnum.Payable}`
      : fullyQualifiedReceivable
        ? `paidFlowTypeId == ${PaidFlowTypesEnum.Receiveable}`
        : null;
    if (monthFilter !== null) {
      apiHelper(PaymentService.getApiPaymentDueAccountOrganizationlist,
        { showNotification: false },
        orgId, undefined, undefined, undefined, filters, undefined, monthFilter, clientDate
      )
        .then((res) => {
          setPayeAccountId(res?.data.success[0]?.payeeAccountId);
          setDisplayData(res?.data.success);
        })
        .catch((err) => { });
    } else {
      apiHelper(PaymentService.getApiPaymentDueAccountOrganizationlist,
        { showNotification: false },
        orgId, undefined, undefined, undefined, filters
      )
        .then((res) => {
          setPayeAccountId(res?.data.success[0]?.payeeAccountId);
          setDisplayData(res?.data.success);
        })
        .catch((err) => { });
    }
  };

  const handlePaymentModeChange = (mode: "Payables" | "Receivables") => {
    setPaymentMode(mode);
    if (mode === "Payables") {
      setFullyQualifiedPayable(true);
      setFullyQualifiedReceivable(false);
      setDisplayData([]);
    } else {
      setFullyQualifiedPayable(false);
      setFullyQualifiedReceivable(true);
      setDisplayData([]);
    }
  };


  useEffect(() => {
    payeeList();
  }, [fullyQualifiedFilters, fullyQualifiedPayable, fullyQualifiedReceivable, monthFilter]);

  const handleCardClick = (paymentData) => {
    navigate(`/payment/settle-payment/${paymentData}`, { state: { selectedPayment: paymentData, paymentMode } });
  };

  const tenantId = localStorage.getItem("tenantId");


  const setMonthFilters = (month: any) => {
    setRev_MonthValue(month);

    const filters =
      month == 30
        ? DateFilterTypesEnum.LastMonth
        : month == 3
          ? DateFilterTypesEnum.Last3Months
          : month == 6
            ? DateFilterTypesEnum.Last6Months
            : null;
    setMonthFilter(filters);
  };

  return (
    <>
      <div className="paymentHeader border-bottom-0 d-flex flex-column">
        <div className="paymentMonthHeader">
          <div className="d-flex justify-content-between">
            <p className="top_header black"> Payment </p>
            <AddButtonWithArrow buttonname="Add Payment" onClick={() => navigate(URLS.CREATE_PAYMENT)} />
          </div>

          <div className="d-flex justify-content-between ">
            <div className="monthhead">
              <button
                onClick={() => handlePaymentModeChange("Payables")}
                className={paymentMode === "Payables" ? "overviewSelectedButton sub_text fw-500 blue" : "overviewUnselectedButton sub_text fw-500 grey_subtext1"}
              >
                Payables
              </button>
              <button
                onClick={() => handlePaymentModeChange("Receivables")}
                className={paymentMode === "Receivables" ? "overviewSelectedButton sub_text fw-500 blue" : "overviewUnselectedButton sub_text fw-500 grey_subtext1"}
              >
                Receivables
              </button>
            </div>

            <div className="monthhead">
              <p className="normal_text fw-500 grey_subtext1">Filter by due in</p>
              <button
                onClick={() => setMonthFilters(4)}
                className={rev_monthValue === 4 ? "overviewSelectedButton sub_text fw-500 blue" : "overviewUnselectedButton sub_text fw-500 grey_subtext1"}
              >
                All
              </button>
              <button
                onClick={() => setMonthFilters(30)}
                className={rev_monthValue === 30 ? "overviewSelectedButton sub_text fw-500 blue" : "overviewUnselectedButton sub_text fw-500 grey_subtext1"}
              >
                30 Days
              </button>
              <button
                onClick={() => setMonthFilters(3)}
                className={rev_monthValue === 3 ? "overviewSelectedButton sub_text fw-500 blue" : "overviewUnselectedButton sub_text fw-500 grey_subtext1"}
              >
                3 months
              </button>
              <button
                onClick={() => setMonthFilters(6)}
                className={rev_monthValue === 6 ? "overviewSelectedButton sub_text fw-500 blue" : "overviewUnselectedButton sub_text fw-500 grey_subtext1"}
              >
                6 months
              </button>
            </div>
          </div>
        </div>

        {displayData.length === 0 ? (
          <EmptyData mainText={`No Due Payments`} button={undefined} ImageSrc={GetImages.DuePayments} />
        ) : (
          <div className="listCard">
            <PaymentListCard
              data={displayData}
              tenantData={undefined}
              selectedItems={selectedItems}
              paymentMode={paymentMode}
              onCardClick={(item) => {
                setPaymentCard(true);
                setPayeAccountId(item?.payeeAccountId);
                localStorage.setItem("tenantId", item?.entityId);
              }}
              payablesData={undefined}
            />
          </div>
        )}

        {paymentCard && (
          <PaymentDetail
            onClose={() => setPaymentCard(false)}
            heading={"Payment Details"}
            show={paymentCard}
            paymentMode={paymentMode}
            payeAccountId={payeAccountId}
            handleClick={handleCardClick}
            tenantId={tenantId}
          />
        )}
      </div>
    </>
  );
};

export default PaymentsView;
