import React from "react";
import { LeaseApplicationStatusTypesEnum } from "@propertelligent/client-api";
import { Row, Col, Badge, Offcanvas,Form } from "react-bootstrap";
import { GetImages } from "../../utils/GetImages";
import ApprovedScreen from "./approved";
import RejectedScreen from "./rejected";

const HeaderTanent: React.FC<any> = ({selectOption,handleChangDropdown,showModel,setShowModel,leaseApplicationId}) => {
  return (
    <>
      <div className="application-header">
          <div className="application-sub-header">
            <Row className="justify-content-between align-items-center">
              <Col xs="auto" className="p-0">
                <h4 className="sub_heading align-items-center">
                  <img src={GetImages.folder} alt="" />
                  Lease Application Details{" "}
                  <Badge bg="primary" className="light_primary">
                    Onboarding - Application Submited
                  </Badge>
                </h4>
              </Col>
              <Col xs="auto" className="p-0">
                <Form.Select
                  id="dropdown"
                  value={selectOption}
                  onChange={(e) => handleChangDropdown(e)}
                > 
                 <option id="" value="" >
                            Action
                      </option>
                <option id="" value="Approved">
                    Approved
                  </option>
                  <option id="" value="Reminder">
                    Reminder
                  </option>
                  <option id="" value="Rejected">
                    Rejected
                  </option>
                </Form.Select>              

              </Col>
            </Row>
          </div>
          {/* canvas modal  */}

          <Offcanvas
            className="offcanvas serviceRequestForm"
            show={showModel}
            onHide={() => setShowModel(false)}
            placement={"end"}
            scroll={true}
          >
            <Offcanvas.Header className="canvasHeader" closeButton>
              <Offcanvas.Title>{selectOption} Application</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {selectOption == "Approved" ? (
                <ApprovedScreen leaseApplicationId={leaseApplicationId} status={LeaseApplicationStatusTypesEnum.Approved} setShowModel={undefined} />
              ) : selectOption === "Rejected" ? (
                <RejectedScreen   leaseApplicationId={leaseApplicationId} setShowModel={undefined}/>
              ) : selectOption === "Reminder" ? (
                <ApprovedScreen  leaseApplicationId={leaseApplicationId} status={LeaseApplicationStatusTypesEnum.Closed} setShowModel={undefined} />
              ) : (
                <p>Unknown status.</p>
              )}
            </Offcanvas.Body>
          </Offcanvas>
        </div>
    </>
  );
};

export default HeaderTanent;
