import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { GetImages } from "../../../utils/GetImages";

const Uploader = ({ handleChange, handleDelete, mainImage, setFileName, name ,Size }) => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const img = mainImage instanceof Blob ? URL.createObjectURL(mainImage) : mainImage;

  return (
    <>
      <FileUploader
        handleChange={handleChange}
        name="fileName"
        children={
          loading ? (
            <>
              <div className="dotted-box-add-tenant">
                <div className="circular-loader">
                  <div
                    className="filler"
                    style={{
                      transform: `rotate(${progress * 3.6}deg)`,
                    }}
                  />
                </div>
                <p style={{ fontSize: "10px" }} className="uploading">
                  Uploading...
                </p>
              </div>
            </>
          ) : mainImage ? (
            <div className={`dotted-box-add-tenant border-0 ${Size?.size}`}>
              <img src={img} className={`${Size?.size}`} alt="property_image" />
            </div>
          ) : (
            <div className={`dotted-box-add-tenant  ${Size?.size}`}>
              <div className="emptyImage">
                <img
                  className={Size?.iconSize}
                  src={GetImages.BlueAdd}
                  alt="add"
                />
                <div className="sub_text fw-500 blue">{name}</div>
              </div>
            </div>
          )
        }
        label="Add Property Image"
      />
    </>
  );
};

export default Uploader;
