import { GetImages } from '../../utils/GetImages';
import { Form } from 'react-bootstrap';
import CustomInputs from './Inputs';
import { FORM_CONTROLFIELD_TYPES } from '../../constants/constants';
import { FileUploader } from 'react-drag-drop-files';
import { useError } from '../../utils/context/ErrorContext';
import { TenantLeaseTypesEnum } from '@propertelligent/client-api';

const SelectLeaseAndUploader = ({
    selectedOption,
    setSelectedOption,
    values,
    setValues,
    uploadDocument,
    setUploadDocument,
    fileName,
    setFileName,
    uploadAgreement,
    setUploadAgreement,
    agreementFileName,
    setAgreementFileName,
    isShowLeaseApplicationUploader = true,
    uploadAgreementError = false,
    setUploadAgreementError = (val: any) => { }
}) => {
    const { errors, setErrors, errorMessages } = useError();

    const handleRadioChange = (value) => {
        setSelectedOption(value);
    };

    const handleStartDateChange = (e) => {
        setErrors({ ...errors, startDate: false });
        setValues({ ...values, startDate: e.target.value })
    };

    const handleEndDateChange = (e) => {
        setErrors({ ...errors, endDate: false });
        setValues({ ...values, endDate: e.target.value })
    };

    const handleImageChange = (selectedFile: File | null) => {
        setErrors({ ...errors, leaseApplication: false });

        if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
            setFileName(selectedFile?.name);
            const blob = new Blob([selectedFile]);
            setUploadDocument(blob);
            //   setMainImage(blob);
        } else {
            setFileName(selectedFile?.name);
            const blob = new Blob([selectedFile]);
            setUploadDocument(blob);
            //   setFileType("file");
            //   fetchImageBlob();
        }
    };

    //   const fetchImageBlob = async () => {
    //     try {
    //       const response = await fetch(GetImages.pdfImage);
    //       const blob = await response.blob();
    //       setMainImage(blob);
    //     } catch (error) {
    //       console.error("Error fetching image:", error);
    //     }
    //   };

    const handleUploadLeaseAgreement = (selectedFile: File | null) => {
        setUploadAgreementError(false)
        setErrors({ ...errors, leaseAgreement: false });

        if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
            setAgreementFileName(selectedFile?.name);
            const blob = new Blob([selectedFile]);
            setUploadAgreement(blob);
        } else {
            setAgreementFileName(selectedFile?.name);
            const blob = new Blob([selectedFile]);
            setUploadAgreement(blob);
        }
    }

    return (
        <>
            <div className="newTenantDesciptionContainer">
                <p className="newTenantDescriptionText sub_text black">Select Lease Type</p>
            </div>
            <div className="radioButtonContainer sub_heading d-flex">
                <div
                    onClick={() => handleRadioChange(TenantLeaseTypesEnum.FixedTermLease)}
                    className="radioButtonItem d-flex justify-content-between clickable"
                    style={{
                        outline: selectedOption === TenantLeaseTypesEnum.FixedTermLease && "1px solid #1A56DB",
                    }}
                >
                    <div className="d-flex align-items-center gap-3 ">
                        <img src={GetImages.home} alt="Home" />
                        <p className="normal_text black fw-500">Fixed Type</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <Form.Check
                            name="group1"
                            type="radio"
                            checked={selectedOption === TenantLeaseTypesEnum.FixedTermLease}
                            onChange={() => handleRadioChange(TenantLeaseTypesEnum.FixedTermLease)}
                        />
                    </div>
                </div>
                <div
                    onClick={() => handleRadioChange(TenantLeaseTypesEnum.MonthToMonth)}
                    className="radioButtonItem d-flex justify-content-between clickable"
                    style={{
                        outline: selectedOption === TenantLeaseTypesEnum.MonthToMonth && "1px solid #1A56DB",
                    }}
                >
                    <div className="d-flex align-items-center gap-3 ">
                        <img src={GetImages.home} alt="Home" />
                        <p className="normal_text black fw-500">Month to Month</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <Form.Check
                            name="group1"
                            type="radio"
                            style={{
                                outlineColor: selectedOption === TenantLeaseTypesEnum.MonthToMonth && "#1A56DB",
                            }}
                            checked={selectedOption === TenantLeaseTypesEnum.MonthToMonth}
                            onChange={() => handleRadioChange(TenantLeaseTypesEnum.MonthToMonth)}
                        />
                    </div>
                </div>
            </div>

            {selectedOption == TenantLeaseTypesEnum.FixedTermLease ? (
                // fixed selected input
                <>
                    <div className="d-flex fixedComponentContainer">
                        <div className="monthComponentItem">
                            <div>
                                <Form.Group controlId="formDate">
                                    <CustomInputs
                                        name={"securityDeposit"}
                                        labelText="Secuirty Deposit"
                                        type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                        value={values.securityDeposit}
                                        isFormControl
                                        currencySymbol="$"
                                        onChange={(e) => {
                                            setValues({ ...values, securityDeposit: e.target.value })
                                            setErrors({ ...errors, securityDeposit: false });
                                        }}
                                        placeholder="Enter Security Deposit"
                                        myClassName="containerTextField normal_text grey"
                                        isError={errors.securityDeposit}
                                        errorMessages={errorMessages.securityDeposit}
                                        height="46px"
                                        required
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="monthComponentItem">
                            <CustomInputs
                                name={"amount"}
                                labelText="Rent"
                                type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                value={values.amount}
                                isFormControl
                                currencySymbol="$"
                                onChange={(e) => {
                                    setValues({ ...values, amount: e.target.value })
                                    setErrors({ ...errors, rent: false });
                                }}
                                placeholder="Enter Rent"
                                myClassName="containerTextField normal_text grey"
                                isError={errors.rent}
                                errorMessages={errorMessages.rent}
                                height="46px"
                                required
                            />
                        </div>
                    </div>
                    <div className="d-flex fixedComponentContainer">
                        <div className="monthComponentItem">
                            <div>
                                <Form.Group controlId="formDate">
                                    <CustomInputs
                                        name={"startDate"}
                                        labelText="Start Date"
                                        type={FORM_CONTROLFIELD_TYPES.DATE}
                                        isFormControl
                                        value={values.startDate || null}
                                        onChange={(e) => handleStartDateChange(e)}
                                        placeholder="Select a Date"
                                        myClassName="containerTextField normal_text grey"
                                        isError={errors.startDate}
                                        errorMessages={errorMessages.startDate}
                                        required
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="monthComponentItem">
                            <div>
                                <Form.Group controlId="formDate">
                                    <CustomInputs
                                        name={"endDate"}
                                        labelText="End Date"
                                        type={FORM_CONTROLFIELD_TYPES.DATE}
                                        isFormControl
                                        value={values.endDate || null}
                                        onChange={(e) => handleEndDateChange(e)}
                                        placeholder="Select a Date"
                                        myClassName="containerTextField normal_text grey"
                                        isError={errors.endDate}
                                        errorMessages={errorMessages.endDate}
                                        required
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                // Month selected input
                <>
                    <div className="d-flex fixedComponentContainer">
                        <div className="monthComponentItem">
                            <div>
                                <Form.Group controlId="formDate">
                                    <CustomInputs
                                        name={"securityDeposit"}
                                        labelText="Secuirty Deposit"
                                        type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                        value={values.securityDeposit}
                                        isFormControl
                                        currencySymbol="$"
                                        onChange={(e) => {
                                            setValues({ ...values, securityDeposit: e.target.value })
                                            setErrors({ ...errors, securityDeposit: false });
                                        }}
                                        placeholder="Enter Security Deposit"
                                        myClassName="containerTextField normal_text grey"
                                        isError={errors.securityDeposit}
                                        errorMessages={errorMessages.securityDeposit}
                                        height="46px"
                                        required
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="monthComponentItem">
                            <CustomInputs
                                name={"amount"}
                                labelText="Rent"
                                type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                value={values.amount}
                                isFormControl
                                currencySymbol="$"
                                onChange={(e) => {
                                    setValues({ ...values, amount: e.target.value })
                                    setErrors({ ...errors, rent: false });
                                }}
                                placeholder="Enter Rent"
                                myClassName="containerTextField normal_text grey"
                                isError={errors.rent}
                                errorMessages={errorMessages.rent}
                                height="46px"
                                required
                            />
                        </div>
                    </div>
                    <div className="d-flex monthComponentContainer">
                        <div className="monthComponentItem">
                            <div>
                                <Form.Group controlId="formDate">
                                    <CustomInputs
                                        name={"startDate"}
                                        labelText="Select Start Date"
                                        type={FORM_CONTROLFIELD_TYPES.DATE}
                                        isFormControl
                                        value={values.startDate || null}
                                        onChange={(e) => handleStartDateChange(e)}
                                        placeholder="Select a Date"
                                        myClassName="containerTextField normal_text grey"
                                        isError={errors.startDate}
                                        errorMessages={errorMessages.startDate}
                                        required
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className="TenantDocumentUploadContainer">
                {isShowLeaseApplicationUploader &&
                    <div className="d-flex flex-column justify-content-center" style={{ width: "50%" }}>
                        <FileUploader
                            handleChange={handleImageChange}
                            classes="TenantDocumentUploader"
                            children={
                                <>
                                    <div className="fileUploaderTenant">
                                        <img src={GetImages.upload} />
                                        <p className="normal_text fw-600 black text-center">
                                            Upload Lease Application
                                        </p>
                                        <p className="sub_text grey_subtext1 text-center">
                                            Max. size 5MB. .pdf, .png, .jpg
                                        </p>
                                    </div>
                                </>
                            }
                        />
                    </div>
                }

                <div className="d-flex flex-column justify-content-center"
                    style={{ width: !isShowLeaseApplicationUploader ? "100%" : "50%" }}>
                    <FileUploader
                        handleChange={handleUploadLeaseAgreement}
                        classes="TenantDocumentUploader"
                        children={
                            <>
                                <div className="fileUploaderTenant">
                                    <img src={GetImages.upload} />
                                    <p className="normal_text fw-600 black text-center">
                                        Upload Lease Agreement
                                    </p>
                                    <p className="sub_text grey_subtext1 text-center">
                                        Max. size 5MB. .pdf, .png, .jpg
                                    </p>
                                </div>
                            </>
                        }
                    />

                    {uploadAgreementError && (
                        <div style={{ marginTop: "8px" }}>
                            <p className="errorMessageStyles">{errorMessages.leaseAgreement}</p>
                        </div>
                    )}
                </div>
            </div>
            <div className='d-flex gap-3'>
                {uploadDocument && (
                    <div className="documentPreview">
                        <img width={21} height={24} src={GetImages.pdfImage} />
                        <p className="normal_text grey_subtext1">{fileName ? fileName : "Lease Application"}</p>
                    </div>
                )}
                {uploadAgreement && (
                    <div className="documentPreview">
                        <img width={21} height={24} src={GetImages.pdfImage} />
                        <p className="normal_text grey_subtext1">{agreementFileName ? agreementFileName : "Lease Agreement"}</p>
                    </div>
                )}
            </div>
        </>
    )
}

export default SelectLeaseAndUploader