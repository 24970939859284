import Spinner from "react-bootstrap/Spinner";

function SimpleSpinner({myClassName="h-50"}) {
  return (
    <div className={`d_grid justify-content-center align-items-center mx-auto my-0 mh-100 ${myClassName}`}>
      <Spinner animation="border" />
    </div>
  );
}

export default SimpleSpinner;