import React from 'react'
import { GetImages } from '../../../utils/GetImages'
import DateHelper from '../../../utils/DateHelper'

const PaymentConfirmation = ({
    icon,
    title,
    timeLine,
    amount,
    date,
    calculateDaysRemaining,
    setShowConfirmationModal,
    formatAccountNumber,
    paymentMethodDetail,
    handleDuePayments
}) => {
    return (
        <>
            <div className='confirmationModal mt-3 mb-3'>
                <div className="firstbox">
                    <div className="CommonRoundBackground BackgroundlightRed Icon40">
                        <img src={icon} alt='' />
                    </div>

                    <div className="commonbox">
                        <p className="sub_heading black text-start">
                            {title} {" "}
                            {date ? DateHelper.formatDateonly(date) : null}
                        </p>
                        <p className="sub_text  grey_subtext1 tenant_timeline">
                            {timeLine ? `$${amount} ${calculateDaysRemaining(timeLine)}` : null}
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <p className='mb-3 px-3'>Payment Method</p>
                <ul className='confirmationModal mb-4'>
                    <li className='d-flex align-items-center justify-content-between'>
                        <div>
                            <p className='normal_text grey_subtext1'>
                                {paymentMethodDetail?.formatted?.PaymentMethodTypeId}
                            </p>
                            <p className='sub_heading text-start text-capitalize'>
                                {paymentMethodDetail?.creditCardPaymentMethod !== null ?
                                    paymentMethodDetail?.creditCardPaymentMethod.cardHolderName
                                    : paymentMethodDetail?.accountName
                                }
                            </p>
                            <p className='normal_text grey_subtext1'>
                                {
                                    paymentMethodDetail?.creditCardPaymentMethod ? (
                                        formatAccountNumber(paymentMethodDetail?.creditCardPaymentMethod?.cardNumber)
                                    ) : paymentMethodDetail?.achPaymentMethod ? (
                                        formatAccountNumber(paymentMethodDetail?.achPaymentMethod?.accountNumber)
                                    ) : null
                                }
                                <span className='ms-3 normal_text grey_subtext1'>
                                    {paymentMethodDetail?.creditCardPaymentMethod?.expMonth ?
                                        `${paymentMethodDetail?.creditCardPaymentMethod?.expMonth} / ${paymentMethodDetail?.creditCardPaymentMethod?.expYear}`
                                        : null
                                    }
                                </span>
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="editFormFooter">
                <p onClick={() => setShowConfirmationModal(false)} className="normal_text fw-600 blue clickable">
                    Cancel
                </p>
                <button
                    onClick={handleDuePayments}
                    type="submit"
                    className="saveChangesButton1 sub_text fw-600"
                >
                    Confirm
                    <img src={GetImages.WhiteForwardArrow} />
                </button>
            </div>
        </>
    )
}

export default PaymentConfirmation