
import React from "react";
import { routeList } from "../../../routes/routesList";
import { USER_ROLES } from "../../../constants/constants";
import { Route, Routes, } from "react-router-dom";

const TenantDashboard = ({ userRoleId }) => {
    return (
        <Routes>
            {routeList[USER_ROLES[userRoleId]]?.map((item) => (
                <Route
                    key={item.id} // Assign unique key here
                    path={item.path}
                    element={item.element}
                />
            ))}
        </Routes>
    );
};

export default TenantDashboard;
