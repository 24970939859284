import React, { useEffect, useRef } from "react";
import EmptyData from "../components/common/EmptyData";
import { Form } from "react-bootstrap";

const ExpenseDistributionDashboard = ({
  data,
  fin_monthValue,
  setFin_MonthValue,
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {}, [data]);

  useEffect(() => {}, [fin_monthValue]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = Math.min(centerX, centerY);

    const drawDoughnutSegment = (startAngle, endAngle, color, innerRadius) => {
      context.fillStyle = color;
      context.beginPath();
      context.arc(centerX, centerY, radius, startAngle, endAngle);
      context.arc(centerX, centerY, innerRadius, endAngle, startAngle, true);
      context.closePath();
      context.fill();
    };

    const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;

    context.clearRect(0, 0, canvas.width, canvas.height);

    const total = data?.reduce((acc, item) => acc + item.value, 0);

    const ringThickness = 30;
    const innerRadius = radius - ringThickness;

    let startAngle = 0;
    data?.forEach((item) => {
      const percentage = (item?.value / total) * 360;
      const endAngle = startAngle + degreesToRadians(percentage);
      drawDoughnutSegment(startAngle, endAngle, item?.color, innerRadius);
      startAngle = endAngle;
    });
  }, [data, fin_monthValue]);
  return (
    <div className="overviewContainerMain">
      <div className="overviewContainerRightSide">
        <div
          style={{ borderBottom: "none" }}
          className="overviewContainerRightSideTopComponent"
        >
          <p className="heading">Expense Distribution</p>
          <div className="overviewButtons">
            <Form.Select
              className="sub_text grey_subtext1"
              aria-label="Default select Service Sub Type"
              required
              onChange={(e) => {
                setFin_MonthValue(e.target.value);
              }}
              value={fin_monthValue}
            >
              <option value={-1}>Last month </option>
              <option value={-3}>Last 3 months </option>
              <option value={-6}>Last 6 months </option>
              <option value={-12}>Last 12 months </option>
            </Form.Select>
          </div>
        </div>
        <div className="justify-content-center d-flex align-items-center flex-wrap expanseDistributionDashboardSubContainer">
          <canvas
            style={{ display: data?.length === 0 && "none" }}
            ref={canvasRef}
            width={188}
            height={188}
            className="m-3"
          />
          <div className="expenseDetails1">
            {data?.length === 0 ? (
              <>
                <EmptyData
                  mainText={undefined}
                  subText="Data Not Available"
                  button={undefined}
                />
              </>
            ) : (
              data?.map((item, index) => (
                <div className="expenseItem" key={index}>
                  <div className=" d-flex flex-row align-items-center justify-content-between ">
                    <div
                      className="expenseColor "
                      style={{ background: item?.color }}
                    >
                      {" "}
                    </div>
                    <p className="sub_text grey_subtext1 ms-2"> {item?.name}</p>
                  </div>
                  <div className="expenseValue ">
                    <p className="sub_text fw-bold grey_subtext1">
                      {(
                        (item?.value /
                          data?.reduce((acc, item) => acc + item?.value, 0)) *
                        100
                      ).toFixed(2)}
                      % (${item?.value})
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseDistributionDashboard;
