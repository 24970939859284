import React from "react";
import Transactions from "../properties/components/transactions";
import { usePaginationHook } from "../../hooks/usePaginationHook";
import { PaymentService } from "@propertelligent/client-api";

const RecentFiveTransactionsComponent = ({ id }) => {
  const {
    loading,
    rows,
    rowCount,
    fetchData,
    CustomPagination,
    handleSortModelChange,
    handleFilterModelChange,
    handlePaginationModelChange,
  } = usePaginationHook({
    httpFetchDataFn: PaymentService.getApiPaymentDueAccountContractorlist,
    paginationDataMapper: null,
    parentEntityId: Number(id),
    isDependentEntity: true,
    FullyQualifiedFilters: null,
  });

  return (
    <div className=" recentTransactionComponentContainer d-flex justify-content-center align-items-center w-100 h-100 flex-column gap-2">
      <Transactions
        rows={rows}
        sorting="last-five"
        paymentMethodsArr={null}
        fetchData={fetchData}
        fetchAndFormatExpensebycategory={null}
      />
      {/* <RecentTransactionComponent />
            <RecentTransactionComponent />
            <RecentTransactionComponent />
            <RecentTransactionComponent />
            <RecentTransactionComponent /> */}
    </div>
  );
};

export default RecentFiveTransactionsComponent;
