import {
  PaymentMethodEntityTypesEnum,
  PaymentMethodTypesEnum,
  PaymentService,
} from "@propertelligent/client-api";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { UserLoginData } from "../common/charts/Enums";
import { GetImages } from "../../utils/GetImages";
import apiHelper from "../../utils/apiHelper";
import Inputs from "../common/Inputs";
import CustomInputs from "../common/Inputs";
import {
  validateAccountName,
  validateAccountNumber,
  validateCVV,
  validateCreditCard,
  validateExpiryDate,
  validateRoutingNumber,
  validateZipCode,
} from "../../utils/validateHelper";
import { useError } from "../../utils/context/ErrorContext";

const AddEditPaymentModal = ({
  setShowModal,
  mode,
  id,
  fetchData = (x) => console.log(x),
  entityTypeId = PaymentMethodEntityTypesEnum.Organization,
  entityId = Number(localStorage.getItem(UserLoginData.organization_id)),
  setShowAddAccountModal = undefined,
}) => {
  const [accountingNumber, setAccountingNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountingName, setAccountingName] = useState("");
  const [selectedManualMethod, setSelectedManualMethod] = useState("ACH");
  const [values, setValues] = useState({
    cardHolderName: "",
    cardNumber: "",
    cvvNumber: "",
    billingZipCode: "",
    expMonth: "",
  });
  const { errors, errorMessages, setErrors, setErrorMessages, clearErrors } = useError();

  const handleCreditCardChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedNumber = inputNumber.slice(0, 16); // Take the first 16 digits
    // Add spaces after every 4 digits
    const formattedWithSpaces = formattedNumber.replace(/(\d{4})/g, "$1 ");
    setValues({
      ...values,
      cardNumber: formattedWithSpaces.trim(), // Trim to remove any trailing space
    });
  };

  const handleExpiryDateChange = (event) => {
    let inputExpiryDate = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const month = inputExpiryDate.slice(0, 2);
    const year = inputExpiryDate.slice(2, 4); // Only take 2 digits for year
    // Format the input as MM/YY
    if (inputExpiryDate.length > 2) {
      inputExpiryDate = `${month}/${year}`;
    }
    setValues({ ...values, expMonth: inputExpiryDate });
    validateExpiryDate(inputExpiryDate, setErrors, setErrorMessages);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, accountingName: false });
  };

  const handleCvvChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, "");
    const formattedNumber = inputNumber.slice(0, 3);

    setValues({
      ...values,
      cvvNumber: formattedNumber.trim(),
    });
  };

  const handleBillingZipCodeChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, "");
    const formattedNumber = inputNumber.slice(0, 5);
    setValues({
      ...values,
      billingZipCode: formattedNumber.trim(),
    });
    setErrors({ ...errors, billingZipCode: false });
  };

  const submitTimeError = () => {
    let updatedError = { ...errors };

    if (selectedManualMethod === "ACH") {
      // Check for ACH specific fields
      if (accountingName.trim() === "") {
        updatedError.accountingName = true;
      }
      if (accountingNumber.trim() === "") {
        updatedError.accountingNumber = true;
      }
      if (routingNumber.trim() === "") {
        updatedError.routingNumber = true;
      }
    } else if (selectedManualMethod === "creditCard") {
      // Check for credit card specific fields
      if (values.billingZipCode.trim() === "") {
        updatedError.billingZipCode = true;
      }
      if (values.cardHolderName.trim() === "") {
        updatedError.cardHolderName = true;
      }
      if (values.cardNumber.trim() === "") {
        updatedError.cardNumber = true;
      }
      if (values.cvvNumber.trim() === "") {
        updatedError.cvvNumber = true;
      }
      if (values.expMonth.trim() === "") {
        updatedError.expMonth = true;
      }
    }

    setErrors(updatedError);
    return Object.values(updatedError).some((error) => error); // Return true if any error exists
  };

  const addPaymentHandler = (e) => {
    e.preventDefault();
    if (submitTimeError()) {
    } else {
      if (mode === "add") {
        if (selectedManualMethod === "ACH") {
          const payload = {
            achPaymentMethod: {
              accountNumber: accountingNumber,
              routingNumber: routingNumber,
            },
            accountName: accountingName,
            paymentMethodTypeId: PaymentMethodTypesEnum.ACH,
            entityTypeId: entityTypeId,
            entityId: entityId,
          };

          apiHelper(
            PaymentService.postApiPaymentMethod,
            {
              successMessage: "Payment method added successfully",
              failureMessage: "Unable to add payment",
              showNotification: true,
            },
            payload
          )
            .then((res) => {
              if (res?.data?.success) {
                fetchData(1);
                setShowModal(false);
                setShowAddAccountModal(false);
                setAccountingNumber("");
                setRoutingNumber("");
                setAccountingName("");
              }
            })
            .catch((error) => { });
        } else if (selectedManualMethod === "creditCard") {
          const creditCardPayload = {
            cardHolderName: values.cardHolderName,
            cardNumber: values.cardNumber.split(" ").join(""),
            cvvNumber: values.cvvNumber,
            billingZipCode: values.billingZipCode,
            expMonth: Number(values.expMonth.split("/")[0]),
            expYear: Number(values.expMonth.split("/")[1]),
          };

          const payload = {
            accountName: values.cardHolderName,
            paymentMethodTypeId: PaymentMethodTypesEnum.CreditCard,
            entityTypeId: entityTypeId,
            entityId: entityId,
            creditCardPaymentMethod: creditCardPayload,
          };

          apiHelper(
            PaymentService.postApiPaymentMethod,
            {
              successMessage: "Payment method added successfully",
              failureMessage: "Unable to add payment",
              showNotification: true,
            },
            payload
          )
            .then((res) => {

              if (res?.data?.success) {
                fetchData(1);
                setValues({
                  cardHolderName: "",
                  cardNumber: "",
                  cvvNumber: "",
                  billingZipCode: "",
                  expMonth: "",
                });

                setShowModal(false);
                setShowAddAccountModal(false);
              }
            })
            .catch((error) => { });
        }
      } else if (mode === "edit") {
        if (selectedManualMethod === "ACH") {
          const payload = {
            id: id,
            achPaymentMethod: {
              accountNumber: accountingNumber,
              routingNumber: routingNumber,
            },
            accountName: accountingName,
            paymentMethodTypeId: PaymentMethodTypesEnum.ACH,
            entityTypeId: entityTypeId,
            entityId: entityId,
          };

          apiHelper(
            PaymentService.putApiPaymentMethod,
            {
              successMessage: "Payment method edited successfully",
              failureMessage: "Unable to edit payment",
              showNotification: true,
            },
            payload
          )
            .then((res) => {

              if (res?.data?.success) {
                fetchData(1);
                setShowModal(false);
                setShowAddAccountModal(false);
                setAccountingNumber("");
                setRoutingNumber("");
                setAccountingName("");
              }
            })
            .catch((error) => { });
        } else if (selectedManualMethod === "creditCard") {
          const creditCardPayload = {
            cardHolderName: values.cardHolderName,
            cardNumber: values.cardNumber.split(" ").join(""),
            cvvNumber: values.cvvNumber,
            billingZipCode: values.billingZipCode,
            expMonth: Number(values.expMonth.split("/")[0]),
            expYear: Number(values.expMonth.split("/")[1]),
          };

          const payload = {
            id: id,
            accountName: values.cardHolderName,
            paymentMethodTypeId: PaymentMethodTypesEnum.CreditCard,
            entityTypeId: entityTypeId,
            entityId: entityId,
            creditCardPaymentMethod: creditCardPayload,
          };

          apiHelper(
            PaymentService.putApiPaymentMethod,
            {
              successMessage: "Payment method edited successfully",
              failureMessage: "Unable to edit payment",
              showNotification: true,
            },
            payload
          )
            .then((res) => {
              if (res?.data?.success) {
                fetchData(1);
                setValues({
                  cardHolderName: "",
                  cardNumber: "",
                  cvvNumber: "",
                  billingZipCode: "",
                  expMonth: "",
                });
                setShowModal(false);
                setShowAddAccountModal(false);
              }
            })
            .catch((error) => { });
        }
      }
    }
  };

  const getDataForEdit = async (id) => {
    const res = await apiHelper(
      PaymentService.getApiPaymentMethod,
      { showNotification: false },
      id
    );

    if (res?.data?.success?.formatted?.PaymentMethodTypeId === "ACH") {
      setSelectedManualMethod("ACH");
      setAccountingNumber(res?.data?.success?.achPaymentMethod?.accountNumber);
      setRoutingNumber(res?.data?.success?.achPaymentMethod?.routingNumber);
      setAccountingName(res?.data?.success?.accountName);
    } else if (
      res?.data?.success?.formatted?.PaymentMethodTypeId === "Credit Card"
    ) {
      setSelectedManualMethod("creditCard");
      setValues({
        cardHolderName:
          res?.data?.success?.creditCardPaymentMethod?.cardHolderName,
        cardNumber: res?.data?.success?.creditCardPaymentMethod?.cardNumber,
        cvvNumber: res?.data?.success?.creditCardPaymentMethod?.cvvNumber,
        billingZipCode:
          res?.data?.success?.creditCardPaymentMethod?.billingZipCode,
        expMonth:
          String(res?.data?.success?.creditCardPaymentMethod?.expMonth) +
          "/" +
          String(res?.data?.success?.creditCardPaymentMethod?.expYear),
      });
    }
  };

  useEffect(() => {
    if (mode === "edit" && id) {
      getDataForEdit(id);
      clearErrors();
    }
    if (mode === "add") {
      clearErrors();
    }

  }, []);

  return (
    <div>
      <div className="addEditPaymentContainer">
        {mode !== "edit" && (
          <div className="radioButtonContainer1 sub_heading">
            <p className="normal_text black mb-2">Payment method *</p>
            <div style={{ gap: 20 }} className="d-flex align-items-center">
              {selectedManualMethod === "ACH" && mode === "edit" && (
                <div
                  className={
                    selectedManualMethod === "ACH"
                      ? "selectedPaymentOption1 clickable"
                      : "nonSelectedPaymentOption1 clickable"
                  }
                  onClick={() => setSelectedManualMethod("ACH")}
                >
                  <Form.Check
                    className={
                      selectedManualMethod === "ACH"
                        ? "radioButtonSelected sub_heading fw-500"
                        : "radioButtonUnSelected sub_heading fw-normal"
                    }
                    name="group2"
                    type="radio"
                    label="ACH"
                    checked={selectedManualMethod === "ACH"}
                    onChange={() => setSelectedManualMethod("ACH")}
                  />
                </div>
              )}
              {mode === "add" && (
                <div
                  className={
                    selectedManualMethod === "ACH"
                      ? "selectedPaymentOption1 clickable"
                      : "nonSelectedPaymentOption1 clickable"
                  }
                  onClick={() => setSelectedManualMethod("ACH")}
                >
                  <Form.Check
                    className={
                      selectedManualMethod === "ACH"
                        ? "radioButtonSelected sub_heading fw-500"
                        : "radioButtonUnSelected sub_heading fw-normal"
                    }
                    name="group2"
                    type="radio"
                    label="ACH"
                    checked={selectedManualMethod === "ACH"}
                    onChange={() => setSelectedManualMethod("ACH")}
                  />
                </div>
              )}

              {selectedManualMethod === "creditCard" && mode === "edit" && (
                <div
                  className={
                    selectedManualMethod === "creditCard"
                      ? "selectedPaymentOption1"
                      : "nonSelectedPaymentOption1"
                  }
                  onClick={() => setSelectedManualMethod("creditCard")}
                >
                  <Form.Check
                    className={
                      selectedManualMethod === "creditCard"
                        ? "radioButtonSelected sub_heading fw-500 clickable"
                        : "radioButtonUnSelected sub_heading fw-normal clickable"
                    }
                    name="group2"
                    type="radio"
                    label="Credit Card"
                    checked={selectedManualMethod === "creditCard"}
                    onChange={() => setSelectedManualMethod("creditCard")}
                  />
                </div>
              )}
              {mode === "add" && (
                <div
                  className={
                    selectedManualMethod === "creditCard"
                      ? "selectedPaymentOption1"
                      : "nonSelectedPaymentOption1"
                  }
                  onClick={() => setSelectedManualMethod("creditCard")}
                >
                  <Form.Check
                    className={
                      selectedManualMethod === "creditCard"
                        ? "radioButtonSelected sub_heading fw-500 clickable"
                        : "radioButtonUnSelected sub_heading fw-normal clickable"
                    }
                    name="group2"
                    type="radio"
                    label="Credit Card"
                    checked={selectedManualMethod === "creditCard"}
                    onChange={() => setSelectedManualMethod("creditCard")}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {selectedManualMethod === "ACH" && (
          <div>
            <div
              style={{ gap: "10px", marginTop: "10px" }}
              className="d-flex flex-column flex-wrap"
            >
              {/* Name of Account */}
              <CustomInputs
                labelText="Name of Account"
                placeholder="Enter Account Name"
                name="accountingName"
                required
                value={accountingName}
                onChange={(e) => {
                  setAccountingName(e.target.value);
                  setErrors((prev) => ({ ...prev, accountingName: false })); // Clear error on change
                  setErrorMessages((prev) => ({ ...prev, accountingName: "" })); // Clear message on change
                }}
                isError={errors.accountingName}
                onBlur={() => validateAccountName(accountingName, setErrors, setErrorMessages)}
                errorMessages={errorMessages.accountingName}
                type="text"
              />

              {/* Account Number */}
              <CustomInputs
                labelText="Account Number"
                required
                placeholder="Enter Account Number"
                name="accountingNumber"
                value={accountingNumber}
                type="text" // Changed to "text" for easier control
                onChange={(e) => {
                  setAccountingNumber(e.target.value);
                  setErrors((prev) => ({ ...prev, accountingNumber: false })); // Clear error on change
                  setErrorMessages((prev) => ({
                    ...prev,
                    accountingNumber: "",
                  })); // Clear message on change
                }}
                isError={errors.accountingNumber}
                onBlur={() => validateAccountNumber(accountingNumber, setErrors, setErrorMessages)}
                errorMessages={errorMessages.accountingNumber}
                maxLength={15}
              />

              {/* Routing Number */}
              <CustomInputs
                labelText="Routing Number"
                placeholder="Enter Routing Number"
                name="routingNumber"
                required
                value={routingNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, ""); // Replace non-numeric characters
                  setRoutingNumber(value);
                  setErrors({ ...errors, routingNumber: false });
                }}
                isError={errors.routingNumber}
                onBlur={() => validateRoutingNumber(routingNumber, setErrors, setErrorMessages)}
                errorMessages={errorMessages.routingNumber}
                maxLength={9}
              />
            </div>
          </div>
        )}
        {selectedManualMethod === "creditCard" && (
          <div>
            <div className="d-flex gap-3 flex-wrap">
              <CustomInputs
                required
                labelText="Name of Account"
                placeholder="Enter Account Name"
                name="cardHolderName"
                value={values.cardHolderName}
                onChange={(e) => {
                  handleChange(e);
                  setErrors({ ...errors, cardHolderName: false });
                  setErrorMessages((prev) => ({ ...prev, cardHolderName: "" }));
                }}
                onBlur={() => validateAccountName(values.cardHolderName, setErrors, setErrorMessages)}
                isError={errors.cardHolderName}
                errorMessages="Account name  is required"
                isFormControl={false}
              />

              <CustomInputs
                required
                labelText="Credit Card Number"
                placeholder="Enter Credit Card Number"
                name="cardNumber"
                value={values.cardNumber}
                onChange={(e) => {
                  handleCreditCardChange(e);
                  setErrors({ ...errors, cardNumber: false });
                  setErrorMessages({ ...errorMessages, cardNumber: "" });
                }}
                onBlur={() => validateCreditCard(values.cardNumber, setErrors, setErrorMessages)}
                isError={errors.cardNumber}
                errorMessages={errorMessages.cardNumber}
                maxLength={19}
              />
            </div>

            <div className="d-flex gap-3 mt-3 flex-wrap">
              <CustomInputs
                required
                labelText="Billing Zip Code"
                placeholder="Enter Zip Code"
                name="billingZipCode"
                value={values.billingZipCode}
                onChange={(e) => {
                  handleBillingZipCodeChange(e);
                  setErrors((prev) => ({ ...prev, billingZipCode: false }));
                }}
                onBlur={() =>
                  validateZipCode(
                    values.billingZipCode,
                    setErrors,
                    setErrorMessages
                  )
                }
                isError={errors.billingZipCode}
                errorMessages={errorMessages.billingZipCode}
                maxLength={5}
              />

              <CustomInputs
                required
                labelText="Expires"
                placeholder="12/24"
                name="expMonth"
                value={values.expMonth}
                onChange={handleExpiryDateChange}
                onBlur={() =>
                  validateExpiryDate(
                    values.expMonth,
                    setErrors,
                    setErrorMessages
                  )
                }
                isError={errors.expMonth}
                errorMessages={errorMessages.expMonth}
                maxLength={5}
              />

              <CustomInputs
                required
                labelText="CVV"
                placeholder="123"
                name="cvvNumber"
                value={values.cvvNumber}
                onChange={(e) => {
                  handleCvvChange(e);
                  setErrors((prev) => ({ ...prev, cvvNumber: false }));
                  setErrorMessages((prev) => ({ ...prev, cvvNumber: "" })); // Clear error message on change
                }}
                onBlur={() =>
                  validateCVV(values.cvvNumber, setErrors, setErrorMessages)
                }
                isError={errors.cvvNumber}
                errorMessages={errorMessages.cvvNumber}
                maxLength={3}
              />
            </div>
          </div>
        )}
      </div>

      <div className="editFormFooter">
        <p onClick={() => setShowModal(false)} className="normal_text fw-600 blue clickable">
          Cancel
        </p>
        <button
          onClick={addPaymentHandler}
          type="submit"
          className="saveChangesButton1 sub_text fw-600"
        >
          {mode === "add" ? "Add Payment Account" : "Edit Payment Account"}
          <img src={GetImages.WhiteForwardArrow} />
        </button>
      </div>
    </div>
  );
};

export default AddEditPaymentModal;
