import { ChangeEvent, useEffect, useState } from "react";
import EditCustomComponent from "./EditCustomComponent";
import { TenantService } from "@propertelligent/client-api";
import { GetImages } from "../../utils/GetImages";
import apiHelper from "../../utils/apiHelper";
import { useError } from "../../utils/context/ErrorContext";
import { validateEmail, validateFirstName, validateLastName, validateNumber } from "../../utils/validateHelper";

const EditTenantForm = ({ id, setShowModal }) => {
  const { errors, setErrors, setErrorMessages, errorMessages, clearErrors } = useError();

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    unitId: null,
    userId: null,
    id: null,
  });


  const checkEmptyFields = (): void => {
    clearErrors();
    if (values.email.trim() === "") {
      setErrors({ ...errors, email: true });
    }

    if (values.firstName.trim() === "") {
      setErrors({ ...errors, firstName: true });
    }

    if (values.lastName.trim() === "") {
      setErrors({ ...errors, lastName: true });
    }

    if (values.phoneNumber.trim() === "") {
      setErrors({ ...errors, phoneNumber: true });
    }
  };

  const submitTimeError = () => {
    return (
      errors.firstName ||
      errors.lastName ||
      errors.email ||
      errors.phoneNumber ||
      values.firstName.trim() === "" ||
      values.lastName.trim() === "" ||
      values.phoneNumber.trim() === "" ||
      values.email.trim() === ""
    );
  };

  useEffect(() => {
    getSingleTenantData();
    return () => {
      clearErrors();
    };
  }, []);

  const getSingleTenantData = () => {
    apiHelper(TenantService.getApiTenant1, { showNotification: false }, id)
      .then((res) => {
        setValues({
          ...values,
          firstName: res?.data?.success?.firstName,
          lastName: res?.data?.success?.lastName,
          email: res?.data?.success?.email,
          phoneNumber: res?.data?.success?.phoneNumber,
          unitId: res?.data?.success?.unitId,
          userId: res?.data?.success?.userId,
          id: Number(id),
        });
      })
      .catch((error) => { });
  };


  const formDetails = [
    {
      label: "First Name",
      placeholder: "Enter First Name",
      value: values.firstName,
      name: "firstName",
      blurFunction: () => validateFirstName(values.firstName, setErrors),
      errorMessages: errorMessages.firstName,
      isError: errors.firstName,
      required: true
    },
    {
      label: "Last Name",
      placeholder: "Enter Last Name",
      value: values.lastName,
      name: "lastName",
      blurFunction: () => validateLastName(values.lastName, setErrors),
      errorMessages: errorMessages.lastName,
      isError: errors.lastName,
      required: true
    },
    {
      label: "Email Address",
      placeholder: "Enter Email Address",
      value: values.email,
      name: "email",
      blurFunction: () => validateEmail(values.email, setErrors, setErrorMessages),
      errorMessages: errorMessages.email,
      isError: errors.email,
      required: true
    },
    {
      label: "Phone Number",
      placeholder: "Enter Phone No.",
      value: values.phoneNumber,
      name: "phoneNumber",
      blurFunction: () => validateNumber(values.phoneNumber, setErrors),
      errorMessages: errorMessages.phoneNumber,
      isError: errors.phoneNumber,
      required: true
    },
  ];

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setErrors({ ...errors, email: false });
        setErrorMessages({ ...errorMessages, email: "Email is required" });
        break;
      case "firstName":
        setErrors({ ...errors, firstName: false });
        setErrorMessages({
          ...errorMessages,
          firstName: "First Name is required",
        });
        break;
      case "lastName":
        setErrors({ ...errors, lastName: false });
        setErrorMessages({
          ...errorMessages,
          lastName: "Last Name is required",
        });
        break;
      case "phoneNumber":
        setErrors({ ...errors, phoneNumber: false });
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "Phone Number is required",
        });
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const editSubmitHandler = (e) => {
    e.preventDefault();
    checkEmptyFields();

    if (submitTimeError()) {
    } else {
      apiHelper(
        TenantService.putApiTenant,
        {
          successMessage: "Tenant updated successfully",
          failureMessage: "Unable to update tenant",
          showNotification: true,
        },
        values
      )
        .then((res) => {
          setValues({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            unitId: null,
            userId: null,
            id: null,
          });
          setShowModal(false);
        })
        .catch((error) => { });
    }
  };

  // useEffect(() =>{
  // if(checkEmptyFields()){
  //   clearErrors();
  // }
  // },[])


  return (
    <div>
      <form onSubmit={editSubmitHandler}>
        <div className="editTenantFormContainer">
          {formDetails.map((item) => (
            <EditCustomComponent
              key={item.label}
              label={item.label}
              placeholder={item.placeholder}
              value={item.value}
              name={item.name}
              onChangeFunction={handleChange}
              onBlurFunction={item.blurFunction}
              errorMessages={item.errorMessages}
              isError={item.isError}
              required={item.required}
            />
          ))}
        </div>

        <div className="editFormFooter">
          <p
            onClick={() => setShowModal(false)}
            className="normal_text fw-600 blue clickable"
          >
            Cancel
          </p>
          <button type="submit" className="saveEditTenantButton sub_text fw-600 white">
            Save Changes <img src={GetImages.WhiteForwardArrow} />
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditTenantForm;


