import { useEffect, useRef, useState } from "react";
import { CommunicationService, MessageEntityTypesEnum } from "@propertelligent/client-api";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import NameAvatarComponent from "../../tenants/NameAvatarComponent";
import { UserLoginData } from "../../common/charts/Enums";
import DateHelper from "../../../utils/DateHelper";
import { GetImages } from "../../../utils/GetImages";
import AddButton from "../../firstTimeUserExperience/commonCom/AddButton";
import EmptyData from "../../common/EmptyData";
import apiHelper from "../../../utils/apiHelper";
import CustomInputs from "../../common/Inputs";

const ChatBox = ({
  entityId,
  isMobileView = false,
  showMessage = false,
  entityTypeId,
  setShowMessage,
  setShowSection = undefined,
  chatname,
  setChatName,
  fetchData,
  setEntityId,
  setEntityTypeId,
  setmessageData = undefined,
  messageData = undefined,
  tenantList = undefined,
  tenantId,
  messageChannel = undefined,
  firstName,
  lastName,
}) => {
  const [chatdata, setChatdata] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [listdata, setList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const scrollDown = useRef(null);

  const name = chatname?.split(/\s+/).filter(Boolean) || [];

  useEffect(() => {
    fetchMessages();
    if (inputValue == "") {
      setSelectedItem(null);
    }
  }, [inputValue]);

  const fetchMessages = () => {
    if (entityTypeId == MessageEntityTypesEnum.Tenant) {
      apiHelper(
        CommunicationService.getApiMessageTenantlist,
        { showNotification: false },
        entityId
      )
        .then((res) => {
          setChatdata(res?.data?.success);
        })
        .catch((error) => { });
    } else if (entityTypeId == MessageEntityTypesEnum.Contractor) {
      apiHelper(
        CommunicationService.getApiMessageContractorlist,
        { showNotification: false },
        entityId
      )
        .then((res) => {
          setChatdata(res?.data?.success);
        })
        .catch((error) => { });
    }
  };

  useEffect(() => {
    setmessageData({
      ...messageData,
      messageData: "",
      entityTypeId: entityTypeId,
      entityId: entityId,
    });
    scrollDown?.current?.scrollIntoView();
    fetchMessages();
  }, [
    entityId,
    entityTypeId,
    chatname,
    chatdata?.length,
    messageData?.entityId,
  ]);

  const sendMessage = () => {
    if (messageData.messageData !== "") {
      apiHelper(CommunicationService.postApiMessage, { showNotification: false }, messageData)
        .then((res) => {
          if (res?.data?.success) {
            setmessageData({
              ...messageData,
              messageData: "",
              entityTypeId: entityTypeId,
              entityId: entityId,
            });
            fetchMessages();
            fetchData();
          }
        })
        .catch((error) => { });
    }
  };

  const resetSearch = () => {
    {
      setList(tenantList?.map((item) => item.name));
    }
  };

  const checkMatch = () => {
    if (inputValue !== "") {
      return tenantList?.map((item, index) => {
        if (
          item?.name.toLowerCase().includes(inputValue.toLowerCase()) &&
          selectedItem?.name !== item.name
        ) {
          return (
            <li
              key={index}
              className={`lists showMe`}
              onClick={() => complete(item)}
            >
              {item.name}
            </li>
          );
        } else {
          return null;
        }
      });
    }
  };
  const complete = (value) => {
    setEntityId(value.id);
    setInputValue(value.name);
    setChatName(value.name);

    setInputValue("");
    setSelectedItem(value);
  };

  const handleGoBackClick = () => {
    setShowMessage(false);
  };

  return (
    <>
      {entityId == null ? (
        <div className="chatboxContainer">
          <div className="chatboxtop">
            <div className="selectTanent">
              <div className="box-container">
                <input
                  type="text"
                  id="userName"
                  placeholder="Select tenant"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onFocus={resetSearch}
                />
                {/* <CustomInputs
                  type="text"
                  // id="userName"
                  placeholder="Select Tenant"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onFocus={resetSearch}
                  id="customInputBorder"
                /> */}
              </div>
            </div>

            {selectedItem?.name || inputValue === "" ? (
              ""
            ) : (
              <ul className="selectedChat">{checkMatch()}</ul>
            )}
          </div>
          <div className="chatboxbottom">
            {/* <input
              type="text"
              name="messageData"
              value={messageData.messageData}
              onChange={(e) =>
                setmessageData({ ...messageData, messageData: e.target.value })
              }
              placeholder="Type your message here...."
              className="serchInput"
            /> */}
            <CustomInputs
              type="text"
              name="messageData"
              value={messageData.messageData}
              onChange={(e) =>
                setmessageData({ ...messageData, messageData: e.target.value })
              }
              placeholder="Type your message here...."
              myClassName="serchInput"
              id="customInputBorder"
            />
            <AddTraspButton
              bname="Send"
              iconPosition={undefined}
              imageValue={undefined}
              onClick={sendMessage}
              border={false}
            />
          </div>
        </div>
      ) : (
        <div className="chatboxContainer">
          <div className="d-flex w-100 align-items-center pb-2">
            {isMobileView && (
              <div className="GoBackChat" onClick={handleGoBackClick}>
                <img src={GetImages.BlackChevronRight} alt="Go Back" />
              </div>
            )}
          </div>

          {tenantId && entityId ? (
            <div className="chatItemContaner " style={{ overflowX: "hidden" }}>
              {messageChannel && messageChannel?.length == 0 ? (
                <EmptyData
                  mainText="No Message Here"
                  subText="Please click the button below to start the conversation"
                  button={
                    <AddButton onClick={undefined} bname="Start Conversation" />
                  }
                />
              ) : (
                messageChannel?.map((item, i) => {
                  const name = item?.name?.split(" ");
                  return (
                    <div
                      className="chatItem "
                      style={{
                        background:
                          item.entityId === entityId
                            ? "var(--Primary-Next-level-Lighter, #EBF5FF)"
                            : "",
                      }}
                      key={i}
                      onClick={() => {
                        setEntityId(item.entityId);
                        setEntityTypeId(item?.entityTypeId);
                        setChatName(item?.name);
                        setShowSection("chat");
                        fetchData(true);
                      }}
                    >
                      <div className="userIcon">
                        <NameAvatarComponent
                          firstName={name[0]}
                          lastName={name[1]}
                          heightWidth={40}
                        />
                      </div>

                      <div className="chatDetailsView">
                        <div className=" d-flex flex-row justify-content-between align-items-center w-100">
                          <p className="normal_text black ">{item?.name}</p>
                          <p className="sub_text  grey_subtext1">
                            {DateHelper.convertUTCtoDateTime(
                              item?.lastMessageDateTime,
                              true
                            )}
                          </p>
                        </div>
                        <div className="message_count sub_text d-flex justify-content-between align-items-center w-100 ">
                          <p
                            className="sub_text  grey_subtext1"
                            id="message-data"
                          >
                            {item?.lastMessageData}
                          </p>
                          {item.unreadMessagesCount > 0 ? (
                            <div className="meesageCount">
                              {" "}
                              <p className="small_text white">
                                {item.unreadMessagesCount}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          ) : (
            ""
          )}

          <div className="chatDetails">
            {tenantId ? (
              <>
                <NameAvatarComponent
                  firstName={firstName.split(" ")[0]}
                  lastName={lastName.split(" ")[0]}
                  heightWidth={40}
                />
                <p className="sub_heading black text-start">
                  {firstName}
                  {lastName}
                </p>
              </>
            ) : (
              <>
                <NameAvatarComponent
                  firstName={name[0] || ""}
                  lastName={name[1] || ""}
                  heightWidth={40}
                />
                <p className="sub_heading black text-start">{chatname}</p>
              </>
            )}
          </div>
          <div className="messageSection">
            {chatdata?.map((item) => (
              <>
                <div
                  key={item.id}
                  className={`${item.createdById ===
                    parseInt(localStorage.getItem(UserLoginData.user_id))
                    ? "outgoingMessage"
                    : "incomingMessage"
                    }`}
                >
                  <div className="chatDp">
                    {item.createdById ===
                      parseInt(localStorage.getItem(UserLoginData.user_id)) ? (
                      <NameAvatarComponent
                        firstName={firstName}
                        lastName={lastName}
                        heightWidth={40}
                        fromTopBar={false}
                        fontSize="16px"
                        color="white"
                        redBorder={true}
                        backgroundColor="#dc3545"
                      />
                    ) : (
                      <NameAvatarComponent
                        firstName={name[0]}
                        lastName={name[1]}
                        heightWidth={40}
                      />
                    )}
                  </div>

                  <div className="chatMessage">
                    <p className="sub_text black receiver">
                      {item?.messageData}
                    </p>
                    <p className="sub_text grey_subtext1 received-date">
                      {DateHelper.convertUTCtoDateTime(item?.created, true)}
                    </p>
                  </div>
                </div>
                <div ref={scrollDown} />
              </>
            ))}
          </div>

          <div className="chatboxbottom">
            {/* <input
              type="text"
              name="messageData"
              value={messageData.messageData}
              onChange={(e) =>
                setmessageData({ ...messageData, messageData: e.target.value })
              }
              placeholder="Type your message here...."
              className="serchInput"
            /> */}
            <CustomInputs
              type="text"
              name="messageData"
              value={messageData.messageData}
              onChange={(e) =>
                setmessageData({ ...messageData, messageData: e.target.value })
              }
              placeholder="Type your message here...."
              myClassName="serchInput"
              id="customInputBorder"
            />
            <AddTraspButton
              bname="Send"
              iconPosition={undefined}
              imageValue={undefined}
              onClick={sendMessage}
              border={false}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChatBox;
