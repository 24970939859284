import CustomInputs from "../../components/common/Inputs";
import { GetImages } from "../../utils/GetImages";

const AccountList = ({
  title = undefined,
  accountList,
  formatAccountNumber,
  handleSelectedAccount,
  selectedAccountList,
  radioBtn,
  icon,
  onClick,
  setId,
  setShowConfirmation,
  setPaymentMethod,
}) => {
  return (
    <>
      {accountList.length > 0 && (
        <>
          <ul className="mb-4 ps-0">
            {accountList
              .filter(
                (account) =>
                  account.formatted?.PaymentMethodTypeId === "ACH" ||
                  account.formatted?.PaymentMethodTypeId === "Credit Card"
              )
              .map((account: any) => (
                <li
                  key={account.id}
                  className={`d-flex align-items-center justify-content-between ${
                    selectedAccountList === account.id ? "selected" : ""
                  }`}
                >
                  {radioBtn ? (
                    <label
                      htmlFor={`radio-${account.id}`}
                      className="d-flex align-items-center w-100 mb-0"
                    >
                      <img src={icon} alt="ach" className="me-3" />
                      <div>
                        <p className="normal_text grey_subtext1">
                          {account.formatted?.PaymentMethodTypeId}
                        </p>
                        <p className="sub_heading text-start text-capitalize">
                          {account.creditCardPaymentMethod
                            ? account.creditCardPaymentMethod?.cardHolderName
                            : account.accountName
                            ? account.accountName
                            : null}
                        </p>
                        <p className="normal_text grey_subtext1">
                          {account.achPaymentMethod
                            ? formatAccountNumber(
                                account.achPaymentMethod?.accountNumber
                              )
                            : account.creditCardPaymentMethod
                            ? formatAccountNumber(
                                account.creditCardPaymentMethod?.cardNumber
                              )
                            : null}
                          {account.creditCardPaymentMethod ? (
                            <span className="ms-3 normal_text grey_subtext1">
                              {account.creditCardPaymentMethod?.expMonth} /{" "}
                              {account.creditCardPaymentMethod?.expYear}
                            </span>
                          ) : null}
                        </p>
                      </div>
                      <CustomInputs
                        id={`radio-${account.id}`}
                        name="achAccount"
                        value={account.id}
                        checked={selectedAccountList === account.id}
                        onChange={() => handleSelectedAccount(account.id)}
                        isRadio={true}
                      />

                    </label>
                  ) : (
                    <div className="d-flex align-items-center w-100 mb-0">
                      <img src={icon} alt="ach" className="me-3" />
                      <div>
                        <p className="normal_text grey_subtext1">
                          {account.formatted?.PaymentMethodTypeId}
                        </p>
                        <p className="sub_heading text-start text-capitalize">
                          {account.creditCardPaymentMethod
                            ? account.creditCardPaymentMethod?.cardHolderName
                            : account.accountName
                            ? account.accountName
                            : null}
                        </p>
                        <p className="normal_text grey_subtext1">
                          {account.achPaymentMethod
                            ? formatAccountNumber(
                                account.achPaymentMethod?.accountNumber
                              )
                            : account.creditCardPaymentMethod
                            ? formatAccountNumber(
                                account.creditCardPaymentMethod?.cardNumber
                              )
                            : null}
                          {account.creditCardPaymentMethod ? (
                            <span className="ms-3 normal_text grey_subtext1">
                              {account.creditCardPaymentMethod?.expMonth} /{" "}
                              {account.creditCardPaymentMethod?.expYear}
                            </span>
                          ) : null}
                        </p>
                      </div>
                      <img
                        className="clickable"
                        src={GetImages.greyEdit}
                        alt=""
                        style={{ width: "16px", marginRight: "20px" }}
                        onClick={() => onClick(account.id)}
                      />
                      <img
                        className="clickable"
                        src={GetImages.greyDelete}
                        alt=""
                        style={{ width: "16px", marginRight: "20px" }}
                        onClick={() => {
                          setId(account.id);
                          setShowConfirmation(true);
                          account?.formatted?.PaymentMethodTypeId === "ACH" &&
                            setPaymentMethod("ACH");
                          account?.formatted?.PaymentMethodTypeId ===
                            "Credit Card" && setPaymentMethod("Credit Card");
                        }}
                      />
                    </div>
                  )}
                </li>
              ))}
          </ul>
        </>
      )}
    </>
  );
};

export default AccountList;
