import AddTraspButton from '../../../components/firstTimeUserExperience/commonCom/AddTraspButton'
import { GetImages } from '../../../utils/GetImages'

const ComponentHeading = ({ title, button, onClick }) => {
    
    return (
        <div className="tenantsTopBar">
            <div>
                <p className="top_header black">{title}</p>
            </div>
            {button ? (
                <AddTraspButton
                    bname="Add Payment Method"
                    iconPosition="right"
                    imageValue={GetImages.BlueAdd}
                    onClick={onClick}
                    border={true}
                />
            ) : null}
        </div>
    )
}

export default ComponentHeading