import React from "react";
import Logo from "./Logo";
import CustomDropDownMenu from "../components/common/CustomDropDownMenu";
import { GetImages } from "../utils/GetImages";
import UserName from "./UserName";
import { useMenu } from "../utils/context/MenuContext";
import Localizer from "../utils/i18nConfig";
import BlackEmailIcon from "../assets/blackemailicon.png";
import BlackChatIcon from "../assets/blackchaticon.png";
import "./appBar.css";
import { USER_ROLES, USERS } from "../constants/constants";


const CustomTopBar: React.FC<{ size: number, userRoleId: string, showUserName: boolean }> = ({ size, userRoleId, showUserName = true }) => {
  const { isHidden, setIsHidden, setIsOpen } = useMenu();

  const data = [
    {
      id: 1,
      img: BlackEmailIcon,
      name: `${Localizer.getLocalizedString("email")}`,
      linkpath: "/messaging/email",
    },
    {
      id: 2,
      img: BlackChatIcon,
      name: `${Localizer.getLocalizedString("chat")}`,
      linkpath: "/messaging/chat",
    },
  ];

  return (
    <div
      style={{
        width: "100%",
        height: 48,
        position: "fixed",
        top: 0,
        zIndex: size > 400 ? 100 : 50,
        background: "#233876",
      }}
      className="appBarMainBox"
    >
      <div>
        {size < 400 ? (
          <div className="d-flex align-items-center justify-content-center p-3 ">
            <img
              onClick={() => {
                setIsHidden(false);
                setIsOpen(true);
              }}
              className="pe-3"
              src={GetImages.alreadyCollapsed}
            />
            <img src={GetImages.leftSideLogoSmall} />
            <img src={GetImages.rightSideLogoSmall} />
          </div>
        ) : (
          <Logo />
        )}
      </div>
      <div className="rightSideButtons ">
        {/* <DemoModeButton /> */}
        {USERS.USER === USER_ROLES[userRoleId] && (
          <CustomDropDownMenu
            data={data}
            Element={
              <img
                src={GetImages.mailGrey}
                width={24}
                height={24}
                alt="Email Icon"
              />
            }
          />
        )}

        {/* <EmailMenu /> */}
        {/* <img src={GetImages.notificatioIcon} width={24} height={24} /> */}
        {/* <div className="seperator1"></div> */}
        {showUserName && <UserName size={size} userRoleId={userRoleId} />}
      </div>
    </div>
  );
};

export default CustomTopBar;
