import { Link } from 'react-router-dom'
import "./tenantCards.css"
import DateHelper from '../../../utils/DateHelper';


const TenantCards = ({ title, icon, userName, phoneNumber, leaseType, background, leaseStartDate, leaseEndDate, amount, border, borderRadius }) => {
    return (
        <div className="tenantCards">
            <div className="tenant-topSectionComponent">
                <div className="tenant-upperComponent pt-2" style={{ backgroundColor: background, border: border, borderRadius: borderRadius }}>
                    <div className="rightSideTextComponent d-flex flex-column justify-content-between">
                        <p className="sub_heading fw-500 black mb-2">
                            {title}
                        </p>
                        <p className="normal_text fw-600 grey_subtext1 d-flex align-items-center">
                            {icon ? (
                                <img width={14} height={17} src={icon} alt='' className='me-2' />
                            ) : null
                            }
                            {
                                userName ? (
                                    <>
                                        {userName} -
                                        <Link to="#" className='text-decoration-none ms-1'>
                                            {phoneNumber ? `(+1) ${phoneNumber}` : null}
                                        </Link>
                                    </>
                                )
                                    : leaseStartDate && leaseEndDate ? `${DateHelper.formatDateonly(leaseStartDate)} - ${DateHelper.formatDateonly(leaseEndDate)}`
                                        : leaseEndDate ? DateHelper.formatDateonly(leaseEndDate)
                                            : leaseType ? leaseType
                                                : amount ? `$${amount} Per month`
                                                    : "NA"
                            }
                        </p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default TenantCards