import { DateFilterTypesEnum } from '@propertelligent/client-api';
import getUserLocale from 'get-user-locale';

class DateHelper {
  userLocale = getUserLocale();

  defaultFormat: string;
  constructor() {
    this.defaultFormat = 'yyyy/MM/dd HH:mm a';
  }

  /**
   * Input  : dd-mm-yyyy  
   * Output : yyyy/mm/dd
  */
  formatDOBString = (dateTime: string) => {
    const dateValue = dateTime.split('-');

    return `${dateValue[1]}/${dateValue[2]}/${dateValue[0]}`;
  };//End of method

  /**
   * Input  : yyyy-mm-ddThh:mm:ss. SSSZ  
   * Output : en-GB --> dd/mm/yyyy, hh:mm:ss a ||
   * Output : en-US --> mm/dd/yyyy, hh:mm:ss a
  */
  convertUTCtoDateTime = (utcDateTime: string, showTime: boolean = false): string | null => {
    if (utcDateTime) {
      const utcDate = new Date(utcDateTime);

      // Format the date to local datetime string
      const localDateTimeString = showTime ? new Intl.DateTimeFormat(this.userLocale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
        // timeZone: 'America/New_York', // Change this to your desired time zone
        // timeZoneName: 'short'
      }).format(utcDate) : new Intl.DateTimeFormat(this.userLocale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour12: true,
        // timeZone: 'America/New_York', // Change this to your desired time zone
        // timeZoneName: 'short'
      }).format(utcDate);
      return localDateTimeString;
    }
    else return '--';
  }; //End of method

  /**
   * Input  : yyyy-mm-ddThh:mm:ss. SSSZ  
   * Output : en-GB --> 2 Apr 2024, 8:17 pm ||
   * Output : en-US --> Apr 2, 2024, 8:17 PM
  */
  convertUTCtoDateTimeSimple = (utcDateTime: string, showTime: boolean = false,): string | null => {
    if (utcDateTime) {
      const dateObject = new Date(utcDateTime);

      const formattedDate = showTime ? dateObject.toLocaleString(this.userLocale, {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }) : dateObject.toLocaleString(this.userLocale, {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      return formattedDate;
    }
    else return '--';
  }; //End of method


  convertUTCtoDateTime2 = (utcDateTime: string, showTime: boolean = false): string | null => {
    if (utcDateTime) {
      const utcDate = new Date(utcDateTime);

      // Get the day, month, and year
      const day = utcDate.getDate().toString().padStart(2, '0'); // Ensure 2 digits
      const month = (utcDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
      const year = utcDate.getFullYear();

      // Format the date as dd-mm-yyyy
      const formattedDate = `${year}-${month}-${day}`;

      if (showTime) {
        // If time is needed, format the time as well
        const hours = utcDate.getHours().toString().padStart(2, '0');
        const minutes = utcDate.getMinutes().toString().padStart(2, '0');
        const seconds = utcDate.getSeconds().toString().padStart(2, '0');
        const time = `${hours}:${minutes}:${seconds}`;

        return `${formattedDate} ${time}`;
      } else {
        return formattedDate;
      }
    } else {
      return '--';
    }
  };




  formatDateonly = (dateString: any) => {

    if (!dateString) {
      return ''; // Handle undefined or empty date string
    }

    let date: Date;

    try {
      // Check if the date string is in ISO format
      if (dateString.includes("T")) {
        date = new Date(dateString);
      } else {
        // Otherwise, manually parse the date string
        const parts = dateString.split(" ");
        const dateParts = parts[0].split("/");
        const timeParts = parts[1]?.split(":") || ["00", "00"]; // Default to "00:00" if time is missing

        if (dateParts.length !== 3 || timeParts.length !== 2) {
          throw new Error('Invalid date format');
        }

        const month = parseInt(dateParts[0], 10);
        const day = parseInt(dateParts[1], 10);
        const year = parseInt(dateParts[2], 10);
        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);

        date = new Date(year, month - 1, day, hours, minutes);
      }

      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }

      // Formatting the date
      const monthName = date.toLocaleString("default", { month: "short" });
      const day = date.getDate();
      const year = date.getFullYear();

      const formattedDate = `${monthName} ${day}, ${year}`;

      // Formatting the time
      const formattedTime = date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      });

      return `${formattedDate}`;
    } catch (error) {
      console.error('Error parsing date:', error);
      return ''; // Handle error case as needed
    }
  };

  getClientDate = () => {
    return (new Date()).toISOString()
  }

  getDateFilterType = (predefinedFilter: string) => {
    switch (predefinedFilter) {
      case "Last 7 Days":
        return DateFilterTypesEnum.Last7Days;

      case "Last 14 Days":
        return DateFilterTypesEnum.Last14Days;

      case "Last Month":
        return DateFilterTypesEnum.LastMonth;

      case "Last 3 Months":
        return DateFilterTypesEnum.Last3Months;

      case "Last 6 Months":
        return DateFilterTypesEnum.Last6Months;

      case "Last Year":
        return DateFilterTypesEnum.LastYear;

      default:
        return DateFilterTypesEnum.Custom
    }
  }

}

export default new DateHelper();
