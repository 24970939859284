import EditCustomComponent from '../../../tenants/EditCustomComponent';

const OfficeAddressForm = ({ formDetails, handleChange }) => {
    return (
        <div className="office-address-form">
            <div className="d-flex justify-content-between addContractorInputContainer1">
                {formDetails.slice(0, 2).map((item: any) => (
                    <EditCustomComponent
                        key={item.label}
                        label={item.label}
                        placeholder={item.placeholder}
                        value={item.value}
                        name={item.name}
                        onChangeFunction={handleChange}
                        onBlurFunction={item.blurFunction}
                        errorMessages={item.errorMessages}
                        isError={item.isError}
                        linkWebsite={item.linkWebsite}
                        width={item.width}
                        borderRadius="4px"
                        type={item.type}
                        required={item.required}
                    />
                ))}
            </div>

            <div className="d-flex mt-2 addContractorInputContainer1">
                {formDetails.slice(2).map((item) => (
                    <EditCustomComponent
                        key={item.label}
                        label={item.label}
                        placeholder={item.placeholder}
                        value={item.value}
                        name={item.name}
                        onChangeFunction={handleChange}
                        onBlurFunction={item.blurFunction}
                        errorMessages={item.errorMessages}
                        isError={item.isError}
                        linkWebsite={item.linkWebsite}
                        width={item.width}
                        borderRadius="4px"
                        type={item.type}
                        maxLength={item.maxLength}
                        required={item.required}
                    />
                ))}
            </div>
        </div>
    )
}

export default OfficeAddressForm