import React, { useState } from "react";
import "./mainDashboardStyles.css";
import "../../components/firstTimeUserExperience/commonCom/CommonStyle.css";
import Welcome from "./Welcome";
import { Route, Routes } from "react-router";
import SelectProperty from "./AddProperty";
import AddUnitTanent from "./AddUnitTanent";
import AddPlaidAccount from "./AddPlaidAccount";

export const FirstTimeDashboard = ({ userRoleId = 1 }) => {
  const [ptyTypeId, setPtyTypeId] = useState();

  return (
    <>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route
          path="/addProperty"
          element={
            <SelectProperty
              ptyTypeId={ptyTypeId}
              setPtyTypeId={setPtyTypeId}
              navigateto="/addunittanent"
            />
          }
        />
        <Route
          path="/addunittanent"
          element={<AddUnitTanent navigateto={"/addplaidaccount"} />}
        />
        <Route path="/addplaidaccount" element={<AddPlaidAccount />} />
      </Routes>
    </>
  );
};
