import { useEffect, useState, useRef } from "react";
import { Button, Card, FormControl, Offcanvas } from "react-bootstrap";
import "./Screens.css";
import { LeaseApplicationService, LeaseApplicationStatusTypesEnum } from "@propertelligent/client-api";
import CustomDropDownMenu from "../common/CustomDropDownMenu";
import { GetImages } from "../../utils/GetImages";
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton";
import AddButtonWithArrow from "../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import apiHelper from "../../utils/apiHelper";
import { useNavigate } from "react-router";

const ApprovedScreen = ({ leaseApplicationId, status, setShowModel }) => {
  const [interalTenant, setInteralTenant] = useState();
  const [noteTenant, setNoteTenant] = useState();
  const navigation = useNavigate()
  const handleChange = (e) => { setInteralTenant(e.target.value) };

  const handleTenantChange = (e) => { setNoteTenant(e.target.value) };

  const handleSubmit = () => {
    const dataToSend = {
      leaseApplicationStatusTypeId: status,
      internalNotes: interalTenant,
      tenantNotes: noteTenant
    };

    apiHelper(LeaseApplicationService.putApiLeaseApplicationReivew, { showNotification: false }, leaseApplicationId, dataToSend)
      .then((response) => {
        if (response?.data?.success) {
          setShowModel(false)
        }
      })
      .catch((error) => {
        console.error("Error toggling section:", error);
      });

  };

  const actions = [
    {
      id: 1,
      img: undefined,
      name: "Approve Only",
      onClick: handleSubmit,
      linkpath: undefined,
      textClass: 'sub_text fw-600 blue'

    },
    {
      id: 2,
      img: undefined,
      name: "Approve & Send lease agreement",
      onClick: () => { handleSubmit; },
      linkpath: undefined,
      textClass: 'sub_text fw-600 blue'
    },]

  return (
    <>
      <div className="approved-app-form">
        <div className="InnerBody">
          <p className="innerBodyTitle">INTERAL NOTES</p>
          <p className="mt-3 mb-2">This is internal notes and would not be sent to Tenat </p>
          <textarea
            className="w-100 mb-3"
            value={interalTenant}
            onChange={handleChange}
            rows={3}
            placeholder="Add note here..."
          ></textarea>
          <p className="innerBodyTitle">NOTES FOR TENANT</p>
          <p className="mt-3">This note will be sent to Tenant</p>
          <br />
          <textarea
            className="w-100 p-3"
            value={noteTenant}
            onChange={handleTenantChange}
            rows={3}
            placeholder="Add note here..."
          ></textarea>
        </div>
        <div className=" d-flex align-items-center px-4 gap-1 float-end">
          <AddTraspButton
            border={true}
            onClick={undefined}
            bname="cancel"
          />
          {status == "5" ? (
            <div className="actionsDropdownContainer clickable">
              <CustomDropDownMenu Action={true} Element={<img src={GetImages.BlueChevronDown} alt="BlueChevronDown" />} data={actions} />
            </div>
          ) :
            <AddButtonWithArrow className="px-5 bg-danger border-0" Icon={GetImages.ArrowForward} iconDirection={"left"} buttonname="Close Application" onClick={handleSubmit} />
          }
        </div>
      </div>
    </>
  );
};

export default ApprovedScreen;
