import { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import apiHelper from '../../utils/apiHelper';
import { LeaseApplicationStatusTypesEnum, TenantService } from '@propertelligent/client-api';
import BoardingReview from '../TenantOnboard/RenderSteps/BoardingReview';
import OrganizationNameHeader from '../common/OrganizationNameHeader/OrganizationNameHeader';
import CustomDropDownMenu from '../common/CustomDropDownMenu';
import { GetImages } from '../../utils/GetImages';
import RequestBreadcrumb from '../common/RequestBreadcrumb';
import { Offcanvas } from 'react-bootstrap';
import ApprovedScreen from './approved';
import RejectedScreen from './rejected';
import AddButtonWithArrow from '../firstTimeUserExperience/commonCom/AddButtonWithArrow';
import EditTenantForm from './EditTenantForm';
import LeaseAgreementSettings from './LeaseAgreement/LeaseAgreementSettings';

const ApplicationDetails = () => {
    const [userData, setUserData] = useState<any>(null);
    const [selectOption, setSelectOption] = useState("Approved");
    const [showModel, setShowModel] = useState(false);
    const [tenantEdit, setTenantEdit] = useState(false);
    const [initiateLeaseAgreement,setInitiateLeaseAgreement] =useState(false)
    const [previewLeaseAgreement,setPreviewLeaseAgreement] =useState(false)
    const { id } = useParams();
    const getTenantsData = async () => {
        const tenantsResponse = await apiHelper(TenantService.getApiTenant1, { showNotification: false }, Number(id));
        setUserData(tenantsResponse?.data?.success)
    };
    useEffect(() => { getTenantsData() }, [showModel, tenantEdit])
   
    const actions = [
        {
          id: 1,
          img: undefined,
          name: "Approve Application",
          status: [LeaseApplicationStatusTypesEnum.InReview, LeaseApplicationStatusTypesEnum.Submitted,],
          onClick: () => {
            setSelectOption("Approved");
            setShowModel(!showModel);
          },
          linkpath: undefined,
          textClass: 'sub_text fw-600 blue',
        },
        {
          id: 2,
          img: undefined,
          name: "Reject Application",
          status: [LeaseApplicationStatusTypesEnum.InReview,LeaseApplicationStatusTypesEnum.Submitted, ],
          onClick: () => {
            setSelectOption("Rejected");
            setShowModel(!showModel);
          },
          linkpath: undefined,
          textClass: 'sub_text fw-600 blue',
        },
        {
          id: 3,
          img: undefined,
          name: "Close Application",
          status: [
            LeaseApplicationStatusTypesEnum.InReview,
            LeaseApplicationStatusTypesEnum.PaymentPending,
            LeaseApplicationStatusTypesEnum.Invited,
            LeaseApplicationStatusTypesEnum.Submitted,
            LeaseApplicationStatusTypesEnum.InProgress,
          ],
          onClick: () => {
            setSelectOption("Closed");
            setShowModel(!showModel);
          },
          linkpath: undefined,
          textClass: 'sub_text fw-600 blue',
        },
        {
          id: 4,
          img: undefined,
          name: "Send Reminder",
          status: [LeaseApplicationStatusTypesEnum.Invited , LeaseApplicationStatusTypesEnum.PaymentPending,LeaseApplicationStatusTypesEnum.InProgress,],
          onClick: () => {
            /* your action */
          },
          linkpath: undefined,
          textClass: 'sub_text fw-600 blue',
        },
        {
          id: 5,
          img: undefined,
          name: "Print",
          status: [
            LeaseApplicationStatusTypesEnum.InProgress,
            LeaseApplicationStatusTypesEnum.PaymentPending,
            LeaseApplicationStatusTypesEnum.Submitted,
            LeaseApplicationStatusTypesEnum.Approved,
            LeaseApplicationStatusTypesEnum.Rejected,
            LeaseApplicationStatusTypesEnum.Closed,
            LeaseApplicationStatusTypesEnum.Withdrawn,
          ],
          onClick: () => {
            /* Print action */
          },
          linkpath: undefined,
          textClass: 'sub_text fw-600 blue',
        },
        {
          id: 6,
          img: undefined,
          name: "Save As PDF",
          status: [
          
            LeaseApplicationStatusTypesEnum.InProgress,
            LeaseApplicationStatusTypesEnum.PaymentPending,
            LeaseApplicationStatusTypesEnum.Submitted,
            LeaseApplicationStatusTypesEnum.Approved,
            LeaseApplicationStatusTypesEnum.Rejected,
            LeaseApplicationStatusTypesEnum.Closed,
            LeaseApplicationStatusTypesEnum.Withdrawn,
           
          ],
          onClick: () => {
            /* Save as PDF action */
          },
          linkpath: undefined,
          textClass: 'sub_text fw-600 blue',
        },
      ];
  
     const filterActions = actions.filter(action => action?.status?.includes(userData?.currentLeaseApplicationInfo?.leaseApplicationStatusTypeId))
   
    return (
        <>        
         <div className="report_fixed_header1 z-1">        
         <RequestBreadcrumb firstName={"Tenant"}  link={"/tenants"}  currentStep={previewLeaseAgreement ? "Lease Agreement Template" : "Tenant Detail"}/>
          </div>           
            <div className='outer-container1 p-0 mt-5 ' >
            {initiateLeaseAgreement  ?  <LeaseAgreementSettings setInitiateLeaseAgreement={setInitiateLeaseAgreement}  previewLeaseAgreement={previewLeaseAgreement} setPreviewLeaseAgreement ={setPreviewLeaseAgreement}/> : 
               <>
                <div className='onboarding-header px-3'>
                    <OrganizationNameHeader OrgName={"Application Details"} element={
                        <div className='d-flex gap-2'>
                            {userData?.currentLeaseApplicationInfo?.leaseApplicationStatusTypeId == LeaseApplicationStatusTypesEnum.Approved && (
                                <AddButtonWithArrow className="BackgroundlightBlue border-0" Icon={GetImages.ArrowForward} iconDirection={"left"}
                                    buttonname="Initiate Lease Agreement" onClick={()=>setInitiateLeaseAgreement(!initiateLeaseAgreement)} />
                            )}
                            <div className="actionsDropdownContainer clickable">
                                <CustomDropDownMenu Action={true} Element={<img src={GetImages.BlueChevronDown} alt="BlueChevronDown" />} data={filterActions} />
                            </div>
                        </div>}
                        headerNameClass="main_heading" OrgImg={GetImages.folder} SubHeaderName="" statusClass="blue BackgroundlightBlue p-1 m-1 sub_text fw-700" status={userData?.currentLeaseApplicationInfo?.formatted?.LeaseApplicationStatusTypeId} />
                </div>
                <div className="onboarding-stepper mh-100 px-3 w-100">
                    <BoardingReview landlord={true} leaseApplication={userData?.currentLeaseApplicationInfo?.id} editable={undefined} isEditClick={undefined} postData={undefined} setPostData={undefined} formData={undefined} setFormData={undefined} steps={undefined}
                        setTenantEdit={setTenantEdit}
                        tenantEdit={tenantEdit}
                    />
                </div>
                </>
            }
            </div>
            <div className='outer-container p-0 mt-5' >
                {initiateLeaseAgreement ? <LeaseAgreementSettings setInitiateLeaseAgreement={setInitiateLeaseAgreement} previewLeaseAgreement={undefined} setPreviewLeaseAgreement={undefined} /> :
                    <>
                        <div className='onboarding-header px-3'>
                            <OrganizationNameHeader OrgName={"Application Details"} element={
                                <div className='d-flex gap-2'>
                                    {userData?.currentLeaseApplicationInfo?.leaseApplicationStatusTypeId == LeaseApplicationStatusTypesEnum.Approved && (
                                        <AddButtonWithArrow className="BackgroundlightBlue border-0" Icon={GetImages.ArrowForward} iconDirection={"left"}
                                            buttonname="Initiate Lease Agreement" onClick={() => setInitiateLeaseAgreement(!initiateLeaseAgreement)} />
                                    )}
                                    <div className="actionsDropdownContainer clickable">
                                        <CustomDropDownMenu Action={true} Element={<img src={GetImages.BlueChevronDown} alt="BlueChevronDown" />} data={filterActions} />
                                    </div>
                                </div>}
                                headerNameClass="main_heading" OrgImg={GetImages.folder} SubHeaderName="" statusClass="blue BackgroundlightBlue p-1 m-1 sub_text fw-700" status={userData?.currentLeaseApplicationInfo?.formatted?.LeaseApplicationStatusTypeId} />
                        </div>
                        <div className="onboarding-stepper mh-100 px-3 w-100">
                            <BoardingReview landlord={true} leaseApplication={userData?.currentLeaseApplicationInfo?.id} editable={undefined} isEditClick={undefined} postData={undefined} setPostData={undefined} formData={undefined} setFormData={undefined} steps={undefined}
                                setTenantEdit={setTenantEdit}
                                tenantEdit={tenantEdit}
                            />
                        </div>
                    </>
                }
            </div>

            <Offcanvas
                className="offcanvas serviceRequestForm"
                show={showModel}
                onHide={() => setShowModel(false)}
                placement={"end"}
                scroll={true} >
                <Offcanvas.Header className="canvasHeader" closeButton>
                    <Offcanvas.Title>{selectOption} Application</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {selectOption == "Approved" ? (
                        <ApprovedScreen leaseApplicationId={userData?.currentLeaseApplicationInfo?.id} setShowModel={setShowModel} status={LeaseApplicationStatusTypesEnum.Approved} />
                    ) : selectOption === "Rejected" ? (
                        <RejectedScreen leaseApplicationId={userData?.currentLeaseApplicationInfo?.id} setShowModel={setShowModel} />
                    ) : selectOption === "Closed" ? (
                        <ApprovedScreen leaseApplicationId={userData?.currentLeaseApplicationInfo?.id} setShowModel={setShowModel} status={LeaseApplicationStatusTypesEnum.Closed} />
                    ) : (<p>Unknown status.</p>)}
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas
                className="offcanvas serviceRequestForm"
                show={tenantEdit}
                onHide={() => setTenantEdit(false)}
                placement={"end"}
                scroll={true} >
                <Offcanvas.Header className="canvasHeader" closeButton>
                    <Offcanvas.Title>Edit Tenant</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <EditTenantForm id={userData?.id} setShowModal={setTenantEdit} />
                </Offcanvas.Body>
            </Offcanvas>


        </>
    )
}

export default ApplicationDetails