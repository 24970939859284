import TemplateMainHeader from '../../Templete/CommonComp/TemplateMainHeader'
import BasicDetailsForm from './BasicDetailsForm'
import AddressAutocomplete from '../../../firstTimeUserExperience/commonCom/AddressAutocomplete'
import OfficeAddressForm from './OfficeAddressForm'

const FormSections = ({
    formDetails,
    handleChangeMainImage = (val: any) => { },
    handleDelete = (val: any) => { },
    mainImage = undefined,
    setMainImage = (val: any) => { },
    setFileName = (val: any) => { },
    handleChange,
    headerName,
    isUploader,
    isBasicDetail = false,
    propertyValue = undefined,
    setPropertyvalue = undefined,
    isAdreesSection = false
}) => {


    return (
        <div className='mb-3'>
            <TemplateMainHeader
                searchTerm={undefined}
                placeholder={undefined}
                setSearchTerm={undefined}
                headerName={headerName}
            />
            <div className="basicDetailsContainer">

                {isAdreesSection &&
                    <div className='ms-2'>
                        <AddressAutocomplete
                            propertyValue={propertyValue}
                            setPropertyvalue={setPropertyvalue}
                            required={false}
                            showSelectedValue={false}
                        />
                    </div>
                }

                <div className="basicDetailsForm">
                    {isAdreesSection ? (
                        <OfficeAddressForm
                            formDetails={formDetails}
                            handleChange={handleChange}
                        />
                    ) : (
                        <BasicDetailsForm
                            formDetails={formDetails}
                            handleChangeMainImage={handleChangeMainImage}
                            handleDelete={handleDelete}
                            mainImage={mainImage}
                            setMainImage={setMainImage}
                            setFileName={setFileName}
                            handleChange={handleChange}
                            isUploader={isUploader}
                            isBasicDetail={isBasicDetail}
                        />
                    )}

                </div>
            </div>
        </div>
    )
}

export default FormSections