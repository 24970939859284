import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Col, Form, Row } from "react-bootstrap";
import {
  DocumentEntityTypesEnum,
  DocumentService,
  DocumentTypesEnum,
  UnitService,
  UnitVM,
} from "@propertelligent/client-api";
import AddTraspButton from "./commonCom/AddTraspButton";
import RadioButton from "../common/RadioButton";
import { getTenantList } from "../../redux/actions/unitAction";
import { GetImages } from "../../utils/GetImages";
import AddButtonWithArrow from "./commonCom/AddButtonWithArrow";
import Uploader from "../common/CustomUploader/Uploader";
import { CustomInput } from "../common/formControls";
import { FORM_CONTROLFIELD_TYPES } from "../../constants/constants";
import CounterBox from "./commonCom/IncDecBox";
import Delete from "../../assets/Delete.png";
import Replace from "../../assets/Replace.png";
import apiHelper from "../../utils/apiHelper";
import { useError } from "../../utils/context/ErrorContext";
import {
  validateRequired,
  validateUnitNumber,

  validateUnitNumberSize,
} from "../../utils/validateHelper";
import CustomInputs from "../common/Inputs";

const UnitTanentForm = ({
  id,
  setShowModel,
  unitId,
  isEdit,
  setISEdit,
  canvasbody,
}) => {
  const [fileName, setFileName] = useState("");
  const [mainImage, setMainImage] = useState(null);
  const [validated, setValidated] = useState(false);
  const [unitval, setUnitVal] = useState<UnitVM | any>({ propertyId: id });
  const [progress, setProgress] = useState(0);
  const location = useLocation();
  const dispatch: any = useDispatch();
  const { unitList } = useSelector((state: any) => state.unitList);

  const { errors, errorMessages, setErrors, setErrorMessages, clearErrors } =
    useError();

  const fetchUnitList = async () => {
    await UnitService.getApiUnitPropertylist(Number(id));
  };

  useMemo(() => { }, [unitList?.length, isEdit, unitId]);

  useEffect(() => {
    if (isEdit === true) {
      apiHelper(UnitService.getApiUnit1, { showNotification: false }, unitId)
        .then(async (unitDataRes: any) => {
          setUnitVal(unitDataRes?.data.success);

          if (unitDataRes?.success?.mainImageId !== 0) {
            apiHelper(
              DocumentService.getApiDocumentBase641,
              { showNotification: false },
              unitDataRes?.data.success?.mainImageId
            )
              .then((imageResponse: any) => {
                const imageData =
                  "data:image/*;base64," + imageResponse?.success;
                setMainImage(imageData);
              })
              .catch((imageErr: any) => { });
          }
        })
        .catch((unitErr: any) => { });
    } else {
      setUnitVal({
        propertyId: id,
        unitNumber: "",
        size: "",
        unitTypeId: "",
        mainImageId: 0,
        numberOfBathrooms: 0,
        numberOfBedrooms: 0,
        isBathAttached: false,
      });
      setMainImage("");
      setFileName("");
    }
  }, [unitId, isEdit, unitval?.mainImageId, unitList?.length, id]);

  const handleOnChange = (e) => {
    setUnitVal({ ...unitval, [e.target.name]: e.target.value });
    clearErrors();
  };

  const checkEmptyFields = () => {
    clearErrors();
    const newErrors = { ...errors };
    const newErrorMessages = { ...errorMessages };

    // Checking each field for emptiness and setting appropriate error messages
    if (!unitval.unitNumber) {
      newErrors.unitNumber = true;
      newErrorMessages.unitNumber = "Unit number is required";
    } else {
      newErrors.unitNumber = false;
    }

    if (!unitval.size) {
      newErrors.unitNumberSize = true;
      newErrorMessages.unitNumberSize = "Size is required";
    } else {
      newErrors.unitNumberSize = false;
    }

    if (!unitval.unitTypeId) {
      newErrors.unitTypeId = true;
      newErrorMessages.unitTypeId = "Unit type is required";
    } else {
      newErrors.unitTypeId = false;
    }

    setErrors(newErrors);
    setErrorMessages(newErrorMessages);

    return Object.values(newErrors).some((error) => error);
  };

  useEffect(() => {
    if (checkEmptyFields()) {
      clearErrors()
    }
  }, [])

  const resetForm = () => {
    // Reset form values
    setUnitVal({
      unitNumber: "",
      size: "",
      unitTypeId: "",
      numberOfBathrooms: "",
      numberOfBedrooms: "",
      isBathAttached: false,
    });
    setMainImage("");
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    checkEmptyFields();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      validateUnitNumber(form.value, setErrors, setErrorMessages);
      validateUnitNumberSize(form.value, setErrors, setErrorMessages);
      return;
    } else {
      if (!isEdit) {
        const response = await apiHelper(
          UnitService.postApiUnit,
          {
            successMessage: "Unit added successfully",
            failureMessage: "Unable to add unit",
            showNotification: true,
          },
          unitval
        );

        if (mainImage) {
          const imgData = {
            EntityTypeId: DocumentEntityTypesEnum.Unit,
            EntityId: Number(response?.data?.success?.id),
            DocumentTypeId: DocumentTypesEnum.Other,
            IsMain: true,
            Document: mainImage,
            FileName: fileName,
          };
          await apiHelper(
            DocumentService.postApiDocument,
            { showNotification: false },
            imgData
          );
        }
        resetForm();
        clearErrors();
        setTimeout(() => {
          setShowModel(false);
        }, 500);

        dispatch(getTenantList(id));
        fetchUnitList();
        setValidated(false);
      } else {
        const unitData = {
          id: unitval.id,
          propertyId: unitval.propertyId,
          unitNumber: unitval.unitNumber,
          unitTypeId: unitval.unitTypeId,
          size: unitval.size,
          numberOfBedrooms: unitval.numberOfBedrooms,
          numberOfBathrooms: unitval.numberOfBathrooms,
          isBathAttached: unitval.isBathAttached,
        };

        apiHelper(
          UnitService.putApiUnit,
          {
            successMessage: "Unit Updated Successfully",
            failureMessage: "Unable to update unit",
            showNotification: true,
          },
          unitData
        ).then((res: any) => {
          if (res.success) {
            setShowModel(false);
            setTimeout(() => {
              setISEdit(false);
            }, 1000);
          }
        });
      }
    }
  };
  const handleDelete = () => {
    setProgress(0);
    if (!isEdit) {
      setMainImage(null);
      setFileName(null);
      dispatch(getTenantList(id));
    } else {
      apiHelper(
        DocumentService.deleteApiDocument,
        { showNotification: false },
        unitval?.mainImageId
      )
        .then((res) => {
          setMainImage(null);
          setFileName(null);

          dispatch(getTenantList(id));
        })
        .catch((error) => { });
    }
  };

  const handleDeleteUnit = () => {
    try {
      apiHelper(
        UnitService.deleteApiUnit,
        {
          successMessage: "Unit Deleted Successfully",
          failureMessage: "Unable to delete unit",
          showNotification: true,
        },
        unitId
      ).then((res) => {
        if (res?.data?.success) {
          setShowModel(false);
          setTimeout(() => {
            setISEdit(false);
          }, 1000);
          resetForm();
          dispatch(getTenantList(id));
        }
      });
    } catch (error) { }
  };

  const handleReplace = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
  };

  return (
    <>
      {!isEdit ? (
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => handleSubmit(e)}
          style={{ marginBottom: "50px" }}
          className={`${canvasbody == true ? "unitTanentCanForm" : " unitTanentForm m-3"
            }`}
        >
          <div
            className={`${canvasbody == true
              ? "uploader-add-tenant p-3"
              : " uploader-add-tenant  "
              }`}
          >
            <Uploader
              name="Add Image"
              setMainImage={setMainImage}
              mainImage={mainImage}
              setFileName={setFileName}
              progress={progress}
              setProgress={setProgress}
              Size={{
                size: "Icon80",
                iconSize: "Icon11",
                pSize: 30,
                uploadimage: "iconstyle10",
              }}
            />

            <div>
              {mainImage && (
                <>
                  <div className="deletereplacemain">
                    <div
                      onChange={(e) => handleReplace(e)}
                      className="deletereplace"
                    >
                      <img src={GetImages.BlueReplace} className="iconstyle" />
                      <p className="replace">Replace</p>
                    </div>

                    <div className="divider"></div>
                    <div onClick={handleDelete} className="deletereplace">
                      <img
                        src={GetImages.deleteIconRed}
                        alt={GetImages.deleteIconRed}
                        className="iconstyle"
                      />
                      <p className="delete">Delete</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="inputParentBox p-3">
            <Form.Group>
              <Form.Label className="normal_text grey_subtext1">
                Select Unit Type *
              </Form.Label>

              <Form.Select
                className="selectTagAddUnitTenant normal_text grey_subtext1"
                required
                value={unitval?.unitTypeId || ""}
                onChange={(e) =>
                  setUnitVal({
                    ...unitval,
                    unitTypeId: Number(e.target.value),
                  })
                }
                placeholder="Select Unit Type"
              >
                <option className="normal_text grey_subtext1" value="">
                  Select Unit Type
                </option>
                <option className="normal_text grey_subtext1" value={1}>
                  Condominium
                </option>
                <option className="normal_text grey_subtext1" value={3}>
                  Room
                </option>
                <option className="normal_text grey_subtext1" value={2}>
                  Studio
                </option>
              </Form.Select>
              <Form.Control.Feedback
                type="invalid"
                className="invalid-feedback"
              >
                Unit Type is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div
            className={`${canvasbody == true ? "parentDiv flex-column gap-2" : " parentDiv"
              }`}
          >
            <div className="inputParentBox p-3">
              <Form.Group>
                <CustomInputs
                  labelText="Unit Number"
                  name="unitNumber"
                  isFormControl
                  type={FORM_CONTROLFIELD_TYPES.TEXT}
                  value={unitval?.unitNumber || ""}
                  required
                  onChange={(e) => {
                    const value = e.target.value;
                    handleOnChange(e);
                    setErrors((prev) => ({ ...prev, unitNumber: false }));
                    setErrorMessages((prev) => ({ ...prev, unitNumber: "" }));
                    validateUnitNumber(
                      unitval?.unitNumber,
                      setErrors,
                      setErrorMessages
                    );
                  }}
                  onBlur={() => {
                    validateUnitNumber(
                      unitval.unitNumber,
                      setErrors,
                      setErrorMessages
                    );
                  }}
                  placeholder="Enter Unit Number"
                  myClassName="containerTextField"
                  isError={errors.unitNumber}
                  errorMessages={errorMessages.unitNumber}
                />
              </Form.Group>
            </div>
            <div className="inputParentBox p-3">
              <Form.Group>
                <CustomInputs
                  labelText="Area Sqft"
                  name={"size"}
                  isFormControl={true}
                  value={unitval?.size}
                  required
                  type={FORM_CONTROLFIELD_TYPES.NUMBER}
                  onChange={(e) => {
                    const inputValue = e?.target?.value;

                    if (parseFloat(inputValue) < 0) {
                      return;
                    }
                    setUnitVal({ ...unitval, size: parseInt(inputValue) });
                    setErrors((prev) => ({ ...prev, unitNumberSize: false }));
                    setErrorMessages((prev) => ({
                      ...prev,
                      unitNumberSize: "",
                    }));
                    validateUnitNumberSize(
                      unitval?.size,
                      setErrors,
                      setErrorMessages
                    );
                  }}
                  placeholder="Enter Unit Size in Sqft"
                  myClassName="containerTextField"
                  onBlur={() => {
                    validateUnitNumberSize(
                      unitval?.size,
                      setErrors,
                      setErrorMessages
                    );
                  }}
                  isError={errors.unitNumberSize}
                  errorMessages={errorMessages.unitNumberSize}
                />
              </Form.Group>
            </div>
          </div>

          {unitval.unitTypeId === 1 ? (
            <>
              <div
                className={`${canvasbody == true
                  ? "CondominiumSection p-3"
                  : " CondominiumSection "
                  }`}
              >
                <div className="condominium">
                  <div className="bedroom">
                    <p className="normal_text grey_subtext1">Bedroom</p>
                    <CounterBox
                      unitval={unitval}
                      setUnitVal={setUnitVal}
                      type="bedroom"
                      initialValue={unitval.numberOfBedrooms}
                    />
                  </div>
                  <div className="bathroom">
                    <p className="normal_text grey_subtext1">Bathroom</p>
                    <CounterBox
                      unitval={unitval}
                      setUnitVal={setUnitVal}
                      type="bathroom"
                      initialValue={unitval.numberOfBathrooms}
                    />
                    <div></div>
                  </div>
                </div>
              </div>
            </>
          ) : unitval.unitTypeId === 3 ? (
            <div
              className={`${canvasbody == true ? "parentDiv p-3" : " parentDiv "
                }`}
            >
              <div className="inputParentBox">
                <p className="normal_text grey_subtext1">Bathrooms</p>
                <div className="paymentRadioButton">
                  <RadioButton
                    label="Attached"
                    name="isBathAttached"
                    value={unitval.isBathAttached}
                    checked={unitval.isBathAttached === true}
                    onChange={(e) => {
                      setUnitVal({
                        ...unitval,
                        isBathAttached: Boolean(true),
                      });
                    }}
                  />
                  <RadioButton
                    label="Common"
                    name="isBathAttached"
                    value={unitval?.isBathAttached}
                    checked={unitval?.isBathAttached === false}
                    onChange={(e) => {
                      setUnitVal({
                        ...unitval,
                        isBathAttached: Boolean(false),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ) : unitval.unitTypeId === 2 ? (
            ""
          ) : (
            ""
          )}

          <div className="editFormFooter start-0">
            <AddTraspButton
              bname="Cancel"
              iconPosition={undefined}
              imageValue={undefined}
              onClick={() => setShowModel(false)}
              border={false}
            />

            <AddButtonWithArrow
              buttonname="Add Unit"
              disabled={undefined}
              onClick={undefined}
              iconDirection="left"
              Icon={GetImages.WhiteForwardArrow}
            />
          </div>
        </Form>
      ) : (
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => handleSubmit(e)}
          className="unitTanentForm m-3"
        >
          <div className="uploader-add-tenant">
            {/* <Form.Group> */}
            <Uploader
              name="Add Image"
              setMainImage={setMainImage}
              mainImage={mainImage}
              setFileName={setFileName}
              progress={progress}
              setProgress={setProgress}
              Size={{
                size: "Icon80",
                iconSize: "Icon11",
                pSize: 30,
                uploadimage: "iconstyle10",
              }}
            />
            <div>
              {mainImage && (
                <>
                  <div className="deletereplacemain">
                    <div
                      onClick={() => setFileName(null)}
                      className="deletereplace"
                    >
                      <img src={Replace} className="iconstyle" />
                      <p className="replace">Replace</p>
                    </div>

                    <div className="divider"></div>
                    <div onClick={handleDelete} className="deletereplace">
                      <img src={Delete} className="iconstyle" />
                      <p className="delete">Delete</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <Row>
            <Col className="inputParentBox">
              <Form.Group>
                <Form.Label className="normal_text grey_subtext1">
                  Select Unit Type *
                </Form.Label>
                <Form.Select
                  className="selectTagAddUnitTenant "
                  required
                  name="unitTypeId"
                  value={unitval?.unitTypeId || ""}
                  onChange={(e) =>
                    setUnitVal({
                      ...unitval,
                      unitTypeId: Number(e.target.value),
                    })
                  }
                  placeholder="Select Unit Type"
                >
                  <option value="">Select unit type</option>
                  <option value={1}>Condominium</option>
                  <option value={3}>Room</option>
                  <option value={2}>Studio</option>
                </Form.Select>
                <Form.Control.Feedback
                  type="invalid"
                  className="invalid-feedback"
                >
                  This field is require
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="parentDiv d-flex flex-column gap-2">
            <Col className="inputParentBox">
              <Form.Group>
                <CustomInputs
                  labelText="Unit Number"
                  name="unitNumber"
                  isFormControl
                  type={FORM_CONTROLFIELD_TYPES.TEXT}
                  value={unitval?.unitNumber || ""}
                  required
                  onChange={(e) => {
                    const value = e.target.value;
                    handleOnChange(e);
                    setErrors((prev) => ({ ...prev, unitNumber: false }));
                    setErrorMessages((prev) => ({ ...prev, unitNumber: "" }));
                    validateUnitNumber(
                      unitval?.unitNumber,
                      setErrors,
                      setErrorMessages
                    );
                  }}
                  onBlur={() => {
                    validateUnitNumber(
                      unitval.unitNumber,
                      setErrors,
                      setErrorMessages
                    );
                  }}
                  placeholder="Enter Unit Number"
                  myClassName="containerTextField"
                  isError={errors.unitNumber}
                  errorMessages={errorMessages.unitNumber}
                />
              </Form.Group>
            </Col>
            <Col className="inputParentBox p-3">
              <Form.Group>
                <CustomInputs
                  labelText="Area sq. ft"
                  name={"size"}
                  isFormControl={true}
                  value={unitval?.size}
                  required
                  type={FORM_CONTROLFIELD_TYPES.NUMBER}
                  onChange={(e) => {
                    const inputValue = e?.target?.value;

                    if (parseFloat(inputValue) < 0) {
                      return;
                    }
                    setUnitVal({ ...unitval, size: parseInt(inputValue) });
                    setErrors((prev) => ({ ...prev, unitNumberSize: false }));
                    setErrorMessages((prev) => ({
                      ...prev,
                      unitNumberSize: "",
                    }));
                  }}
                  placeholder="Area in Square Feet"
                  myClassName="containerTextField"
                  onBlur={() =>
                    validateUnitNumberSize(
                      unitval?.size,
                      setErrors,
                      setErrorMessages
                    )
                  }
                  isError={errors.unitNumberSize}
                  errorMessages={errorMessages.unitNumberSize}
                />
              </Form.Group>
            </Col>
          </Row>

          {unitval.unitTypeId === 1 ? (
            <>
              <div className="CondominiumSection">
                <div className="condominium ">
                  <div className=" bathroom">
                    <p className="   normal_text grey_subtext1">Bathroom</p>
                    <CounterBox
                      unitval={unitval}
                      setUnitVal={setUnitVal}
                      type="bathroom"
                      initialValue={unitval.numberOfBathrooms}
                    />
                  </div>
                  <div className="bedroom">
                    <p className="normal_text grey_subtext1">Bedroom</p>
                    <CounterBox
                      unitval={unitval}
                      setUnitVal={setUnitVal}
                      type="bedroom"
                      initialValue={unitval?.numberOfBedrooms}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : unitval.unitTypeId === 3 ? (
            <div className="parentDiv">
              <div className="inputParentBox p-3">
                <p className="normal_text grey_subtext1">Bathrooms</p>
                <div className="paymentRadioButton">
                  <RadioButton
                    label="Attached"
                    name="isBathAttached"
                    value={unitval.isBathAttached}
                    checked={unitval.isBathAttached === true}
                    onChange={(e) => {
                      setUnitVal({
                        ...unitval,
                        isBathAttached: Boolean(true),
                      });
                    }}
                  />
                  <RadioButton
                    label="Common"
                    name="isBathAttached"
                    value={unitval?.isBathAttached}
                    checked={unitval?.isBathAttached === false}
                    onChange={(e) => {
                      setUnitVal({
                        ...unitval,
                        isBathAttached: Boolean(false),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ) : unitval.unitTypeId === 2 ? (
            <div className="parentDiv">
              <p className="normal_text grey_subtext1"> Studio</p>
            </div>
          ) : (
            ""
          )}

          <div className="editFormFooter start-0">
            <div
              className="deletereplace"
              style={{ position: "absolute", left: "12px" }}
              onClick={handleDeleteUnit}
            >
              <img src={Delete} className="iconstyle" />
              <p className="delete">Delete</p>
            </div>

            <AddTraspButton
              bname="Cancel"
              iconPosition={undefined}
              imageValue={undefined}
              onClick={() => setISEdit(false)}
              border={false}
            />

            <AddButtonWithArrow
              buttonname="Update details"
              disabled={undefined}
              onClick={undefined}
              iconDirection="left"
              Icon={GetImages.WhiteForwardArrow}
            />
          </div>
        </Form>
      )}
    </>
  );
};

export default UnitTanentForm;
