import './topFixedSubBar.css';
import { GetImages } from '../../../utils/GetImages';

const TopFixedSubBar = ({ currentSortDirection,
  handleSort, isSelectDropdown = false }) => {

  return (
    <>
      <div className="subTopBarContainer" style={{ marginTop: isSelectDropdown ? "0" : "20px" }}>
        <div className="subTopBarSortButton" onClick={() => handleSort()}>
          <img src={GetImages.sort} />
          <p className="normal_text">
            {currentSortDirection === "asc" ? "Sort by: A-Z" : "Sort by: Z-A"}
          </p>
        </div>
      </div>
    </>
  );
};

export default TopFixedSubBar;
